import React from 'react'
import {Typography} from '@mui/material'

export interface HighlightProps {
  // text to be highlighted
  text: string
  // text with words contacted with separator
  highlight?: string
  // split highlight props to separated words *default " "
  separator?: string
}

/**
 * All words in "highlight" will be bolded in "text"
 */
export default class Highlight extends React.Component<HighlightProps> {
  render() {
    if (!this.props.highlight) {
      // Empty or undefined string
      return this.props.text
    }
    if (new RegExp(/^\s+$/g).test(this.props.highlight ?? '')) {
      // String contains only white spaces
      return this.props.text
    }

    const separator = this.props.separator ?? ' '
    const search = this.props.highlight
      .replace(/^\s+|\s+$/g, '') // remove white space at the beginning and ending
      .replace(/\s+/g, ' ') // remove additional white space in the middle
      .split(separator)
      .map(
        (w) =>
          `(^|\\s|-)${w.replace(
            /[.*+?^${}()|[\]\\]/g,
            '\\$&'
          )}[^\\s\\-]*(?=-|\\s|$)`
      )
      .join('|') // match word till the end

    // check if current word exist in search query
    const hasMatch = (str: string) => {
      const length = str.match(new RegExp(`(${search})`, 'gi'))?.length ?? 0
      return length > 0
    }

    // remove all empty and undefined string from text
    const parts = this.props.text
      .split(new RegExp(`(${search})`, 'gi'))
      .filter((w) => w !== undefined && w !== '')

    // highlight matches
    return (
      <Typography variant={'inherit'}>
        {' '}
        {parts.map((part, i) => (
          <span key={i} style={hasMatch(part) ? {fontWeight: 'bold'} : {}}>
            {part}
          </span>
        ))}{' '}
      </Typography>
    )
  }
}
