import {
  EndpointRequest,
  PostResponseLocation
} from 'web-common/services/request/Request'
import {AxiosRequestConfig} from 'axios'

interface RequireToken {
  requireToken?: boolean
}

interface ToString {
  toString: (...args: string[]) => string
}

interface ENDPOINT_UTILS extends ToString, RequireToken {
  isTokenRequired?: () => boolean

  get: <T>(
    r: EndpointRequest,
    ...params: string[]
  ) => (config?: AxiosRequestConfig) => Promise<T>
  delete: <T>(
    r: EndpointRequest,
    ...params: string[]
  ) => (config?: AxiosRequestConfig) => Promise<T>
  post: <T>(
    r: EndpointRequest,
    ...params: string[]
  ) => (data?: any, config?: AxiosRequestConfig) => Promise<T>
  patch: <T>(
    r: EndpointRequest,
    ...params: string[]
  ) => (data?: any, config?: AxiosRequestConfig) => Promise<T>
  put: <T>(
    r: EndpointRequest,
    ...params: string[]
  ) => (data?: any, config?: AxiosRequestConfig) => Promise<T>
  postAndGetLocation: <T>(
    r: EndpointRequest,
    ...params: string[]
  ) => (
    data?: any,
    config?: AxiosRequestConfig
  ) => Promise<PostResponseLocation<T>>
}

// USED FOR THIS CLASS ONLY
type RECURSIVE<T, D> = {[K in keyof T]: RECURSIVE<T[K], D>} & D
type ENDPOINT<T> = RECURSIVE<T, ENDPOINT_UTILS>

// USED FOR REQUEST CLASS AS PATH PARAM
export type PATH = RECURSIVE<{}, ENDPOINT_UTILS>

// USED ONLY FOR CREATING API PATH CONFIG
export type PATH_CONFIG<T> = ToString & RECURSIVE<T, any>

export class ApiConfig<T> {
  public paths!: ENDPOINT<T>

  constructor(private rawPaths: PATH_CONFIG<T>) {
    if (rawPaths.toString === undefined) {
      throw new Error('Paths missing root "toString" method')
    }
    const paths = {}
    this.clone(rawPaths, paths)
    this.modifyPaths(paths as ENDPOINT<T>)
    this.paths = paths as ENDPOINT<T>
  }

  private clone(source: any, target: any) {
    Object.keys(source).forEach((key) => {
      if (!target[key]) {
        target[key] = {}
      }
      if (typeof source[key] === 'function') {
        target[key] = source[key].bind(null)
      } else if (
        typeof source[key] === 'string' ||
        typeof source[key] === 'boolean'
      ) {
        target[key] = source[key]
      } else {
        this.clone(source[key], target[key])
      }
    })
  }

  protected modifyPaths(data: ENDPOINT<any>) {
    Object.keys(data)
      .filter((path) => path !== 'toString' && path !== 'requireToken')
      .forEach((path) => {
        this.modifyPaths(data[path])
        data[path].toString = this.generateToString(data, path)
        data[path].isTokenRequired = this.generateITokenRequired(data, path)
        data[path].get = this.generateGetQuery(data, path)
        data[path].delete = this.generateDeleteQuery(data, path)
        data[path].post = this.generatePostQuery(data, path)
        data[path].patch = this.generatePatchQuery(data, path)
        data[path].put = this.generatePutQuery(data, path)
        data[path].postAndGetLocation = this.generatePostAndGetLocationQuery(
          data,
          path
        )
      })
  }

  public generateGetQuery<D>(data: ENDPOINT<any>, path: string) {
    return (r: EndpointRequest, ...params: string[]) =>
      (config?: AxiosRequestConfig) =>
        r.get<D>(data[path], config, ...params)
  }

  public generateDeleteQuery<D>(data: ENDPOINT<any>, path: string) {
    return (r: EndpointRequest, ...params: string[]) =>
      (config?: AxiosRequestConfig) =>
        r.delete<D>(data[path], config, ...params)
  }

  public generatePostQuery<D>(data: ENDPOINT<any>, path: string) {
    return (r: EndpointRequest, ...params: string[]) =>
      (model?: any, config?: AxiosRequestConfig) =>
        r.post<D>(data[path], model, config, ...params)
  }

  public generatePostAndGetLocationQuery<D>(data: ENDPOINT<any>, path: string) {
    return (r: EndpointRequest, ...params: string[]) =>
      (model?: any, config?: AxiosRequestConfig) =>
        r.postAndGetLocation<D>(data[path], model, config, ...params)
  }

  public generatePatchQuery<D>(data: ENDPOINT<any>, path: string) {
    return (r: EndpointRequest, ...params: string[]) =>
      (model?: any, config?: AxiosRequestConfig) =>
        r.patch<D>(data[path], model, config, ...params)
  }

  public generatePutQuery<D>(data: ENDPOINT<any>, path: string) {
    return (r: EndpointRequest, ...params: string[]) =>
      (model?: any, config?: AxiosRequestConfig) =>
        r.put<D>(data[path], model, config, ...params)
  }

  public generateToString(data: ENDPOINT<any>, path: string) {
    let overrideBaseURL: string | undefined
    if (
      data.toString() === '' &&
      this.rawPaths[path].hasOwnProperty('toString')
    ) {
      overrideBaseURL = (this.rawPaths as any)[path].toString()
    }
    return (...args: string[]) => {
      const b = overrideBaseURL ? overrideBaseURL : data.toString()
      let fullPath = b + '/' + path
      if (args.length > 0) {
        fullPath = fullPath.replace(/\/\*/gi, () => '/' + (args.shift() ?? ''))
        fullPath = fullPath.replace(/(\/\*\?)/gi, '')
      }
      return fullPath
    }
  }

  public generateITokenRequired(data: ENDPOINT<any>, path: string) {
    return () =>
      data[path].requireToken ??
      data?.requireToken ??
      data.isTokenRequired?.call(this) ??
      false
  }

  public doesPathRequireToken(url: string): boolean {
    // GET all available roots *toString* from the rawPaths
    // This is required in order to match the requireToken properly
    let cleanURL = url.replace(this.rawPaths.toString(), '')
    Object.keys(this.rawPaths).forEach((path) => {
      if ((this.rawPaths as any)[path].hasOwnProperty('toString')) {
        cleanURL = cleanURL.replace(
          ((this.rawPaths as any)[path] as any).toString(),
          ''
        )
      }
    })
    const paths = cleanURL.split('/').filter((path) => !!path)
    const match: ENDPOINT<any> = paths.reduce(
      (prev: ENDPOINT<any>, curr) => prev && (prev[curr] || prev['*']),
      this.paths
    )
    return match?.isTokenRequired?.call(this) ?? false
  }
}
