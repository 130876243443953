import {Card, styled} from '@mui/material'

export const BlueCard = styled(Card)((props) => ({
  border: `1px solid ${props.theme.palette.grey[300]}`,
  '.MuiCardHeader-root': {
    background: props.theme.palette.primary.dark,
    color: props.theme.palette.common.white
  },
  '.MuiCardContent-root': {
    background: props.theme.palette.primary.dark,
    color: props.theme.palette.common.white,
    paddingBottom: '1rem'
  }
}))
