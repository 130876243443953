import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {AppConfig} from 'AppConfig'

export interface RegistrationTriggerData {
  [lang: string]: {
    triggers: string[]
  }
}

const paths = {
  public: {
    triggers: {
      wa: {
        registration: {
          '*': {}
        }
      }
    }
  },
  requireToken: false,
  toString: () => AppConfig.api.notifications
}

class WhatsAppRegistrationGenericService extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: WhatsAppRegistrationGenericService

  static shared() {
    if (!WhatsAppRegistrationGenericService.instance) {
      WhatsAppRegistrationGenericService.instance =
        new WhatsAppRegistrationGenericService()
    }
    return WhatsAppRegistrationGenericService.instance
  }

  get(registrationType: string) {
    return this.api.paths.public.triggers.wa.registration[
      '*'
    ].get<RegistrationTriggerData>(this.request, registrationType)()
  }
}

export default WhatsAppRegistrationGenericService.shared()
