import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementTimePicker} from '@fnd/survey'
import TimepickerWrapper from 'web-common/components/survey/ui/elements/helpers/TimepickerWrapper'

export default function SurveyElementUITimePicker({
  field,
  element
}: SurveyFactoryElementBaseProps<SurveyElementTimePicker>) {
  return <TimepickerWrapper {...field} element={element} />
}
