import React from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded'
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded'
import {WithTranslation, withTranslation} from 'react-i18next'

interface ArrowProps extends WithTranslation {
  direction: 'left' | 'right'
}

interface ArrowState {}

class Arrow extends React.Component<ArrowProps, ArrowState> {
  render() {
    const props: any = {fontSize: 'inherit', color: 'inherit'}
    let direction = this.props.direction
    if (this.props.i18n.dir() === 'rtl') {
      direction = this.props.direction === 'left' ? 'right' : 'left'
    }
    return direction === 'left' ? (
      <ChevronLeftIcon {...props} />
    ) : (
      <ChevronRightIcon {...props} />
    )
  }
}

export default withTranslation()(Arrow)
