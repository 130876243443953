import {
  ChangeEvent,
  forwardRef,
  startTransition,
  useContext,
  useEffect,
  useState
} from 'react'
import {ControllerRenderProps} from 'react-hook-form/dist/types/controller'
import {
  SurveyElementTimePicker,
  SurveyElementTimePickerRange
} from '@fnd/survey'
import {
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack
} from '@mui/material'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'
import {useTranslation} from 'react-i18next'
import {FieldValues} from 'react-hook-form'

export interface TimepickerWrapperProps extends ControllerRenderProps {
  element: SurveyElementTimePicker
}

function rangeToValue(range: SurveyElementTimePickerRange) {
  if (range.length !== 2) return '00-00'
  return range.join('-')
}

function valueToRange(value: string) {
  if (value === 'other') {
    return value
  }
  return value
    .split('-')
    .map((n) => parseInt(n)) as unknown as SurveyElementTimePickerRange
}

function numberToTime(n: number) {
  return [
    Math.floor((n / 3600) % 24)
      .toString()
      .padStart(2, '0'),
    Math.floor((n / 60) % 60)
      .toString()
      .padStart(2, '0')
  ].join(':')
}

function rangeToTime(num: SurveyElementTimePickerRange) {
  if (num.length !== 2) {
    return [numberToTime(0), numberToTime(0)]
  }
  return num.map(numberToTime)
}

interface SurveyElementUIOtherProps {
  field: FieldValues
  element: SurveyElementTimePicker
}

function SurveyElementUIOther(props: SurveyElementUIOtherProps) {
  const step = 3600
  if (!props.element.otherValue || props.element.otherValue.length !== 2) {
    props.element.otherValue = [0, step]
  }
  const [t] = useTranslation()
  const [from, setFrom] = useState(props.element.otherValue[0])
  const [to, setTo] = useState(props.element.otherValue[1])

  /**
   *  Before unmount component check if "other" is unchecked and clear its value
   */
  useEffect(() => {
    return () => {
      if (!props.field.value?.includes('other')) {
        delete props.element.otherValue
      }
    }
  })

  // Generate dropdown values
  const generateValues = (type: 'from' | 'to'): number[] => {
    return Array(24)
      .fill(0)
      .map((_, i) => (i + (type === 'from' ? 0 : 1)) * step)
  }

  // When picking FROM value, automatically change the TO value with +1/step/
  const onSetFrom = (e: SelectChangeEvent<number>) => {
    const start = +e.target.value
    const end = start >= to ? start + step : to
    setFrom(start)
    setTo(end)
    props.element.otherValue = [start, end]
  }

  // When picking TO value, automatically change the FROM value with -1/step/
  const onSetTo = (e: SelectChangeEvent<number>) => {
    const end = +e.target.value
    const start = end <= from ? end - step : from
    setTo(end)
    setFrom(start)
    props.element.otherValue = [start, end]
  }

  return (
    <>
      <FormControlLabel
        control={<Radio />}
        value={'other'}
        label={t('common:fnd-common-other')}
      />
      {props.field.value?.includes('other') && (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Select value={from} onChange={onSetFrom}>
            {generateValues('from').map((num) => (
              <MenuItem key={`from-${num}`} value={num}>
                {numberToTime(num)}
              </MenuItem>
            ))}
          </Select>
          <Select value={to} onChange={onSetTo}>
            {generateValues('to').map((num) => (
              <MenuItem key={`to-${num}`} value={num}>
                {numberToTime(num)}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
    </>
  )
}

const TimepickerWrapper = forwardRef((props: TimepickerWrapperProps, ref) => {
  const context = useContext(SRContext)
  const defaultValue = props.value ? rangeToValue(props.value) : null
  const [t] = useTranslation()
  const [value, setValue] = useState<string | null>(defaultValue)

  const generateLabel = (range: SurveyElementTimePickerRange) => {
    const time = rangeToTime(range)
    if (range[0] === 0) {
      return t('common:fnd-common-widget-time-before', {
        time: time[1],
        lgn: context.language
      })
    }
    if (range[1] === 3600 * 24) {
      return t('common:fnd-common-widget-time-after', {
        time: time[0],
        lgn: context.language
      })
    }
    return t('common:fnd-common-widget-time-between', {
      time1: time[0],
      time2: time[1],
      lgn: context.language
    })
  }

  const onChange = (_: ChangeEvent, value: string) => {
    const range = valueToRange(value)
    props.onChange(range)
    startTransition(() => {
      setValue(value)
    })
  }

  const {element, ...field} = {...props}

  return (
    <>
      <RadioGroup
        ref={ref}
        value={value}
        onChange={onChange}
        id={props.element.name}
      >
        {props.element.ranges?.map((range, index) => (
          <FormControlLabel
            value={rangeToValue(range)}
            key={`range-${range.toString()}-${index}`}
            control={<Radio />}
            label={generateLabel(range)}
          />
        ))}
        {props.element.hasOther && (
          <SurveyElementUIOther field={field} element={element} />
        )}
      </RadioGroup>
    </>
  )
})

export default TimepickerWrapper
