import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementRegions} from '@fnd/survey'
import RegionsWrapper from 'web-common/components/survey/ui/elements/helpers/RegionsWrapper'

export default function SurveyElementUIRegions({
  field,
  element
}: SurveyFactoryElementBaseProps<SurveyElementRegions>) {
  return <RegionsWrapper {...field} element={element} />
}
