import {CardHeader, CardHeaderProps, Divider} from '@mui/material'
import {SystemCard} from 'web-common/views/timeline/styled/SystemCard'
import TimelineEventTemplate from 'web-common/views/timeline/TimelineEventTemplate'
import {WithTranslation, withTranslation} from 'react-i18next'
import {ReactNode} from 'react'

export interface SystemEventProps extends CardHeaderProps {
  body?: ReactNode
}

interface InternalProps extends SystemEventProps, WithTranslation {}

class SystemEvent extends TimelineEventTemplate<InternalProps, any> {
  render() {
    const {producer, title, subheader, i18n, t, tReady, body, ...rest} = {
      ...this.props
    }
    let tl = title,
      d = subheader
    if (typeof title === 'string') {
      tl = t(title)
    }
    if (typeof subheader === 'string') {
      d = t(subheader)
    }
    return (
      <SystemCard variant={'outlined'}>
        <CardHeader
          titleTypographyProps={{variant: 'subtitle2'}}
          title={tl}
          subheader={d}
          {...rest}
        />
        {body && (
          <>
            <Divider />
            {body}
          </>
        )}
      </SystemCard>
    )
  }
}

export default withTranslation()(SystemEvent)
