import React from 'react'

interface LayoutHeadlessProps {
  fullWidth?: boolean
  children?: JSX.Element
}

interface LayoutHeadlessState {}

class LayoutHeadless extends React.Component<
  LayoutHeadlessProps,
  LayoutHeadlessState
> {
  render() {
    return <>{this.props.children}</>
  }
}

export default LayoutHeadless
