import React from 'react'
import DemandsService from 'views/demands/DemandsService'
import {WithTranslation, withTranslation} from 'react-i18next'
import {Stack, Typography} from '@mui/material'
import AppDate from 'web-common/components/date/AppDate'
import ServicesService from 'web-common/services/ServicesService'
import {SearchableService} from 'web-common/models/CategoriesAndServices'
import {DemandRequestData} from 'models/Demands'
import QuestionnaireAnswersGeneric from 'web-common/components/survey/QuestionnaireAnswersGeneric'

interface DemandDetailsViewProps extends WithTranslation {
  demandId: string
  serviceId: string
  dateCreated: number
}

interface DemandDetailsViewState {
  questionnaireData?: DemandRequestData
  service?: SearchableService
}

class DemandDetailsView extends React.Component<
  DemandDetailsViewProps,
  DemandDetailsViewState
> {
  state: DemandDetailsViewState = {}

  async componentDidMount() {
    const questionnaireData = await DemandsService.getDemandQuestionnaireData(
      this.props.demandId
    )
    const service = ServicesService.getServiceById(this.props.serviceId)
    this.setState({
      questionnaireData: questionnaireData,
      service: service
    })
  }

  render() {
    if (!this.state.questionnaireData) {
      return null
    }
    return (
      <Stack direction={'column'} spacing={2}>
        <span>
          <Typography variant={'h4'} component={'h3'} color={'primary'}>
            {this.state.service?.service.name}
          </Typography>
          <Typography variant={'body1'}>
            {this.state.service?.category.name} /{' '}
            {this.state.service?.parentCategory.name}
          </Typography>
          <Typography variant={'body2'} color={'textSecondary'}>
            <AppDate timestamp={this.props.dateCreated} format={'date-time'} />
          </Typography>
        </span>
        <QuestionnaireAnswersGeneric
          survey={this.state.questionnaireData.survey}
          answers={this.state.questionnaireData.answers}
          language={this.props.i18n.language}
          disableImagePreview={true}
        />
      </Stack>
    )
  }
}

export default withTranslation()(DemandDetailsView)
