import {createTheme, Stack, ThemeProvider} from '@mui/material'
import {createContext, useContext, useEffect, useMemo, useState} from 'react'
import SurveyProgressView from 'web-common/components/survey/ui/view/SurveyProgressView'
import SurveyPageView from 'web-common/components/survey/ui/view/SurveyPageView'
import SurveyNavigationView from 'web-common/components/survey/ui/view/SurveyNavigationView'
import {FormProvider, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {CacheProvider} from '@emotion/react'
import {themeDirection} from 'web-common/theme/AppTheme'
import {AppContext} from 'web-common/contexts/AppContext'
import {
  Survey,
  SurveyAnalytics,
  SurveyAnswersSimple,
  SurveyPage,
  SurveyPageProcessorFactory,
  SurveyRendererService,
  SurveySessionStorage
} from '@fnd/survey'
import {SurveyPageProcessorCustomerAddress} from 'web-common/components/survey/services/SurveyCustomerAddressService'

interface SRContextModel {
  language: string
  renderer: SurveyRendererService
  inTransition: boolean
  setInTransition: (value: boolean) => void
  page: SurveyPage
  setPage: (page: SurveyPage) => void
  complete: () => void
}

export const SRContext = createContext<SRContextModel>({
  language: 'en'
} as SRContextModel)

export interface SurveyRendererProps {
  survey: Survey
  templates?: Survey[]
  language?: string
  onComplete: (answers: SurveyAnswersSimple) => void
  sessionStorage?: SurveySessionStorage
  surveyAnalytics?: SurveyAnalytics
}

function SurveyRendererView(props: SurveyRendererProps) {
  const appContext = useContext(AppContext)
  const [, i18n] = useTranslation()
  const formContext = useForm()
  const renderer = useMemo(
    () =>
      SurveyRendererService.shared(
        props.survey,
        props.templates,
        props.sessionStorage,
        props.surveyAnalytics,
        new SurveyPageProcessorFactory({
          'get-customer-address': SurveyPageProcessorCustomerAddress
        })
      ),
    [props.survey, props.templates, props.sessionStorage, props.surveyAnalytics]
  )
  const [currentPage, setCurrentPage] = useState(renderer.getUpdatedPage())
  const [inTransition, setInTransition] = useState(false)
  const [loading, setLoading] = useState(true)
  const language = props.language ?? i18n.language
  const context: SRContextModel = {
    language: language,
    inTransition: inTransition,
    setInTransition: setInTransition,
    renderer: renderer,
    page: currentPage,
    setPage: setCurrentPage,
    complete: () => props.onComplete(renderer.getAnswers(false))
  }

  // Free shared resource
  useEffect(() => {
    return () => {
      renderer.freeShared()
    }
  }, [renderer])

  // Trigger pre processor if available
  useEffect(() => {
    const survey = renderer.getPages()
    if (survey[0].preProcessor) {
      renderer
        .executeProcessor(survey[0].preProcessor, {pageIndex: 0})
        .then((_) => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [renderer])

  const copy = createTheme(appContext.theme)
  copy.direction = i18n.dir(language)

  if (loading) {
    return <>...</>
  }

  return (
    <SRContext.Provider value={context}>
      <CacheProvider value={themeDirection[copy.direction]}>
        <ThemeProvider theme={copy}>
          <FormProvider {...formContext}>
            <form dir={copy.direction}>
              <Stack spacing={3}>
                <SurveyProgressView />
                <SurveyPageView />
                <SurveyNavigationView />
              </Stack>
            </form>
          </FormProvider>
        </ThemeProvider>
      </CacheProvider>
    </SRContext.Provider>
  )
}

export default SurveyRendererView
