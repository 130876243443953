import {CardHeaderProps} from '@mui/material'
import SystemEvent, {
  SystemEventProps
} from 'web-common/views/timeline/events/SystemEvent'
import CustomerEventProducer from 'views/demands/demand/timeline/CustomerEventProducer'
import DemandsService, {AssignmentData} from 'views/demands/DemandsService'
import {OfferData, OfferStatus} from 'web-common/models/OfferModel'
import DemandDispatcher, {
  DemandEventData
} from 'views/demands/demand/timeline/dispatchers/DemandDispatcher'
import {JobAccepted} from 'views/demands/demand/timeline/events/JobAccepted'
import NewOfferEvent, {
  NewOfferEventProps
} from 'views/demands/demand/timeline/events/NewOfferEvent'
import {NewJobEvent} from 'views/demands/demand/timeline/events/NewJobEvent'
import {ASSIGNMENT_OFFER_NEW_TYPE} from 'web-common/models/ws/assignments'
import {
  BusEvent,
  EventBusRegistry,
  TimelineAdapter,
  TimelineEventData
} from '@fnd/timeline'

export interface AssignmentTimelineEvent extends AssignmentData {
  lastEvent: boolean

  [key: string]: any
}

type AssignmentEventType = AssignmentTimelineEvent | CardHeaderProps

export class AssignmentAdapter extends TimelineAdapter<any> {
  static id = 'assignment'
  private dispatcher: EventBusRegistry | undefined

  // When creating adapter you should provide assignment or assignmentId param
  constructor(
    public producer: CustomerEventProducer,
    private assignment: AssignmentData
  ) {
    super()
    this.dispatcher = new DemandDispatcher(this.assignment._id).register(
      (event) => {
        if (event.type === ASSIGNMENT_OFFER_NEW_TYPE) {
          this.producer.actionManager.addAccept(this.assignment)
          this.dispatcherEventNewOffer(event).then()
        }
      }
    )
  }

  public complete() {
    this.dispatcher?.unregister()
  }

  async load(): Promise<TimelineEventData<AssignmentEventType>[]> {
    let assignment = this.assignment
    const idGenerator = this.generateID()
    let events = [
      this.eventNewJob(idGenerator.next().value, assignment),
      this.eventJobAccepted(idGenerator.next().value, assignment),
      this.eventNewOffers(idGenerator.next().value, assignment),
      this.eventOfferAccepted('offer-accepted', assignment),
      this.eventCanceled('demand-canceled')
    ]
      .filter((d) => d !== undefined)
      .flat() as TimelineEventData<any>[]

    // EXTRACT LAST MAJOR EVENT/EVENT THAT HAS AVAILABLE ACTIONS/
    events = events.sort((a, b) => a.date - b.date)
    for (let i = events.length - 1; i >= 0; i--) {
      if ('lastEvent' in events[i].props) {
        ;(events[i].props as AssignmentTimelineEvent).lastEvent = true
        break
      }
    }

    if (this.assignment.offer && !this.assignment.offer.dateSeen) {
      await DemandsService.markOfferAsSeen(
        this.assignment._refs.demandId,
        this.assignment._id,
        this.assignment.offer.dateCreated
      )
      this.assignment.offer.dateSeen = Date.now()
    }

    return events
  }

  private eventNewJob(
    id: string,
    assignment: AssignmentData
  ): TimelineEventData<SystemEventProps> {
    return {
      _id: id,
      alignment: 'flex-end',
      producer: this.producer,
      date: assignment.dateCreated,
      props: {
        title: 'fnd-event-title-demand-created',
        body: <NewJobEvent assignment={assignment} />
      },
      element: SystemEvent,
      adapterId: AssignmentAdapter.id
    }
  }

  // LEAD ACTIVATED FROM CONTRACTOR SIDE
  private eventJobAccepted(
    id: string,
    assignment: AssignmentData
  ): TimelineEventData<SystemEventProps> {
    return {
      _id: id,
      alignment: 'flex-start',
      producer: this.producer,
      date: assignment.dateActivated ?? assignment.dateCreated,
      props: {
        title: 'fnd-event-title-demand-activated',
        body: <JobAccepted />
      },
      element: SystemEvent,
      adapterId: AssignmentAdapter.id
    }
  }

  private eventNewOffer(
    id: string,
    offer: OfferData
  ): TimelineEventData<NewOfferEventProps> {
    return {
      _id: id,
      date: offer.dateCreated!,
      props: {
        offer: offer
      },
      element: NewOfferEvent,
      alignment: 'flex-start',
      producer: this.producer,
      adapterId: AssignmentAdapter.id
    }
  }

  private eventNewOffers(
    id: string,
    assignment: AssignmentData
  ): TimelineEventData<NewOfferEventProps>[] {
    let offers: TimelineEventData<NewOfferEventProps>[] = []
    if (assignment.offerHistory && assignment.offerHistory.length > 0) {
      assignment.offerHistory.forEach((offer, index) => {
        offers.push(this.eventNewOffer(id + index, offer))
      })
    }
    if (assignment.offer) {
      offers.push(this.eventNewOffer(id, assignment.offer))
    }
    return offers
  }

  // TODO: ask vlado - we need to change data changed when user accept
  private eventOfferAccepted(
    id: string,
    assignment: AssignmentData
  ): TimelineEventData<SystemEventProps> | undefined {
    if (
      assignment.status === 'ACCEPTED' &&
      assignment.offer?.status === OfferStatus.ACCEPTED
    ) {
      return {
        _id: id,
        date: assignment.offer.dateChanged!,
        props: {
          title: 'fnd-event-title-offer-accept',
          subheader: 'fnd-event-subtitle-offer-accept'
        },
        element: SystemEvent,
        alignment: 'flex-end',
        producer: this.producer,
        adapterId: AssignmentAdapter.id
      }
    }
    return
  }

  private eventCanceled(
    id: string
  ): TimelineEventData<SystemEventProps> | undefined {
    if (this.producer.demand.status === 'CANCELED') {
      this.producer.actionManager.replace([])
      this.producer.actionManager.disableChat()
      const canceledDate =
        this.producer.demand.actions?.find(
          (action) => action?.name === 'CANCEL'
        )?.dateCreated ?? Date.now()
      return {
        _id: id,
        date: canceledDate,
        props: {
          title: 'fnd-event-title-demand-canceled',
          subheader: 'fnd-event-subtitle-demand-canceled'
        },
        element: SystemEvent,
        alignment: 'flex-end',
        producer: this.producer,
        adapterId: AssignmentAdapter.id
      }
    }
    return
  }

  private async dispatcherEventNewOffer(
    event: BusEvent<DemandEventData, undefined>
  ) {
    const data = event as BusEvent<DemandEventData, undefined>
    const offer = await DemandsService.getOffer(
      data.data!.assignment._refs.demandId,
      data.data!.assignment._id
    )
    if (offer.offer) {
      this.assignment.offer = offer.offer
      this.producer.update([this.eventNewOffer(offer._id, offer.offer)])
    }
  }

  async acceptOffer(assignment: AssignmentData) {
    const customerReview = await DemandsService.acceptOffer(
      assignment._refs.demandId,
      assignment._id,
      assignment.offer!.dateCreated
    )
    this.producer.actionManager.replace([])
    assignment.customerReview = customerReview
    this.producer.actionManager.addReview(assignment)
    return this.eventOfferAccepted('offer-accepted', {
      status: 'ACCEPTED',
      offer: {
        status: OfferStatus.ACCEPTED,
        dateChanged: Date.now()
      }
    } as AssignmentData)!
  }

  *generateID(): Generator<string> {
    let index = 0

    while (true) {
      yield `assignment-adapter${index++}`
    }
  }
}
