import {Survey} from '@fnd/survey'
import QuestionnaireAnswers from 'web-common/components/survey/QuestionnaireAnswers'
import SurveyAnswers from 'web-common/components/survey/SurveyAnswers'

interface QuestionnaireAnswersGenericProps {
  survey: Survey | any
  answers: any
  language: string
  showEmptyAnswers?: boolean
  disableImagePreview?: boolean
  // Show first `n` answers and then show 'show more' button
  limit?: boolean
}

export default function QuestionnaireAnswersGeneric(
  props: QuestionnaireAnswersGenericProps
) {
  const Qar = props.survey.hasOwnProperty('version')
    ? QuestionnaireAnswers
    : SurveyAnswers
  const prs: any = props.survey.hasOwnProperty('version')
    ? {...props}
    : {...props, fallbackLanguage: 'he'}
  return <Qar {...prs} />
}
