import {SearchableService} from 'web-common/models/CategoriesAndServices'
import FileStorage from 'web-common/services/files/FileStorage'

class ServiceImageService {
  private readonly superSpecialServices = [
    'pesticides-and-pests.jpg',
    'care_and_beauty.jpg',
    'transport-and-logistics.jpg',
    'plumbing-and-solar-water-heaters.jpg',
    'locksmith.jpg',
    'air-conditioning-technician.jpg',
    'electricians-and-contractors.jpg',
    'cellular-computer-laboratories.jpg',
    'photographers-and-studio.jpg',
    'bb-and-country-lodging.jpg',
    'spa-treatments-and-body-massages.jpg',
    'home-appliance-technicians.jpg',
    'spare-parts.jpg',
    'car-locksmith.jpg',
    'cakes.jpg',
    'animal-equipment-and-services.jpg',
    'nutrition-consultants.jpg',
    'building-contractors-aa.jpg',
    'chefs-and-private-cooks.jpg',
    'real-estate.jpg',
    // ---
    // 'car-drag-and-motorcycle-rescue.png',
    'hair-extensions-wigs.png',
    'carpenters-and-carpenters.png',
    'privet-teacher.png',
    'translation-services.png',
    'driving-lessons.png',
    'car-rental.png',
    // 'paint-and-plastering-contractors.png',
    'accountant-and-bookkeeper.png',
    'laundry-services.png',
    'transportation-companies.png',
    'garden-architecture.png',
    'music-studies.png',
    'house-keeping.png',
    'cleaning-services.png',
    'nanny-and-babysitter.png',
    'gift-cases.png',
    'villa-rental.png',
    'handyman.png',
    'blinds-and-aluminum-products.png'
  ]

  private readonly services: string[] = []
  private readonly extensions: Map<string, string> = new Map<string, string>()

  constructor() {
    this.superSpecialServices.forEach((entry) => {
      const [service, extension] = entry.split('.')
      this.services.push(service)
      this.extensions.set(service, extension)
    })
  }

  public getImage(service: SearchableService): string {
    let imageURL: string
    if (this.services.includes(service?.service?.id ?? '')) {
      imageURL = service
        ? this.getServiceImageURL(
            service.service.id,
            this.extensions.get(service.service.id)!
          )
        : ''
    } else {
      imageURL = service
        ? this.getCategoryImageURL(service.parentCategory.id)
        : ''
    }
    return imageURL
  }

  public getCategoryImageURL(categoryId: string) {
    return FileStorage.getWellKnownResource(
      `public/services/${categoryId}-demand_card.png`
    )
  }

  public getServiceImageURL(serviceId: string, ext: string) {
    return FileStorage.getWellKnownResource(
      `public/services/${serviceId}.${ext}`
    )
  }
}

const serviceImageService = new ServiceImageService()
export default serviceImageService
