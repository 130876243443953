import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  styled,
  Typography
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import AppCheckbox from 'web-common/components/inputs/AppCheckbox'
import Grid from '@mui/material/Grid'
import AppRadio from 'web-common/components/inputs/AppRadio'
import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import RegionService, {
  RegionSelectionData
} from 'web-common/services/RegionService'
import {useTranslation} from 'react-i18next'
import {AppContext} from 'web-common/contexts/AppContext'

const RegionSelectionBox = styled(Box)((props) => ({
  [props.theme.breakpoints.up('md')]: {
    minHeight: '600px'
  }
}))

export interface RegionsListSelectionProps {
  selected?: string[]
  onSelect?: (regionsId: string[]) => void
  maxRegions?: number
  maxGroups?: number
  onFocus?: (regions?: string[]) => void
  onLimitReach?: () => void
}

const RegionsListSelection = (props: RegionsListSelectionProps) => {
  const appContext = useContext(AppContext)
  const [, i18n] = useTranslation()
  const [expandedRegions, setExpandedRegions] = useState<string[]>([])
  const [selectedSubregions, setSelectedSubregions] = useState<string[]>(
    props.selected ?? []
  )
  const [regions, setRegions] = useState<RegionSelectionData[]>([])
  const [, setRegionsOnFocus] = useState<string[]>([])

  useEffect(() => {
    ;(async () => {
      const regions = await RegionService.getAll()
      const regionMap: {[id: string]: RegionSelectionData} = {}
      regions
        .sort((a, b) => a.level - b.level)
        .forEach((region) => {
          regionMap[region._id] = {...region}
          if (region.parentId) {
            if (!regionMap[region.parentId].children) {
              regionMap[region.parentId].children = []
            }
            regionMap[region.parentId].children!.push(region)
          }
        })
      const availableRegions = Object.values(regionMap)
        // Get top region from level 4 with at least on child
        .filter((r) => r.level === 4 && (r.children?.length ?? 0) > 0)
        .sort((a, b) => (a.uiOrder ?? Infinity) - (b.uiOrder ?? Infinity))
        .map((r) => {
          // Sort children as well
          r.children = r.children?.sort(
            (a, b) => (a.uiOrder ?? Infinity) - (b.uiOrder ?? Infinity)
          )
          return r
        })
      setRegions(availableRegions)
    })()
  }, [])

  const getSelectedCount = (regionId: string) =>
    regions
      .filter((r) => r._id === regionId)?.[0]
      .children?.filter((a) => selectedSubregions.includes(a._id)).length ?? 0

  const onRegionGainFocus = (regionId: string, regionIds: string[]) => {
    const expanded = []
    const currentIndex = expandedRegions.indexOf(regionId)
    if (currentIndex !== -1) {
    } else {
      expanded.push(regionId)
    }
    setExpandedRegions(expanded)
    setRegionsOnFocus(regionIds)
    props.onFocus?.([regionId])
  }

  const getRegionName = (region: RegionSelectionData) => {
    if ((region as any)[`name:${i18n.language}`]) {
      return (region as any)[`name:${i18n.language}`]
    }
    if (region['name:he']) {
      return region['name:he']
    }
    return region.name
  }

  const hasLimitReached = (regionId: string) => {
    const groupLimit = props.maxGroups ?? Infinity
    const regionsLimit = props.maxRegions ?? Infinity
    const hasRegionLimitReached = regionsLimit <= selectedSubregions.length
    const hasGroupLimitReached =
      groupLimit <=
      regions.filter((r) =>
        r.children?.some((s) => selectedSubregions.includes(s._id))
      ).length
    const isTryingToAddNewRegion = getSelectedCount(regionId) === 0
    return (
      isTryingToAddNewRegion && (hasGroupLimitReached || hasRegionLimitReached)
    )
  }

  const onParentRegionSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const regionId = e.target.value
    const selected = e.target.checked
    const copy = [...selectedSubregions]

    if (hasLimitReached(regionId)) {
      props.onLimitReach?.()
      return false
    }
    regions
      .find((r) => r._id === regionId)
      ?.children?.forEach((r) => {
        if (selected && !copy.includes(r._id)) {
          copy.push(r._id)
        } else if (!selected && copy.includes(r._id)) {
          copy.splice(copy.indexOf(r._id), 1)
        }
      })
    setSelectedSubregions(copy)
    props.onSelect?.(copy)
  }

  const regionCheckboxHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const regionId = e.target.value
    const checked = e.target.checked
    const copy = [...selectedSubregions]
    if (checked && !copy.includes(regionId)) {
      copy.push(regionId)
    } else if (!checked && copy.includes(regionId)) {
      copy.splice(copy.indexOf(regionId), 1)
    }
    setSelectedSubregions(copy)
    props.onSelect?.(copy)
  }

  const regionRadioHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const regionId = e.target.value
    setSelectedSubregions([regionId])
    props.onSelect?.([regionId])
  }

  return (
    <RegionSelectionBox>
      {regions.map((region) => (
        <Accordion
          key={region._id}
          expanded={expandedRegions.includes(region._id)}
        >
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon />}
            sx={
              getSelectedCount(region._id) > 0
                ? {
                    background: appContext.theme.palette.primary.dark,
                    color: appContext.theme.palette.primary.contrastText,
                    fontWeight: 'bold',
                    '& label svg': {
                      background: appContext.theme.palette.common.white
                    }
                  }
                : {}
            }
            onClick={onRegionGainFocus.bind(
              this,
              region._id,
              region.children?.map((r) => r._id) ?? []
            )}
          >
            {/*SELECTED REGION ICON*/}
            {(() => {
              if ((props.maxRegions ?? Infinity) === 1) {
                return (
                  <Typography variant={'body2'}>
                    {getRegionName(region)}
                  </Typography>
                )
              }
              return (
                <Box display={'flex'} alignItems={'center'}>
                  <AppCheckbox
                    onChange={onParentRegionSelect.bind(this)}
                    value={region._id}
                    onClick={(e) => {
                      if (
                        !(e.target as any).checked ||
                        expandedRegions.includes(region._id) ||
                        hasLimitReached(region._id)
                      ) {
                        e.stopPropagation()
                      }
                    }}
                    checked={getSelectedCount(region._id) > 0}
                  />
                  <Typography variant={'body2'}>
                    {getRegionName(region) +
                      ` (${getSelectedCount(region._id)}/${
                        region.children?.length ?? 0
                      })`}
                  </Typography>
                </Box>
              )
            })()}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              {/* SUB REGION CHECKBOX */}
              {region.children?.map((subRegion) => (
                <Grid item key={'region-selection-' + subRegion._id} xs={12}>
                  {((_) => {
                    if ((props.maxRegions ?? Infinity) > 1) {
                      // MULTIPLE SELECTION
                      return (
                        <AppCheckbox
                          label={getRegionName(subRegion)}
                          onChange={regionCheckboxHandler.bind(this)}
                          checked={selectedSubregions.includes(subRegion._id)}
                          value={subRegion._id}
                          disabled={hasLimitReached(region._id)}
                        />
                      )
                    } else {
                      // SINGLE SELECTION
                      return (
                        <AppRadio
                          label={getRegionName(subRegion)}
                          onChange={regionRadioHandler.bind(this)}
                          checked={selectedSubregions.includes(subRegion._id)}
                          value={subRegion._id}
                        />
                      )
                    }
                  })()}
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </RegionSelectionBox>
  )
}

export default RegionsListSelection
