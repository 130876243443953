import {AppConfig} from 'AppConfig'
import i18n from 'i18n/i18n'

export function getWhatsAppLink(key: string, tArgs?: any): string {
  const translate = AppConfig.isProduction ? key : key + '-test'
  return `${AppConfig.whatsApp.api}send?${i18n.t(translate, tArgs)}`
}

export function getWhatsAppRegisterLink(trigger: string): string {
  const phone = AppConfig.whatsApp.phone
  return `${AppConfig.whatsApp.api}send?phone=${phone}&text=${trigger}`
}

// GENERATE TAB INDEX FOR NEXT ELEMENT - CURRENTLY ALL INDEXES ARE 0
export const tabIndex = (function () {
  // function* getNextTabIndexGenerator() {
  //   let index = 0;
  //
  //   while (true) {
  //     yield index++;
  //   }
  // }
  // let getNextTabIndexInstance: Generator<number> = getNextTabIndexGenerator()
  return {
    // reset: () => { getNextTabIndexInstance = getNextTabIndexGenerator() },
    value: () => {
      return 0
    } // getNextTabIndexInstance.next().value }
  }
})()

// GENERATE PSEUDO BUTTON ATTR FOR REACT COMPONENTS
export function spreadAriaButton(label: string, onClick: () => void) {
  return {
    role: 'button',
    tabIndex: tabIndex.value(),
    'aria-label': label,
    onClick: onClick,
    onKeyPress: (event: any) => {
      if (event.key === 'Enter' || event.key === ' ') {
        onClick()
        event.stopPropagation()
        event.preventDefault()
        return false
      }
    }
  }
}

interface SCProps {
  text: string
}
export function SC(props: SCProps) {
  return <span className={'sr-only'}>{props.text}</span>
}
