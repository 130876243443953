import React from 'react'
import {QuestionMultipleTextModel} from 'survey-react'
import RegionsMapSelection from 'web-common/components/regions/RegionsMapSelection'
import {createRoot} from 'react-dom/client'

const WIDGET_NAME = 'regions'

function widgetReactRegions(Survey: any) {
  return {
    value: [],
    name: WIDGET_NAME,
    title: 'Regions Multiple',
    iconName: 'icon-drag-item',
    isFit: function (question: QuestionMultipleTextModel) {
      return question.getType() === WIDGET_NAME
    },
    widgetIsLoaded: () => true,
    isDefaultRender: false,
    htmlTemplate: '<div></div>',
    afterRender: function (
      question: QuestionMultipleTextModel,
      el: HTMLElement
    ) {
      const scope = this
      const root = createRoot(el)
      root.render(
        <RegionsMapSelection
          onSelect={(regionsIds) => {
            question.updateValueFromSurvey(regionsIds)
            // @ts-ignore
            scope.value = regionsIds
          }}
          selected={question.value}
        />
      )
    },
    activatedByChanged: () => {
      Survey.Serializer.addClass(WIDGET_NAME, [], undefined, 'multipletext')
    },
    willUnmount: function (question: QuestionMultipleTextModel) {
      if (this.value) {
        question.value = this.value
      }
    }
  }
}

export default widgetReactRegions
