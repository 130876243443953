import {AssignmentData} from 'views/demands/DemandsService'
import LeaveReviewAction from 'views/demands/demand/timeline/actions/LeaveReviewAction'
import AcceptOffer from 'views/demands/demand/timeline/actions/AcceptOffer'
import CompareOffersAction from 'views/demands/demand/timeline/actions/CompareOffersAction'
import {TimelineAction, TimelineActionManager} from '@fnd/timeline'

class CustomerActionManager extends TimelineActionManager {
  constructor(
    data: AssignmentData,
    private totalAssignment: number
  ) {
    super()
  }

  addReview(assignment: AssignmentData) {
    this.pushUnique({
      id: 'write-review',
      node: LeaveReviewAction,
      props: {
        assignment: assignment
      }
    })
  }

  addAccept(assignment: AssignmentData) {
    this.tryAddCompareOffer(assignment)
    this.pushUnique({
      id: 'accept-offer',
      node: AcceptOffer,
      props: {
        assignment: assignment
      }
    })
  }

  public tryAddCompareOffer(assignment: AssignmentData) {
    if (this.totalAssignment < 2) {
      return
    }
    this.pushUnique(
      {
        id: 'compare-offers',
        node: CompareOffersAction,
        props: {
          demandId: assignment._refs.demandId
        }
      },
      false
    )
  }

  private pushUnique(action: TimelineAction<any>, push: boolean = true) {
    this.actions = this.actions.filter((a) => a.id !== action.id)
    this.actions.push(action)
    if (push) this.notify()
  }

  public notify() {
    super.notify()
  }
}

export default CustomerActionManager
