// NEW OFFER
export const ASSIGNMENT_OFFER_NEW_TYPE = 'NEW_OFFER'

export interface WS_ASSIGNMENT_OFFER_NEW_RECEIVE_DATA {
  assignment: {
    _id: string
    _refs: {
      demandId: string
      serviceId: string
      contractorId: string
      userId: string
      customerUserId: 'string'
    }
    contractor: {
      name: string
    }
    status: string
  }
}

// OFFER ACCEPTED
export const ASSIGNMENT_OFFER_ACCEPTED_TYPE = 'OFFER_ACCEPTED'

export interface WS_ASSIGNMENT_OFFER_ACCEPTED_RECEIVE_DATA {
  assignment: {
    _id: string
    _refs: {
      demandId: string
      serviceId: string
      contractorId: string
      userId: string
      customerUserId: string
    }
    contact: {
      name: string
    }
    customerReview: {
      _id: string
      status: string
    }
    contractorReview: {
      _id: string
      status: string
    }
  }
}

// NEW ASSIGNMENT
export const ASSIGNMENT_NEW_TYPE = 'NEW_ASSIGNMENT'

export interface WS_ASSIGNMENT_NEW_RECEIVE_DATA {
  demand: {
    _id: string
    _refs: {
      userId: string
      serviceId: string
    }
    dateCreated: number
    request: {
      offices: string[]
      online: boolean
    }
    contact: {
      name: string
    }
  }
  assignment: {
    _id: string
    dateCreated: number
  }
}

// ACTIVE ASSIGNMENT
export const ASSIGNMENT_ACTIVE_TYPE = 'ACTIVE_ASSIGNMENT'

export interface WS_ASSIGNMENT_ACTIVE_RECEIVE_DATA {
  assignment: {
    _id: string
    _refs: {
      demandId: string
      serviceId: string
      contractorId: string
      userId: string
      customerUserId: string
    }
    contractor: {
      name: string
    }
  }
}
