import {MenuItem, Select} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {languages} from 'i18n/i18n'
import {useTranslation} from 'react-i18next'

interface LanguageSelectorProps {
  onLanguageChange: (lang: string) => void
  defaultValue: string
  // Default size is small
  size?: 'small' | 'medium'
  fullWidth?: boolean
}

const LanguageSelector = (props: LanguageSelectorProps) => {
  const [t] = useTranslation()
  const [lang, setLang] = useState<string>(props.defaultValue)

  useEffect(() => {
    setLang(props.defaultValue)
  }, [props.defaultValue])

  const fullWidth = props.fullWidth ? {width: '100%'} : undefined
  return (
    <Select
      value={lang}
      onChange={(e) => {
        props.onLanguageChange(e.target.value)
      }}
      size={props.size ?? 'small'}
      style={fullWidth}
      aria-label={t('fnd-aria-language-menu')}
    >
      {languages.map((item) => (
        <MenuItem value={item.code} key={item.code} tabIndex={0}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default LanguageSelector
