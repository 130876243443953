import {AppNotificationData} from 'web-common/contexts/AppContext'
import DemandDispatcher from 'views/demands/demand/timeline/dispatchers/DemandDispatcher'
import DemandsDispatcher from 'views/demands/listing/DemandsDispatcher'
import DemandView from 'views/demands/demand/DemandView'
import {BusEvent, Dispatcher, DispatcherPriority} from '@fnd/timeline'
import {ChatAdapterDispatcher} from 'web-common/views/timeline/chat/ChatAdapterDispatcher'
import EventBus from 'web-common/services/EventBus'

class GlobalEventDispatcher extends Dispatcher<any, any> {
  // Do not consume events
  protected consumable: boolean = false

  // Subscribe for all events
  protected criteria(event: BusEvent<any, any>): boolean {
    return true
  }

  protected priority: DispatcherPriority = 'SYSTEM'

  public async eventToNotification(
    event: BusEvent<any, any>
  ): Promise<AppNotificationData | undefined> {
    // THIS EVENT IS NOT FOR CUSTOMER - SKIP NOTIFICATION
    if (event?.data?.data?.target !== 'customer') {
      return undefined
    }
    // NEW OFFER
    if (new DemandDispatcher().criteria(event)) {
      return DemandDispatcher.eventToNotification(event)
    }
    // NEW & ACTIVE ASSIGNMENT
    if (DemandsDispatcher.shared().criteria(event)) {
      return DemandsDispatcher.eventToNotification(event)
    }
    // NEW MESSAGE
    if (ChatAdapterDispatcher.shared().notificationCriteria(event)) {
      const notification = ChatAdapterDispatcher.eventToNotification(event)
      // backend unable to provide demandId - send undefined instead
      notification!.action = (navigate) =>
        navigate(
          DemandView.getPath('undefined', event.data!.meta.assignment._id)
        )
      return notification
    }

    return undefined
  }
}

const globalEventDispatcher = new GlobalEventDispatcher(EventBus)
export default globalEventDispatcher
