export class FileUtils {
  static async base64ToFile(file: string, filename?: string): Promise<File> {
    const preferredFilename = filename ?? 'file' + Date.now()
    const res = await fetch(file)
    const blob = await res.blob()
    return new File([blob], preferredFilename, {type: blob.type})
  }

  static async fileToBase64(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = async (e: any) => {
        resolve(e.target.result)
      }
      reader.readAsDataURL(file)
    })
  }
}
