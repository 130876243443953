import React from 'react'
import Box from '@mui/material/Box'
import logoNonBeta from 'web-common/assets/img/logo.svg'
import {WithTranslation, withTranslation} from 'react-i18next'
import {getWhatsAppLink} from 'web-common/utilities/whatsappUtilities'
import {Button, Typography} from '@mui/material'
import AccessibilityControl from 'components/accessibility/AccessibilityControl'

interface WhatsAppRegistrationProps extends WithTranslation {}

interface WhatsAppRegistrationState {}

class WhatsAppRegistration extends React.Component<
  WhatsAppRegistrationProps,
  WhatsAppRegistrationState
> {
  render() {
    return (
      <Box textAlign={'center'} display={'flex'} justifyContent={'center'}>
        <Box maxWidth={'540px'}>
          {/* LOGO */}
          <img
            src={logoNonBeta}
            alt={'finderella'}
            width={'259px'}
            style={{marginTop: '30px'}}
          />

          <Typography variant={'h6'} style={{margin: '30px auto 0 auto'}}>
            {this.props.t('fnd-whatsapp-registration-title')}
          </Typography>

          <Typography variant={'h6'} style={{marginTop: '30px'}}>
            {this.props.t('fnd-whatsapp-registration-description')}
          </Typography>

          <Button
            color={'primary'}
            variant={'contained'}
            style={{marginTop: '30px'}}
            href={getWhatsAppLink('fnd-whatsapp-registration-link')}
            target={'_blank'}
          >
            {this.props.t('fnd-whatsapp-registration-button')}
          </Button>

          <Typography variant={'body1'} style={{margin: '30px auto 0 auto'}}>
            {this.props.t('fnd-whatsapp-registration-footer')}
          </Typography>
        </Box>
        <AccessibilityControl />
      </Box>
    )
  }
}

export default withTranslation()(WhatsAppRegistration)
