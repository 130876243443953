import React from 'react'
import {InputLabel, Select, SelectChangeEvent} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'

interface AppFormSelectProps extends AppFormComponent {}

export interface AppFormSelectData {
  // Value to be saved
  key: string
  // Text to be rendered
  value: string
  // Can be selected
  disabled?: boolean
}

export default class AppFormSelect extends AppFormInputComponent<
  AppFormSelectProps,
  any
> {
  extractOptions(): AppFormSelectData[] {
    if (!this.props.data) {
      throw new Error(
        'AppFormSelect expect to receive data prop in AppFormSelectData format'
      )
    }
    try {
      return this.props.data as AppFormSelectData[]
    } catch {
      throw new Error('Expected data to be in AppFormSelectData format')
    }
  }

  onChange(e: SelectChangeEvent<any>) {
    const {onChangeCallback, ...rest} = {...this.props}
    const value: string = e.target.value
    this.props.onChangeCallback(rest, value)
  }

  render() {
    const error = this.props.errorText ?? 'Error'
    const label = this.props.label ?? ''
    return (
      <FormControl
        variant="outlined"
        fullWidth={true}
        size={'small'}
        aria-label={typeof label === 'string' ? label : ''}
      >
        <InputLabel htmlFor={this.props.id}>
          {this.props.hasError ? error : label}
        </InputLabel>
        <Select
          size={'small'}
          disabled={this.props.disabled}
          hidden={this.props.hidden}
          error={this.props.hasError}
          label={this.props.hasError ? error : label}
          name={this.props.name}
          id={this.props.id}
          value={this.props.value}
          onChange={this.onChange.bind(this)}
        >
          {this.extractOptions().map((item) => (
            <MenuItem value={item.key} key={item.key} disabled={item.disabled}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}
