import React, {ReactNode} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {ImageSet} from 'web-common/surveyjs-customization/image-upload'
import RegionService from 'web-common/services/RegionService'
import AppDate from 'web-common/components/date/AppDate'
import AppGallery from 'web-common/components/gallery/AppGallery'
import {Button, Stack, Typography} from '@mui/material'
import Loading from 'web-common/components/loading/Loading'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded'
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import FileStorage from 'web-common/services/files/FileStorage'

interface TextSectionProps {
  title: ReactNode
  subtitle: ReactNode
}

function TextSection({title, subtitle}: TextSectionProps) {
  return (
    <Stack>
      <Typography variant={'subtitle1'} component={'span'}>
        {title}
      </Typography>
      <Typography variant={'body1'} component={'span'}>
        {subtitle}
      </Typography>
    </Stack>
  )
}

interface SurveyAnswersProps extends WithTranslation {
  language: string
  fallbackLanguage: string
  survey: any
  answers: {[key: string]: any}
  showEmptyAnswers?: boolean
  disableImagePreview?: boolean
  // Show first `n` answers and then show 'show more' button
  limit?: boolean
}

interface SurveyAnswersData {
  // survey real question name
  name: string
  // Text for UI
  text: string
  description: string
  // Value for UI
  value: any
  // Type of the question
  type: string
  // Is filter
  filter?: boolean
}

interface SurveyAnswersState {
  answers: SurveyAnswersData[]
  // Toggle all vs limited
  showMore: boolean
}

class SurveyAnswers extends React.Component<
  SurveyAnswersProps,
  SurveyAnswersState
> {
  readonly answersLimit = 8
  state: SurveyAnswersState = {
    answers: [],
    showMore: false
  }

  async componentDidMount() {
    await this.extractQuestions()
  }

  async componentDidUpdate(
    prevProps: Readonly<SurveyAnswersProps>,
    prevState: Readonly<SurveyAnswersState>,
    snapshot?: any
  ) {
    if (prevProps.survey !== this.props.survey) {
      await this.extractQuestions()
    }
  }

  extractQuestionTextProperty(question: any, field: string) {
    if (typeof question?.[field] === 'object') {
      if (question[field][this.props.language]) {
        return question[field][this.props.language]
      }
      if (question[field][this.props.fallbackLanguage]) {
        return question[field][this.props.fallbackLanguage]
      }
      if (question[field]['default']) {
        return question[field]['default']
      }
      return Object.values(question[field])[0]
    } else {
      return question[field]
    }
  }

  extractQuestionTitle(question: any) {
    return this.extractQuestionTextProperty(question, 'title')
  }

  extractQuestionDescription(question: any) {
    return this.extractQuestionTextProperty(question, 'description')
  }

  extractChoiceText(choice: any) {
    if (typeof choice?.text === 'object') {
      const defaultValue =
        choice.text['default'] ?? Object.values(choice.text)[0]
      if (choice.text[this.props.language]) {
        return choice.text[this.props.language]
      }
      if (choice.text[this.props.fallbackLanguage]) {
        return choice.text[this.props.fallbackLanguage]
      }
      return defaultValue
    } else {
      return choice.text
    }
  }

  extractPriceRange = (question: any, value: number[] | undefined) => {
    return value ? value.join('-') : ''
  }

  extractMultipleChoices = (
    question: any,
    value: string | string[] | undefined
  ) => {
    if (typeof question.choices?.[0] === 'object' && value) {
      return (question.choices as any[])
        .filter((choice) => (value as string[]).includes(choice.value))
        .map((choice) => this.extractChoiceText(choice))
        .join(', ')
    }
    return value && typeof value === 'object' ? value.join(', ') : value
  }

  async extractImages(question: any, value: any[] | undefined | null) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        if (typeof value[0] === 'string') {
          const images: ImageSet[] = await Promise.all(
            (value as string[]).map(async (image) => {
              const imageURL = await FileStorage.getResource(image, true)
              return {
                thumb: imageURL,
                standard: imageURL.replace('%2Fthumb%2F', '%2F'),
                name: imageURL
              }
            })
          )
          return (
            <AppGallery
              images={images}
              showAll={this.props.disableImagePreview}
              allowPreview={!this.props.disableImagePreview}
            />
          )
        }
        return (
          <AppGallery
            images={value as ImageSet[]}
            showAll={this.props.disableImagePreview}
            allowPreview={!this.props.disableImagePreview}
          />
        )
      }
    }
    return null
  }

  async extractRegion(question: any, value: string | undefined) {
    if (!value) {
      return ''
    }
    const region = await RegionService.get(value)
    if (region === undefined) {
      return ''
    }

    if ((region as any)[`name:${this.props.language}`]) {
      return (region as any)[`name:${this.props.language}`]
    }
    if ((region as any)[`name:${this.props.fallbackLanguage}`]) {
      return (region as any)[`name:${this.props.fallbackLanguage}`]
    }
    return region.name
  }

  async extractRegions(question: any, value: string[] | undefined) {
    if (!value) {
      return []
    }
    const regions = await RegionService.getAll()
    return regions
      .filter((r) => value.includes(r._id))
      .map((region) => {
        if ((region as any)[`name:${this.props.language}`]) {
          return (region as any)[`name:${this.props.language}`]
        }
        if ((region as any)[`name:${this.props.fallbackLanguage}`]) {
          return (region as any)[`name:${this.props.fallbackLanguage}`]
        }
        return region.name
      })
  }

  extractDatepicker(
    question: any,
    value: string | undefined
  ): ReactNode | undefined {
    const timestamp = parseInt(value ?? '')
    if (isNaN(timestamp)) {
      return undefined
    }
    return <AppDate timestamp={timestamp} format={'date'} />
  }

  async extractQuestions() {
    const answers: SurveyAnswersData[] = []
    for (let i = 0; i < this.props.survey.pages.length; i++) {
      if (this.props.survey.pages[i].elements) {
        for (let j = 0; j < this.props.survey.pages[i].elements.length; j++) {
          const question = this.props.survey.pages[i].elements[j]
          const rawValue = this.props.answers[question.name]
          let value
          switch (question.type) {
            case 'pricerange':
              value = this.extractPriceRange(question, rawValue)
              break
            case 'dropdown':
            case 'radiogroup':
            case 'checkbox':
              value = this.extractMultipleChoices(question, rawValue)
              break
            case 'image-upload':
              value = await this.extractImages(question, rawValue)
              break
            case 'region':
              value = await this.extractRegion(question, rawValue)
              break
            case 'regions':
              const regions = await this.extractRegions(question, rawValue)
              value = regions.join(', ')
              break
            case 'datepicker':
              value = this.extractDatepicker(question, rawValue)
              break
            default:
              value =
                value && typeof rawValue === 'object'
                  ? rawValue.join(', ')
                  : rawValue
          }

          if (this.props.showEmptyAnswers || !!value) {
            // PUSH ONLY IF VALUE IS NOT EMPTY OR showEmptyAnswers IS TRUE
            answers.push({
              name: question.name,
              value: value,
              text: this.extractQuestionTitle(question) ?? question.name,
              description: this.extractQuestionDescription(question) ?? '',
              type: question.type,
              filter: question.filter ?? false
            })
          }
        }
      }
    }
    this.setState({
      answers: answers
    })
  }

  onShowMore(more: boolean) {
    this.setState({showMore: more})
  }

  renderAnswers() {
    const limit =
      this.props.limit && !this.state.showMore
        ? this.answersLimit
        : this.state.answers.length
    const label = this.state.showMore ? 'Show less' : 'Show more'
    const Icon = this.state.showMore
      ? KeyboardArrowUpRoundedIcon
      : KeyboardArrowDownRoundedIcon
    return (
      <Stack spacing={3}>
        {this.state.answers.slice(0, limit).map((item) => (
          <TextSection
            title={item.text}
            subtitle={item.value}
            key={item.name + item.value}
          />
        ))}
        {this.props.limit && (
          <Button
            color={'primary'}
            variant={'text'}
            endIcon={<Icon />}
            onClick={this.onShowMore.bind(this, !this.state.showMore)}
          >
            {label}
          </Button>
        )}
      </Stack>
    )
  }

  render() {
    return (
      <Loading
        loading={this.state.answers.length === 0}
        render={this.renderAnswers.bind(this)}
      />
    )
  }
}

export default withTranslation()(SurveyAnswers)
