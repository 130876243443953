import React from 'react'
import {Box} from '@mui/material'

interface HiddenProps {
  xsUp?: boolean
  smUp?: boolean
  mdUp?: boolean
  xlUp?: boolean

  xsDown?: boolean
  smDown?: boolean
  mdDown?: boolean
  xlDown?: boolean

  children?: any
}

/**
 * MUI deprecated Hidden component https://mui.com/guides/migration-v4/#hidden, so if they not come to their senses we will use this component
 * @param props
 * @constructor
 */
const Hidden: React.FC<HiddenProps> = (props) => {
  const query = Object.keys(props).find((key) => (props as any)[key])
  if (query === undefined) {
    throw Error('Hidden required valid props but was not found')
  }
  let config: {}
  if (query.includes('Up')) {
    config = {xs: 'block', [query.substring(0, 2)]: 'none'}
  } else {
    config = {xs: 'none', [query.substring(0, 2)]: 'block'}
  }
  return <Box sx={{display: config}}>{props.children}</Box>
}

export default Hidden
