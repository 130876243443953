import React, {ReactNode} from 'react'
import {styled, Grid, GridSize} from '@mui/material'

export type FocusOn = 'start' | 'end'

const Wrapper = styled(Grid)((pr) => ({
  [pr.theme.breakpoints.down('md')]: {
    display: 'none',
    '&.focused': {
      display: 'initial'
    }
  }
}))

interface FocusViewProps {
  start: ReactNode
  end: ReactNode
  focus: FocusOn
  startSize: GridSize
  endSize: GridSize
  style?: React.CSSProperties
  startStyle?: React.CSSProperties
  endStyle?: React.CSSProperties
}

interface FocusViewState {}

class FocusView extends React.Component<FocusViewProps, FocusViewState> {
  render() {
    return (
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        style={this.props.style}
        spacing={3}
      >
        <Wrapper
          item
          xs={12}
          md={this.props.startSize}
          className={this.props.focus === 'start' ? 'focused' : ''}
          style={this.props.startStyle}
        >
          {this.props.start}
        </Wrapper>
        <Wrapper
          item
          xs={12}
          md={this.props.endSize}
          className={this.props.focus === 'end' ? 'focused' : ''}
          style={this.props.endStyle}
        >
          {this.props.end}
        </Wrapper>
      </Grid>
    )
  }
}

export default FocusView
