// 0 - sunday
const workingDaysDefinitions = {
  he: [true, true, true, true, true, true, false]
}
// 0 - sunday
const workingHoursDefinitions = {
  he: [[8, 22], [8, 22], [8, 22], [8, 22], [8, 22], [8, 22], []]
}

// TODO add more countryCodes, workingDaysDefinitions, workingHoursDefinitions
export function isTimeInWorkingHours(timestamp: number, countryCode: 'he') {
  const demandDate = new Date(timestamp)
  const demandDay = demandDate.getDay()
  const demandHour = demandDate.getHours()

  const workingDays = workingDaysDefinitions[countryCode]
  const workingHours = workingHoursDefinitions[countryCode]

  if (!workingDays[demandDay]) {
    return false
  }
  const hours = workingHours[demandDay]
  return demandHour >= hours[0] && demandHour < hours[1]
}

function isDST(d: Date) {
  const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset()
  const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset()
  return Math.max(jan, jul) !== d.getTimezoneOffset()
}

/**
 * Summer: Fri 18:00 - Sat 19:30
 * Regular: Fri 16:00 - Sat 17:00
 */
export function isShabbat(timestamp: number): boolean {
  const date = new Date(timestamp)
  const dayOfWeek = date.getDay()
  const hour = date.getHours()
  const hourAndMinutes = +`${hour}.${date.getMinutes()}`
  const isSummerTime = isDST(date)
  return (
    (dayOfWeek === 5 && (hour >= 18 || (hour >= 16 && !isSummerTime))) ||
    (dayOfWeek === 6 && (hour < 17 || (hourAndMinutes < 19.3 && isSummerTime)))
  )
}
