import React, {useEffect, useState} from 'react'
import {DateInterval, DayPicker, useInput} from 'react-day-picker'
import {Popover} from '@mui/material'

export interface DayPickerInputProps {
  format: string
  component: React.ComponentClass<any> | React.FunctionComponent<any>
  value?: Date
  dayPickerProps: Partial<DateInterval>
  onDayChange: (date: Date) => void
}

const DayPickerInput = (props: DayPickerInputProps) => {
  const {inputProps, dayPickerProps, setSelected} = useInput({
    format: props.format,
    defaultSelected: props.value,
    fromDate: props.dayPickerProps.after,
    fromMonth: props.dayPickerProps.after,
    toDate: props.dayPickerProps.before,
    toMonth: props.dayPickerProps.before
  })
  const changeFn = dayPickerProps.onDayClick!.bind({})
  const modified = (day: Date, a: any, e: any) => {
    changeFn(day, a, e)
    props.onDayChange(day)
    handleClose()
  }
  useEffect(() => {
    if (props.value === undefined && dayPickerProps.selected !== undefined) {
      setSelected(undefined)
    }
  })

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'day-picker-popover' : undefined
  const Input = props.component

  return (
    <>
      <Input {...inputProps} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <DayPicker {...dayPickerProps} onDayClick={modified} />
      </Popover>
    </>
  )
}
export default DayPickerInput
