import Guard from 'routes/Guard'
import HomeView from 'views/home/HomeView'
import AboutView from 'views/about/AboutView'
import QnAView from 'views/qna/QnAView'
import BrowseView from 'views/browse/BrowseView'
import ProfileView from 'views/profile/ProfileView'
import LoginGuard from 'routes/LoginGuard'
import DemandsView from 'views/demands/listing/DemandsView'
import {ComponentClass, ComponentType} from 'react'
import DemandView from 'views/demands/demand/DemandView'
import PreRegistrationView from 'views/registration/pre-registration/PreRegistrationView'
import WhatsAppRegistration from 'views/registration/whatsapp/WhatsAppRegistration'
import MarketProfileView from 'views/contractor/MarketProfileView'
import WhatsAppRegistrationCustomer from 'views/registration/whatsapp/WhatsAppRegistrationCustomer'
import WhatsAppRegistrationGeneric from 'views/registration/whatsapp/WhatsAppRegistrationGeneric'
import ContactView from 'web-common/components/contactus/ContactView'
import InstallView from 'views/install/InstallView'
import QuestionnairesView from 'views/questionnaires/QuestionnairesView'
import LoginPasswordLessView from 'views/magic-links/LoginPasswordLessView'
import SurveyView from 'views/survey/SurveyView'

export enum LayoutType {
  'main' = 'main',
  'headless' = 'headless',
  'full' = 'full'
}

export interface RouteLinkItem {
  // Path to attach the component
  path: string
  // Permissions - list of required roles
  guard?: Guard
}

export interface RouteItem extends RouteLinkItem {
  // Component to load
  component: ComponentClass<any, any> | ComponentType
  // Main content layout - default centred
  layout: LayoutType
  // If true will render app without header&footer
  minimum?: boolean
}

const routes: RouteItem[] = [
  // MAIN LAYOUT
  {
    component: HomeView,
    path: QuestionnairesView.getPath(),
    layout: LayoutType.full
  },
  {
    component: DemandsView,
    path: DemandsView.getPath(),
    layout: LayoutType.main,
    guard: LoginGuard
  },
  {
    component: DemandView,
    path: DemandView.getPath(),
    layout: LayoutType.main,
    guard: LoginGuard
  },
  {
    component: ProfileView,
    path: ProfileView.getPath(),
    layout: LayoutType.main,
    guard: LoginGuard
  },
  {
    component: ContactView,
    path: ContactView.getPath(),
    layout: LayoutType.main
  },
  {
    component: SurveyView,
    path: SurveyView.getPath(),
    layout: LayoutType.main
  },
  // FULL LAYOUT
  {
    component: BrowseView,
    path: BrowseView.getPath(),
    layout: LayoutType.full
  },
  {
    component: QnAView,
    path: QnAView.getPath(),
    layout: LayoutType.full
  },
  {
    component: AboutView,
    path: AboutView.getPath(),
    layout: LayoutType.full
  },
  {
    component: HomeView,
    path: HomeView.getPath(),
    layout: LayoutType.full
  },

  // Headless
  {
    component: PreRegistrationView,
    path: PreRegistrationView.getPath(),
    layout: LayoutType.headless
  },
  {
    component: WhatsAppRegistration,
    path: '/preregistration-phone-validation',
    layout: LayoutType.headless
  },
  {
    component: WhatsAppRegistrationCustomer,
    path: '/preregistration/wa',
    layout: LayoutType.headless
  },
  {
    component: WhatsAppRegistrationGeneric,
    path: WhatsAppRegistrationGeneric.getPath(),
    layout: LayoutType.headless
  },
  {
    component: MarketProfileView,
    path: MarketProfileView.getPath(),
    layout: LayoutType.headless
  },
  {
    component: InstallView,
    path: InstallView.getPath(),
    layout: LayoutType.main
  },
  {
    component: LoginPasswordLessView,
    path: LoginPasswordLessView.getPath(),
    layout: LayoutType.main
  }
]

export default routes
