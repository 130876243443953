import {useContext, useMemo} from 'react'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'
import {LinearProgress} from '@mui/material'

function SurveyProgressView() {
  const context = useContext(SRContext)
  const progress = useMemo(() => {
    const currentIndex =
      context.renderer
        .getPages()
        .findIndex((page) => page.name === context.page.name) + 1
    return [currentIndex, context.renderer.getPages().length]
  }, [context])
  const v = (progress[0] / progress[1]) * 100
  return <LinearProgress variant="determinate" value={v} />
}

export default SurveyProgressView
