import React from 'react'
import {Stack, Typography} from '@mui/material'
import Money from 'web-common/components/money/Money'
import {OfferData} from 'web-common/models/OfferModel'
import {useTranslation} from 'react-i18next'
import PriceBox from 'web-common/components/pricing/PriceBox'

export interface OfferPreviewProps {
  offer: OfferData
}

export const OfferPreview = (props: OfferPreviewProps) => {
  const {t} = useTranslation()
  const extractPrices = () => {
    if (props.offer.noPricingReason) {
      const reasonLabel = props.offer.noPricingReason.reason
        .replaceAll('_', '-')
        .toLocaleLowerCase()
      return (
        <Typography
          variant={'body1'}
          component={'div'}
          style={{whiteSpace: 'pre-wrap', overflowWrap: 'anywhere'}}
        >
          <Stack flexDirection={'column'}>
            <strong>
              {t(`common:fnd-common-no-offer-reason-${reasonLabel}`)}
            </strong>
            {props.offer.noPricingReason.tax && (
              <Money value={props.offer.noPricingReason.tax} />
            )}
            <span>{props.offer.noPricingReason.descriptions?.join(', ')}</span>
          </Stack>
        </Typography>
      )
    }
    if (props.offer.pricing?.items) {
      return <PriceBox prices={props.offer.pricing!.items} />
    }

    // LEGACY
    return (
      <>
        {props.offer.price && (
          <Typography variant={'h5'}>
            {props.offer.price}
            <Typography
              variant={'body1'}
              color={'textSecondary'}
              component={'span'}
            >
              {t('common:fnd-common-total')}
            </Typography>
          </Typography>
        )}
        {props.offer.hourRate && (
          <Typography variant={'h5'}>
            {props.offer.hourRate}
            <Typography
              variant={'body1'}
              color={'textSecondary'}
              component={'span'}
            >
              {t('common:fnd-common-per-hour')}
            </Typography>
          </Typography>
        )}
      </>
    )
  }

  return (
    <Stack flexDirection={'column'} spacing={1}>
      <Stack flexDirection={'column'}>{props.offer && extractPrices()}</Stack>
      <Typography variant={'body1'} color={'textSecondary'}>
        {props.offer.comment ?? ''}
      </Typography>
    </Stack>
  )
}
