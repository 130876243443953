import React from 'react'
import {QuestionRadiogroupModel} from 'survey-react'
import {Collapse, TextField} from '@mui/material'
import surveyInput from './radiocheckbox'
import {createRoot} from 'react-dom/client'

function CheckboxExample(props: {
  question: QuestionRadiogroupModel
  onChangeOther: (n: string) => void
}) {
  let allItems: any[] = [
    ...(typeof props.question.propertyHash.choices === 'function'
      ? props.question.propertyHash.choices()
      : props.question.propertyHash.choices)
  ]
  if (props.question.choicesOrder === 'asc') {
    allItems = allItems.sort((a, b) => a.text.localeCompare(b.text))
  } else if (props.question.choicesOrder === 'desc') {
    allItems = allItems.sort((a, b) => b.text.localeCompare(a.text))
  }
  const initState: string[] = props.question.value || []
  const [checked, setChecked] = React.useState(initState)
  const handleToggle = (value: string) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
    props.question.value = newChecked

    /**
     * Go To next page if has next page and if value match property
     */
    // @ts-ignore
    if (props.question.goToNextPage) {
      const matches = props.question.goToNextPage.filter((item: string) => {
        return (props.question.value as string[]).includes(item)
      })

      if (matches.length === props.question.goToNextPage.length) {
        // @ts-ignore
        props.question.survey.nextPage()
      }
    }
  }
  if (props.question.hasOther) {
    allItems.push({value: 'other'})
  }
  const list = allItems.map((item, index) =>
    surveyInput(
      'checkbox',
      checked.indexOf(item.value) !== -1,
      item.value,
      item.value === 'other' ? props.question.otherText : item.text,
      (value) => {
        handleToggle(value)
      },
      'checkbox' +
        index +
        (item.value === 'other' ? props.question.otherText : item.value)
    )
  )
  const ref = React.createRef<HTMLInputElement>()
  const other = (
    <Collapse
      in={checked.indexOf('other') !== -1}
      collapsedSize={0}
      key={'otherinput'}
      onEntered={() => {
        ref.current?.focus()
      }}
    >
      <TextField
        inputRef={ref}
        inputProps={{
          defaultValue: props.question.comment,
          placeholder: props.question.otherPlaceHolder,
          onChange: (event: any) => {
            props.question.updateCommentFromSurvey(event.target.value)
            props.onChangeOther(event.target.value)
          }
        }}
        fullWidth
      />
    </Collapse>
  )
  return (
    <>
      {list} {other}
    </>
  )
}

const WIDGET_NAME = 'checkbox'
const widgetReactCheckbox = {
  value: '',
  updateValue: function (value: string) {
    this.value = value
  },
  name: WIDGET_NAME,
  title: 'React Checkbox',
  iconName: '',
  isFit: function (question: any) {
    return question.getType() === WIDGET_NAME
  },
  isDefaultRender: false,
  htmlTemplate: '<div></div>',
  afterRender: function (question: QuestionRadiogroupModel, el: HTMLElement) {
    const root = createRoot(el)
    root.render(
      <CheckboxExample
        question={question}
        onChangeOther={this.updateValue.bind(this)}
      />
    )
  },
  willUnmount: function (question: QuestionRadiogroupModel, el: HTMLElement) {
    if (this.value) {
      // question.hasComment = true
      question.comment = this.value
    }
  }
}
export default widgetReactCheckbox
