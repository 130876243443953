import {FileUtils} from 'web-common/services/files/FileUtils'

export enum ImageSizeType {
  logo = 'logo',
  thumb = 'thumb',
  standard = 'standard',
  category = 'category',
  gallery = 'gallery',
  hero = 'hero'
}

export interface ImageSize {
  w: number
  h: number
}

export const ImageSizes: {[type: string]: ImageSize} = {
  logo: {
    w: 128,
    h: 128
  },
  thumb: {
    w: 150,
    h: 113
  },
  standard: {
    w: 600,
    h: 450
  },
  category: {
    w: 376,
    h: 165
  },
  hero: {
    w: 1200,
    h: 400
  },
  preview: {
    w: 400,
    h: 300
  },
  gallery: {
    w: 1024,
    h: 768
  }
}

class ImageResizeService {
  /**
   * Predefined image dimensions
   */
  private sizes = {
    logo: ImageSizes.logo,
    thumb: ImageSizes.thumb,
    standard: ImageSizes.standard,
    category: ImageSizes.category,
    hero: ImageSizes.hero,
    gallery: ImageSizes.gallery
  }

  private resizeProportionately(
    imageData: string,
    fileType: string,
    MAX_WIDTH: number,
    MAX_HEIGHT: number
  ): Promise<string> {
    return new Promise((resolve) => {
      const img = document.createElement('img')
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')!
        ctx.imageSmoothingQuality = 'high'

        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height = height * (MAX_WIDTH / width)
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width = width * (MAX_HEIGHT / height)
            height = MAX_HEIGHT
          }
        }

        canvas.width = width
        canvas.height = height
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL(fileType))
      }
      img.src = imageData
    })
  }

  /**
   * Resize base64 image to new string
   *
   * @param imageData
   * @param fileType
   * @param MAX_WIDTH
   * @param MAX_HEIGHT
   * @param SCALE_TO_FIT resize image to fit aspect ratio not the size
   */
  private resize(
    imageData: string,
    fileType: string,
    MAX_WIDTH: number,
    MAX_HEIGHT: number,
    SCALE_TO_FIT: boolean = false
  ): Promise<string> {
    return new Promise((resolve) => {
      const img: any = document.createElement('img')
      img.onload = function () {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')!
        const oc = document.createElement('canvas')
        const octx = oc.getContext('2d')!
        const step = 0.5

        canvas.width = MAX_WIDTH
        canvas.height = MAX_HEIGHT

        if (SCALE_TO_FIT) {
          // SCALE IMAGE TO FIT
          const SCALE = Math.max(
            img.width / MAX_WIDTH,
            img.height / MAX_HEIGHT,
            MAX_WIDTH / img.width,
            MAX_HEIGHT / img.height
          )
          canvas.width *= SCALE * (1 / step)
          canvas.height *= SCALE * (1 / step)
          // get the scale
          const s = Math.min(
            canvas.width / img.width,
            canvas.height / img.height
          )
          // get the top left position of the image
          const x = canvas.width / 2 - (img.width / 2) * s
          const y = canvas.height / 2 - (img.height / 2) * s
          ctx.drawImage(img, x, y, img.width * s, img.height * s)
          const dataUrl = canvas.toDataURL(fileType)
          resolve(dataUrl)
          return
        }

        // Smooth resize
        let cur = {
          width: Math.floor(img.width * 0.5),
          height: Math.floor(img.height * 0.5)
        }
        oc.width = cur.width
        oc.height = cur.height
        octx.drawImage(img, 0, 0, cur.width, cur.height)

        while (cur.width * step > canvas.width) {
          cur = {
            width: Math.floor(cur.width * step),
            height: Math.floor(cur.height * step)
          }
          octx.drawImage(
            oc,
            0,
            0,
            cur.width * 2,
            cur.height * 2,
            0,
            0,
            cur.width,
            cur.height
          )
        }
        // Calc center of the canvas
        const scale = Math.min(
          canvas.width / cur.width,
          canvas.height / cur.height
        )
        const w = cur.width * scale
        const h = cur.height * scale
        const left = canvas.width / 2 - w / 2
        const top = canvas.height / 2 - h / 2
        ctx.drawImage(oc, 0, 0, cur.width, cur.height, left, top, w, h)
        const dataUrl = canvas.toDataURL(fileType)
        resolve(dataUrl)
      }
      img.src = imageData
    })
  }

  public getImageSize(imageData: string): Promise<ImageSize> {
    return new Promise((resolve) => {
      const img = document.createElement('img')
      img.onload = () => {
        resolve({
          w: img.width,
          h: img.height
        })
      }
      img.src = imageData
    })
  }

  public scaleToCoverBase64(
    imageData: string,
    fileType: string,
    x: number,
    y: number,
    w: number,
    h: number,
    offsetX: number,
    offsetY: number
  ) {
    return new Promise((resolve) => {
      const img = document.createElement('img')
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = w
        canvas.height = h
        const ctx = canvas.getContext('2d')!

        // default offset is center
        offsetX = typeof offsetX === 'number' ? offsetX : 0.5
        offsetY = typeof offsetY === 'number' ? offsetY : 0.5

        // keep bounds [0.0, 1.0]
        if (offsetX < 0) offsetX = 0
        if (offsetY < 0) offsetY = 0
        if (offsetX > 1) offsetX = 1
        if (offsetY > 1) offsetY = 1

        let iw = img.width,
          ih = img.height,
          r = Math.min(w / iw, h / ih),
          nw = iw * r, // new prop. width
          nh = ih * r, // new prop. height
          cx,
          cy,
          cw,
          ch,
          ar = 1

        // decide which gap to fill
        if (nw < w) ar = w / nw
        if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh // updated
        nw *= ar
        nh *= ar

        // calc source rectangle
        cw = iw / (nw / w)
        ch = ih / (nh / h)

        cx = (iw - cw) * offsetX
        cy = (ih - ch) * offsetY

        // make sure source rectangle is valid
        if (cx < 0) cx = 0
        if (cy < 0) cy = 0
        if (cw > iw) cw = iw
        if (ch > ih) ch = ih

        // fill image in dest. rectangle
        ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h)
        const dataUrl = canvas.toDataURL(fileType)
        resolve(dataUrl)
      }
      img.src = imageData
    })
  }

  /**
   * Extract file type from base64 url
   * @param image
   */
  getTypeFromBase64(image: string): string {
    return image.match(/data:(.*);base.*/)![1]
  }

  /**
   * Convert file to bas64
   * @param file
   */
  getBase64FromFile(file: File): Promise<string> {
    return FileUtils.fileToBase64(file)
  }

  /**
   * Convert base64 to file
   * @param dataString
   */
  getFileFromBase64(dataString: string): Promise<File> {
    return FileUtils.base64ToFile(dataString)
  }

  /**
   * Resize base64 string to preferred size
   *
   * @param imageData
   * @param size
   * @param forceType - force image file type
   */
  resizeBase64(
    imageData: string,
    size: ImageSize,
    forceType?: string
  ): Promise<string> {
    return new Promise(async (resolve) => {
      const type = this.getTypeFromBase64(imageData)
      const resizedString = await this.resize(
        imageData,
        forceType ?? type,
        size.w,
        size.h
      )
      resolve(resizedString)
    })
  }

  /**
   * Resize & convert file input image to base64 and preferred size
   * @param file
   * @param size
   * @param forceType - force apply jpg, png etc
   */
  async resizeFile(
    file: File,
    size: ImageSizeType | ImageSize,
    forceType?: string
  ): Promise<string> {
    const dataString = await this.getBase64FromFile(file)
    const sizeObject = typeof size === 'string' ? this.sizes[size] : size
    return await this.resize(
      dataString,
      forceType ?? file.type,
      sizeObject.w,
      sizeObject.h
    )
  }

  /**
   * Resize & convert file input image to base64 and preferred size
   * @param file
   * @param size
   * @param forceType - force apply jpg, png etc
   */
  async resizeFileProportionately(
    file: File,
    size: ImageSizeType | ImageSize,
    forceType?: string
  ): Promise<string> {
    const dataString = await this.getBase64FromFile(file)
    const sizeObject = typeof size === 'string' ? this.sizes[size] : size
    return await this.resizeProportionately(
      dataString,
      forceType ?? file.type,
      sizeObject.w,
      sizeObject.h
    )
  }

  async scaleToFitRatio(
    file: File,
    size: ImageSizeType,
    forceType?: string
  ): Promise<string> {
    const dataString = await this.getBase64FromFile(file)
    const sizeObject = this.sizes[size]
    return await this.resize(
      dataString,
      forceType ?? file.type,
      sizeObject.w,
      sizeObject.h,
      true
    )
  }

  async scaleToFitRatioBase64(
    imageData: string,
    size: ImageSize,
    forceType?: string
  ): Promise<string> {
    const type = this.getTypeFromBase64(imageData)
    return await this.resize(imageData, forceType ?? type, size.w, size.h, true)
  }
}

const imageResizeService = new ImageResizeService()
export default imageResizeService
