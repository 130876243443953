import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementAlert} from '@fnd/survey'
import {Alert, AlertTitle} from '@mui/material'

export default function SurveyElementUIAlert({
  element
}: SurveyFactoryElementBaseProps<SurveyElementAlert>) {
  return (
    <Alert severity={element.severity} id={element.name}>
      {element.header && <AlertTitle>{element.header}</AlertTitle>}
      {element.body}
    </Alert>
  )
}
