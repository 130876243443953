import {BehaviorSubject} from 'rxjs'
import {AppConfig} from 'AppConfig'

class GoogleMapsLoader {
  ready = new BehaviorSubject(false)

  private static _instance: GoogleMapsLoader

  private constructor() {}

  public static instance(language: string): GoogleMapsLoader {
    if (!GoogleMapsLoader._instance) {
      GoogleMapsLoader._instance = new GoogleMapsLoader()

      // Dynamic load script
      const script = document.createElement('script')
      script.id = 'google-maps-api'
      // clubsmarty project -> credentials -> fnd-maps-js-api-key
      script.src = `https://maps.googleapis.com/maps/api/js?key=${AppConfig.googleMapsApiKey}&loading=async&language=${language}&libraries=places,geometry,marker&callback=transportGMA`
      script.async = true
      // Add script to document body
      document.body.appendChild(script)

      // @ts-ignore
      window.transportGMA = function () {
        GoogleMapsLoader._instance.ready.next(true)
      }
    }

    return GoogleMapsLoader._instance
  }
}

export default GoogleMapsLoader
