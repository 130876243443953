import {Divider, styled} from '@mui/material'
import Box from '@mui/material/Box'
import HeaderImage from 'views/home/assets/header.jpg'
import HeaderImage960 from 'views/home/assets/header-960.jpg'
import HeaderImage600 from 'views/home/assets/header-600.jpg'
import Grid from '@mui/material/Grid'

export const ContentWrapper = styled(Box)((props) => ({
  background: props.theme.palette.common.white,
  marginTop: '-7rem',
  paddingTop: '7rem'
}))
export const HeaderWrapper = styled('div')((props) => ({
  width: '100%',
  minHeight: '200px',
  backgroundSize: 'auto 350px',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
  marginTop: '96px',
  paddingTop: '145px',
  position: 'relative',
  zIndex: 9,
  backgroundImage: `url(${HeaderImage})`,
  '& > .MuiContainer-root': {
    padding: '2rem 1rem'
  },
  [props.theme.breakpoints.down('md')]: {
    backgroundImage: `url(${HeaderImage960})`,
    marginTop: '0px',
    minHeight: '0px'
  },
  [props.theme.breakpoints.down('sm')]: {
    paddingTop: '158px',
    backgroundImage: `url(${HeaderImage600})`
  }
}))
export const WorkerWrapper = styled('div')((_) => ({
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'top center',
  position: 'absolute',
  width: '120px',
  height: '150px',
  left: '-18px',
  top: '-20px',
  zIndex: 1
}))
export const GrayBoxWrapper = styled('div')((_) => ({
  height: '130px',
  background: '#f8f8f8',
  position: 'relative'
}))
export const HowItWorksWrapper = styled('div')((props) => ({
  margin: '152px 0',
  [props.theme.breakpoints.down('md')]: {
    margin: '64px 0'
  },
  [props.theme.breakpoints.down('sm')]: {
    margin: '64px 0'
  }
}))
export const ArrowWrapper = styled('div')((props) => ({
  width: '0',
  height: '0',
  borderStyle: 'solid',
  borderWidth:
    props.theme.direction === 'ltr' ? '65px 0 65px 50px' : '65px 50px 65px 0',
  borderColor: 'transparent transparent transparent #f8f8f8'
}))

export const UrgentWrapper = styled('div')((props) => ({
  background: props.theme.palette.primary.main,
  color: 'white',
  fontSize: '10px',
  fontWeight: 500,
  padding: '0px 12px',
  borderRadius: '10px',
  position: 'relative',
  top: '-12px',
  display: 'inline-block'
}))
export const UrgentServiceBox = styled('div')((_) => ({
  transition: 'all .2s ease-in-out',
  borderRadius: '8px',
  opacity: 1,
  background: 'gray',
  width: '100%',
  paddingTop: '58%',
  position: 'relative',
  overflow: 'hidden',
  '& img': {
    transition: 'all .2s ease-in-out',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0px',
    left: '0px'
  }
}))
export const UrgentServiceLabel = styled('div')((_) => ({
  textAlign: 'start',
  color: '#6d757e',
  fontSize: '14px',
  fontWeight: 500,
  marginTop: '4px'
}))
export const TopCategoriesWrapper = styled('div')((props) => ({
  textAlign: 'center',
  marginTop: '100px',
  marginBottom: '30px',
  [props.theme.breakpoints.down('xs')]: {
    marginTop: '0px'
  }
}))

export const HomeClickableGrid = styled(Grid)((props) => ({
  cursor: 'pointer',
  outline: 'none',
  opacity: 1,
  '&:hover, &:focus': {
    opacity: 0.5
  },
  '&:hover img, &:focus img': {
    transform: 'scale(1.3)'
  },
  '&:focus div:first-of-type': {
    boxShadow: `0 0 3pt 2pt ${props.theme.palette.primary.main}`
  }
}))

export const HomeDivider = styled(Divider)((props) => ({
  margin: '60px 0',
  [props.theme.breakpoints.down('xs')]: {
    margin: '30px 0'
  }
}))

export const HomeCategoryGrid = styled(Grid)((_) => ({
  transition: 'all .2s ease-in-out',
  opacity: 1,
  '&:hover, &:focus': {
    opacity: 0.5,
    transform: 'scale(1.1)'
  }
}))
