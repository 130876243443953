import React from 'react'
import {Box} from '@mui/material'
import Grid from '@mui/material/Grid'
import {WithTranslation, withTranslation} from 'react-i18next'
import {TransformComponent, TransformWrapper} from 'react-zoom-pan-pinch'
import {
  AppGalleryModalWrapper,
  AppGalleryModalWrapperImage,
  CarouselController,
  CarouselLeftHandler,
  CarouselRightHandler,
  CarouselThumbImage,
  CarouselThumbsHolderGrid
} from 'web-common/components/gallery/AppGalleryStyles'
import {ImageSet} from 'web-common/surveyjs-customization/image-upload'
import Arrow from 'web-common/components/arrow/Arrow'
import {ImageSizes} from 'web-common/services/ImageResizeService'
import AppGalleryModal from 'web-common/components/gallery/AppGalleryModal'

interface AppGalleryProps extends WithTranslation {
  // Image set to render
  images: ImageSet[]
  // Will allow to open thumb in modal with preview size
  allowPreview?: boolean
  // Will show all images without prev & next controllers
  showAll?: boolean
}

interface AppGalleryState {
  // Image index to load in preview modal
  imageOnFocus: number
  // Image set to render
  images: ImageSet[]
  // Allow user to click on thumb and show preview image in modal
  showPreview: boolean
  // CSS property for thumb carousel
  thumbOffset: number

  // Access state property by key
  [key: string]: number | boolean | ImageSet[]
}

class AppGallery extends React.Component<AppGalleryProps, AppGalleryState> {
  state: AppGalleryState = {
    showPreview: false,
    imageOnFocus: 0,
    images: this.props.images,
    thumbOffset: 0
  }
  carouselRef = React.createRef<HTMLDivElement>()

  componentDidMount() {
    if (this.props.allowPreview) {
      document.addEventListener('keydown', this.onKeyDown.bind(this))
    }
    // Reload carouselRef
    this.setState({})
  }

  componentWillUnmount() {
    if (this.props.allowPreview) {
      document.removeEventListener('keydown', this.onKeyDown.bind(this))
    }
  }

  onKeyDown(e: KeyboardEvent) {
    if (this.state.showPreview) {
      let key = e.code
      if (key === 'ArrowLeft' || key === 'ArrowRight') {
        if (this.props.i18n.dir() === 'rtl') {
          key = key === 'ArrowLeft' ? 'ArrowRight' : 'ArrowLeft'
        }
        ;(key === 'ArrowLeft' ? this.onPrevItem : this.onNextItem).call(
          this,
          'imageOnFocus'
        )
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }

  onChangePreviewIndex(index: number) {
    if (this.props.allowPreview) {
      this.setState({imageOnFocus: index, showPreview: true})
    }
  }

  closePreview() {
    this.setState({showPreview: false})
  }

  onNextItem(key: 'thumbOffset' | 'imageOnFocus') {
    const position: number = this.state[key]
    const limit = this.state.images.length
    const newPosition = position >= limit - 1 ? 0 : position + 1
    this.setState({[key]: newPosition})
  }

  onPrevItem(key: 'thumbOffset' | 'imageOnFocus') {
    const position: number = this.state[key]
    const limit = this.state.images.length
    const newPosition = position <= 0 ? limit - 1 : position - 1
    this.setState({[key]: newPosition})
  }

  renderPreview() {
    if (this.state.images.length > this.state.imageOnFocus) {
      const image = this.state.images[this.state.imageOnFocus]!
      return (
        <AppGalleryModalWrapper>
          <AppGalleryModalWrapperImage>
            <TransformWrapper>
              <TransformComponent>
                <img src={image.standard} alt={'preview'} />
              </TransformComponent>
            </TransformWrapper>
          </AppGalleryModalWrapperImage>

          <CarouselLeftHandler
            onClick={this.onPrevItem.bind(this, 'imageOnFocus')}
          >
            <Arrow direction={'left'} />
          </CarouselLeftHandler>
          <CarouselRightHandler
            onClick={this.onNextItem.bind(this, 'imageOnFocus')}
          >
            <Arrow direction={'right'} />
          </CarouselRightHandler>
        </AppGalleryModalWrapper>
      )
    }
    return null
  }

  renderCarousel() {
    const images = this.state.images
    const containerWidth = this.props.showAll
      ? '100%'
      : images.length * (ImageSizes.thumb.w + 24) + 'px'
    const dirMultiplier = this.props.i18n.dir() === 'ltr' ? -1 : 1
    const thumbGridItemWidth = 166
    let showLeftController = false
    let showRightController = false
    if (this.state.thumbOffset > 0) {
      showLeftController = true
    }

    const carouselWidth =
      this.carouselRef.current?.getBoundingClientRect().width ?? 0
    const imagesWidth = images.length * (ImageSizes.thumb.w + 24)
    const currentOffset = this.state.thumbOffset * (ImageSizes.thumb.w + 24)

    if (carouselWidth < imagesWidth - currentOffset) {
      showRightController = true
    }

    return (
      <div ref={this.carouselRef}>
        <CarouselThumbsHolderGrid
          container
          flexDirection={'row'}
          alignItems="center"
          spacing={0}
        >
          {/* PREVIOUS CONTROLLER */}
          <Grid item>
            {((_) => {
              if (!this.props.showAll && showLeftController) {
                return (
                  <CarouselController
                    onClick={this.onPrevItem.bind(this, 'thumbOffset')}
                  >
                    <Arrow direction={'left'} />
                  </CarouselController>
                )
              }
              return <Box width="20px" />
            })()}
          </Grid>

          {/* GALLERY CAROUSEL */}
          <Grid item style={{width: 'calc(100% - 40px)', overflow: 'hidden'}}>
            <Box
              width={containerWidth}
              style={{
                paddingBottom: '15px',
                transition: 'all 0.2s',
                transform: `translateX(${
                  dirMultiplier * thumbGridItemWidth * this.state.thumbOffset
                }px)`
              }}
            >
              <Grid container spacing={2}>
                {images.map((image, index) => {
                  return (
                    <Grid item key={'img-' + index}>
                      <CarouselThumbImage
                        onClick={this.onChangePreviewIndex.bind(this, index)}
                        style={{
                          cursor: this.props.allowPreview
                            ? 'pointer'
                            : 'default'
                        }}
                      >
                        <img src={image.thumb} alt={'thumb'} />
                      </CarouselThumbImage>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </Grid>

          {/* NEXT CONTROLLER */}
          <Grid item>
            {((_) => {
              if (!this.props.showAll && showRightController) {
                return (
                  <CarouselController
                    onClick={this.onNextItem.bind(this, 'thumbOffset')}
                  >
                    <Arrow direction={'right'} />
                  </CarouselController>
                )
              }
              return <Box width="20px" />
            })()}
          </Grid>
        </CarouselThumbsHolderGrid>
      </div>
    )
  }

  render() {
    return (
      <>
        {/* THUMBNAIL SCROLL */}
        {this.renderCarousel()}

        <AppGalleryModal
          open={this.state.showPreview}
          title={`${this.state.imageOnFocus + 1}/${this.state.images.length}`}
          onClose={this.closePreview.bind(this)}
          maxWidth={'lg'}
        >
          {/* PREVIEW BIG IMAGE */}
          {this.renderPreview()}
        </AppGalleryModal>
      </>
    )
  }
}

export default withTranslation()(AppGallery)
