import {
  Breakpoint,
  GridSpacing,
  PaletteColor,
  ThemeOptions
} from '@mui/material'
import {ColorPartial, TypeAction} from '@mui/material/styles/createPalette'
import rtlPlugin from 'stylis-plugin-rtl'
import createCache from '@emotion/cache'

export const whiteColor = '#ffffff'
export const blackColor = '#000000'
export const backgroundColor = '#f3f4f6'

export const primaryColor: PaletteColor = {
  main: '#007fff',
  light: '#69aeff',
  dark: '#0059b2',
  contrastText: whiteColor
}
export const secondaryColor: PaletteColor = {
  main: '#8000ff',
  light: '#ba4dff',
  dark: '#4000ca',
  contrastText: whiteColor
}
export const successColor: PaletteColor = {
  dark: '#388e3c',
  light: '#81c784',
  main: '#4caf50',
  contrastText: whiteColor
}
export const errorColor: PaletteColor = {
  main: '#f44336',
  light: '#e57373',
  dark: '#d32f2f',
  contrastText: whiteColor
}
export const warningColor: PaletteColor = {
  main: '#ff9800',
  light: '#ffb74d',
  dark: '#f57c00',
  contrastText: whiteColor
}
export const infoColor: PaletteColor = {
  main: '#007fff',
  light: '#69aeff',
  dark: '#0059b2',
  contrastText: whiteColor
}
export const grayColor: ColorPartial = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  700: '#616161',
  800: '#424242',
  900: '#212121',
  A100: '#d5d5d5',
  A200: '#aaaaaa',
  A400: '#303030',
  A700: '#303030'
}
export const actionColor: Partial<TypeAction> = {
  hover: 'rgba(0,0,0,0.04)',
  hoverOpacity: 0.04,
  selected: 'rgba(0,0,0,0.08)',
  selectedOpacity: 0.08,
  disabled: 'rgba(0,0,0,0.38)',
  disabledOpacity: 0.26,
  disabledBackground: 'rgba(0, 89, 178, 0.14)',
  active: 'rgba(0, 0, 0, 0.54)'
}

export const BaseTheme: ThemeOptions = {
  // App Directions
  direction: 'ltr',
  typography: {
    fontSize: 16,
    fontFamily: `Arimo, Roboto, sans-serif`,
    h1: {
      fontSize: '2.25rem',
      fontWeight: 700,
      lineHeight: 1.33
      //lineHeight: 1.17
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: 1.25
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1.28
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: 1.33
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1.3
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: 1.375
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: 1.75,
      fontWeight: 700
    },
    subtitle2: {
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.1px',
      fontWeight: 700
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.42
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: 1.67,
      letterSpacing: '0.4px'
    },
    overline: {
      fontSize: '0.75rem',
      lineHeight: '2.67',
      letterSpacing: '1px'
    },
    button: {
      fontSize: '0.875rem',
      letterSpacing: '0.3px'
    }
  },
  palette: {
    primary: primaryColor,
    secondary: secondaryColor,
    success: successColor,
    error: errorColor,
    warning: warningColor,
    info: infoColor,
    grey: grayColor,
    action: actionColor,
    text: {
      primary: '#0a1929',
      secondary: '#535E69',
      disabled: '#9E9E9E'
    },
    background: {
      paper: whiteColor
    }
  },

  shadows: [
    'none',
    '0px 2px 1px -1px rgba(10, 25, 41, 0.2),0px 1px 1px 0px rgba(10, 25, 41, 0.12),0px 1px 3px 0px rgba(0, 89, 178, 0.14)',
    '0px 3px 1px -2px rgba(10, 25, 41, 0.2),0px 2px 2px 0px rgba(10, 25, 41, 0.12),0px 1px 5px 0px rgba(0, 89, 178, 0.14)',
    '0px 3px 3px -2px rgba(10, 25, 41, 0.2),0px 3px 4px 0px rgba(10, 25, 41, 0.12),0px 1px 8px 0px rgba(0, 89, 178, 0.14)',
    '0px 2px 4px -1px rgba(10, 25, 41, 0.2),0px 4px 5px 0px rgba(10, 25, 41, 0.12),0px 1px 10px 0px rgba(0, 89, 178, 0.14)',
    '0px 3px 5px -1px rgba(10, 25, 41, 0.2),0px 5px 8px 0px rgba(10, 25, 41, 0.12),0px 1px 14px 0px rgba(0, 89, 178, 0.14)',
    '0px 3px 5px -1px rgba(10, 25, 41, 0.2),0px 6px 10px 0px rgba(10, 25, 41, 0.12),0px 1px 18px 0px rgba(0, 89, 178, 0.14)',
    '0px 4px 5px -2px rgba(10, 25, 41, 0.2),0px 7px 10px 1px rgba(10, 25, 41, 0.12),0px 2px 16px 1px rgba(0, 89, 178, 0.14)',
    '0px 5px 5px -3px rgba(10, 25, 41, 0.2),0px 8px 10px 1px rgba(10, 25, 41, 0.12),0px 3px 14px 2px rgba(0, 89, 178, 0.14)',
    '0px 5px 6px -3px rgba(10, 25, 41, 0.2),0px 9px 12px 1px rgba(10, 25, 41, 0.12),0px 3px 16px 2px rgba(0, 89, 178, 0.14)',
    '0px 6px 6px -3px rgba(10, 25, 41, 0.2),0px 10px 14px 1px rgba(10, 25, 41, 0.12),0px 4px 18px 3px rgba(0, 89, 178, 0.14)',
    '0px 6px 7px -4px rgba(10, 25, 41, 0.2),0px 11px 15px 1px rgba(10, 25, 41, 0.12),0px 4px 20px 3px rgba(0, 89, 178, 0.14)',
    '0px 7px 8px -4px rgba(10, 25, 41, 0.2),0px 12px 17px 2px rgba(10, 25, 41, 0.12),0px 5px 22px 4px rgba(0, 89, 178, 0.14)',
    '0px 7px 8px -4px rgba(10, 25, 41, 0.2),0px 13px 19px 2px rgba(10, 25, 41, 0.12),0px 5px 24px 4px rgba(0, 89, 178, 0.14)',
    '0px 7px 9px -4px rgba(10, 25, 41, 0.2),0px 14px 21px 2px rgba(10, 25, 41, 0.12),0px 5px 26px 4px rgba(0, 89, 178, 0.14)',
    '0px 8px 9px -5px rgba(10, 25, 41, 0.2),0px 15px 22px 2px rgba(10, 25, 41, 0.12),0px 6px 28px 5px rgba(0, 89, 178, 0.14)',
    '0px 8px 10px -5px rgba(10, 25, 41, 0.2),0px 16px 24px 2px rgba(10, 25, 41, 0.12),0px 6px 30px 5px rgba(0, 89, 178, 0.14)',
    '0px 8px 11px -5px rgba(10, 25, 41, 0.2),0px 17px 26px 2px rgba(10, 25, 41, 0.12),0px 6px 32px 5px rgba(0, 89, 178, 0.14)',
    '0px 9px 11px -5px rgba(10, 25, 41, 0.2),0px 18px 28px 2px rgba(10, 25, 41, 0.12),0px 7px 34px 6px rgba(0, 89, 178, 0.14)',
    '0px 9px 12px -6px rgba(10, 25, 41, 0.2),0px 19px 29px 2px rgba(10, 25, 41, 0.12),0px 7px 36px 6px rgba(0, 89, 178, 0.14)',
    '0px 10px 13px -6px rgba(10, 25, 41, 0.2),0px 20px 31px 3px rgba(10, 25, 41, 0.12),0px 8px 38px 7px rgba(0, 89, 178, 0.14)',
    '0px 10px 13px -6px rgba(10, 25, 41, 0.2),0px 21px 33px 3px rgba(10, 25, 41, 0.12),0px 8px 40px 7px rgba(0, 89, 178, 0.14)',
    '0px 10px 14px -6px rgba(10, 25, 41, 0.2),0px 22px 35px 3px rgba(10, 25, 41, 0.12),0px 8px 42px 7px rgba(0, 89, 178, 0.14)',
    '0px 11px 14px -7px rgba(10, 25, 41, 0.2),0px 23px 36px 3px rgba(10, 25, 41, 0.12),0px 9px 44px 8px rgba(0, 89, 178, 0.14)',
    '0px 11px 15px -7px rgba(10, 25, 41, 0.2),0px 24px 38px 3px rgba(10, 25, 41, 0.12),0px 9px 46px 8px rgba(0, 89, 178, 0.14)'
  ],

  // Components
  components: {
    // DIALOG
    MuiDialog: {
      styleOverrides: {
        root: {
          width: '100%'
        },
        paper: {
          width: '100%'
        }
      }
    },
    // BUTTON
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      },
      defaultProps: {
        variant: 'contained',
        color: 'secondary',
        size: 'large'
      }
    },
    // TOGGLE BUTTONS
    MuiToggleButtonGroup: {
      defaultProps: {
        size: 'small',
        color: 'primary'
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          background: whiteColor
        }
      },
      defaultProps: {
        color: 'primary'
      }
    },
    // TEXT FIELD
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small'
      }
    },
    // PAPER
    MuiPaper: {
      defaultProps: {
        elevation: 2
      }
    },

    // CONTAINER
    MuiContainer: {
      defaultProps: {
        maxWidth: 'lg'
      }
    },

    // TYPOGRAPHY
    MuiTypography: {
      defaultProps: {
        // color: 'textSecondary'
      }
    },

    // GRID
    // MuiGrid: {
    //   defaultProps: {
    //     spacing: 3
    //   }
    // },

    // CARD
    MuiCard: {
      defaultProps: {
        variant: 'outlined'
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '1rem'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&.Mui-focusVisible': {
            outline: 'none',
            boxShadow: `0 0 0 3px ${primaryColor.main}`
          }
        }
      }
    }
  }
}

export const responsiveBP = {
  forDesktop: {mdUp: true},
  forMobile: {mdDown: true}
}

interface AppDefaultData {
  spacing: GridSpacing
  elevation: number
  container: Breakpoint
}

export const AppDefault: AppDefaultData = {
  spacing: 3,
  elevation: 2,
  container: 'lg'
}

// RTL SUPPORT
export const themeDirection = {
  ltr: createCache({
    key: 'app-ltr'
  }),
  rtl: createCache({
    key: 'app-rtl',
    // @ts-ignore
    stylisPlugins: [rtlPlugin]
  })
}
