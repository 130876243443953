import React, {ReactNode} from 'react'
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import {SvgIconComponent} from '@mui/icons-material'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import MarketProfileService, {
  MarketPlaceProfileData
} from 'views/contractor/MarketProfileService'
import RegionService, {
  RegionSelectionData
} from 'web-common/services/RegionService'
import {SearchableService} from 'web-common/models/CategoriesAndServices'
import ServicesService from 'web-common/services/ServicesService'
import AppDate from 'web-common/components/date/AppDate'
import AppRating from 'web-common/components/rating/AppRating'
import AppAvatar from 'web-common/components/avatar/AppAvatar'
import ReactTable from 'components/table/ReactTable'
import AppPage from 'web-common/utilities/AppPage'
import OnlineIcon from '@mui/icons-material/Language'
import OfficesIcon from '@mui/icons-material/Business'

interface MarketProfileViewProps {}

interface MarketProfileViewState {
  contractor?: MarketPlaceProfileData
}

class MarketProfileView extends AppPage<
  MarketProfileViewProps,
  MarketProfileViewState
> {
  static path = '/contractor/:id/market-profile'

  title = ''
  state: MarketProfileViewState = {}

  tableConfig = [
    {
      accessor: 'label',
      Header: ''
    },
    {
      accessor: 'reward',
      Header: this.props.t('fnd-mp-rewarded-credits')
    },
    {
      accessor: 'paid',
      Header: this.props.t('fnd-mp-paid-credits')
    }
  ]
  regions: RegionSelectionData[] = []
  services: SearchableService[] = []

  async componentDidMount() {
    try {
      const regions = await RegionService.getAll()
      const services = await ServicesService.load(this.props.i18n.language)
      const contractorId: string = this.props.params.id
      const profile = await MarketProfileService.get(
        contractorId,
        this.props.location.search
      )
      this.regions = regions
      this.services = services
      this.setState({contractor: profile})
    } catch (e: any) {
      console.log(e)
      this.props.navigate('/')
      this.props.apiError(e)
    }
  }

  renderBusinessInfo(Icon: SvgIconComponent, str: ReactNode) {
    return (
      <Stack direction={'row'} spacing={1}>
        <Box color={'#dedede'}>
          <Icon color={'inherit'} />
        </Box>
        <Typography variant={'body1'} component={'span'}>
          {str}
        </Typography>
      </Stack>
    )
  }

  render() {
    if (!this.state.contractor) {
      return (
        <Backdrop open={true} style={{zIndex: 9999}}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )
    }
    return (
      <Container maxWidth={'lg'}>
        {/*PAGE TITLE*/}
        <Typography variant={'h5'} style={{margin: '4rem 0 2rem'}}>
          {this.props.t('fnd-mp-business-profile-title')}
        </Typography>

        <Grid container>
          {/*BUSINESS DETAILS*/}
          <Grid item xs={12}>
            <Card>
              {/* CONTRACTOR AVATAR & RATING */}
              <CardContent>
                <Grid
                  container
                  wrap={'nowrap'}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {/* AVATAR */}
                  <Grid item>
                    <AppAvatar
                      url={this.state.contractor.pictures}
                      userId={this.state.contractor._refs.userId}
                      type={'logo'}
                    />
                  </Grid>

                  {/*DETAILS*/}
                  <Grid
                    item
                    style={{
                      flex: 1,
                      overflow: 'hidden',
                      paddingTop: '0.75rem',
                      textAlign: 'start'
                    }}
                  >
                    <Typography
                      variant={'body1'}
                      style={{
                        flex: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {this.props.title}
                    </Typography>
                    <Box paddingTop={'0.375rem'}>
                      <AppRating
                        rating={this.state.contractor.ratingSummary.rating}
                        count={this.state.contractor.ratingSummary.votes}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>

              {/* ON-BOARDING, REGIONS, SERVICES */}
              <CardContent>
                <Grid container spacing={1}>
                  {/*ON-BOARDING*/}
                  <Grid item xs={12}>
                    {this.renderBusinessInfo(
                      AssignmentIndIcon,
                      <span>
                        {this.props.t('fnd-mp-onboarding-date')} &nbsp;
                        <AppDate
                          timestamp={this.state.contractor.onboardingDate}
                          format={'date'}
                        />
                      </span>
                    )}
                  </Grid>

                  {/*ONLINE*/}
                  {((_) => {
                    if (this.state.contractor?.serviceType.online) {
                      return (
                        <Grid item xs={12}>
                          {this.renderBusinessInfo(
                            OnlineIcon,
                            this.props.t('fnd-mp-online')
                          )}
                        </Grid>
                      )
                    }
                    return null
                  })()}

                  {/*OFFICES*/}
                  {((_) => {
                    if (this.state.contractor?.offices.length > 0) {
                      return (
                        <Grid item xs={12}>
                          {this.renderBusinessInfo(
                            OfficesIcon,
                            this.state.contractor?.offices
                              .map((office) =>
                                MarketProfileService.getRegionName(
                                  office.regionId + '',
                                  this.regions,
                                  this.props.i18n.language
                                )
                              )
                              .join(', ')
                          )}
                        </Grid>
                      )
                    }
                    return null
                  })()}

                  {/*REGIONS*/}
                  {((_) => {
                    if (this.state.contractor?.regions.length > 0) {
                      return (
                        <Grid item xs={12}>
                          {this.renderBusinessInfo(
                            LocationOnIcon,
                            this.state.contractor.regions
                              .map((region) =>
                                MarketProfileService.getRegionName(
                                  region.regionId,
                                  this.regions,
                                  this.props.i18n.language
                                )
                              )
                              .join(', ')
                          )}
                        </Grid>
                      )
                    }
                    return null
                  })()}

                  {/*CATEGORIES AND SERVICES*/}
                  {((_) => {
                    if (this.state.contractor?.subscriptions.length > 0) {
                      return (
                        <Grid item xs={12}>
                          {this.renderBusinessInfo(
                            BusinessCenterIcon,
                            <>
                              <Box marginBottom={'18px'}>
                                {this.props.t('fnd-mp-categories-services')}
                              </Box>
                              {this.state.contractor.subscriptions.map(
                                (sub) => (
                                  <Grid
                                    container
                                    spacing={1}
                                    key={sub.categoryId}
                                  >
                                    <Grid item>
                                      <b>
                                        {MarketProfileService.getCategoryName(
                                          sub.categoryId,
                                          this.services
                                        )}
                                        :
                                      </b>
                                    </Grid>
                                    <Grid item>
                                      {sub.services
                                        .map((item) =>
                                          MarketProfileService.getServiceName(
                                            item,
                                            this.services
                                          )
                                        )
                                        .join(', ')}
                                    </Grid>
                                  </Grid>
                                )
                              )}
                            </>
                          )}
                        </Grid>
                      )
                    }
                    return null
                  })()}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/*ACTIVITY DETAILS*/}
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant={'h5'} style={{marginBottom: '12px'}}>
                  {this.props.t('fnd-mp-activity')}
                </Typography>
                <Typography variant={'body1'} style={{marginTop: '1.5rem'}}>
                  {this.props.t('fnd-mp-leads-assigned')}{' '}
                  <b>{this.state.contractor.stats.leads.assigned}</b>
                </Typography>
                <Typography variant={'body1'} style={{marginTop: '1.5rem'}}>
                  {this.props.t('fnd-mp-leads-accepted')}{' '}
                  <b>{this.state.contractor.stats.leads.accepted}</b>
                </Typography>
                <Typography variant={'body1'} style={{marginTop: '1.5rem'}}>
                  {this.props.t('fnd-mp-deals')}{' '}
                  <b>{this.state.contractor.stats.leads.deals}</b>
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/*CREDITS DETAILS*/}
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant={'h5'} style={{marginBottom: '12px'}}>
                  {this.props.t('fnd-mp-credits')}
                </Typography>

                <ReactTable
                  columns={this.tableConfig}
                  data={[
                    {
                      label: this.props.t('fnd-mp-total'),
                      reward: this.state.contractor.stats.credits.total,
                      paid: 0
                    },
                    {
                      label: this.props.t('fnd-mp-spent'),
                      reward: this.state.contractor.stats.credits.spent,
                      paid: 0
                    },
                    {
                      label: this.props.t('fnd-mp-balance'),
                      reward: this.state.contractor.stats.credits.balance,
                      paid: 0
                    },
                    {
                      label: this.props.t('fnd-mp-my-income'),
                      reward: 0,
                      paid: 0
                    }
                  ]}
                  tableHeaderColor={'whiteRow'}
                  tableBodyColor={'whiteRow'}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

export default MarketProfileView.exportDefault()
