import {Box, styled, TextField, Typography} from '@mui/material'
import {red} from '@mui/material/colors'

export const LocationPickerInput = styled(TextField)((props) => ({
  '& input, & .MuiInputBase-multiline': {
    background: props.theme.palette.common.white
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: props.theme.palette.primary.main + ' !important;'
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: props.theme.palette.primary.main
  },
  '& label.Mui-focused': {
    color: props.theme.palette.primary.main
  },
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: red.A100 + '!important'
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: red.A100 + '!important'
  },
  margin: '0.5rem',
  width: 'calc(100% - 1rem)',
  display: 'none',
  '.map-wrapper &': {
    display: 'block'
  }
}))
export const LocationPickerHint = styled(Typography)((props) => ({
  background: props.theme.palette.primary.main,
  color: props.theme.palette.primary.contrastText,
  fontWeight: 'bold',
  borderRadius: '0.5rem',
  padding: '0.5rem',
  marginBottom: '2.5rem'
}))

export const LocationPickerWrapper = styled(Box)({
  margin: '1rem 2rem',
  width: 'calc(100% - 4rem)',
  display: 'none',
  '.map-wrapper &': {
    display: 'block'
  }
})
