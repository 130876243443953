import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {AppConfig} from 'AppConfig'
import {
  Survey,
  SurveyElement,
  SurveyElementLocationPicker,
  SurveyElementLocationPickerValue,
  SurveyPageProcessor,
  SurveyPageProcessorMeta,
  SurveyPageProcessorResponse
} from '@fnd/survey'

const paths = {
  customer: {
    profile: {
      locations: {}
    }
  },
  requireToken: true,
  toString: () => AppConfig.api.base!
}

class SurveyCustomerAddressService extends RequestService {
  protected api: ApiConfig<typeof paths> = new ApiConfig<typeof paths>(paths)

  protected static instance: SurveyCustomerAddressService

  static shared() {
    return (
      SurveyCustomerAddressService.instance ??
      (SurveyCustomerAddressService.instance =
        new SurveyCustomerAddressService())
    )
  }

  getLastLocation(variant: SurveyElementLocationPicker['variant']) {
    return this.api.paths.customer.profile.locations.get<SurveyElementLocationPickerValue>(
      this.request
    )({params: {variant}})
  }
}

export default SurveyCustomerAddressService

export class SurveyPageProcessorCustomerAddress extends SurveyPageProcessor {
  static instance(survey: Survey, meta: SurveyPageProcessorMeta) {
    return new SurveyPageProcessorCustomerAddress(survey, meta)
  }

  process(): Promise<SurveyPageProcessorResponse> {
    return new Promise((resolve) => {
      const locationWidget = this.survey.pages[
        this.meta.pageIndex
      ].elements.find(
        (w: SurveyElement) => w.type === 'location-picker'
      ) as SurveyElementLocationPicker
      if (!locationWidget) {
        resolve({survey: this.survey, canProceed: true})
        return
      }
      SurveyCustomerAddressService.shared()
        .getLastLocation(locationWidget.variant)
        .then((value) => {
          locationWidget.value = value
        })
        .catch((e) => {
          console.error(e)
        })
        .finally(() => {
          resolve({survey: this.survey, canProceed: true})
        })
    })
  }
}
