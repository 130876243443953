import {Grid, styled} from '@mui/material'
import {ImageSizes} from 'web-common/services/ImageResizeService'

const carouselHandler = (props: any, dir: 'left' | 'right'): any => {
  const base = {
    position: 'absolute',
    borderRadius: '10px',
    fontSize: '40px',
    alignSelf: 'center',
    textAlign: 'center',
    background: 'rgba(0,0,0, 0.8)',
    color: '#555',
    cursor: 'pointer',
    top: 'calc(50% - 20px)',
    opacity: 0,
    transition: 'all 0.5s',
    [props.theme.breakpoints.down('md')]: {
      opacity: 1
    }
  }
  const add =
    dir === 'left'
      ? {
          left: '20px',
          'body:dir(rtl)': {
            left: 'initial',
            right: '20px'
          }
        }
      : {
          right: '20px',
          'body:dir(rtl)': {
            right: 'initial',
            left: '20px'
          }
        }
  return {...base, ...add}
}

export const CarouselLeftHandler = styled('div')((props) =>
  carouselHandler(props, 'left')
)

export const CarouselRightHandler = styled('div')((props) =>
  carouselHandler(props, 'right')
)

export const CarouselController = styled('div')((_) => ({
  width: '20px',
  height: '20px',
  cursor: 'pointer'
}))

export const AppGalleryModalWrapper = styled('div')((_) => ({
  position: 'relative',
  '&:hover > div': {
    opacity: 1
  }
}))

export const AppGalleryModalWrapperImage = styled('div')((props) => ({
  backgroundColor: props.theme.palette.common.black,
  textAlign: 'center',
  margin: '0 60px',
  borderRadius: '4px',
  overflow: 'hidden',
  '& img': {
    maxWidth: ImageSizes.gallery.w + 'px',
    maxHeight: 'calc(100vh - 92px)',
    objectFit: 'contain',
    width: '100%'
  },
  '& > div': {
    margin: '0 auto'
  },
  [props.theme.breakpoints.down('md')]: {
    margin: '0px'
  }
}))

export const CarouselThumbsHolderGrid = styled(Grid)((props) => ({
  width: 'calc(100% + 40px)',
  margin: '0 -20px',
  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '0px'
  }
}))

export const CarouselThumbImage = styled('div')((_) => ({
  boxShadow:
    'rgba(0, 0, 0, 0.24) 0px 5px 15px -8px, rgba(0, 0, 0, 0.2) 0px 8px 10px -5px',
  display: 'inline-block',
  textAlign: 'center',
  width: ImageSizes.thumb.w + 'px',
  height: ImageSizes.thumb.h + 'px',
  lineHeight: ImageSizes.thumb.h + 'px',
  overflow: 'hidden'
}))
