import React, {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default function ScrollToTop() {
  const location = useLocation().pathname
  const prevLocation = usePrevious(location)

  useEffect(() => {
    if (location !== prevLocation && prevLocation) {
      const currentPath = location.split('/')[1] ?? ''
      const prevPath = prevLocation.split('/')[1] ?? ''
      if (currentPath !== prevPath) {
        window.scrollTo(0, 0)
      }
    }
  })
  return <></>
}
