import React from 'react'
import {styled} from '@mui/material'

const Wrapper = styled('span')({
  dir: 'ltr'
})

export interface MoneyProps {
  value: number
}

class Money extends React.Component<MoneyProps> {
  getCurrency(): string {
    return '₪'
  }
  formatMoney() {
    return this.props.value.toFixed(0)
  }

  render() {
    return (
      <Wrapper dir={'ltr'}>
        {this.formatMoney()} {this.getCurrency()}
      </Wrapper>
    )
  }
}

export default Money
