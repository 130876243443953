import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from 'i18n/translations/en.json'
import he from 'i18n/translations/he.json'
import ar from 'i18n/translations/ar.json'
import commonEn from 'web-common/i18n/en.json'
import commonHe from 'web-common/i18n/he.json'
import commonAr from 'web-common/i18n/ar.json'

const extractCookieDomain = () => {
  const host = window.location.host
  if (host.startsWith('localhost')) {
    return ''
  }
  return (
    '.' +
    (host.split('.').length > 2 ? host.substring(host.indexOf('.') + 1) : host)
  )
}

const langDetectorConfig = {
  order: ['cookie', 'localStorage'],
  lookupCookie: 'lang',
  lookupLocalStorage: 'lang',
  caches: ['cookie', 'localStorage'],
  cookieDomain: extractCookieDomain(),
  checkWhitelist: true
}

type NameSpaces = 'translation' | 'common'

const isProductionDeployment = () =>
  window.env?.REACT_APP_WEB_DEPLOYMENT === 'production'

const resources: Record<
  string,
  Record<NameSpaces, Record<string, string | null>>
> = {
  en: {translation: en, common: commonEn},
  he: {translation: he, common: commonHe},
  ar: {translation: ar, common: commonAr}
}

export interface AppLanguage {
  name: string
  code: string
  direction: 'ltr' | 'rtl'
}

// These are the options that appear in the language selector on the UI
export const languages: AppLanguage[] = [
  {
    name: 'English',
    code: 'en',
    direction: 'ltr'
  },
  {
    name: 'עִברִית',
    code: 'he',
    direction: 'rtl'
  },
  {
    name: 'عربي',
    code: 'ar',
    direction: 'rtl'
  }
]

let fallbackLng = 'he'
// Add an empty languages that forces to shows the translation keys
// so that the admin can see which key is used where and find them
// in our translation manager (POEditor)
// https://smarty.atlassian.net/browse/FIND-1517
if (!isProductionDeployment()) {
  const fakeLanguageCode = 'dev'
  resources[fakeLanguageCode] = {
    translation: {},
    common: {}
  }
  languages.push({
    name: 'DEV',
    code: fakeLanguageCode,
    direction: 'ltr'
  })
  fallbackLng = fakeLanguageCode
}

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: Object.keys(resources),
    keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng,
    // Set the default lang to English explicitly so we don't get DEV as default
    // lng: isProductionDeployment() ? undefined : 'en',
    interpolation: {
      escapeValue: false, // react is already safe from xss
      prefix: '{',
      suffix: '}'
    },
    // Plugin config
    detection: langDetectorConfig,
    // Show debug messages in the console when we run the project locally
    // on the dev server
    debug: process.env.NODE_ENV === 'development'
  })

export default i18n
