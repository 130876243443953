import {Container, Grid, styled} from '@mui/material'
import homeImage from 'views/about/assets/home-reconstruction-2000.jpg'
import homeImage2x from 'views/about/assets/home-reconstruction-2000@2x.jpg'
import customCake from 'views/about/assets/custom-cake-2000-x-800.jpg'
import customCake2x from 'views/about/assets/custom-cake-2000-x-800@2x.jpg'

export const AboutHomeBox = styled('div')((props) => ({
  width: '100%',
  backgroundPosition: 'top left',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [props.theme.breakpoints.up('lg')]: {
    backgroundImage: `url(${homeImage2x})`
  },
  [props.theme.breakpoints.down('lg')]: {
    backgroundImage: `url(${homeImage})`
  }
}))

export const AboutHomeTextContainer = styled(Grid)((props) => ({
  backgroundColor: `#ffffffc0`,
  padding: '50px 40px',
  '& > h4': {
    marginBottom: '100px !important'
  },
  '& > h5': {
    marginBottom: '30px !important'
  },
  '& blockquote': {
    borderColor: props.theme.palette.primary.main + ' !important',
    color: props.theme.palette.primary.main + ' !important',
    marginBottom: '30px !important'
  },
  '& > blockquote > small': {
    color: props.theme.palette.primary.main + ' !important'
  },
  [props.theme.breakpoints.down('xs')]: {
    padding: '30px 20px',
    '& > h4': {
      marginBottom: '20px !important'
    }
  }
}))

export const AboutFocusWhiteBoxContainer = styled(Container)((props) => ({
  paddingTop: '120px',
  [props.theme.breakpoints.down('md')]: {
    paddingTop: '60px'
  }
}))

export const AboutFocusWhiteBoxImage = styled(Grid)((props) => ({
  width: '163px',
  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    textAlign: 'center',
    padding: '32px 36px 0 !important'
  }
}))

export const AboutFocusWhiteBoxText = styled(Grid)((props) => ({
  width: 'calc(100% - 240px)',
  [props.theme.breakpoints.down('md')]: {
    width: '100%'
  }
}))

export const AboutFocusGrayBoxContainer = styled('div')((props) => ({
  backgroundColor: '#f8f8f8',
  textAlign: 'center',
  padding: '40px 0px 60px',
  marginTop: '120px',
  [props.theme.breakpoints.down('md')]: {
    marginTop: '60px'
  }
}))

export const AboutMenCallTextGrid = styled(Grid)((props) => ({
  width: 'calc(100% - 500px)',
  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    paddingTop: '40px'
  }
}))

export const AboutMenCallImageGrid = styled(Grid)((props) => ({
  width: '500px',
  [props.theme.breakpoints.down('md')]: {
    width: '250px'
  },
  [props.theme.breakpoints.down('md')]: {
    width: '100%',
    textAlign: 'center',
    '& img': {
      width: '250px !important'
    }
  }
}))

export const AboutFooter = styled('div')((props) => ({
  margin: '60px 0px',
  width: '100%',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  [props.theme.breakpoints.up('lg')]: {
    backgroundImage: `url(${customCake2x})`
  },
  [props.theme.breakpoints.down('lg')]: {
    backgroundImage: `url(${customCake})`
  },
  '& > div': {
    maxWidth: '540px',
    backgroundColor: `#ffffffc0`,
    padding: '60px 40px',
    margin: '0 auto',
    textAlign: 'center'
  },
  '& h5': {
    marginTop: '10px !important'
  },
  '& hr': {
    margin: '30px 0px'
  }
}))

export const cssClasses = {
  homeBoxRTL: {
    transform: 'scale(-1, 1)',
    '& > div': {
      transform: 'scale(-1, 1)'
    }
  }
}
