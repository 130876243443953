import React, {ReactNode} from 'react'
import {TimelineEventProducer} from '@fnd/timeline'

export type TimelineEventTemplateProps<P> = P & {
  producer: TimelineEventProducer
}

// ALL EVENT TEMPLATES/CARDS THAT ARE RENDERED IN TIMELINE SHOULD EXTEND THIS CLASS
abstract class TimelineEventTemplate<P, S> extends React.Component<
  TimelineEventTemplateProps<P>,
  S
> {
  abstract render(): ReactNode
}

export default TimelineEventTemplate
