import logoNonBeta from 'web-common/assets/img/logo.svg'
import Box from '@mui/material/Box'
import AppModal from 'web-common/components/modal/AppModal'
import React from 'react'
import {useTranslation} from 'react-i18next'

export interface HowItWorksModalProps {
  open: boolean
  onClose: () => void
}

export default function HowItWorksModal(props: HowItWorksModalProps) {
  const [t] = useTranslation()
  return (
    <AppModal
      title={
        <>
          {t('fnd-home-how')}{' '}
          <img
            src={logoNonBeta}
            alt={'finderella'}
            style={{position: 'relative', top: '-1px'}}
            width={'100px'}
          />{' '}
          {t('fnd-home-work')}
        </>
      }
      open={props.open}
      onClose={props.onClose}
    >
      <Box width={'100%'} paddingTop={'56%'} position={'relative'}>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/rNwhCJ6JZPs?controls=1&autoplay=1"
          title={'Finderella'}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            border: 'none'
          }}
        />
      </Box>
    </AppModal>
  )
}
