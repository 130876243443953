import {AppNotificationData} from 'web-common/contexts/AppContext'
import DemandView from 'views/demands/demand/DemandView'
import {
  ASSIGNMENT_ACTIVE_TYPE,
  WS_ASSIGNMENT_ACTIVE_RECEIVE_DATA
} from 'web-common/models/ws/assignments'
import {BusEvent, Dispatcher, DispatcherPriority} from '@fnd/timeline'
import EventBus from 'web-common/services/EventBus'

class DemandsDispatcher extends Dispatcher<
  WS_ASSIGNMENT_ACTIVE_RECEIVE_DATA,
  any
> {
  protected consumable: boolean = true

  public criteria(
    event: BusEvent<WS_ASSIGNMENT_ACTIVE_RECEIVE_DATA, any>
  ): boolean {
    return event.type === ASSIGNMENT_ACTIVE_TYPE
  }

  protected priority: DispatcherPriority = 'MEDIUM'

  public static eventToNotification(
    event: BusEvent<WS_ASSIGNMENT_ACTIVE_RECEIVE_DATA, any>
  ): AppNotificationData | undefined {
    if (event.type === ASSIGNMENT_ACTIVE_TYPE) {
      return DemandsDispatcher.activeAssignmentNotification(event)
    }
    return undefined
  }

  private static activeAssignmentNotification(
    event: BusEvent<WS_ASSIGNMENT_ACTIVE_RECEIVE_DATA, undefined>
  ): AppNotificationData {
    return {
      color: 'info',
      message: 'fnd-notification-active-assignment-start-chat',
      action: (navigate) =>
        navigate(
          DemandView.getPath(
            event.data!.assignment._refs.demandId,
            event.data!.assignment._id
          )
        )
    }
  }

  private static instance: DemandsDispatcher

  public static shared() {
    if (!DemandsDispatcher.instance) {
      DemandsDispatcher.instance = new DemandsDispatcher(EventBus)
    }
    return DemandsDispatcher.instance
  }
}

export default DemandsDispatcher
