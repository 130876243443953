import React, {ChangeEvent} from 'react'
import {TextField} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import InputMask from 'react-input-mask'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'

interface AppFormPhoneProps extends AppFormComponent, WithTranslation {}

class AppFormPhone extends AppFormInputComponent<AppFormPhoneProps, any> {
  componentDidMount() {
    if (!this.props.data || typeof this.props.data !== 'string') {
      throw new Error('AppFormPhone expected a `data` property  to be string')
    }
  }

  onChange(e: ChangeEvent<HTMLInputElement>) {
    const {onChangeCallback, ...rest} = {...this.props}
    const value: string = e.currentTarget.value
    const countryCode = this.props.data as string
    const phone = value.replace(/^0/, countryCode).replace(/\s|-|_/g, '')
    this.props.onChangeCallback(rest, phone)
  }

  render() {
    let error = 'Error'
    if (this.props.dynamicError) {
      error = this.props.dynamicError(this.props.value, this.props.validator)
    } else if (this.props.errorText) {
      error = this.props.t(this.props.errorText)
    }
    const countryCode = this.props.data as string
    const value = (this.props.value ?? '').replace(
      new RegExp(`^${countryCode}`),
      '0'
    )
    let label = this.props.label ?? ''
    if (typeof label === 'string') {
      label = this.props.t(label)
    }
    const inputLabel = this.props.hasError ? error : label
    return (
      <InputMask
        mask={`999-999-9999`}
        // maskChar={null}
        value={value}
        name={this.props.name}
        onChange={this.onChange.bind(this)}
        disabled={this.props.disabled}
        placeholder={
          this.props.placeholder
            ? this.props.t(this.props.placeholder)
            : undefined
        }
      >
        {/* @ts-ignore */}
        {() => (
          <TextField
            fullWidth
            placeholder={
              this.props.placeholder
                ? this.props.t(this.props.placeholder)
                : undefined
            }
            name={this.props.name}
            id={this.props.id}
            error={this.props.hasError}
            label={inputLabel}
            inputProps={{
              dir: 'ltr'
            }}
            InputProps={{
              startAdornment: this.props.icon,
              disabled: this.props.disabled
            }}
            variant={'outlined'}
          />
        )}
      </InputMask>
    )
  }
}

export default withTranslation()(AppFormPhone)
