import React, {ReactNode} from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  Link,
  styled,
  Typography
} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import EmailIcon from '@mui/icons-material/EmailRounded'
import PhoneIcon from '@mui/icons-material/CallRounded'
import ChangePasswordIcon from '@mui/icons-material/LockRounded'
import {UserData} from 'web-common/models/Profile'
import CustomerService, {
  UserDetailsData
} from 'web-common/services/CustomerService'
import UserDetailsEdit from 'web-common/views/profile/UserDetailsEdit'
import UserContactEdit from 'web-common/views/profile/UserContactEdit'
import UserChangePassword from 'web-common/views/profile/UserChangePassword'
import AppModal from 'web-common/components/modal/AppModal'
import AppAvatar from 'web-common/components/avatar/AppAvatar'
import AppRating from 'web-common/components/rating/AppRating'
import ProfileEditSection from 'web-common/views/profile/ProfileEditSection'

interface UserDetailsViewProps extends WithTranslation {
  onProfileUpdate?: (customer: UserData) => void
}

type VoidType = () => void
const Void = () => {}

interface UserDetailsViewState {
  user?: UserData
  modal: {
    open: boolean
    title: ReactNode | string | undefined
    body: ReactNode | string | undefined
    onClose: VoidType
  }
}

const StyledLink = styled(Link)((props) => ({
  '&:hover': {
    color: props.theme.palette.primary.main
  }
}))

class UserDetailsView extends React.Component<
  UserDetailsViewProps,
  UserDetailsViewState
> {
  state: UserDetailsViewState = {
    user: undefined,
    modal: {
      open: false,
      title: '',
      body: undefined,
      onClose: Void
    }
  }

  componentDidMount() {
    CustomerService.get().then((userData) => {
      this.setState({
        user: userData
      })
    })
  }

  onDetailsEdited(userDetailsData: UserDetailsData) {
    this.setState(
      (prev) => {
        if (prev.user) {
          prev.user.contact.firstName = userDetailsData.firstName
          prev.user.contact.lastName = userDetailsData.lastName
          prev.user.picture = userDetailsData.hasAvatar ? '' : null
        }
      },
      () => {
        this.props.onProfileUpdate?.call(this, this.state.user!)
        this.closeModal()
      }
    )
  }

  onContactEdited(type: 'email' | 'phone', contact: string) {
    this.setState((state) => {
      state.user!.contact[type] = contact
      if (type === 'email') {
        state.user!.contact.emailVerified = true
      }
      return {
        user: state.user,
        modal: {
          open: false,
          title: undefined,
          body: undefined,
          onClose: Void
        }
      }
    })
  }

  onEditDetails() {
    this.openModal(
      this.props.t('common:fnd-common-edit'),
      <UserDetailsEdit
        onSave={(userDetailsData) => this.onDetailsEdited(userDetailsData)}
        onCancel={this.closeModal.bind(this)}
        onError={(message) => console.log(message)}
      />
    )
  }

  onEditEmail() {
    this.openModal(
      this.props.t('common:fnd-common-edit'),
      <UserContactEdit
        type={'email'}
        currentContact={this.state.user?.contact?.email}
        onSave={this.onContactEdited.bind(this)}
        onCancel={this.closeModal.bind(this)}
        onError={(message) => console.log(message)}
      />
    )
  }

  onEditPhone() {
    this.openModal(
      this.props.t('common:fnd-common-edit'),
      <UserContactEdit
        type={'phone'}
        onSave={this.onContactEdited.bind(this)}
        onCancel={this.closeModal.bind(this)}
        onError={(message) => console.log(message)}
      />
    )
  }

  onEditPassword() {
    this.openModal(
      this.props.t('common:fnd-common-edit'),
      <UserChangePassword
        onSave={(_: any) => {
          // console.log(data);
        }}
        onCancel={this.closeModal.bind(this)}
        onError={(message) => console.log(message)}
      />
    )
  }

  onChangePassword() {
    this.openModal(this.props.t('common:fnd-common-edit'), <></>)
  }

  render() {
    if (this.state.user === undefined) {
      return null
    }
    const userName =
      this.state.user.contact.firstName?.concat(
        ' ',
        this.state.user.contact.lastName ?? ''
      ) ?? ''
    return (
      <>
        <Card>
          <CardContent>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <ProfileEditSection
                  subtitle={
                    <CardHeader
                      avatar={
                        <AppAvatar
                          url={this.state.user?.picture}
                          userId={this.state.user?._id}
                          userName={`${
                            this.state.user?.contact.firstName ?? ' '
                          } ${this.state.user?.contact.lastName ?? ' '}`}
                          type={'avatar'}
                        />
                      }
                      title={userName}
                      subheader={
                        <AppRating
                          rating={this.state.user.ratingSummary.rating}
                          count={this.state.user.ratingSummary.votes}
                        />
                      }
                      style={{padding: '0px'}}
                    />
                  }
                  ariaLabel={this.props.t(
                    'common:fnd-common-aria-change-username-avatar'
                  )}
                  onActionClick={this.onEditDetails.bind(this)}
                />
              </Grid>
              <Grid item>
                <Divider variant={'fullWidth'} />
              </Grid>
              <Grid item>
                <ProfileEditSection
                  subtitle={
                    <Grid container spacing={1}>
                      <Grid item>
                        <Box color={'#d8d8d8'}>
                          <EmailIcon />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography variant={'body1'}>
                          {this.state.user.contact.email}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          size={'small'}
                          color={
                            this.state.user.contact.emailVerified
                              ? 'primary'
                              : 'error'
                          }
                          label={
                            <Typography variant={'body2'}>
                              {this.props.t(
                                this.state.user.contact.emailVerified
                                  ? 'common:fnd-common-verified'
                                  : 'common:fnd-common-not-verified'
                              )}
                            </Typography>
                          }
                        />
                      </Grid>
                      {((_) => {
                        if (!this.state.user.contact.emailVerified) {
                          return (
                            <Grid item>
                              <Typography variant={'body2'}>
                                <StyledLink
                                  style={{cursor: 'pointer'}}
                                  onClick={this.onEditEmail.bind(this)}
                                >
                                  {this.props.t(
                                    'common:fnd-common-verify-email'
                                  )}
                                </StyledLink>
                              </Typography>
                            </Grid>
                          )
                        }
                      })()}
                    </Grid>
                  }
                  ariaLabel={this.props.t(
                    'common:fnd-common-aria-change-user-email'
                  )}
                  onActionClick={this.onEditEmail.bind(this)}
                />
              </Grid>
              <Grid item>
                <Divider variant={'fullWidth'} />
              </Grid>
              <Grid item>
                <ProfileEditSection
                  subtitle={
                    <Grid container spacing={1}>
                      <Grid item>
                        <Box color={'#d8d8d8'}>
                          <PhoneIcon />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Typography variant={'body1'}>
                          {this.state.user.contact.phone}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  ariaLabel={this.props.t(
                    'common:fnd-common-aria-change-user-phone'
                  )}
                  onActionClick={this.onEditPhone.bind(this)}
                />
              </Grid>
              <Grid item>
                <Divider variant={'fullWidth'} />
              </Grid>
              <Grid item>
                <Grid container spacing={1} style={{cursor: 'pointer'}}>
                  <Grid item>
                    <Box color={'#d8d8d8'}>
                      <ChangePasswordIcon />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button
                      variant={'text'}
                      size={'small'}
                      color={'inherit'}
                      onClick={this.onEditPassword.bind(this)}
                    >
                      {this.props.t('common:fnd-common-change-password')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <AppModal
          open={this.state.modal.open}
          title={this.state.modal.title}
          body={this.state.modal.body}
          onClose={this.state.modal.onClose}
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
        />
      </>
    )
  }

  openModal(title: string, body: ReactNode) {
    this.setState({
      modal: {
        open: true,
        title: title,
        body: body,
        onClose: this.closeModal.bind(this)
      }
    })
  }

  closeModal() {
    this.setState({
      modal: {
        open: false,
        title: undefined,
        body: undefined,
        onClose: Void
      }
    })
  }
}

export default withTranslation()(UserDetailsView)
