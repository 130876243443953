import {
  ChatAdapterDispatcher as TChatAdapterDispatcher,
  BusEvent
} from '@fnd/timeline'
import {AppNotificationData} from 'web-common/contexts/AppContext'
import EventBus from 'web-common/services/EventBus'

export class ChatAdapterDispatcher extends TChatAdapterDispatcher {
  static instance: ChatAdapterDispatcher

  static shared(eventBus = EventBus, assignmentId?: string) {
    if (!ChatAdapterDispatcher.instance) {
      ChatAdapterDispatcher.instance = new ChatAdapterDispatcher(eventBus)
    }
    if (assignmentId) {
      ChatAdapterDispatcher.instance.assignmentId = assignmentId
    }
    return ChatAdapterDispatcher.instance
  }

  public static eventToNotification(
    event: BusEvent<any, any>
  ): AppNotificationData | undefined {
    return {
      color: 'info',
      message: 'fnd-event-notification-new-message'
    }
  }
}
