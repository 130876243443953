import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'
import yellow from './assets/q-a-yellow.jpg'
import blue from './assets/q-a-blue.jpg'
import Paper from '@mui/material/Paper'
import customer from './customer.json'
import contractor from './contractor.json'
import HeaderImageContent from 'components/HeaderImageContent'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AppPage from 'web-common/utilities/AppPage'

interface QnAViewProps {}

interface QnAViewState {
  type?: 'customer' | 'contractor'
}

class QnAView extends AppPage<QnAViewProps, QnAViewState> {
  static path = '/qna/:type'

  title = 'Q&A'
  state: QnAViewState = {}

  componentDidMount() {
    super.componentDidMount()
    const type = this.props.params.type
    this.updateType(type)
  }

  componentDidUpdate(
    prevProps: Readonly<QnAViewProps>,
    prevState: Readonly<QnAViewState>,
    snapshot?: any
  ) {
    const type = this.props.params.type
    if (type !== this.state.type) {
      this.updateType(type)
    }
  }

  updateType(type: 'customer' | 'contractor') {
    this.setState({type: type}, () => {
      this.updateTitle()
      window.scrollTo(0, 0)
    })
  }

  protected updateTitle() {
    this.title = this.props.t('fnd-qna-title-' + this.state.type)
    super.updateTitle()
  }

  parseData() {
    const data: any[] = this.state.type === 'customer' ? customer : contractor
    return data.map((item) => (
      <Accordion key={item.title}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant={'h6'} component={'h2'}>
            {item.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant={'body2'}>
            {item.content.replaceAll('{{br}}', '\n')}
          </Typography>
        </AccordionDetails>
      </Accordion>
    ))
  }

  render() {
    if (!this.state.type) {
      return null
    }
    return (
      <>
        <HeaderImageContent
          image={this.state.type === 'customer' ? yellow : blue}
          title={this.props.t('fnd-qna-title-' + this.state.type)}
        >
          <Paper elevation={3}>
            <Box whiteSpace={'pre-line'}>{this.parseData()}</Box>
          </Paper>
        </HeaderImageContent>

        {/* SPACE TO FOOTER */}
        <br />
        <br />
      </>
    )
  }
}

export default QnAView.exportDefault()
