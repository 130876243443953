import {
  SurveyElement,
  SurveyElementAlert,
  SurveyElementBudget,
  SurveyElementFileUploader,
  SurveyElementLocationPicker,
  SurveyElementLocationType,
  SurveyElementText,
  SurveyElementTimePicker,
  SurveyElementValidator,
  SurveyPage,
  SurveyValueType
} from '@fnd/survey'
import {Control, Controller, FieldValues} from 'react-hook-form'
import SurveyElementUIText from 'web-common/components/survey/ui/elements/SurveyElementUIText'
import {useContext} from 'react'
import SurveyElementUIDropdown from 'web-common/components/survey/ui/elements/SurveyElementUIDropdown'
import SurveyElementUIChoices from 'web-common/components/survey/ui/elements/SurveyElementUIChoices'
import SurveyElementUICalendar from 'web-common/components/survey/ui/elements/SurveyElementUICalendar'
import {ControllerRenderProps} from 'react-hook-form/dist/types/controller'
import {Box, Stack, styled, Typography} from '@mui/material'
import SurveyElementUIRegions from 'web-common/components/survey/ui/elements/SurveyElementUIRegions'
import SurveyElementUIFileUploader from 'web-common/components/survey/ui/elements/SurveyElementUIFileUploader'
import SurveyElementUITimePicker from 'web-common/components/survey/ui/elements/SurveyElementUITimePicker'
import SurveyElementUIBudget from 'web-common/components/survey/ui/elements/SurveyElementUIBudget'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'
import SurveyElementUIAlert from 'web-common/components/survey/ui/elements/SurveyElementUIAlert'
import SurveyElementUILocationType from 'web-common/components/survey/ui/elements/SurveyElementUILocationType'
import SurveyElementUILocationPicker from 'web-common/components/survey/ui/elements/SurveyElementUILocationPicker'
import {SurveyElementChoices} from '@fnd/survey/dist/core/SurveyModels'

const ErrorText = styled(Typography)((props) => ({
  color: props.theme.palette.error.main
}))

export interface SurveyFactoryElementBaseProps<E = SurveyElement> {
  field: ControllerRenderProps
  element: E
}

const VoidField: ControllerRenderProps = {
  name: 'dummy',
  value: undefined,
  ref: () => {},
  onChange: () => {},
  onBlur: () => {}
}

export interface SurveyElementWrapperProps {
  children: JSX.Element
  hasError?: boolean
}

export function SurveyElementWrapper({
  children,
  hasError
}: SurveyElementWrapperProps) {
  const context = useContext(SRContext)
  const lng = context.language
  const element = children.props.element
  const field = children.props.field
  const showError = hasError && element.errorText?.[lng]
  const questionNumber = context.renderer?.getQuestionNumber(element)
  return (
    <Stack key={`element-wrapper-${field.name}`}>
      {/*TITLE*/}
      <Typography variant={'body1'} component={'label'} htmlFor={field.name}>
        {questionNumber ? `${questionNumber}.` : ''} {element?.title?.[lng]}
      </Typography>
      {/*HINT*/}
      {element.hint?.[lng] && (
        <Typography variant={'caption'}>{element?.hint?.[lng]}</Typography>
      )}
      {/*INPUT*/}
      <Box display={'block'} width={'100%'}>
        {children}
      </Box>
      {/*ERROR*/}
      {showError && (
        <ErrorText variant={'caption'}>{element.errorText?.[lng]}</ErrorText>
      )}
    </Stack>
  )
}

export class SurveyFactory {
  static createFormElement(
    page: SurveyPage,
    element: SurveyElement,
    control: Control<FieldValues>,
    validator: (
      validator: SurveyElementValidator,
      value: SurveyValueType
    ) => boolean,
    hasError: boolean
  ) {
    const name = `${page.name}.${element.name}`
    const defaultValue = ((el: SurveyElement) => {
      if ((el as SurveyElementChoices).choices) {
        return el.value ?? ((el as SurveyElementChoices).multiple ? [] : '')
      }
      return el.value ?? ''
    })(element)
    return (
      <Controller
        defaultValue={defaultValue}
        render={({field}) =>
          SurveyFactory.createElement(element, field, hasError)
        }
        name={name}
        control={control}
        rules={{
          validate: (value) => {
            if (element.validation && !!element.value) {
              return validator(element.validation, value)
            }
            if (element.required) {
              return Array.isArray(value) ? value.length > 0 : !!value
            }
            return true
          }
        }}
      />
    )
  }

  static createPreviewElement(
    element: SurveyElement,
    field: ControllerRenderProps = VoidField
  ) {
    return SurveyFactory.createElement(element, field, !!element.errorText)
  }

  private static createElement(
    element: SurveyElement,
    field: ControllerRenderProps = VoidField,
    hasError?: boolean
  ) {
    return (
      <SurveyElementWrapper hasError={hasError}>
        {((_) => {
          switch (element.type) {
            case 'text':
              return (
                <SurveyElementUIText
                  field={field}
                  element={element as SurveyElementText}
                />
              )
            case 'dropdown':
              return <SurveyElementUIDropdown field={field} element={element} />
            case 'choices':
              return <SurveyElementUIChoices field={field} element={element} />
            case 'datepicker':
              return <SurveyElementUICalendar field={field} element={element} />
            case 'regions':
              return <SurveyElementUIRegions field={field} element={element} />
            case 'file-uploader':
              return (
                <SurveyElementUIFileUploader
                  field={field}
                  element={element as SurveyElementFileUploader}
                />
              )
            case 'time-picker':
              return (
                <SurveyElementUITimePicker
                  field={field}
                  element={element as SurveyElementTimePicker}
                />
              )
            case 'budget':
              return (
                <SurveyElementUIBudget
                  field={field}
                  element={element as SurveyElementBudget}
                />
              )
            case 'alert':
              return (
                <SurveyElementUIAlert
                  field={field}
                  element={element as SurveyElementAlert}
                />
              )
            case 'location-type':
              return (
                <SurveyElementUILocationType
                  field={field}
                  element={element as SurveyElementLocationType}
                />
              )
            case 'location-picker':
              return (
                <SurveyElementUILocationPicker
                  field={field}
                  element={element as SurveyElementLocationPicker}
                />
              )
            default:
              return <></>
          }
        })()}
      </SurveyElementWrapper>
    )
  }
}
