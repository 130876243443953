// HIGH CONTRAST THEME
import {BaseTheme, whiteColor} from 'web-common/theme/AppTheme'

// Jest/nodejs/ compatibility
if (!global.structuredClone)
  global.structuredClone = (o: any) => JSON.parse(JSON.stringify(o))

const contrastTheme = structuredClone(BaseTheme)
contrastTheme.palette!.primary = {
  main: '#424242',
  light: '#6D6D6D',
  dark: '#1B1B1B'
}
contrastTheme.palette!.secondary = {
  main: '#666666',
  light: '#949494',
  dark: '#3c3c3c'
}
contrastTheme.palette!.error = {
  main: '#D32F2F',
  light: '#FFCDD2',
  dark: '#9A0007',
  contrastText: whiteColor
}
contrastTheme.palette!.warning = {
  main: '#BC5100',
  light: '#FFECB3',
  dark: '#852300',
  contrastText: whiteColor
}
contrastTheme.palette!.success = {
  main: '#2E7D32',
  light: '#FBFFFC',
  dark: '#005005',
  contrastText: whiteColor
}
contrastTheme.palette!.info = {
  main: '#1976D2',
  light: '#EEFFFF',
  dark: '#004BA0',
  contrastText: whiteColor
}
export default contrastTheme
