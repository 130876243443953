import {AppAvatarProps} from 'web-common/components/avatar/AppAvatar'
import FileStorage from 'web-common/services/files/FileStorage'
import {AppConfig} from 'AppConfig'

export function getAvatarURL(props: AppAvatarProps) {
  const extractFirebasePath = () => {
    if (props.type === 'avatar') {
      return FileStorage.getWellKnownResource(
        `user/${props.userId}/avatar/avatar.jpg`
      )
    } else if (props.type === 'logo') {
      return FileStorage.getWellKnownResource(
        `user/${props.userId}/logo/logo.jpg`
      )
    } else {
      return ''
    }
  }

  const predefinedAvatar = () => {
    if (props.url!.startsWith('default://')) {
      const avatarPath = props.url!.substring(props.url!.indexOf('://') + 3)
      return `${AppConfig.customer.base}/${avatarPath}`
    } else {
      return props.url!
    }
  }

  switch (props.url) {
    case '':
      // AVATAR IS IN FIREBASE
      return extractFirebasePath()
    case null:
    case undefined:
      // USER HAS NO AVATAR
      return ''
    default:
      // PREDEFINED AVATAR
      return predefinedAvatar()
  }
}
