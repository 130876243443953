import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {
  SurveyAnalyticsData,
  SurveyElement,
  SurveyElementChoices,
  SurveyElementType,
  SurveyValueType
} from '@fnd/survey'
import {AppConfig} from 'AppConfig'

const paths = {
  'survey-progress-stats': {
    '*': {}
  },
  requireToken: true,
  toString: () => AppConfig.api.base
}

interface SurveyLogData {
  sessionId: string
  pageId: string
  questionId: string
  questionType: SurveyElementType
  questionName: string
  questionValue: string
  valueText: string | undefined
  direction: SurveyAnalyticsData['direction']
}

interface QuestionnairesStatsMeta {
  rev: number
  questionnaireId: string
  serviceId: string
}

type FullLogData = SurveyLogData & QuestionnairesStatsMeta

export default class QuestionnairesStatsService extends RequestService {
  protected api: ApiConfig<typeof paths> = new ApiConfig<typeof paths>(paths)

  protected static instance: QuestionnairesStatsService

  static shared() {
    return (
      QuestionnairesStatsService.instance ??
      (QuestionnairesStatsService.instance = new QuestionnairesStatsService())
    )
  }

  private questionValueToString(question: SurveyElement) {
    let valueString: string
    if (typeof question.value === 'string') {
      valueString = question.value
    } else {
      valueString = question.value ? JSON.stringify(question.value) : ''
    }
    return valueString
  }

  private questionValueToTranslatedString(question: SurveyElement) {
    let translatedValue: string | undefined
    const choicesElement: SurveyElementChoices =
      question as SurveyElementChoices

    const valueToChoiceLabel = (value: SurveyValueType) =>
      choicesElement.choices?.find((choice) => choice.value === value)?.label
        .en ?? (value as unknown as string)
    if (choicesElement.choices) {
      if (Array.isArray(question.value)) {
        translatedValue = question.value.map(valueToChoiceLabel).join(', ')
      } else {
        translatedValue = valueToChoiceLabel(question.value)
      }
    }
    return translatedValue
  }

  private mapToFullLogData(
    data: SurveyAnalyticsData,
    meta: QuestionnairesStatsMeta
  ): FullLogData {
    return {
      ...meta,
      sessionId: data.sessionId,
      pageId: data.pageId,
      questionId: data.question.name,
      questionType: data.question.type,
      questionName: data.question.title.en,
      questionValue: this.questionValueToString(data.question),
      valueText: this.questionValueToTranslatedString(data.question),
      direction: data.direction
    }
  }

  log(data: SurveyAnalyticsData, meta: QuestionnairesStatsMeta) {
    this.api.paths['survey-progress-stats']
      .post(this.request)(this.mapToFullLogData(data, meta))
      .catch((e) => {
        console.error(e)
      })
  }

  completeLog(sessionId: string, demandId: string) {
    this.api.paths['survey-progress-stats']['*'].put(this.request, sessionId)(
      {},
      {
        params: {
          demandId: demandId
        }
      }
    )
  }
}
