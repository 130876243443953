import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import {withUserContext, WithUserContext} from 'web-common/contexts/UserContext'
import {withAppContext, WithAppContext} from 'web-common/contexts/AppContext'
import {Link} from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import StorefrontIcon from '@mui/icons-material/Storefront'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import AppAvatar from 'web-common/components/avatar/AppAvatar'
import Footer from 'components/navigation/Footer'
import {withTranslation, WithTranslation} from 'react-i18next'
import AssignmentIcon from '@mui/icons-material/Assignment'
import FinderellaIcon from 'web-common/assets/icons/Finderella'
import appLogo from 'web-common/assets/img/logo.svg'
import SearchView from 'views/search/SearchView'
import kc from 'web-common/services/auth'
import Guard from 'routes/Guard'
import LoginGuard from 'routes/LoginGuard'
import {AppConfig} from 'AppConfig'
import CustomerService from 'web-common/services/CustomerService'
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Collapse,
  Container,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Slide,
  styled,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material'
import {responsiveBP} from 'web-common/theme/AppTheme'
import HomeView from 'views/home/HomeView'
import ProfileView from 'views/profile/ProfileView'
import DemandsView from 'views/demands/listing/DemandsView'
import GlobalEventDispatcher from 'components/navigation/dispatchers/GlobalEventDispatcher'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'
import QuestionnaireBus from 'services/QuestionnaireBus'
import AppModal from 'web-common/components/modal/AppModal'
import LanguageSelector from 'components/LanguageSelector'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import QuestionnairesView from 'views/questionnaires/QuestionnairesView'
import {EventBusRegistry} from '@fnd/timeline'

const UserName = styled(Typography)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
})

const CustomAppBar = styled(AppBar)((props) => ({
  background: props.theme.palette.common.white,
  zIndex: 10,
  padding: '0px !important'
}))

const DrawerContentWrapper = styled(Box)({
  width: 'calc(100vw - 200px)',
  maxWidth: '320px',
  minWidth: '260px',
  '& footer .small-links': {
    display: 'flex',
    columnGap: '1rem',
    rowGap: '0.25rem',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  }
})

export interface HeaderProps
  extends RouteComponentProps,
    WithUserContext,
    WithAppContext,
    WithTranslation {}

interface HeaderState {
  drawer: boolean // is mobile drawer visible
  menu: boolean // is desktop menu visible
  searchbar: boolean // is searchbar visible

  surveyIdModal?: string
  enlargeSurveyModal: boolean
}

class Header extends React.Component<HeaderProps, HeaderState> {
  state: HeaderState = {
    drawer: false,
    menu: false,
    searchbar: this.props.location.pathname !== HomeView.getPath(),
    enlargeSurveyModal: false
  }
  desktopMenuRef = React.createRef<any>()
  inRequest = false
  dispatcher: EventBusRegistry | undefined
  questionnaireBus?: () => void

  menuItems = [
    {
      icon: <AccountCircleIcon fontSize={'small'} />,
      label: 'fnd-menu-account',
      fn: () => {
        this.openGuardedLink(
          ProfileView.getPath('account'),
          LoginGuard,
          true
        ).then()
      }
    },
    {
      icon: <StorefrontIcon fontSize={'small'} />,
      label: 'fnd-menu-demands',
      fn: () => {
        this.openGuardedLink(DemandsView.getPath(), LoginGuard, true).then()
      }
    },
    {
      icon: <ExitToAppIcon fontSize={'small'} />,
      label: 'fnd-menu-logout',
      fn: () => {
        this.openGuardedLink('/logout', undefined, true).then()
      }
    }
  ]

  // Check if url is guarded, and if it is execute guard - else proceed to url
  async openGuardedLink(
    path: string,
    guard: Guard | undefined = undefined,
    fromMenu: boolean = false
  ) {
    let url = path
    if (guard) {
      if (!(await guard.hasPermission())) {
        if (guard.getFallbackUrl(this.props.location) === '/login') {
          // If fallback url is login call kc login
          await kc.automaticLoginFlow(window.location.origin + path)
        } else {
          // if fallback is custom page e.g.: upgrade plan goto custom page
          url = guard.getFallbackUrl(this.props.location)
        }
      }
    }
    if (fromMenu) {
      this.setState({menu: false}, () => {
        this.props.navigate(url)
      })
    } else {
      this.props.navigate(url)
    }
  }

  onLogin() {
    kc.automaticLoginFlow().finally(() => {
      if (
        this.props.location.state &&
        (this.props.location.state as any).from
      ) {
        // Go to previous page
        this.props.navigate((this.props.location.state as any).from)
      }
    })
  }

  onMenuCall() {
    this.setState((state) => ({menu: !state.menu}))
  }

  onDrawerCall() {
    this.setState((state) => ({drawer: !state.drawer}))
  }

  componentDidMount() {
    this.dispatcher = GlobalEventDispatcher.register(async (e) => {
      const notification = await GlobalEventDispatcher.eventToNotification(e)
      if (notification) {
        this.props.pushNotification(notification)
      }
    })
    this.questionnaireBus = QuestionnaireBus.shared().subscribe((serviceId) => {
      this.setState({surveyIdModal: serviceId})
    })
  }

  componentWillUnmount() {
    this.dispatcher?.unregister()
    this.questionnaireBus?.()
  }

  componentDidUpdate(
    prevProps: Readonly<HeaderProps>,
    prevState: Readonly<HeaderState>,
    snapshot?: any
  ) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.location.pathname === HomeView.getPath()) {
        this.setState({searchbar: false})
      } else {
        this.setState({searchbar: true})
      }
    }
  }

  // Upgrade user to business account
  requestBusinessUpgrade() {
    if (this.inRequest) {
      return
    }
    this.inRequest = true
    this.props.setBackdrop(true)
    CustomerService.requestUpgradeToBusiness()
      .then((_) => {
        this.inRequest = false
        this.props.setBackdrop(false)
        // Request success goto on-boarding
        window.location.href = `${AppConfig.contractor.base?.replace(
          /\/$/,
          ''
        )}/onboarding-welcome`
      })
      .catch((error) => {
        // Request failed
        console.log(error)
        this.inRequest = false
        this.props.setBackdrop(false)
        this.props.pushNotification({
          color: 'error',
          message: 'fnd-label-unexpected_error'
        })
      })
  }

  switchToBusinessProfile() {
    window.location.href = AppConfig.contractor.base as string
  }

  // USER AVATAR
  renderUserBlock() {
    if (this.props.profile.customer === undefined) {
      return (
        <Button
          variant={'text'}
          color={'primary'}
          size={'medium'}
          onClick={this.onLogin.bind(this)}
        >
          {this.props.t('fnd-menu-login')}
        </Button>
      )
    }
    const name = `${this.props.profile.customer.contact.firstName ?? ' '} ${
      this.props.profile.customer.contact.lastName ?? ' '
    }`
    return (
      <>
        <Tooltip title={this.props.t('fnd-aria-user-profile-menu') as string}>
          <IconButton
            aria-label={this.props.t('fnd-aria-user-profile-menu')}
            onClick={this.onMenuCall.bind(this)}
            aria-expanded={this.state.menu}
          >
            <AppAvatar
              userId={this.props.profile.customer?._id}
              userName={name}
              type={'avatar'}
              url={this.props.profile.customer?.picture}
              alt={this.props.t('fnd-aria-user-profile-menu')}
              ref={this.desktopMenuRef}
            />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={this.desktopMenuRef.current}
          open={this.state.menu}
          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          onClose={this.onMenuCall.bind(this)}
        >
          {this.renderMenuContent()}
        </Menu>
      </>
    )
  }

  // RENDER AS TOP NAVIGATION
  renderDesktop() {
    return (
      <Box style={{paddingBottom: this.props.fixedHeader ? '65px' : '0px'}}>
        <CustomAppBar position={this.props.fixedHeader ? 'fixed' : 'static'}>
          <Container maxWidth={'lg'}>
            <Toolbar disableGutters>
              <Grid
                container
                direction="row"
                wrap={'nowrap'}
                justifyContent={'space-between'}
                columnSpacing={3}
                alignItems={'center'}
              >
                {/*APP LOGO*/}
                <Grid item style={{flex: '0 1 auto'}}>
                  <Link to={HomeView.getPath()}>
                    <img alt="Finderella" src={appLogo} />
                  </Link>
                </Grid>

                {/*SERVICES SEARCH*/}
                <Grid item style={{flex: '1 1 auto'}}>
                  <Collapse in={this.state.searchbar}>
                    <SearchView compact />
                  </Collapse>
                </Grid>

                {/*LANGUAGE SELECTOR*/}
                <Grid item>
                  <LanguageSelector
                    onLanguageChange={this.props.i18n.changeLanguage.bind(
                      this.props.i18n
                    )}
                    defaultValue={this.props.i18n.language}
                    fullWidth
                  />
                </Grid>

                {/*DEMANDS*/}
                <Grid item>
                  <Button
                    variant={'text'}
                    color={'primary'}
                    size={'medium'}
                    onClick={this.openGuardedLink.bind(
                      this,
                      '/demands',
                      LoginGuard,
                      false
                    )}
                  >
                    {this.props.t('fnd-menu-demands')}
                  </Button>
                </Grid>

                {/*USER PROFILE/LOGIN BUTTON*/}
                <Grid item>{this.renderUserBlock()}</Grid>
              </Grid>
            </Toolbar>
          </Container>
        </CustomAppBar>
      </Box>
    )
  }

  //  RENDER AS BOTTOM NAVIGATION
  renderMobile() {
    return (
      <>
        {/*VOID WITH HEIGHT SIZE AS MOBILE BOTTOM NAVIGATION*/}
        {this.props.fixedHeader && <Box height={'56px'} />}
        {/* STICK AT THE BOTTOM OF THE PAGE*/}
        <Slide direction={'up'} in={this.props.fixedHeader}>
          <Paper
            sx={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2}}
            elevation={4}
          >
            <BottomNavigation>
              <BottomNavigationAction
                value="home"
                icon={<FinderellaIcon color={'primary'} />}
                arai-label={'Finderella'}
                onClick={this.openGuardedLink.bind(
                  this,
                  HomeView.getPath(),
                  undefined,
                  false
                )}
              />
              <BottomNavigationAction
                value="demands"
                icon={<AssignmentIcon />}
                arai-label={this.props.t('fnd-label-go-to-demands')}
                onClick={this.openGuardedLink.bind(
                  this,
                  DemandsView.getPath(),
                  LoginGuard,
                  false
                )}
              />
              <BottomNavigationAction
                value="menu"
                icon={<MenuIcon />}
                onClick={this.onDrawerCall.bind(this)}
                aria-expanded={this.state.drawer}
                aria-label={this.props.t('fnd-aria-mobile-navigation-menu')}
              />
            </BottomNavigation>
          </Paper>
        </Slide>

        {/* DRAWER DECLARATION */}
        <Drawer
          anchor={'right'}
          open={this.state.drawer}
          onClose={this.onDrawerCall.bind(this)}
        >
          <DrawerContentWrapper>
            {/*APP LOGO*/}
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              padding={'0.5rem 1rem'}
            >
              <Link to={HomeView.getPath()}>
                <img alt="Finderella" src={appLogo} />
              </Link>
              <IconButton
                aria-label={this.props.t('fnd-label-close')}
                onClick={this.onDrawerCall.bind(this)}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Box>
            {/*USER MENU*/}
            {((_) => {
              if (this.props.profile.customer === undefined) {
                return (
                  <Button
                    variant={'text'}
                    color={'primary'}
                    size={'medium'}
                    onClick={this.onLogin.bind(this)}
                  >
                    {this.props.t('fnd-menu-login')}
                  </Button>
                )
              }
              return this.renderMenuContent()
            })()}
            {/*RENDER FOOTER*/}
            <Footer />
          </DrawerContentWrapper>
        </Drawer>
      </>
    )
  }

  // ACTUAL MENU
  renderMenuContent() {
    const name = `${this.props.profile.customer?.contact.firstName ?? ' '} ${
      this.props.profile.customer?.contact.lastName ?? ' '
    }`
    let menu = [
      // CUSTOMER NAME
      <MenuItem tabIndex={-1} divider key={'contactor-name'} dense>
        <Hidden {...responsiveBP.forDesktop}>
          <ListItemAvatar>
            <AppAvatar
              userId={this.props.profile.customer?._id}
              userName={name}
              url={this.props.profile.customer?.picture}
              type={'avatar'}
              size={24}
            />
          </ListItemAvatar>
        </Hidden>
        <ListItemText>
          <UserName variant={'subtitle1'}>
            {(this.props.profile.customer?.contact.firstName ?? '') +
              ' ' +
              (this.props.profile.customer?.contact.lastName ?? '')}
          </UserName>
        </ListItemText>
      </MenuItem>
    ]

    this.menuItems.forEach((item) => {
      menu.push(
        <ListItemButton onClick={item.fn.bind(this)} key={item.label} dense>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText>{this.props.t(item.label)}</ListItemText>
        </ListItemButton>
      )
    })

    menu.push(<Divider key={'divider0'} />)

    if (this.props.profile.contractor) {
      // GOTO  CONTRACTOR
      menu.push(
        <MenuItem
          key={'switch-to-business'}
          onClick={this.switchToBusinessProfile.bind(this)}
          dense
        >
          <ListItemAvatar>
            <AppAvatar
              userId={this.props.profile.contractor?._refs.userId}
              url={this.props.profile.contractor?.profile?.picture}
              type={'logo'}
              size={24}
            />
          </ListItemAvatar>
          <ListItemText>{this.props.t('fnd-switch-to-business')}</ListItemText>
        </MenuItem>
      )
    } else {
      // CREATE CONTRACTOR
      menu.push(
        <MenuItem
          onClick={this.requestBusinessUpgrade.bind(this)}
          key={'request-upgrade'}
          dense
        >
          <ListItemText>
            {this.props.t('fnd-create-business-account')}
          </ListItemText>
        </MenuItem>
      )
    }

    return menu
  }

  onCloseSurveyModal() {
    this.setState({enlargeSurveyModal: false, surveyIdModal: undefined})
  }

  render() {
    return (
      <>
        <Hidden {...responsiveBP.forMobile}>{this.renderDesktop()}</Hidden>
        <Hidden {...responsiveBP.forDesktop}>{this.renderMobile()}</Hidden>

        {/* SURVEY MODAL */}
        <AppModal
          open={this.state.surveyIdModal !== undefined}
          onClose={this.onCloseSurveyModal.bind(this)}
          maxWidth={this.state.enlargeSurveyModal ? 'md' : 'sm'}
          disableBackdropClick={true}
          title={this.props.t('fnd-label-finderella-questionnaire')}
        >
          {this.state.surveyIdModal && (
            <QuestionnairesView
              serviceId={this.state.surveyIdModal}
              onBegin={() => this.setState({enlargeSurveyModal: true})}
              onComplete={this.onCloseSurveyModal.bind(this)}
            />
          )}
        </AppModal>
      </>
    )
  }
}

export default withTranslation()(
  withAppContext(withUserContext(withRouter(Header)))
)
