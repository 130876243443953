import React from 'react'
import {Box, Typography} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import RegionService, {
  RegionGeoJSONData,
  RegionSelectionData
} from 'web-common/services/RegionService'
import RegionGoogleMaps from 'web-common/components/regions/RegionGoogleMaps'

interface RegionSelectionProps extends WithTranslation {
  selected?: string[]
  onSelect?: (regionsId: string[]) => void
  maxRegions?: number
}

interface RegionSelectionState {
  geoJSONs: RegionGeoJSONData[]
  regions: RegionSelectionData[]
  selectedSubregions: string[]
}

class RegionsMapSelection extends React.Component<
  RegionSelectionProps,
  RegionSelectionState
> {
  state: RegionSelectionState = {
    geoJSONs: [],
    regions: [],
    selectedSubregions: []
  }

  max_regions = this.props.maxRegions ?? Infinity
  service = RegionService

  async componentDidMount() {
    const regions = await this.service.getAll()
    const regionMap: {[id: string]: RegionSelectionData} = {}
    regions
      .sort((a, b) => a.level - b.level)
      .forEach((region) => {
        regionMap[region._id] = region
        if (region.parentId) {
          if (!regionMap[region.parentId].children) {
            regionMap[region.parentId].children = []
          }
          regionMap[region.parentId].children!.push(region)
        }
      })
    this.setState({
      selectedSubregions: this.props.selected ?? [],
      regions: Object.values(regionMap).filter((r) => r.level === 4),
      geoJSONs: await this.service.getGeoJSONs()
    })
  }

  componentDidUpdate(
    prevProps: Readonly<RegionSelectionProps>,
    prevState: Readonly<RegionSelectionState>,
    snapshot?: any
  ) {
    if (
      this.props.selected &&
      !this.compareFocuses(prevProps.selected, this.props.selected)
    ) {
      this.setState({
        selectedSubregions: this.props.selected
      })
    }
  }

  // Compare two array to find whether they are the same or not
  compareFocuses(pf?: string[], cf?: string[]) {
    return (
      Array.isArray(pf) &&
      Array.isArray(cf) &&
      pf.length === cf.length &&
      pf.every((v, i) => v === cf[i])
    )
  }

  focusAllRegions(): string[] {
    return this.state.regions.flatMap(
      (r) => r.children?.map((sr) => sr._id) ?? []
    )
  }

  focusOn(): string[] {
    if (this.state.selectedSubregions.length > 0) {
      return this.state.selectedSubregions
    }
    return this.focusAllRegions()
  }

  getRegionById(regionId: string): RegionSelectionData | undefined {
    let founded: RegionSelectionData | undefined = undefined
    this.state.regions.forEach((region) => {
      if (founded === undefined) {
        founded = region.children?.find((sr) => sr._id === regionId)
      }
    })
    return founded
  }

  extractSelectedName(): string[] {
    return this.state.selectedSubregions.map((regionId) => {
      const region = this.getRegionById(regionId)
      if (region === undefined) {
        return ''
      }
      // preferred language
      if ((region as any)[`name:${this.props.i18n.language}`]) {
        return (region as any)[`name:${this.props.i18n.language}`]
      }
      // fallback
      if (region['name:he']) {
        return region['name:he']
      }
      return region.name
    })
  }

  onSelect(regionId: string) {
    this.setState(
      (state) => {
        const copy = {...state}
        if (this.max_regions === 1) {
          // IF USER CAN SELECT ONLY ONE REGION, JUST REPLACE THE CURRENT
          copy.selectedSubregions = [regionId]
        } else {
          copy.selectedSubregions = [...copy.selectedSubregions]
          const regionIndex = copy.selectedSubregions.indexOf(regionId)
          if (regionIndex !== -1) {
            // DESELECT REGION
            copy.selectedSubregions.splice(regionIndex, 1)
          } else {
            if (copy.selectedSubregions.length < this.max_regions) {
              // PUSH NEW REGION ONLY IF THERE IS AVAILABLE SPACE
              copy.selectedSubregions.push(regionId)
            }
          }
        }
        // this.props.onSelect?.call(this, copy.selectedSubregions)
        return copy
      },
      () => {
        this.props.onSelect?.call(this, this.state.selectedSubregions)
      }
    )
  }

  render() {
    return (
      <>
        <Typography variant={'body2'}>
          {this.props.t('common:fnd-common-selected-region')}{' '}
          {this.extractSelectedName().join(', ')}
        </Typography>
        <Box width={'100%'} height={window.innerHeight * (2 / 3) + 'px'}>
          {((_) => {
            if (this.state.geoJSONs.length !== 0) {
              return (
                <RegionGoogleMaps
                  center={{lat: 31.41171045, lng: 35.0812614}}
                  zoom={8}
                  focusOn={this.focusOn()}
                  geoJsons={this.state.geoJSONs}
                  selectedFeatures={this.state.selectedSubregions}
                  onRegionSelect={this.onSelect.bind(this)}
                />
              )
            }
          })()}
        </Box>
      </>
    )
  }
}

export default withTranslation()(RegionsMapSelection)
