import React, {ReactNode} from 'react'
import {
  AlertColor,
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  Stack
} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import {AxiosResponse} from 'axios'
import {AppConfig} from 'AppConfig'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import {withSnackbar, WithSnackbarProps} from 'notistack'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'
import {NavigateFunction} from 'react-router-dom'
import {AppTheme, MainTheme} from 'AppTheme'
import {AppPreferences} from 'services/AppPreferences'

// MODEL FOR NOTIFICATION POP
export interface AppNotificationData {
  color: AlertColor
  message: ReactNode
  duration?: number | null
  action?: (history: NavigateFunction) => void
}

// CONTEXT CONSUMER MODEL
export interface AppContextData {
  setTitle: (title: ReactNode) => void
  setBackdrop: (state: boolean) => void
  pushNotification: (data?: AppNotificationData) => void
  apiError: (
    error: AxiosResponse,
    map?: {[statusCode: number]: string}
  ) => string
  setFixedHeader: (fixed: boolean) => void
  fixedHeader: boolean
  title: ReactNode
  theme: AppTheme
  setTheme: (theme: AppTheme) => void
}

// ALIAS FOT THE HOC WRAPPER
export type WithAppContext = AppContextData

// CONTEXT STATE
export interface AppContextState extends AppContextData {
  backdrop: boolean
  popNotification: boolean
}

// ACTUAL CONTEXT
export const AppContext = React.createContext<AppContextData>({
  setTitle: () => {},
  setFixedHeader: () => {},
  setBackdrop: () => {},
  pushNotification: () => {},
  apiError: () => '',
  fixedHeader: false,
  title: '',
  theme: MainTheme,
  setTheme: () => {}
})

interface AppContextProviderProps
  extends WithTranslation,
    RouteComponentProps,
    WithSnackbarProps {
  children: JSX.Element
}

// CONTEXT PROVIDER
class AppContextProvider extends React.Component<
  AppContextProviderProps,
  AppContextState
> {
  state: AppContextState = {
    title: '',
    backdrop: false,
    popNotification: false,
    fixedHeader: AppConfig?.header === 'fixed',
    setTitle: (title) => this.setState({title: title}),
    setBackdrop: (bdState: boolean) => this.setState({backdrop: bdState}),
    pushNotification: (data?: AppNotificationData) => {
      let message = data?.message ?? ''
      if (typeof message === 'string') {
        message = this.props.t(message) || message
      }
      const key = this.props.enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        autoHideDuration: data?.duration,
        variant: data?.color ?? 'info',
        action: data?.action ? (
          <Stack direction={'row'} alignItems={'center'}>
            <Button
              variant={'text'}
              color={'inherit'}
              size={'small'}
              onClick={() => {
                data.action?.(this.props.navigate)
                this.props.closeSnackbar(key)
              }}
            >
              {this.props.t('common:fnd-common-open')}
            </Button>

            <IconButton
              aria-label={this.props.t('common:fnd-common-close') as string}
              size={'small'}
              color={'inherit'}
              onClick={() => {
                this.props.closeSnackbar(key)
              }}
            >
              <CloseRoundedIcon fontSize={'inherit'} />
            </IconButton>
          </Stack>
        ) : undefined
      })
    },
    apiError: (error: AxiosResponse, map?: {[statusCode: number]: string}) => {
      let errorMessage = error.data.key ?? 'common:fnd-common-unexpected-error'
      if (map !== undefined) {
        if (map[error.status ?? 0]) {
          errorMessage = map[error.status ?? 0]
        }
      }
      // Send notification
      this.state.pushNotification({
        color: 'error',
        message: errorMessage
      })
      return errorMessage
    },
    setFixedHeader: (fixed) => {
      this.setState({fixedHeader: fixed})
    },

    theme: {...AppPreferences.theme.default, direction: this.props.i18n.dir()},
    setTheme: (theme) => {
      theme.direction = this.props.i18n.dir()
      AppPreferences.theme.default = theme
      this.setState({theme: theme}, () => {
        this.updateDocumentLanguage()
        this.updateDocumentMeta()
      })
    }
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', () => {
      this.state.setTheme(this.state.theme)
    })
    this.updateDocumentLanguage()
    this.updateDocumentMeta()
  }

  updateDocumentLanguage() {
    document
      .querySelector('html')!
      .setAttribute('lang', this.props.i18n.language)
    document.querySelector('body')!.setAttribute('dir', this.props.i18n.dir())
  }

  updateDocumentMeta() {
    document.querySelector('meta[name=viewport]')!.setAttribute(
      'content',
      (() => {
        if (this.state.theme.type === 'accessibility') {
          document
            .querySelector('body')!
            .setAttribute('data-accessibility', 'on')
          return 'width=device-width, initial-scale=1.0'
        } else {
          document.querySelector('body')!.removeAttribute('data-accessibility')
          return 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        }
      })()
    )
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
        <Backdrop open={this.state.backdrop} style={{zIndex: 9999}}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </AppContext.Provider>
    )
  }
}

// HOC WRAPPER
export function withAppContext<T extends AppContextData>(
  WrappedComponent: React.ComponentType<T>
): React.ComponentClass<Omit<T, keyof AppContextData>> {
  return class extends React.Component<Omit<T, keyof AppContextData>> {
    render() {
      return (
        <AppContext.Consumer>
          {(appContext) => (
            <WrappedComponent
              {...appContext}
              {...(this.props as unknown as T)}
            />
          )}
        </AppContext.Consumer>
      )
    }
  }
}

export default withSnackbar(withRouter(withTranslation()(AppContextProvider)))
