import React, {ChangeEvent} from 'react'
import {Button, Grid, TextField, styled} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'
import Countdown from 'web-common/components/date/Countdown'

const TextFieldWrapper = styled('div')((_) => ({
  maxWidth: '320px',
  margin: '0 auto',
  '& input': {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      appearance: 'textfield'
    },
    appearance: 'textfield'
  }
}))

interface AppFormCodeProps extends AppFormComponent, WithTranslation {}

interface AppFormCodeState {
  code: string[]
  canResendAfter?: number
}

/**
 * Accept "data" property which must be of type data: (cb: () => void) => void
 */
class AppFormCode extends AppFormInputComponent<
  AppFormCodeProps,
  AppFormCodeState
> {
  defaultCode = ['', '', '', '', '', '']
  state: AppFormCodeState = {
    code: [...this.defaultCode]
  }

  inputRefs = [
    React.createRef<HTMLInputElement>(),
    React.createRef<HTMLInputElement>(),
    React.createRef<HTMLInputElement>(),
    React.createRef<HTMLInputElement>(),
    React.createRef<HTMLInputElement>(),
    React.createRef<HTMLInputElement>()
  ]
  inputIndex: number = 0
  RESEND_INTERVAL = 1000 * 60 // 1 min

  onPasteCode(event: React.ClipboardEvent) {
    if (event.clipboardData) {
      const rawCode = event.clipboardData
        .getData('text')
        .replace(/\D/g, '')
        .substring(0, 6)
      if (rawCode.length === 0) {
        // No valid code to paste
        return
      }
      this.inputIndex = 0
      this.setState({code: rawCode.split('')}, () => {
        this.onChange(this.state.code.join(''))
        this.inputRefs[rawCode.length - 1].current?.focus()
      })
    }
    event.preventDefault()
    event.stopPropagation()
  }

  onKeyPress(index: number, event: React.KeyboardEvent) {
    const value = event.key
    if (value === 'Backspace') {
      this.setState(
        (state) => {
          state.code[index] = ''
          return state
        },
        () => {
          this.focusPreviousInput(index)
          this.onChange(this.state.code.join(''))
        }
      )
    }
  }

  focusNextInput(from?: number) {
    const index = from ?? this.inputIndex
    if (index < 5) {
      this.inputIndex = index + 1
      this.inputRefs[this.inputIndex].current?.focus()
    }
  }

  focusPreviousInput(from?: number) {
    const index = from ?? this.inputIndex
    if (index > 0) {
      this.inputIndex = index - 1
      this.inputRefs[this.inputIndex].current?.focus()
    }
  }

  onChange(code: string) {
    const {onChangeCallback, ...rest} = {...this.props}
    this.props.onChangeCallback(rest, code)
  }

  // REQUEST NEW VALIDATION CODE
  onResendCode() {
    this.props.data(() => {
      this.setState({
        canResendAfter: Date.now() + this.RESEND_INTERVAL
      })
    })
  }

  // CHECK IF USER CAN RECEIVE NEW VALIDATION CODE
  canResend() {
    return (
      !this.state.canResendAfter ||
      !(this.state.canResendAfter && this.state.canResendAfter > Date.now())
    )
  }

  // RESET "REQUEST VALIDATION CODE" RESTRICTION
  onCountdownFinish() {
    this.setState({canResendAfter: undefined})
  }

  onChangeBox(
    index: number,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const value = e.target.value.substr(0, 1)
    const reg = new RegExp(/^\d$/)
    if (!reg.test(value)) {
      return
    }
    this.setState(
      (state) => {
        state.code[index] = value
        return state
      },
      () => {
        this.focusNextInput(index)
        this.onChange(this.state.code.join(''))
      }
    )
  }

  render() {
    const bodyDir = document.querySelector('body')?.getAttribute('dir') ?? 'ltr'
    return (
      <TextFieldWrapper>
        <Grid
          container
          spacing={1}
          direction={bodyDir === 'rtl' ? 'row-reverse' : 'row'}
        >
          {[0, 1, 2, 3, 4, 5].map((index) => (
            <Grid item key={'box-code-' + index} xs={2}>
              <TextField
                variant={'outlined'}
                fullWidth
                onChange={this.onChangeBox.bind(this, index)}
                inputRef={this.inputRefs[index]}
                value={this.state.code[index]}
                onPaste={this.onPasteCode.bind(this)}
                error={this.props.hasError}
                inputProps={{
                  style: {textAlign: 'center'},
                  min: 0,
                  max: 0,
                  inputMode: 'numeric',
                  pattern: '[0-9]*'
                }}
                onKeyDown={this.onKeyPress.bind(this, index)}
              />
            </Grid>
          ))}
        </Grid>

        {/* RESEND VALIDATION CODE */}
        {((_) => {
          if (this.props.data) {
            return (
              <Button
                variant={'text'}
                color={'primary'}
                disabled={!this.canResend()}
                style={{marginTop: '16px'}}
                fullWidth
                onClick={this.onResendCode.bind(this)}
              >
                {((_) => {
                  if (this.canResend()) {
                    return this.props.t(
                      'common:fnd-common-resend-validation-code'
                    )
                  } else {
                    return (
                      <>
                        {this.props.t('common:fnd-common-can-resend-after')}
                        &nbsp;
                        <Countdown
                          finish={this.state.canResendAfter!}
                          onCountdownFinish={this.onCountdownFinish.bind(this)}
                        />
                      </>
                    )
                  }
                })()}
              </Button>
            )
          }
        })()}
      </TextFieldWrapper>
    )
  }
}

export default withTranslation()(AppFormCode)
