import {createTheme} from '@mui/material'
import {BaseTheme} from 'web-common/theme/AppTheme'
import contrastTheme from 'web-common/theme/ContrastTheme'
import {Theme} from '@mui/material/styles/createTheme'

export interface AppTheme extends Theme {
  type: 'normal' | 'accessibility'
}

export const MainTheme = createTheme(BaseTheme, {type: 'normal'}) as AppTheme
export const ContrastTheme = createTheme(contrastTheme, {
  type: 'accessibility'
}) as AppTheme
