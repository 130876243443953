import React from 'react'
import {Stack} from '@mui/material'
import UserNotificationsView from 'web-common/views/profile/UserNotificationsView'
import NotificationLanguageView from 'views/profile/notifications/NotificationLanguageView'
import NotificationService from 'web-common/services/NotificationService'
import Loading from 'web-common/components/loading/Loading'
import ValidateUserEmailView from 'web-common/components/email/ValidateUserEmailView'

interface NotificationsWrapperProps {}

interface NotificationsWrapperState {
  loading: boolean
}

class NotificationsWrapper extends React.Component<
  NotificationsWrapperProps,
  NotificationsWrapperState
> {
  state: NotificationsWrapperState = {
    loading: true
  }
  service!: typeof NotificationService

  async componentDidMount() {
    await NotificationService.init()
    this.service = NotificationService
    this.setState({loading: false})
  }

  renderView() {
    return (
      <Stack rowGap={3}>
        <NotificationLanguageView service={this.service} />
        <ValidateUserEmailView />
        <UserNotificationsView service={this.service} />
      </Stack>
    )
  }

  render() {
    return (
      <Loading
        loading={this.state.loading}
        render={this.renderView.bind(this)}
      />
    )
  }
}

export default NotificationsWrapper
