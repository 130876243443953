import {SurveyAnswers, SurveySessionStorage} from '@fnd/survey'

export class AppSurveySessionStorage extends SurveySessionStorage {
  set(answers: SurveyAnswers) {
    window.sessionStorage.setItem(this.storageKey, JSON.stringify(answers))
  }

  update(answers: SurveyAnswers) {
    const prev = this.get()
    this.set({...prev, ...answers})
  }

  get(): SurveyAnswers {
    try {
      const session = window.sessionStorage.getItem(this.storageKey)!
      return (JSON.parse(session) as SurveyAnswers) ?? {}
    } catch (e) {
      console.log('Session storage `get` error ', e)
      return {}
    }
  }

  delete() {
    try {
      window.sessionStorage.removeItem(this.storageKey)
    } catch (e) {
      console.log('Session storage `delete` error ', e)
    }
  }

  isEmpty() {
    return Object.keys(this.get()).length === 0
  }
}
