import React from 'react'
import AppPage from 'web-common/utilities/AppPage'

interface SurveyViewState {}

interface SurveyViewProps {
  serviceId?: string
  onBegin?: () => void
  onComplete?: () => void
}

class SurveyView extends AppPage<SurveyViewProps, SurveyViewState> {
  static path = '/survey/:id'

  // No Title
  title = ''
  // Service ID:
  serviceId: string = this.props.serviceId ?? this.props.params.id

  state: SurveyViewState = {}

  componentDidMount() {
    super.componentDidMount()
    const newLocation = window.location.href.replace('survey', 'questionnaires')
    const url = new URL(newLocation)
    setTimeout(() => {
      this.props.navigate(url.pathname + url.search)
    })
  }

  render() {
    return <></>
  }
}

export default SurveyView.exportDefault()
