import React from 'react'
import {QuestionRadiogroupModel} from 'survey-react'
import {Collapse, TextField} from '@mui/material'
import surveyInput from './radiocheckbox'
import {createRoot} from 'react-dom/client'

function RadioElement(props: {
  question: QuestionRadiogroupModel
  onChangeOther: (s: string) => void
}) {
  let allItems: any[] = [
    ...(typeof props.question.propertyHash.choices === 'function'
      ? props.question.propertyHash.choices()
      : props.question.propertyHash.choices)
  ]
  if (props.question.choicesOrder === 'asc') {
    allItems = allItems.sort((a, b) => a.text.localeCompare(b.text))
  } else if (props.question.choicesOrder === 'desc') {
    allItems = allItems.sort((a, b) => b.text.localeCompare(a.text))
  }
  const [selectedEnabled, setSelectedEnabled] = React.useState(
    props.question.value
  )
  if (props.question.hasOther) {
    allItems.push({value: 'other'})
  }
  const list = allItems.map((item, index) =>
    surveyInput(
      'radio',
      selectedEnabled === item.value,
      item.value,
      item.value === 'other' ? props.question.otherText : item.text,
      (value) => {
        setSelectedEnabled(value)
        if (value === 'other') {
          props.question.updateValueFromSurvey(value)
        } else {
          props.question.value = value
        }

        /**
         * Go To next page if has next page and if value match property
         */
        // @ts-ignore
        if (
          props.question.goToNextPage &&
          (props.question.value as string[]).includes(
            props.question.goToNextPage
          )
        ) {
          // @ts-ignore
          props.question.survey.nextPage()
        }
      },
      'radio' +
        index +
        (item.value === 'other' ? props.question.otherText : item.value)
    )
  )
  const ref = React.createRef<HTMLInputElement>()
  const other = (
    <Collapse
      in={selectedEnabled === 'other'}
      collapsedSize={0}
      key={'otherinput'}
      onEntered={() => {
        ref.current?.focus()
      }}
    >
      <TextField
        inputRef={ref}
        inputProps={{
          defaultValue: props.question.comment,
          placeholder: props.question.otherPlaceHolder,
          onChange: (event: any) => {
            props.question.updateCommentFromSurvey(event.target.value)
            props.onChangeOther(event.target.value)
          }
        }}
        fullWidth
      />
    </Collapse>
  )
  return (
    <>
      {list}
      {other}
    </>
  )
}

const WIDGET_NAME = 'radiogroup'
const widgetReactRadio = {
  value: '',
  updateValue: function (value: string) {
    this.value = value
  },
  name: WIDGET_NAME,
  title: 'React Radio',
  iconName: '',
  isFit: function (question: any) {
    return question.getType() === WIDGET_NAME
  },
  isDefaultRender: false,
  htmlTemplate: '<div></div>',
  afterRender: function (question: QuestionRadiogroupModel, el: HTMLElement) {
    const root = createRoot(el)
    root.render(
      <RadioElement
        question={question}
        onChangeOther={this.updateValue.bind(this)}
      />
    )
  },
  willUnmount: function (question: QuestionRadiogroupModel, el: HTMLElement) {
    if (this.value) {
      // TODO: need more research for comments
      // question.hasComment = true
      question.comment = this.value
    }
  }
}
export default widgetReactRadio
