class QuestionnaireBus {
  private constructor() {}

  private subs: ((serviceId: string) => void)[] = []

  request(serviceId: string) {
    this.subs.forEach((s) => s(serviceId))
  }

  subscribe(sub: (serviceId: string) => void) {
    const index = this.subs.push(sub) - 1
    return () => {
      this.subs.splice(index, 0)
    }
  }

  private static instance: QuestionnaireBus

  static shared() {
    if (!QuestionnaireBus.instance) {
      QuestionnaireBus.instance = new QuestionnaireBus()
    }
    return QuestionnaireBus.instance
  }
}

export default QuestionnaireBus
