import React from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material'
import DemandsService, {DemandsCancelReason} from 'views/demands/DemandsService'
import GridComposer from 'components/layout/GridComposer'
import {WithTranslation, withTranslation} from 'react-i18next'
import DemandsView from 'views/demands/listing/DemandsView'
import {withAppContext, WithAppContext} from 'web-common/contexts/AppContext'
import {CancelButton} from 'web-common/components/inputs/CancelButton'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'
import {DemandData} from 'models/Demands'

interface DemandCancelViewProps
  extends RouteComponentProps,
    WithTranslation,
    WithAppContext {
  demand: DemandData
  onCancel: () => void
}

interface DemandCancelViewState {
  cancelReason: DemandsCancelReason
}

class DemandCancelView extends React.Component<
  DemandCancelViewProps,
  DemandCancelViewState
> {
  state: DemandCancelViewState = {
    cancelReason: DemandsCancelReason.DONT_NEED_ANYMORE
  }
  comment?: string = undefined
  inRequest = false

  cancelReasons = [
    {
      key: DemandsCancelReason.DONT_NEED_ANYMORE,
      label: 'fnd-label-dont-need-any-more'
    },
    {
      key: DemandsCancelReason.WANTED_RESEARCH,
      label: 'fnd-label-wanted-research'
    },
    {
      key: DemandsCancelReason.NOT_FOUND_RIGHT_SUPPLIER,
      label: 'fnd-label-not-found-right-supplier'
    },
    {key: DemandsCancelReason.OTHER, label: 'fnd-label-other'}
  ]

  cancelDemand() {
    this.inRequest = true
    this.props.setBackdrop(true)
    DemandsService.cancelDemand(
      this.props.demand._id,
      this.state.cancelReason,
      this.comment
    )
      .then(() => {
        this.props.pushNotification({
          color: 'success',
          message: 'fnd-demand-canceled-success'
        })
        this.inRequest = false
        this.props.setBackdrop(false)
        this.props.navigate(DemandsView.getPath(true))
      })
      .catch((error) => {
        this.inRequest = false
        this.props.setBackdrop(false)
        this.props.pushNotification({
          color: 'error',
          message: error ?? 'fnd-label-unexpected-error'
        })
      })
  }

  onChangeCancelReason(reason: DemandsCancelReason) {
    if (reason !== DemandsCancelReason.OTHER) {
      this.comment = undefined
    }
    this.setState({cancelReason: reason})
  }

  render() {
    return (
      <GridComposer>
        <Box component={'fieldset'} sx={{border: 'none'}}>
          <Typography variant={'subtitle1'} component={'legend'}>
            {this.props.t('fnd-label-reason-to-close-demand')}
          </Typography>
          <FormControl fullWidth>
            <RadioGroup
              value={this.state.cancelReason}
              onChange={(event, value) =>
                this.onChangeCancelReason(value as DemandsCancelReason)
              }
            >
              {this.cancelReasons.map((reason) => {
                return (
                  <FormControlLabel
                    key={`cancel-reason-${reason.key}`}
                    value={reason.key}
                    control={<Radio />}
                    label={`${this.props.t(reason.label)}`}
                  />
                )
              })}
            </RadioGroup>
            {((_) => {
              if (this.state.cancelReason === DemandsCancelReason.OTHER) {
                return (
                  <TextField
                    type={'text'}
                    multiline
                    rows={4}
                    fullWidth
                    placeholder={this.props.t(
                      'fnd-placeholder-cancel-demand-other-option'
                    )}
                    onChange={(e) => (this.comment = e.target.value)}
                  />
                )
              }
            })()}
          </FormControl>
        </Box>
        <Grid container justifyContent={'center'}>
          <Grid item>
            <CancelButton
              variant={'text'}
              onClick={this.props.onCancel.bind(this)}
            >
              {this.props.t('fnd-label-cancel')}
            </CancelButton>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={this.cancelDemand.bind(this)}
            >
              {this.props.t('fnd-label-confirm')}
            </Button>
          </Grid>
        </Grid>
      </GridComposer>
    )
  }
}

export default withRouter(withTranslation()(withAppContext(DemandCancelView)))
