import React, {ReactNode} from 'react'
import {Box, Button, GridSize, GridSpacing} from '@mui/material'
import Grid from '@mui/material/Grid'
import AppFormHidden from './inputs/AppFormHidden'
import {WithTranslation, withTranslation} from 'react-i18next'
import AppFormEmail from './inputs/AppFormEmail'
import {AppFormComponent} from 'web-common/components/formBuilder/AppFormFactory'
import AppFormDatepicker from 'web-common/components/formBuilder/inputs/AppFormDatepicker'
import AppFormCheckbox from 'web-common/components/formBuilder/inputs/AppFormCheckbox'
import AppFormText from 'web-common/components/formBuilder/inputs/AppFormText'
import AppFormPassword from 'web-common/components/formBuilder/inputs/AppFormPassword'
import AppFormCode from 'web-common/components/formBuilder/inputs/AppFormCode'
import AppFormPhone from 'web-common/components/formBuilder/inputs/AppFormPhone'
import AppFormDivider from 'web-common/components/formBuilder/inputs/AppFormDivider'
import AppFormLabel from 'web-common/components/formBuilder/inputs/AppFormLabel'
import AppFormTextarea from 'web-common/components/formBuilder/inputs/AppFormTextarea'
import AppFormSelect from 'web-common/components/formBuilder/inputs/AppFormSelect'
import AppFormRadioGroup from 'web-common/components/formBuilder/inputs/AppFormRadioGroup'
import AppFormService from 'web-common/components/formBuilder/inputs/AppFormService'
import AppFormSwitch from 'web-common/components/formBuilder/inputs/AppFormSwitch'
import {CancelButton} from 'web-common/components/inputs/CancelButton'
import AppFormToggle from 'web-common/components/formBuilder/inputs/AppFormToggle'
import AppFormChips from 'web-common/components/formBuilder/inputs/AppFormChips'
import AppFormUsers from 'web-common/components/formBuilder/inputs/AppFormUsers'

export type AppFormData = {[id: string]: string | string[]}

interface AppFormInputSize {
  xs?: GridSize
  sm?: GridSize
  md?: GridSize
  lg?: GridSize
  xl?: GridSize
}

export interface AppFormInput {
  type:
    | 'switch'
    | 'service'
    | 'text'
    | 'textarea'
    | 'select'
    | 'label'
    | 'divider'
    | 'phone'
    | 'code'
    | 'hidden'
    | 'email'
    | 'password'
    | 'checkbox'
    | 'datepicker'
    | 'radio'
    | 'toggle'
    | 'chips'
    | 'users'
  label: string | React.ReactElement // | number
  placeholder?: string
  value?: string
  name: string
  id?: string
  errorText?: string
  dynamicError?: (value?: string, validator?: RegExp) => string
  validator?: RegExp
  pristine?: boolean
  hasError?: boolean
  disabled?: boolean
  hidden?: boolean
  icon?: ReactNode
  // If filed is not required, validator will be checked only if field is not empty *default is true
  required?: boolean
  size?: AppFormInputSize
  // Excluded when form is submitted
  exclude?: boolean
  // used for custom input like checkbox/selects/maps etc
  data?: any
  // Only for inputs like radio, check, switch ...
  checked?: boolean
  // Remove element on save if its with empty value
  excludeIfEmpty?: boolean
  // Remove element on save if func evaluation return true
  customExclude?: (value: string) => boolean
  // Custom Attributes
  customAttr?: any
  // Only for chips
  values?: string[]
  dynamicAttributes?: (
    input: AppFormInput,
    value?: string
  ) => Partial<AppFormInput>
  // trim text value
  trim?: boolean
}

interface AppFormProps<T> extends WithTranslation {
  form: AppFormInput[]
  spacing?: GridSpacing
  onSave?: (data: any | T) => any
  onError?: () => void
  onCancel?: () => void
  lock?: boolean
  saveLabel?: ReactNode
  cancelLabel?: ReactNode
  customButton?: ReactNode
  onCustomButton?: (data: any | T) => any
  controlJustify?:
    | 'center'
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'flex-start'
    | 'flex-end'
}

interface AppFormState {
  form: {[name: string]: AppFormInput}
  submitted: boolean
}

class AppForm<T> extends React.Component<AppFormProps<T>, AppFormState> {
  pristineData: {[name: string]: AppFormInput} = {}
  state: AppFormState = {
    form: {},
    submitted: false
  }
  emptyFun = () => {}

  getId(cfg: AppFormInput): string {
    return cfg.id ?? cfg.name
  }

  componentDidMount() {
    const form: {[name: string]: AppFormInput} = {}
    this.props.form.forEach((cfg) => {
      form[this.getId(cfg)] = cfg
      this.pristineData[this.getId(cfg)] = Object.assign({}, cfg)
    })
    this.setState({form: {...form}})
  }

  componentDidUpdate(
    prevProps: Readonly<AppFormProps<T>>,
    prevState: Readonly<AppFormState>,
    snapshot?: any
  ) {
    if (prevProps.lock !== this.props.lock) {
      if (this.props.lock === false) {
        this.unlockForm()
      }
      if (this.props.lock === true) {
        this.lockForm()
      }
    }
  }

  onChange(cfg: AppFormInput, value: string) {
    this.setState((state) => {
      const copy = {...state}
      if (cfg.validator && this.state.submitted) {
        if ((cfg.required === false && value) || cfg.required !== false) {
          copy.form[this.getId(cfg)].hasError = !cfg.validator.test(value)
        } else {
          copy.form[this.getId(cfg)].hasError = false
        }
      }
      copy.form[this.getId(cfg)].value = value
      copy.form[this.getId(cfg)].pristine = false
      return copy
    })
  }

  generateFormInput(
    rawCfg: AppFormInput
  ): React.ReactElement<AppFormComponent> {
    let cfg = rawCfg
    if (cfg.dynamicAttributes) {
      cfg = {
        ...cfg,
        ...cfg.dynamicAttributes(cfg, this.state.form[this.getId(cfg)].value)
      }
    }
    switch (cfg.type) {
      case 'datepicker':
        return (
          <AppFormDatepicker
            onChangeCallback={this.onChange.bind(this)}
            {...cfg}
          />
        )
      case 'select':
        return (
          <AppFormSelect onChangeCallback={this.onChange.bind(this)} {...cfg} />
        )
      case 'switch':
        return (
          <AppFormSwitch onChangeCallback={this.onChange.bind(this)} {...cfg} />
        )
      case 'toggle':
        return (
          <AppFormToggle onChangeCallback={this.onChange.bind(this)} {...cfg} />
        )
      case 'radio':
        return (
          <AppFormRadioGroup
            onChangeCallback={this.onChange.bind(this)}
            {...cfg}
          />
        )
      case 'checkbox':
        return (
          <AppFormCheckbox
            onChangeCallback={this.onChange.bind(this)}
            {...cfg}
          />
        )
      case 'textarea':
        return (
          <AppFormTextarea
            onChangeCallback={this.onChange.bind(this)}
            {...cfg}
          />
        )
      case 'text':
        return (
          <AppFormText onChangeCallback={this.onChange.bind(this)} {...cfg} />
        )
      case 'service':
        return (
          <AppFormService
            onChangeCallback={this.onChange.bind(this)}
            {...cfg}
          />
        )
      case 'password':
        return (
          <AppFormPassword
            onChangeCallback={this.onChange.bind(this)}
            {...cfg}
          />
        )
      case 'email':
        return (
          <AppFormEmail onChangeCallback={this.onChange.bind(this)} {...cfg} />
        )
      case 'label':
        return (
          <AppFormLabel onChangeCallback={this.emptyFun.bind(this)} {...cfg} />
        )
      case 'divider':
        return (
          <AppFormDivider
            onChangeCallback={this.emptyFun.bind(this)}
            {...cfg}
          />
        )
      case 'phone':
        return (
          <AppFormPhone onChangeCallback={this.onChange.bind(this)} {...cfg} />
        )
      case 'code':
        return (
          <AppFormCode onChangeCallback={this.onChange.bind(this)} {...cfg} />
        )
      case 'hidden':
        return (
          <AppFormHidden onChangeCallback={this.emptyFun.bind(this)} {...cfg} />
        )
      case 'chips':
        return (
          <AppFormChips onChangeCallback={this.onChange.bind(this)} {...cfg} />
        )
      case 'users':
        return (
          <AppFormUsers onChangeCallback={this.onChange.bind(this)} {...cfg} />
        )
    }
  }

  generateFormInputCell(config: AppFormInput) {
    return (
      <Grid
        item
        {...config.size}
        key={this.getId(config)}
        hidden={config.hidden}
      >
        {this.generateFormInput(config)}
      </Grid>
    )
  }

  markInputsAsNonValid(inputIds: string[]) {
    this.setState((state) => {
      const copy = {...state}
      inputIds.forEach((id) => {
        copy.form[id].hasError = true
      })
      copy.submitted = true
      return copy
    })
  }

  lockForm() {
    this.setState((state) => {
      const copy = {...state}
      for (const id in copy.form) {
        if (copy.form.hasOwnProperty(id)) {
          copy.form[id].disabled = true
        }
      }
      return copy
    })
  }

  unlockForm() {
    this.setState((state) => {
      const copy = {...state}
      for (const id in copy.form) {
        if (copy.form.hasOwnProperty(id)) {
          copy.form[id].disabled = this.pristineData[id].disabled
        }
      }
      return copy
    })
  }

  extractData(): T {
    const data: any = {}
    Object.values(this.state.form).forEach((input) => {
      if (input.exclude === true) {
        return
      }
      const rawValue = input.value ?? ''
      const value = input.trim ? rawValue.trim() : rawValue
      if (input.customExclude && input.customExclude(value)) {
        return
      }
      if (input.excludeIfEmpty && value === '') {
        return
      }
      if (data[input.name]) {
        // CONVERT TO STRING[]
        if (typeof data[input.name] === 'string') {
          data[input.name] = [data[input.name] as string]
        }
        ;(data[input.name] as string[]).push(value)
      } else {
        data[input.name] = value
      }
    })
    return data
  }

  onSave() {
    const notValidInputIds: string[] = []
    Object.values(this.state.form).forEach((input) => {
      const value: string = input.value ?? ''
      if (
        (input.required === false && input.value) ||
        input.required !== false
      ) {
        if (input.validator?.test(value) === false) {
          notValidInputIds.push(this.getId(input))
        }
      }
    })
    if (notValidInputIds.length > 0) {
      this.markInputsAsNonValid(notValidInputIds)
    } else {
      this.props.onSave?.call(this, this.extractData())
    }
  }

  onCustomButton() {
    this.props.onCustomButton?.call(this, this.extractData())
  }

  shouldSaveBeDisabled() {
    const notValidInputIds: string[] = []
    Object.values(this.state.form).forEach((input) => {
      if (input.validator && !input.value && input.required !== false) {
        notValidInputIds.push(this.getId(input))
      }
    })
    return notValidInputIds.length > 0
  }

  render() {
    let cancelLabel = this.props.cancelLabel ?? 'common:fnd-common-cancel'
    if (typeof cancelLabel === 'string') {
      cancelLabel = this.props.t(cancelLabel)
    }
    let saveLabel = this.props.saveLabel ?? 'common:fnd-common-save'
    if (typeof saveLabel === 'string') {
      saveLabel = this.props.t(saveLabel)
    }

    return (
      <Grid container spacing={this.props.spacing}>
        {/* FORM */}
        {Object.values(this.state.form).map(
          this.generateFormInputCell.bind(this)
        )}

        {/* CONTROLS */}
        <Grid item xs={12}>
          <Grid
            container
            justifyContent={this.props.controlJustify ?? 'center'}
            spacing={1}
          >
            {/* CANCEL */}
            {((_) => {
              if (this.props.onCancel) {
                return (
                  <Grid item>
                    <CancelButton
                      disabled={this.props.lock}
                      variant={'text'}
                      onClick={this.props.onCancel?.bind(this)}
                    >
                      {cancelLabel}
                    </CancelButton>
                  </Grid>
                )
              }
            })()}

            {/* SAVE */}
            <Grid item>
              <Button
                onClick={this.onSave.bind(this)}
                color={'primary'}
                disabled={this.props.lock /*|| this.shouldSaveBeDisabled()*/}
              >
                {saveLabel}
              </Button>
            </Grid>

            {/*CUSTOM BUTTON*/}
            {this.props.customButton && (
              <Grid item>
                <Box onClick={this.onCustomButton.bind(this)}>
                  {this.props.customButton}
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation()(AppForm)
