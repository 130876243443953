import React, {ReactNode, useEffect, useState} from 'react'
import {Box, CircularProgress} from '@mui/material'

interface LoadingProps {
  loading: boolean
  render: () => ReactNode
}

const Loading: React.FC<LoadingProps> = (props) => {
  // Init state
  const [loading, setLoading] = useState(props.loading)

  // On loading change
  useEffect(() => {
    setLoading(props.loading)
  }, [props.loading])

  if (loading) {
    return (
      <Box
        width={'100%'}
        height={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        className={'loader'}
      >
        <CircularProgress />
      </Box>
    )
  }
  return <>{props.render()}</>
}

export default Loading
