import {AppConfig} from 'AppConfig'

/**
 * https://smarty.atlassian.net/browse/FIND-1454
 * Handles the shortened tokenized "magic links" that can be obtained from the
 * WhatsApp bot by typing a specific trigger.
 * The WA bot can't give the user the full tokenized links because they are too long
 * to be displayed in WhatsApp's UI and they become truncated and unclicakble,
 * so we create links like <customer-domain>/s/UUID which can be resolved to the full
 * tokenized magic link via this service
 */
class URLShortenerService {
  protected static instance: URLShortenerService
  private shortenedPath = '/s/'

  static shared() {
    if (!URLShortenerService.instance) {
      URLShortenerService.instance = new URLShortenerService()
    }
    return URLShortenerService.instance
  }

  public isValidShortenedURL(url: string) {
    const urlObj = new URL(url)
    const isShortenedPath = urlObj.pathname.includes(this.shortenedPath)
    const hasUUID = urlObj.pathname.split(this.shortenedPath)[1]

    return isShortenedPath && hasUUID
  }

  public makeLink(url: string) {
    const urlObj = new URL(url)
    // The service wouldn't work with localhost URLs so we
    // switch the host to the current customer URLS (coming from env specific config)
    // this way we can test the feature on localhost as well
    if (urlObj.hostname === 'localhost') {
      return `${AppConfig.customer.base}${urlObj.pathname}`
    }

    return url
  }

  public resolve(url: string) {
    if (!this.isValidShortenedURL(url)) {
      return
    }
    const shortenedLink = this.makeLink(url)
    // This API responds with a Location HTTP header and 302 response code
    // so the browser will do the redirect automatically
    // first it will jump to the authentication service then to the link with the login token
    // We actually can't do this via AJAX requests cause then we would get a CORS error
    // (auth domain is not the same as the customer domain)
    window.location.href = `${AppConfig.api.base}/short-links?link=${shortenedLink}`
  }
}

export default URLShortenerService.shared()
