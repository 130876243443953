import React from 'react'
import {CardContent, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

export const JobAccepted = () => {
  const {t} = useTranslation()

  return (
    <CardContent>
      <Stack direction={'column'} spacing={1}>
        <Typography variant={'body1'}>
          {t('fnd-event-subtitle-demand-activated')}
        </Typography>
      </Stack>
    </CardContent>
  )
}
