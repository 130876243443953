import {RequestService} from 'web-common/services/request/RequestService'

type ResourceURL = string
type ResourcePath = string

export interface FileStorageAPI {
  getResource: (
    relativeResourceURI: string,
    secured: boolean
  ) => Promise<ResourceURL>

  uploadResource: (
    resource: File | string,
    relativePath: string,
    fileName: string,
    secured: boolean
  ) => Promise<ResourcePath>

  deleteResource: (relativePath: string, secured: boolean) => Promise<void>

  list: (relativePathDir: string, secured: boolean) => Promise<string[]>

  getWellKnownResource: (relativePath: string) => string
}

export class FileStorageFactory implements FileStorageAPI {
  constructor(private api: FileStorageAPI & RequestService) {}

  async getResource(
    relativeResourceURI: string,
    secured: boolean
  ): Promise<ResourceURL> {
    return await this.api.getResource(relativeResourceURI, secured)
  }

  async uploadResource(
    resource: File | string,
    relativePath: string,
    fileName: string,
    secured: boolean
  ): Promise<ResourcePath> {
    return await this.api.uploadResource(
      resource,
      relativePath,
      fileName,
      secured
    )
  }

  async deleteResource(relativePath: string, secured: boolean): Promise<void> {
    await this.api.deleteResource(relativePath, secured)
  }

  async list(relativePathDir: string, secured: boolean) {
    return await this.api.list(relativePathDir, secured)
  }

  getWellKnownResource(relativePath: string) {
    return this.api.getWellKnownResource(relativePath)
  }
}
