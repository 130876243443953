import {Box, Fab, styled} from '@mui/material'
import {AccessibilityRounded} from '@mui/icons-material'
import {useContext, useMemo} from 'react'
import {AppContext} from 'web-common/contexts/AppContext'
import {ContrastTheme, MainTheme} from 'AppTheme'
import {useTranslation} from 'react-i18next'

const FabWrapper = styled(Box)({
  position: 'fixed',
  bottom: '3rem',
  right: '3rem',
  zIndex: '1301',
  '&.stationary': {
    position: 'initial'
  }
})

interface AccessibilityControlProps {
  stationary?: boolean
}

const AccessibilityControl = (props: AccessibilityControlProps) => {
  const [t] = useTranslation()
  const appContext = useContext(AppContext)
  const isAccessibility = useMemo(
    () => appContext.theme.type === 'accessibility',
    [appContext.theme]
  )
  const controlColor = useMemo(() => {
    return isAccessibility ? 'primary' : 'default'
  }, [isAccessibility])

  const toggleTheme = () => {
    appContext.setTheme(isAccessibility ? MainTheme : ContrastTheme)
    appContext.pushNotification({
      color: 'info',
      message: t(
        isAccessibility
          ? 'common:fnd-common-aria-accessibility-mode-off'
          : 'common:fnd-common-aria-accessibility-mode-on'
      )
    })
  }

  const accessibilityLabel = `
    ${t('fnd-aria-change-theme')}
    ${t(
      isAccessibility
        ? 'fnd-aria-selected-theme-accessibility'
        : 'fnd-aria-selected-theme-main'
    )}
  `
  return (
    <FabWrapper className={props.stationary ? 'stationary' : ''}>
      <Fab
        color={controlColor}
        onClick={toggleTheme}
        aria-label={accessibilityLabel}
        aria-live={'polite'}
      >
        <AccessibilityRounded />
      </Fab>
    </FabWrapper>
  )
}

export default AccessibilityControl
