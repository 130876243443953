import React from 'react'
import AppPage from 'web-common/utilities/AppPage'
import {Typography} from '@mui/material'

interface NotFoundRouteParams {}

interface NotFoundState {}

interface NotFoundProps {}

class NotFound extends AppPage<
  NotFoundProps,
  NotFoundState,
  NotFoundRouteParams
> {
  static path = '/404'
  title = 'Page Not Found'

  render() {
    return (
      <Typography
        variant={'h1'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 'calc(100vh - 281px)'
        }}
      >
        {this.props.t('fnd-page-not-found-title')}
      </Typography>
    )
  }
}

export default NotFound.exportDefault()
