import React from 'react'
import {withTranslation, WithTranslation} from 'react-i18next'
import AppForm, {AppFormInput} from 'web-common/components/formBuilder/AppForm'
import AppValidator from 'web-common/components/formBuilder/AppValidator'
import CustomerService from 'web-common/services/CustomerService'
import {WithAppContext, withAppContext} from 'web-common/contexts/AppContext'

interface UserChangePasswordProps extends WithTranslation, WithAppContext {
  onSave: (data: any) => void
  onCancel: () => void
  onError: (message?: string) => void
}

interface UserPasswordForm {
  password: string
  newPassword: string
}

class UserChangePassword extends React.Component<UserChangePasswordProps, any> {
  inRequest = false

  form: AppFormInput[] = [
    {
      type: 'password',
      label: 'common:fnd-common-password-old-label',
      placeholder: 'common:fnd-common-password-old-label',
      name: 'password',
      errorText: 'common:fnd-common-invalid-password',
      validator: AppValidator.password,
      size: {xs: 12}
    },
    {
      type: 'password',
      label: 'common:fnd-common-password-new-label',
      placeholder: 'common:fnd-common-password-new-placeholder',
      name: 'newPassword',
      validator: AppValidator.password,
      errorText: 'common:fnd-common-invalid-password',
      size: {xs: 12}
    }
  ]

  onSave(data: UserPasswordForm) {
    if (this.inRequest) {
      return
    }
    this.inRequest = true
    CustomerService.changePassword(data.password, data.newPassword)
      .then((_) => {
        this.props.pushNotification({
          color: 'success',
          message: 'common:fnd-common-password-change-success'
        })
        this.inRequest = false
        this.props.onCancel()
      })
      .catch((error) => {
        this.inRequest = false
        this.props.onError(this.props.apiError(error))
      })
  }

  render() {
    return (
      <AppForm
        form={this.form}
        onSave={this.onSave.bind(this)}
        onCancel={this.props.onCancel.bind(this)}
        spacing={3}
      />
    )
  }
}

export default withAppContext(withTranslation()(UserChangePassword))
