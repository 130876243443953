interface StorageInterface {
  get(key: string): string | null | undefined

  set(key: string, value: string): void

  delete(key: string): void
}

class CookieStorage implements StorageInterface {
  set(name: string, values: string) {
    const date = new Date()
    const value = values
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000)
    document.cookie =
      name + '=' + value + '; expires=' + date.toUTCString() + '; path=/'
  }

  get(name: string): string | undefined {
    const value = '; ' + document.cookie
    const parts = value.split('; ' + name + '=')

    if (parts.length === 2) {
      return parts.pop()!.split(';').shift()
    }
  }

  delete(name: string) {
    const date = new Date()
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000)
    document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/'
  }
}

class LocalStorage implements StorageInterface {
  set(key: string, value: string) {
    localStorage.setItem(key, value)
  }

  get(key: string) {
    return localStorage.getItem(key)
  }

  delete(key: string) {
    localStorage.removeItem(key)
  }
}

class BrowserStorage implements StorageInterface {
  engine: StorageInterface

  constructor() {
    try {
      const localEngine = new LocalStorage()
      // Check if localStorage is available
      localEngine.set('test', '1')
      localEngine.delete('test')
      this.engine = localEngine
    } catch {
      this.engine = new CookieStorage()
    }
  }

  local = new LocalStorage()
  cookie = new CookieStorage()

  get(key: string): string | undefined | null {
    return this.engine.get(key)
  }

  set(key: string, value: string) {
    return this.engine.set(key, value)
  }

  delete(key: string) {
    this.engine.delete(key)
  }

  setObject(key: string, object: {} | []) {
    this.set(key, JSON.stringify(object))
  }

  getAsObject<E>(key: string): E | null {
    try {
      return JSON.parse(this.get(key)!)
    } catch {
      return null
    }
  }
}

const browserStorage = new BrowserStorage()
export default browserStorage
