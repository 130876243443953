import {ControllerRenderProps} from 'react-hook-form/dist/types/controller'
import RegionsMapSelection from 'web-common/components/regions/RegionsMapSelection'
import {SurveyElementRegions} from '@fnd/survey'
import React, {useContext} from 'react'
import {Box} from '@mui/material'
import {AppContext} from 'web-common/contexts/AppContext'
import RegionsListSelection from 'web-common/components/regions/RegionsListSelection'

export interface RegionsWrapperProps extends ControllerRenderProps {
  element: SurveyElementRegions
}

const RegionsWrapper = React.forwardRef((props: RegionsWrapperProps, ref) => {
  const appContext = useContext(AppContext)
  const value = Array.isArray(props.value) ? props.value : []
  return (
    <Box ref={ref} id={props.element.name}>
      {appContext.theme.type !== 'accessibility' && (
        <RegionsMapSelection
          maxRegions={props.element.multiple ? Infinity : 1}
          onSelect={props.onChange}
          selected={value}
        />
      )}
      {appContext.theme.type === 'accessibility' && (
        <RegionsListSelection
          maxRegions={props.element.multiple ? Infinity : 1}
          onSelect={props.onChange}
          selected={value}
        />
      )}
    </Box>
  )
})
export default RegionsWrapper
