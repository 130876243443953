import React from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import AppDateService, {
  AppDateFormat
} from 'web-common/components/date/AppDateService'

export interface DateProps extends WithTranslation {
  timestamp: number
  format: AppDateFormat
  fallback?: string
}

class AppDate extends React.Component<DateProps> {
  render() {
    if (!this.props.timestamp && this.props.fallback) {
      return this.props.fallback
    }
    const date = new Date(this.props.timestamp)
    return AppDateService.shared().format(date, this.props.format)
  }
}

export default withTranslation()(AppDate)
