import React from 'react'
import Box from '@mui/material/Box'
import logoNonBeta from 'web-common/assets/img/logo.svg'
import {WithTranslation, withTranslation} from 'react-i18next'
import {getWhatsAppRegisterLink} from 'web-common/utilities/whatsappUtilities'
import {Button, Typography} from '@mui/material'
import AccessibilityControl from 'components/accessibility/AccessibilityControl'

interface WhatsAppRegistrationCustomerProps extends WithTranslation {}

interface WhatsAppRegistrationCustomerState {}

class WhatsAppRegistrationCustomer extends React.Component<
  WhatsAppRegistrationCustomerProps,
  WhatsAppRegistrationCustomerState
> {
  render() {
    return (
      <Box textAlign={'center'} display={'flex'} justifyContent={'center'}>
        <Box maxWidth={'540px'}>
          {/* LOGO */}
          <img
            src={logoNonBeta}
            alt={'finderella'}
            width={'259px'}
            style={{marginTop: '30px'}}
          />

          <Typography variant={'h6'} style={{margin: '30px auto 0 auto'}}>
            {this.props.t('fnd-whatsapp-registration-customer-title')}
          </Typography>

          <Typography variant={'h6'} style={{marginTop: '30px'}}>
            {this.props.t('fnd-whatsapp-registration-customer-description')}
          </Typography>

          <Button
            color={'primary'}
            variant={'contained'}
            style={{marginTop: '30px'}}
            href={getWhatsAppRegisterLink('I want to register')}
            target={'_blank'}
          >
            {this.props.t('fnd-whatsapp-registration-customer-button')}
          </Button>

          <Typography variant={'body1'} style={{margin: '30px auto 0 auto'}}>
            {this.props.t('fnd-whatsapp-registration-customer-footer')}
          </Typography>
        </Box>
        <AccessibilityControl />
      </Box>
    )
  }
}

export default withTranslation()(WhatsAppRegistrationCustomer)
