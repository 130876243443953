export type SurveyLocationType = number
export type SurveyLocationTypeValue = 'location' | 'office' | 'online'

class SurveyElementUILocationTypeService {
  static readonly LOCATION: SurveyLocationType = 1 << 0
  static readonly OFFICE: SurveyLocationType = 1 << 1
  static readonly ONLINE: SurveyLocationType = 1 << 2

  static readonly LOCATION_VALUE: SurveyLocationTypeValue = 'location'
  static readonly OFFICE_VALUE: SurveyLocationTypeValue = 'office'
  static readonly ONLINE_VALUE: SurveyLocationTypeValue = 'online'

  constructor(public mask: number) {}

  isChecked(type: SurveyLocationType) {
    return !!(this.mask & type)
  }

  add(type: SurveyLocationType) {
    this.mask |= type
  }

  remove(type: SurveyLocationType) {
    this.mask &= ~type
  }

  toggle(type: SurveyLocationType) {
    this.mask ^= type
  }

  textToBit(str: 'location' | 'office' | 'online'): SurveyLocationType {
    switch (str) {
      case 'location':
        return SurveyElementUILocationTypeService.LOCATION
      case 'office':
        return SurveyElementUILocationTypeService.OFFICE
      default:
        return SurveyElementUILocationTypeService.ONLINE
    }
  }

  maskToValues() {
    return [
      SurveyElementUILocationTypeService.LOCATION_VALUE,
      SurveyElementUILocationTypeService.OFFICE_VALUE,
      SurveyElementUILocationTypeService.ONLINE_VALUE
    ].filter((v) => this.isChecked(this.textToBit(v)))
  }
}

export default SurveyElementUILocationTypeService
