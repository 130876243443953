import React from 'react'
import {Box, styled, Typography} from '@mui/material'
import {withTranslation, WithTranslation} from 'react-i18next'
import {PricingItem} from 'web-common/models/OfferModel'
import PriceUnitsService from 'web-common/services/PriceUnitsService'
import Money from 'web-common/components/money/Money'
import {SC} from 'web-common/utilities/utilites'

const PriceTable = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridColumnGap: '8px',
  alignItems: 'baseline'
})

interface PriceBoxProps extends WithTranslation {
  prices: PricingItem[]
}

class PriceBox extends React.Component<PriceBoxProps, any> {
  priceUnits = PriceUnitsService.priceUnits

  extractUnitName(item: PricingItem) {
    return (
      this.priceUnits.find((priceUnit) => priceUnit.id === item._refs.unitId)
        ?.l10n?.[this.props.i18n.language].name ?? item.unit
    )
  }

  render() {
    return (
      <PriceTable>
        {this.props.prices.map((item, index) => (
          <React.Fragment key={'price-box-list' + index}>
            <Typography
              variant={'h6'}
              component={'span'}
              key={'c1' + index}
              sx={{textAlign: 'end'}}
            >
              <Money value={item.price} />
              <SC
                text={
                  this.extractUnitName(item) +
                  ' ' +
                  (item.description ? `, ${item.description}` : '')
                }
              />
            </Typography>

            <Typography
              variant={'body2'}
              color={'textSecondary'}
              key={'c2' + index}
              aria-hidden={true}
            >
              {this.extractUnitName(item)}
              {item.description ? `, ${item.description}` : ''}
            </Typography>
          </React.Fragment>
        ))}
      </PriceTable>
    )
  }
}

export default withTranslation()(PriceBox)
