import {Autocomplete, TextField, Typography, styled} from '@mui/material'
import {forwardRef, useContext} from 'react'
import {
  SurveyChoiceOptionType,
  SurveyLocalizedString,
  SurveyValueType
} from '@fnd/survey'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'

const CustomAutocomplete = styled(Autocomplete)({
  '& input': {
    caretColor: 'transparent'
  },
  '&.searchable input': {
    caretColor: 'initial'
  }
})

export interface DropdownWrapperProps {
  value: SurveyValueType
  onChange: (...e: unknown[]) => void
  choices: SurveyChoiceOptionType[]
  multiple?: boolean
  searchable?: boolean
  placeholder?: SurveyLocalizedString
}

const DropdownWrapper = forwardRef((props: DropdownWrapperProps, ref) => {
  const context = useContext(SRContext)
  const options = ['', ...(props.choices ?? []).map((o) => o.value)]
  const value = props.value ?? (props.multiple ? [] : '')
  const defaultLabel =
    props.placeholder?.[context.language] ??
    props.placeholder?.['en'] ??
    'Select'

  function onChange(_: unknown, selection: unknown) {
    if (Array.isArray(selection)) {
      props.onChange(selection)
    } else if (selection) {
      props.onChange(selection)
    }
  }

  return (
    <CustomAutocomplete
      ref={ref}
      autoComplete
      onChange={onChange}
      value={value}
      options={options}
      className={props.searchable ? 'searchable' : ''}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={defaultLabel}
          variant="outlined"
          onKeyDown={(e) => !props.searchable && e.preventDefault()}
        />
      )}
      renderOption={(props1, option) => {
        return (
          <li {...props1}>
            <Typography variant={'body1'}>
              {props.choices.find((o) => o.value === option)?.label[
                context.language
              ] ?? defaultLabel}
            </Typography>
          </li>
        )
      }}
      getOptionLabel={(option) =>
        props.choices.find((o) => o.value === option)?.label[
          context.language
        ] ?? defaultLabel
      }
      getOptionDisabled={(option) => option === ''}
      multiple={props.multiple}
      disableClearable
    />
  )
})
export default DropdownWrapper
