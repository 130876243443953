import {v4 as UUIDv4} from 'uuid'
import {AppConfig} from 'AppConfig'
import {Observable, ReplaySubject} from 'rxjs'
import {AxiosRequestConfig} from 'axios'
import BrowserStorage from 'web-common/services/BrowserStorage'
import kc from 'web-common/services/auth'
import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'

export interface MatchData {
  serviceId: string
  words: string[]
}

export interface SearchResultLogData {
  // User search query
  query?: string
  // Results
  matches?: MatchData[]
}

export interface SearchLoggerData {
  // Browser uuid
  sessionId?: string
  // Local date
  dataCreated?: number
  // Search session from API
  searchId?: string
  // search results
  searches: SearchResultLogData[]
  // Service at which user has clicked
  serviceId?: string
}

const paths = {
  log: {
    search: {
      '*': {
        searches: {}
      }
    }
  },
  requireToken: false,
  toString: () => AppConfig.api.base!
}

class SearchLogger extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: SearchLogger

  static shared() {
    if (!SearchLogger.instance) {
      SearchLogger.instance = new SearchLogger()
    }
    return SearchLogger.instance
  }

  // Path to api
  // private path: string = `${AppConfig.api.base}/log/search`
  // Browser id
  private readonly uuid: string = (() => {
    let browserUUID = BrowserStorage.get('browser-uuid')
    if (!browserUUID) {
      browserUUID = UUIDv4()
      BrowserStorage.set('browser-uuid', browserUUID)
    }
    return browserUUID
  })()
  // Search session id
  private searchId: string | undefined
  // Stack logs
  private queue: ReplaySubject<Observable<any>> | undefined

  /**
   * Log search results.
   * @param data
   */
  log(data: SearchResultLogData) {
    if (this.queue === undefined) {
      this.createSearchLog(data)
    } else {
      this.updateSearchLog(data)
    }
  }

  /**
   * Create new search log and store response as searchId
   * @param data
   */
  private createSearchLog(data: SearchResultLogData) {
    // Init request queue
    this.queue = new ReplaySubject<Observable<any>>()

    // Prepare new log data
    const body: SearchLoggerData = {
      sessionId: this.uuid,
      searches: [data]
    }

    // POST log
    this.api.paths.log.search
      .post(this.request)(body, SearchLogger.headerWithUserToken())
      .then((response: any) => {
        // Store searchId for the current session
        this.searchId = response.id
        // Create new subject
        this.queue!.subscribe((observe) => observe.subscribe())
      })
  }

  /**
   * Update current search session with new entry
   * @param data
   */
  private updateSearchLog(data: SearchResultLogData) {
    // PUSH new request in queue
    this.queue?.next(
      new Observable<any>((observer) => {
        if (this.searchId) {
          observer.next(
            this.api.paths.log.search['*'].searches
              .post(
                this.request,
                this.searchId
              )(data, SearchLogger.headerWithUserToken())
              .then()
          )
        }
      })
    )
  }

  /**
   * Clear current session
   */
  private clearSession() {
    this.queue?.complete()
    this.queue = undefined
    this.searchId = undefined
    BrowserStorage.delete('search-log-sync')
  }

  /**
   * Append user token if its available
   */
  private static headerWithUserToken(): AxiosRequestConfig {
    if (!kc.instance.authenticated) {
      return {}
    }
    return {
      headers: {
        Authorization: 'Bearer ' + kc.instance.token
      }
    }
  }

  /**
   * Finish search session with service id.
   * If user was not logged searchId must not be cleared,
   * because we wait for user to login and update the search session user
   *
   * @param serviceId
   */
  completeSessionSuccess(serviceId: string) {
    // PUSH new request in queue
    this.queue?.next(
      new Observable((observer) => {
        if (this.searchId) {
          observer.next(
            this.api.paths.log.search['*']
              .put(this.request, this.searchId)(
                {serviceId: serviceId},
                SearchLogger.headerWithUserToken()
              )
              .then((_) => {
                if (kc.instance.authenticated) {
                  // Clear current search session
                  this.clearSession()
                } else {
                  // Save pending sync
                  BrowserStorage.set(
                    'search-log-sync',
                    this.searchId!.toString()
                  )
                }
              })
          )
        }
      })
    )
  }

  /**
   * Check if there is pending sync search id and if he has complete it
   */
  syncPendingLogs() {
    const pendingSearchId = BrowserStorage.get('search-log-sync')
    if (pendingSearchId) {
      // SEND USER TOKEN TO UPDATE SEARCH ID
      this.api.paths.log.search['*']
        .put(this.request, pendingSearchId)(
          {},
          SearchLogger.headerWithUserToken()
        )
        .then((_) => {
          if (pendingSearchId === this.searchId) {
            // Clear current search session
            this.clearSession()
          }
        })
    }
  }
}

export default SearchLogger.shared()
