import React from 'react'
import Avatar, {AvatarProps} from '@mui/material/Avatar'
import {Box} from '@mui/material'
import {getAvatarURL} from 'web-common/components/avatar/AppAvatarHelper'

export interface AppAvatarProps extends AvatarProps {
  url?: string | null
  userId?: string
  type?: 'avatar' | 'logo'
  size?: number
  userName?: string
}

function userIdToHexColor(userId: string): string {
  const map: any = {
    0: '#C3B091',
    1: '#C0C0C0',
    2: '#808080',
    3: '#D2B48C',
    4: '#007FFF',
    5: '#084C9E',
    6: '#008080',
    7: '#228B22',
    8: '#808000',
    9: '#DAA520',
    a: '#FA8072',
    b: '#CC8899',
    c: '#843179',
    d: '#B57EDC',
    e: '#800000',
    f: '#DC143C'
  }
  const key = userId.slice(-1)
  return map[key] ?? map['0']
}

const AppAvatar = React.forwardRef<any, AppAvatarProps>((props, ref) => {
  const defaultSize = 40
  const {url, userId, type, classes, size, userName, ...rest} = {...props}
  const avatarSize = {width: size ?? defaultSize, height: size ?? defaultSize}
  if (rest.sx) {
    rest.sx = {...rest.sx, avatarSize}
  } else {
    rest.sx = avatarSize
  }

  const avatarURL = getAvatarURL(props)
  if (avatarURL === '' && userName) {
    const chunks = userName.trim().split(' ')
    rest.children = (
      <Box fontSize={`${(size ?? defaultSize) / 32}rem`}>
        {chunks[0][0] + (chunks.length > 1 ? chunks[1][0] : chunks[0][1])}
      </Box>
    )
    rest.sx = {...rest.sx, background: userIdToHexColor(userId ?? '')}
  } else {
    rest.src = avatarURL
  }

  return <Avatar {...rest} ref={ref} />
})

export default AppAvatar
