import {Button, Stack} from '@mui/material'
import {MouseEvent, useCallback, useContext, useEffect, useMemo} from 'react'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'
import {useFormContext} from 'react-hook-form'
import Loading from 'web-common/components/loading/Loading'
import {useTranslation} from 'react-i18next'

function SurveyNavigationView() {
  const [t] = useTranslation()
  const context = useContext(SRContext)
  const formContext = useFormContext()
  const progress = useMemo(() => {
    const currentIndex = context.renderer
      .getPages()
      .findIndex((page) => page.name === context.page.name)
    return [currentIndex, context.renderer.getPages().length]
  }, [context])

  const canSkip = !context.renderer.hasPageRequiredElements(context.page.name)
  const dirty = context.page?.elements?.some((el) => !!el.value)

  // PREVIOUS PAGE
  const previousPage = async () => {
    const newIndex = await context.renderer.previousPageIndex()
    context.setPage(context.renderer.getPages()[newIndex])
  }

  // NEXT PAGE
  const nextPage = useCallback(() => {
    ;(async () => {
      context.setInTransition(true)
      const newIndex = await context.renderer.nextPageIndex()
      if (newIndex > 0 && newIndex > progress[1] - 1) {
        // COMPLETE
        context.complete()
      } else {
        // NEXT PAGE
        context.setPage(context.renderer.getUpdatedPage())
      }
      context.setInTransition(false)
    })()
  }, [context, progress])

  // VALIDATE INPUTS BEFORE NEXT PAGE
  const onAdvance = async (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    try {
      if (await formContext.trigger()) {
        await nextPage()
      }
    } catch (err) {
      console.log(err)
    }
  }

  // AUTO ADVANCE
  useEffect(() => {
    const subscription = formContext.watch(async () => {
      if (
        context.page.elements[0].value &&
        context.renderer.canAutoAdvance(context.page.name)
      ) {
        await nextPage()
      }
    })
    return subscription.unsubscribe
  }, [formContext, context, progress, nextPage])

  const renderActions = () => {
    // ACTION DISABLED
    const disabled =
      formContext.formState.isSubmitted &&
      !!formContext.formState.errors?.[context.page?.name]
    return (
      <Stack direction={'row'} justifyContent={'center'} spacing={3}>
        {/*BACK*/}
        {progress[0] > context.renderer.getStartingPageIndex() && (
          <Button
            type={'button'}
            variant={'text'}
            color={'inherit'}
            onClick={previousPage}
          >
            {t('common:fnd-common-questionnaire-back')}
          </Button>
        )}
        {/*SKIP/NEXT*/}
        {progress[0] < progress[1] && (
          <Button type={'submit'} onClick={onAdvance} disabled={disabled}>
            {t(
              `common:fnd-common-questionnaire-${
                canSkip && !dirty ? 'skip' : 'next'
              }`
            )}
          </Button>
        )}
        {/*SUBMIT*/}
        {progress[0] >= progress[1] && (
          <Button type={'submit'} onClick={onAdvance} disabled={disabled}>
            {t('common:fnd-common-questionnaire-submit')}
          </Button>
        )}
      </Stack>
    )
  }

  return <Loading loading={context.inTransition} render={renderActions} />
}

export default SurveyNavigationView
