import React from 'react'
import {Button, IconButton, SnackbarContent} from '@mui/material'
import CustomerService from 'web-common/services/CustomerService'
import {WithTranslation, withTranslation} from 'react-i18next'
import NotificationService from 'web-common/services/NotificationService'
import notificationService from 'web-common/services/NotificationService'
import {UserData, UserEmailNotifications} from 'web-common/models/Profile'
import CloseIcon from '@mui/icons-material/Close'
import AppModal from 'web-common/components/modal/AppModal'
import UserContactEdit from 'web-common/views/profile/UserContactEdit'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'
import {WithAppContext, withAppContext} from 'web-common/contexts/AppContext'

interface NotificationsChannelsStatusViewProps
  extends WithTranslation,
    RouteComponentProps,
    WithAppContext {}

interface NotificationsChannelsStatusViewState {
  showEmailNotVerifiedAlert: boolean
  showVerifyEmail: boolean
  userData?: UserData
}

class NotificationsChannelsStatusView extends React.Component<
  NotificationsChannelsStatusViewProps,
  NotificationsChannelsStatusViewState
> {
  state: NotificationsChannelsStatusViewState = {
    showEmailNotVerifiedAlert: false,
    showVerifyEmail: false,
    userData: undefined
  }

  async componentDidMount() {
    await NotificationService.init()
    const userSettings = notificationService.settings.user
    let userData = await CustomerService.get()
    this.setState({
      userData: userData,
      showEmailNotVerifiedAlert:
        (this.isEmailNotificationsActivated(userSettings?.email) &&
          !userData?.contact.emailVerified) ??
        false
    })
  }

  isEmailNotificationsActivated(email?: UserEmailNotifications) {
    return (email?.jobRequestStatus || email?.chatMessages) ?? false
  }

  verifyEmail() {
    this.setState({
      showVerifyEmail: true
    })
  }

  closeModal() {
    this.setState({
      showVerifyEmail: false
    })
  }

  onEmailSuccessfullyVerified() {
    this.setState({
      showEmailNotVerifiedAlert: false,
      showVerifyEmail: false
    })
  }

  render() {
    return (
      <>
        {((_) => {
          if (this.state.showEmailNotVerifiedAlert) {
            return (
              <SnackbarContent
                style={{backgroundColor: this.props.theme.palette.primary.main}}
                message={this.props.t(
                  `common:fnd-label-email-not-verified-alert`,
                  {email: this.state.userData?.contact.email}
                )}
                action={
                  <>
                    <Button
                      variant={'text'}
                      color={'inherit'}
                      size={'small'}
                      onClick={this.verifyEmail.bind(this)}
                    >
                      {this.props.t('common:fnd-common-verify')}
                    </Button>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={() =>
                        this.setState({
                          showEmailNotVerifiedAlert: false
                        })
                      }
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </>
                }
              />
            )
          }
        })()}
        <AppModal
          open={this.state.showVerifyEmail}
          onClose={this.closeModal.bind(this)}
        >
          <UserContactEdit
            type={'email'}
            currentContact={this.state.userData?.contact?.email}
            onSave={this.onEmailSuccessfullyVerified.bind(this)}
            onCancel={this.closeModal.bind(this)}
            onError={(message) => console.log(message)}
          />
        </AppModal>
      </>
    )
  }
}

export default withRouter(
  withTranslation()(withAppContext(NotificationsChannelsStatusView))
)
