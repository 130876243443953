import {ReactElement} from 'react'
import {
  Alert,
  Box,
  Button,
  CardContent,
  CircularProgress,
  Typography
} from '@mui/material'
import {BlueCard} from 'web-common/views/timeline/styled/BlueCard'
import {GreyCard} from 'web-common/views/timeline/styled/GreyCard'
import kc from 'web-common/services/auth'
import TimelineEventTemplate from 'web-common/views/timeline/TimelineEventTemplate'
import {WithTranslation, withTranslation} from 'react-i18next'
import {TimelineEventProducer} from '@fnd/timeline'
import EventBus from 'web-common/services/EventBus'

export interface MessageEventProps {
  userId: string
  message: string | ReactElement
  date: number
  sent?: boolean
  eventId: string
  producer: TimelineEventProducer
}

interface InternalProps extends MessageEventProps, WithTranslation {}

interface MessageEventState {
  inRequest: boolean
}

class MessageEvent extends TimelineEventTemplate<
  InternalProps,
  MessageEventState
> {
  state: MessageEventState = {
    inRequest: false
  }

  async resendMessage() {
    // Reconnect if connection is lost
    if (!EventBus.connection.value) {
      await EventBus.connect().catch()
    }
    this.setState({inRequest: true}, async () => {
      await this.props.producer.reCreateEvent(this.props.eventId)
      this.setState({inRequest: false})
    })
  }

  render() {
    const UserCard =
      this.props.userId === kc.instance.tokenParsed?.sub ? BlueCard : GreyCard
    return (
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'inherit'}
        gap={'0.25rem'}
        position={'relative'}
      >
        <UserCard variant={'outlined'}>
          <CardContent>
            <Typography variant={'body1'} style={{whiteSpace: 'pre-line'}}>
              {this.props.message}
            </Typography>
          </CardContent>
        </UserCard>

        {this.props.sent === false && (
          <Alert severity={'error'} sx={{alignItems: 'center'}}>
            {this.props.t('common:fnd-common-event-message-send-failed')}
            <Button
              color={'error'}
              size={'small'}
              variant={'text'}
              onClick={this.resendMessage.bind(this)}
              disabled={this.state.inRequest}
            >
              {this.state.inRequest ? (
                <CircularProgress />
              ) : (
                this.props.t('common:fnd-common-event-resend-message')
              )}
            </Button>
          </Alert>
        )}
      </Box>
    )
  }
}

export default withTranslation()(MessageEvent)
