import {Trans} from 'react-i18next'
import kc from 'web-common/services/auth'
import {AppConfig} from 'AppConfig'
import PreRegistrationService, {
  RegistrationFormData,
  RegistrationRequestData
} from 'views/registration/pre-registration/PreRegistrationService'
import AppForm, {AppFormInput} from 'web-common/components/formBuilder/AppForm'
import AppValidator from 'web-common/components/formBuilder/AppValidator'
import {Box, CircularProgress, Container, Link, Typography} from '@mui/material'
import {AxiosResponse} from 'axios'
import logo from 'web-common/assets/img/logo.svg'
import PhoneAlreadyRegistered from 'views/registration/pre-registration/ErrorViews/PhoneAlreadyRegistered'
import InvalidExpiredCode from 'views/registration/pre-registration/ErrorViews/InvalidExpiredCode'
import AppPage from 'web-common/utilities/AppPage'
import AccessibilityControl from 'components/accessibility/AccessibilityControl'

class UserLoggedError extends Error {
  constructor(public role: 'customer' | 'contractor') {
    super()
  }
}

class InvalidURLError extends Error {}

interface RouteParams {
  code?: string
}

interface PreRegistrationViewProps {}

interface PreRegistrationViewState {
  // Code from url
  code?: string
  // If error is empty and data is presented will render form
  data?: RegistrationRequestData
  // Page to handle all invalid code/data flow
  error?: 'invalid-code' | 'phone-is-registered'
  // Form error message
  formError?: string
}

class PreRegistrationView extends AppPage<
  PreRegistrationViewProps,
  PreRegistrationViewState,
  RouteParams
> {
  static path = '/r/:code?'

  title = 'Registration'
  state: PreRegistrationViewState = {}

  phrases = {
    customer: {
      title: 'fnd-pre-registration-customer-title',
      subtitle: 'fnd-pre-registration-customer-subtitle'
    },
    contractor: {
      title: 'fnd-pre-registration-contractor-title',
      subtitle: 'fnd-pre-registration-contractor-subtitle'
    }
  }
  inRequest = false

  async componentDidMount() {
    super.componentDidMount()
    try {
      this.checkUserSession()
      this.validateURLParams()
      await this.parseURLCode()
    } catch (e) {
      if (e instanceof UserLoggedError) {
        if (e.role === 'contractor') {
          window.location.href = AppConfig.contractor.base!
        } else {
          this.props.navigate('/')
        }
      } else if (e instanceof InvalidURLError) {
        this.props.navigate('/')
      } else {
        this.setState({error: 'invalid-code'})
      }
    } finally {
      await this.tryApplyLanguage()
    }
  }

  async tryApplyLanguage() {
    const data = new URLSearchParams(this.props.location.search)
    if (data.get('lang')) {
      await this.props.i18n.changeLanguage(data.get('lang')!)
    }
  }

  // User should not be logged
  checkUserSession() {
    if (kc.instance.authenticated) {
      if ((kc.instance.realmAccess?.roles ?? []).includes('contractor')) {
        throw new UserLoggedError('contractor')
      }
      throw new UserLoggedError('customer')
    }
  }

  validateURLParams() {
    if (!this.props.params.code) {
      throw new InvalidURLError()
    }
  }

  async parseURLCode() {
    const data = await PreRegistrationService.getData(this.props.params.code!)
    data.phone = data.phone
      .replace(/\D/g, '') // REMOVE ALL NON_DIGITS
      .replace(/^0/, '972') // REPLACE '0' WITH COUNTRY CODE
    this.setState({data: data, code: this.props.params.code})
  }

  generateForm(): AppFormInput[] {
    return [
      {
        type: 'phone',
        name: 'phone',
        disabled: true,
        label: 'common:fnd-common-phone',
        placeholder: 'common:fnd-common-phone',
        value: this.state.data?.phone ?? '',
        size: {xs: 12},
        data: '972',
        required: false,
        dynamicError: (value?: string, _?: RegExp) => {
          const badCode = new RegExp(/^9725[679]\d*/)
          if (badCode.test(value ?? '')) {
            return this.props.t('common:fnd-company-phone-bad-code')
          }
          return this.props.t('common:fnd-company-phone-invalid-format')
        }
      },
      {
        type: 'text',
        name: 'firstName',
        label: 'common:fnd-common-first-name',
        placeholder: 'common:fnd-common-first-name',
        errorText: 'common:fnd-common-validation-first-name',
        trim: true,
        validator: AppValidator.name,
        value: this.state.data?.firstName ?? '',
        size: {xs: 12}
      },
      {
        type: 'text',
        name: 'lastName',
        label: 'common:fnd-common-last-name',
        placeholder: 'common:fnd-common-last-name',
        errorText: 'common:fnd-common-validation-last-name',
        trim: true,
        validator: AppValidator.name,
        value: this.state.data?.lastName ?? '',
        size: {xs: 12}
      },
      {
        type: 'email',
        name: 'email',
        label: 'common:fnd-common-email',
        placeholder: 'common:fnd-common-email',
        errorText: 'common:fnd-common-required-valid-email',
        validator: AppValidator.email,
        value: this.state.data?.email ?? '',
        size: {xs: 12}
      },
      {
        type: 'text',
        name: 'password',
        label: 'fnd-pre-registration-password-label',
        placeholder: 'fnd-pre-registration-password-placeholder',
        errorText: 'fnd-pre-registration-password-error',
        validator: AppValidator.password,
        size: {xs: 12}
      },
      {
        type: 'checkbox',
        name: 'toc',
        label: (
          <Trans i18nKey="fnd-agree-terms-link">
            w1
            <Link
              href={
                AppConfig.publicDir +
                '/docs/he/Terms_and_Conditions_of_Use-Hebrew.html'
              }
              target="_blank"
              rel="noreferrer noopener"
            >
              w2
            </Link>
            w3
          </Trans>
        ),
        size: {xs: 12},
        data: 'ticked',
        excludeIfEmpty: true,
        errorText: 'fnd-toc-are-required',
        validator: new RegExp(/ticked/)
      }
    ]
  }

  onRegister(data: RegistrationFormData) {
    if (this.inRequest) {
      return
    }
    this.inRequest = true
    this.props.setBackdrop(true)
    delete data.toc
    PreRegistrationService.create(this.state.code!, data).then(
      (result) => {
        if (result['redirectUri']) {
          window.location.href = result['redirectUri']
        } else {
          window.location.href = AppConfig.contractor.base + 'onboarding'
        }
      },
      (error: AxiosResponse) => {
        this.inRequest = false
        this.props.setBackdrop(false)
        if (error?.data?.key === 'api-reg-err-user-phone-exists') {
          // If phone is used show error page
          this.setState({error: 'phone-is-registered'})
        } else {
          let errorMessage =
            error?.data?.key ?? 'common:fnd-common-unexpected-error'
          this.setState({formError: errorMessage}, () => {
            window.scrollTo(0, 0)
          })
        }
      }
    )
  }

  // REGISTRATION FORM
  renderForm() {
    return (
      <>
        <Container maxWidth="xs">
          {/*LOGO*/}
          <Box sx={{margin: '60px 0'}}>
            <img src={logo} alt="finderella" width="160px" />
          </Box>
          {/*TITLE*/}
          <Typography variant="h4" sx={{marginBottom: '20px'}}>
            {this.props.t(this.phrases.customer.title)}
          </Typography>

          {/*SUBTITLE*/}
          <Typography variant="h5" sx={{marginBottom: '30px'}}>
            {this.props.t(this.phrases.customer.subtitle)}
          </Typography>

          {/*ERROR*/}
          {(() => {
            if (this.state.formError !== undefined) {
              return (
                <Box
                  sx={{
                    color: this.props.theme.palette.common.white,
                    backgroundColor: this.props.theme.palette.error.dark,
                    width: 'calc(100% - 2rem)',
                    margin: '2rem auto',
                    padding: '1rem'
                  }}
                >
                  <Typography variant="body1">
                    {this.props.t(this.state.formError)}
                  </Typography>
                </Box>
              )
            }
          })()}

          <AppForm
            spacing={3}
            form={this.generateForm()}
            saveLabel={this.props.t('fnd-label-register')}
            onSave={this.onRegister.bind(this)}
            key={this.props.i18n.language}
          />
        </Container>
      </>
    )
  }

  renderErrorPage() {
    if (this.state.error === 'phone-is-registered') {
      return <PhoneAlreadyRegistered phone={this.state.data?.phone ?? ''} />
    }
    return <InvalidExpiredCode />
  }

  render() {
    return (
      <>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            backgroundColor: this.props.theme.palette.common.white,
            zIndex: 1300,
            textAlign: 'center',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          {(() => {
            if (this.state.error) {
              // RENDER ERROR
              return this.renderErrorPage()
            } else if (this.state.data) {
              // RENDER FORM
              return this.renderForm()
            } else {
              // RENDER LOADING
              return (
                <Box sx={{textAlign: 'center', lineHeight: '100vh'}}>
                  <CircularProgress color={'inherit'} />
                </Box>
              )
            }
          })()}
        </Box>
        <AccessibilityControl />
      </>
    )
  }
}

export default PreRegistrationView.exportDefault()
