import {t} from 'i18next'

export type AppDateFormat =
  | 'date'
  | 'date-time'
  | 'time-elapsed'
  | 'time'
  | 'short-date'
  | 'short-date-time'
  | 'time-elapsed-short'
  | 'dd-mm-yyyy-hh:mm'

class AppDateService {
  private readonly months = [
    'fnd-common-month-january',
    'fnd-common-month-february',
    'fnd-common-month-march',
    'fnd-common-month-april',
    'fnd-common-month-may',
    'fnd-common-month-june',
    'fnd-common-month-july',
    'fnd-common-month-august',
    'fnd-common-month-september',
    'fnd-common-month-october',
    'fnd-common-month-november',
    'fnd-common-month-december'
  ]
  private readonly monthsShort = [
    'fnd-common-month-january-short',
    'fnd-common-month-february-short',
    'fnd-common-month-march-short',
    'fnd-common-month-april-short',
    'fnd-common-month-may-short',
    'fnd-common-month-june-short',
    'fnd-common-month-july-short',
    'fnd-common-month-august-short',
    'fnd-common-month-september-short',
    'fnd-common-month-october-short',
    'fnd-common-month-november-short',
    'fnd-common-month-december-short'
  ]

  private constructor() {}

  private static instance: AppDateService
  public static shared() {
    return (
      AppDateService.instance ??
      (AppDateService.instance = new AppDateService())
    )
  }

  format(date: Date, format: AppDateFormat) {
    switch (format) {
      case 'date':
      case 'short-date':
        return this.getDate(date, format)
      case 'date-time':
      case 'short-date-time':
        return `${this.getDate(date, format)} ${this.getTime(date)}`
      case 'time':
        return this.getTime(date)
      case 'time-elapsed':
        return this.getTimeElapsed(date)
      case 'time-elapsed-short':
        return this.getTimeElapsedShort(date)
      case 'dd-mm-yyyy-hh:mm':
        return `${this.getNumericDate(date, '.')} ${this.getTime(date)}`
      default:
        return date.toLocaleDateString()
    }
  }

  convertISO8601ToUnixTimestamp(dateTimeString: string) {
    const date = new Date(dateTimeString)

    return date.getTime()
  }

  private getTime(date: Date) {
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
  }

  private getDate(date: Date, format: AppDateFormat) {
    const year = date.getFullYear()
    const month =
      format === 'short-date' || format === 'short-date-time'
        ? this.monthsShort[date.getMonth()]
        : this.months[date.getMonth()]
    const day = date.getDate()
    return [day, t('common:' + month), year].join(' ')
  }

  private getNumericDate(date: Date, separator = ' ') {
    const monthWithLeadingZero = ('0' + (date.getMonth() + 1)).slice(-2)

    return [date.getDate(), monthWithLeadingZero, date.getFullYear()].join(
      separator
    )
  }

  private getTimeElapsedFormatter(
    date: Date,
    formatter: (elapse: number, key: string) => string
  ) {
    const since = date.getTime()
    const elapsed = (new Date().getTime() - since) / 1000

    const diff: {[key: string]: number} = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }

    diff.days = Math.floor(elapsed / 86400)
    diff.hours = Math.floor((elapsed / 3600) % 24)
    diff.minutes = Math.floor((elapsed / 60) % 60)
    diff.seconds = Math.floor(elapsed % 60)

    for (const key in diff) {
      if (diff[key] > 0) {
        return formatter(diff[key], key)
      }
    }
    return 'now'
  }

  private getTimeElapsed(date: Date) {
    return this.getTimeElapsedFormatter(date, (elapse, key) =>
      t(`common:fnd-common-${key}-ago`, {count: elapse})
    )
  }

  private getTimeElapsedShort(date: Date) {
    return this.getTimeElapsedFormatter(date, (elapse, key) => {
      if (elapse < 60 && key[0] === 's') {
        return t('common:fnd-common-time-elapsed-now')
      }
      return elapse + key[0]
    })
  }
}

export default AppDateService
