import React from 'react'

interface ContextComposeProps {
  components: any[]
  children: JSX.Element
}

interface ContextComposeState {}

class ContextCompose extends React.Component<
  ContextComposeProps,
  ContextComposeState
> {
  render() {
    return (
      <>
        {this.props.components.reduceRight(
          (acc, ContextProvider) => (
            <ContextProvider>{acc}</ContextProvider>
          ),
          this.props.children
        )}
      </>
    )
  }
}

export default ContextCompose
