import {SvgIconProps} from '@mui/material'
import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

export default function FinderellaIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M15.442 2.878a8.649 8.649 0 01-3.5 16.417l-1.087 1.88c-.23.397-.546.653-.95.769-.404.115-.814.053-1.23-.187-.397-.23-.652-.55-.762-.964-.111-.412-.052-.818.178-1.215l.163-.284 1.658-2.872a6.028 6.028 0 007.037-2.901l.117-.228a6.027 6.027 0 10-10.023 1.245l.163.187-1.354 2.344a8.65 8.65 0 019.59-14.192zM12.197 6.44c1.226.14 2.641 1.27 3.115 1.994.323.493.562.893.632 1.267.07.374.01.642-.184.803a.494.494 0 01-.13.088c-.318.135-.579.065-.784-.21a1.457 1.457 0 01-.125-.22 7.014 7.014 0 00-.563-.937c-.462-.62-1.186-1.081-1.97-1.293-.255-.07-.6-.05-1.022-.034a1.186 1.186 0 01-.236-.001c-.322-.047-.513-.254-.575-.622a.6.6 0 010-.167c.024-.265.2-.463.527-.594.327-.13.764-.137 1.315-.074z" />
    </SvgIcon>
  )
}
