import React from 'react'
import {Button, Grid, styled, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {WithTranslation, withTranslation} from 'react-i18next'
import {getWhatsAppLink} from 'web-common/utilities/whatsappUtilities'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'
import {Link} from 'react-router-dom'

const CustomLink = styled(Link)((props) => ({
  textDecoration: 'none',
  color: props.theme.palette.primary.main,
  '&:hover, &:focus': {
    color: props.theme.palette.primary.dark
  }
}))

interface CantFindServiceViewProps
  extends WithTranslation,
    RouteComponentProps {
  queryString?: string
  onClose: () => void
}

interface CantFindServiceViewState {}

class CantFindServiceView extends React.Component<
  CantFindServiceViewProps,
  CantFindServiceViewState
> {
  onStartChat() {
    const service = this.props.queryString ?? ''
    const url = getWhatsAppLink('fnd-whatsapp-cant-find-service-title', {
      service: service
    })
    window.open(url, '_blank')
    this.props.onClose()
  }

  render() {
    return (
      <>
        {/* MISSING SERVICE */}
        {((_) => {
          if (this.props.queryString) {
            return (
              <Typography variant={'subtitle2'} style={{marginBottom: '22px'}}>
                {this.props.queryString}
              </Typography>
            )
          }
        })()}

        {/* SUBTITLE */}
        <Typography variant={'body1'} style={{marginBottom: '42px'}}>
          {this.props.t('fnd-label-service-not-found-subtitle')}
        </Typography>

        {/* HINTS */}
        <Grid container spacing={4}>
          {/* HINT 1 */}
          <Grid item xs={12} sm={4}>
            <Box textAlign={'center'} marginBottom={'10px'} aria-hidden>
              <span role={'img'} aria-label={'pin'}>
                📌
              </span>
            </Box>
            <Typography variant={'body1'}>
              {this.props.t('fnd-label-service-not-found-hint-1')}
            </Typography>
          </Grid>

          {/* HINT 2 - BROWSE CATEGORIES */}
          <Grid item xs={12} sm={4}>
            <Box textAlign={'center'} marginBottom={'10px'} aria-hidden>
              <span role={'img'} aria-label={'pin'}>
                📌
              </span>
            </Box>
            <Typography variant={'body1'}>
              {this.props.t('fnd-label-service-not-found-hint-2')}
              <br />
              <br />
              <CustomLink to={'/browse'}>
                {this.props.t('fnd-label-browse-all-categories')}
                <Box
                  position={'relative'}
                  top={'2px'}
                  display={'inline'}
                  component={'span'}
                >
                  {this.props.i18n.dir() === 'ltr' ? (
                    <ChevronRightIcon fontSize={'inherit'} />
                  ) : (
                    <ChevronLeftIcon fontSize={'inherit'} />
                  )}
                </Box>
              </CustomLink>
            </Typography>
          </Grid>

          {/* HINT 3 - START CHAT */}
          <Grid item xs={12} sm={4}>
            <Box textAlign={'center'} marginBottom={'10px'} aria-hidden>
              <span role={'img'} aria-label={'pin'}>
                📌
              </span>
            </Box>
            <Typography variant={'body1'}>
              {this.props.t('fnd-label-service-not-found-hint-3')}
              <br />
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={this.onStartChat.bind(this)}
              >
                {this.props.t('fnd-label-service-not-found-start-chat')}
              </Button>
            </Typography>
          </Grid>
        </Grid>

        {/* CLOSE HANDLER */}
        <Box textAlign={'center'} marginTop={'30px'}>
          <Button color={'secondary'} onClick={this.props.onClose.bind(this)}>
            {this.props.t('fnd-label-close')}
          </Button>
        </Box>
      </>
    )
  }
}

export default withTranslation()(withRouter(CantFindServiceView))
