import {withTranslation, WithTranslation} from 'react-i18next'
import React, {ChangeEvent} from 'react'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'
import {FormControlLabel, Radio, RadioGroup} from '@mui/material'

export interface AppFormRadioGroupData {
  value: string
  label: string | React.ReactElement // | number
}

interface AppFormRadioGroupProps extends AppFormComponent, WithTranslation {}

class AppFormRadioGroup extends AppFormInputComponent<
  AppFormRadioGroupProps,
  any
> {
  componentDidMount() {
    if (!this.props.data) {
      throw new Error(
        'AppFormRadioGroup expect data to be in AppFormRadioGroupData format'
      )
    }
  }

  onChange(e: ChangeEvent<HTMLInputElement>) {
    const {onChangeCallback, ...rest} = {...this.props}
    const value: string = e.target.value
    this.props.onChangeCallback(rest, value)
  }

  render() {
    const options = this.props.data! as AppFormRadioGroupData[]
    return (
      <RadioGroup
        name={this.props.name}
        onChange={this.onChange.bind(this)}
        value={this.props.value}
        style={{flexDirection: 'row'}}
        hidden={this.props.hidden}
      >
        {options.map((item) => (
          <FormControlLabel
            value={item.value}
            key={item.value}
            control={<Radio disabled={this.props.disabled} />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    )
  }
}

export default withTranslation()(AppFormRadioGroup)
