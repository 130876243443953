import React from 'react'
import GridComposer from 'components/layout/GridComposer'
import {WithTranslation, withTranslation} from 'react-i18next'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from '@mui/material'
import NotificationService from 'web-common/services/NotificationService'
import {withAppContext, WithAppContext} from 'web-common/contexts/AppContext'
import UserContactEdit from 'web-common/views/profile/UserContactEdit'
import {
  UserContactData,
  UserNotificationsSettingsData
} from 'web-common/models/Profile'
import CustomerService from 'web-common/services/CustomerService'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import EmailIcon from '@mui/icons-material/Mail'

interface NotificationPreferencesViewProps
  extends WithTranslation,
    WithAppContext {
  onSuccess: () => void
}

interface NotificationPreferencesViewState {
  settings: UserNotificationsSettingsData
  contact?: UserContactData
  inRequest: boolean
}

class NotificationPreferencesView extends React.Component<
  NotificationPreferencesViewProps,
  NotificationPreferencesViewState
> {
  defaultUserSettings = {
    email: {jobRequestStatus: false, chatMessages: false},
    whatsApp: {jobRequestStatus: false, chatMessages: false},
    sms: {jobRequestStatus: false, chatMessages: false},
    emailNews: {
      announcements: false,
      helpTips: false,
      specialOffers: false,
      systemUpdates: false
    }
  }

  state: NotificationPreferencesViewState = {
    settings: this.defaultUserSettings,
    inRequest: false,
    contact: undefined
  }

  async componentDidMount() {
    await NotificationService.init()
    const user = await CustomerService.get()
    this.setState({
      contact: user.contact,
      settings: NotificationService.settings.user ?? this.defaultUserSettings
    })
  }

  onChangePreference(checked: boolean, type: 'email' | 'whatsApp') {
    this.setState((prev) => {
      prev.settings[type].jobRequestStatus = checked
      if (type === 'whatsApp') {
        prev.settings[type].chatMessages = checked
      }
      return prev
    })
  }

  updatePreferences() {
    if (this.state.inRequest) {
      return
    }
    this.setState({inRequest: true}, () =>
      NotificationService.updateUser(this.state.settings)
        .then((_) => {
          this.props.onSuccess()
        })
        .catch((_) => {
          this.props.pushNotification({
            message: this.props.t(
              'fnd-label-update-notification-preferences-error'
            ),
            color: 'error'
          })
        })
    )
  }

  isFormValid() {
    if (
      !this.state.settings.email.jobRequestStatus &&
      !this.state.settings.whatsApp.jobRequestStatus
    ) {
      return false
    }
    return !(
      this.state.settings.email.jobRequestStatus &&
      !this.state.contact?.emailVerified
    )
  }

  onValidateEmail() {
    this.setState((prev) => {
      prev.contact!.emailVerified = true
      return prev
    })
  }

  render() {
    return (
      <GridComposer>
        <Typography variant={'body1'}>
          {this.props.t('fnd-label-notification-preferences-description')}
        </Typography>
        <FormGroup>
          <FormControlLabel
            style={{alignItems: 'self-end'}}
            control={
              <Checkbox
                checked={this.state.settings.email.jobRequestStatus}
                onChange={(event, checked) =>
                  this.onChangePreference(checked, 'email')
                }
              />
            }
            label={
              <Grid
                container
                spacing={1}
                alignItems={'center'}
                alignSelf={'end'}
              >
                <Grid item>
                  <Box color={'#d8d8d8'}>
                    <EmailIcon />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>
                    {this.props.t('fnd-label-email')} (
                    {this.state.contact?.email})
                  </Typography>
                </Grid>
                {((_) => {
                  if (!this.state.contact?.emailVerified) {
                    return (
                      <Grid item>
                        <Typography variant={'caption'} color={'red'}>
                          {this.props.t('fnd-label-not-verified')}
                        </Typography>
                      </Grid>
                    )
                  }
                })()}
              </Grid>
            }
          />
          {((_) => {
            if (
              this.state.settings.email.jobRequestStatus &&
              !this.state.contact?.emailVerified
            ) {
              return (
                <Box style={{marginLeft: '40px', marginRight: '40px'}}>
                  <GridComposer>
                    <Typography variant={'caption'}>
                      {this.props.t(
                        'fnd-label-verify-email-received-notifications'
                      )}
                    </Typography>
                    <UserContactEdit
                      type={'email'}
                      currentContact={this.state.contact?.email}
                      onSave={this.onValidateEmail.bind(this)}
                      onError={(message) => console.log(message)}
                      disableBackdrop
                    />
                  </GridComposer>
                </Box>
              )
            }
          })()}
          <FormControlLabel
            style={{alignItems: 'self-end'}}
            control={
              <Checkbox
                checked={this.state.settings.whatsApp.jobRequestStatus}
                onChange={(event, checked) =>
                  this.onChangePreference(checked, 'whatsApp')
                }
              />
            }
            label={
              <Grid container spacing={1} alignItems={'center'}>
                <Grid item>
                  <Box color={'#d8d8d8'}>
                    <WhatsAppIcon />
                  </Box>
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>
                    {this.props.t('fnd-label-whatsapp')} (
                    {this.state.contact?.phone})
                  </Typography>
                </Grid>
              </Grid>
            }
          />
          {((_) => {
            if (this.state.settings.whatsApp.jobRequestStatus) {
              return (
                <Box marginLeft={'40px'}>
                  <Typography variant={'caption'}>
                    {this.props.t('fnd-label-whatsapp-preferences')}
                  </Typography>
                </Box>
              )
            }
          })()}
        </FormGroup>
        <Box textAlign={'center'}>
          <GridComposer spacing={1}>
            <Button
              color={'primary'}
              disabled={!this.isFormValid()}
              onClick={this.updatePreferences.bind(this)}
            >
              {this.props.t('fnd-label-save-preferences')}
            </Button>
            <Typography variant={'caption'}>
              {this.props.t(
                'fnd-label-notification-preferences-description-note'
              )}
            </Typography>
          </GridComposer>
        </Box>
      </GridComposer>
    )
  }
}

export default withTranslation()(withAppContext(NotificationPreferencesView))
