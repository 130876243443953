import TimelineEventTemplate from 'web-common/views/timeline/TimelineEventTemplate'
import {Button} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded'
import AppModal from 'web-common/components/modal/AppModal'
import CompareOffers from 'views/demands/demand/comparation/CompareOffers'
import React from 'react'

export interface CompareOffersActionProps {
  demandId: string
}

interface InternalProps extends CompareOffersActionProps, WithTranslation {}

interface CompareOffersActionState {
  modal: boolean
}

class CompareOffersAction extends TimelineEventTemplate<
  InternalProps,
  CompareOffersActionState
> {
  state: CompareOffersActionState = {
    modal: false
  }

  onOpenModal() {
    this.setState({modal: true})
  }

  onCloseModal() {
    this.setState({modal: false})
  }

  render() {
    return (
      <>
        <Button
          startIcon={<FormatListNumberedRoundedIcon />}
          variant={'text'}
          color={'primary'}
          onClick={this.onOpenModal.bind(this)}
        >
          {this.props.t('fnd-demand-compare-offers')}
        </Button>

        {/*COMPARE OFFERS*/}
        <AppModal
          open={this.state.modal}
          title={this.props.t('fnd-demand-compare-offers')}
          onClose={this.onCloseModal.bind(this)}
          maxWidth={'lg'}
        >
          <CompareOffers demandId={this.props.demandId} />
        </AppModal>
      </>
    )
  }
}

export default withTranslation()(CompareOffersAction)
