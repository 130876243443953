import React from 'react'
import {List, ListItem, ListItemText, Stack, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import Hidden from '@mui/material/Hidden'
import UserDetailsView from 'web-common/views/profile/UserDetailsView'
import {UserData} from 'web-common/models/Profile'
import MyReviewsView from 'web-common/views/reviews/MyReviewsView'
import ReceivedReviewsView from 'web-common/views/reviews/ReceivedReviewsView'
import SplitView from 'web-common/components/layouts/SplitView'
import AppModal from 'web-common/components/modal/AppModal'
import Navigation from 'web-common/components/navigation/Navigation'
import AppPage, {AppPageProps} from 'web-common/utilities/AppPage'
import LeftAction from 'components/navigation/LeftAction'
import {responsiveBP} from 'web-common/theme/AppTheme'
import NotificationsWrapper from 'views/profile/notifications/NotificationsWrapper'

export enum ProfileMenuType {
  ACCOUNT = 'account',
  NOTIFICATIONS = 'notification',
  MY_REVIEWS = 'my-reviews',
  RECEIVED_REVIEWS = 'received-reviews'
}

interface ProfileViewProps {}

interface ProfileViewState {
  // Visibility state of the modal
  editModal: boolean
  // Mark selected button
  selectedMenu: string
  // focused view
  focusedView: 'left' | 'right'
}

interface ProfileMenuItem {
  id: ProfileMenuType
  label: string
  view: any //ComponentClass<any, any> | ComponentType,
  props?: any
}

class ProfileView extends AppPage<ProfileViewProps, ProfileViewState> {
  static path = '/profile/:edit?'

  title = 'My Profile'

  constructor(props: AppPageProps<ProfileViewProps>, state: ProfileViewState) {
    super(props, state)

    const params = props.params as any
    let selectedMenu = ProfileMenuType.ACCOUNT
    if (params.edit) {
      selectedMenu = params.edit
    }
    this.state = {
      selectedMenu: selectedMenu,
      editModal: false,
      focusedView: 'left'
    }
  }

  menu: ProfileMenuItem[] = [
    {
      id: ProfileMenuType.ACCOUNT,
      label: 'fnd-menu-account',
      view: UserDetailsView,
      props: {
        onProfileUpdate: (customer: UserData) => {
          this.props.update({
            customer: customer,
            contractor: this.props.profile.contractor
          })
          this.saveSuccess()
        }
      }
    },
    {
      id: ProfileMenuType.NOTIFICATIONS,
      label: 'fnd-menu-notifications',
      view: NotificationsWrapper
    },
    {
      id: ProfileMenuType.MY_REVIEWS,
      label: 'fnd-menu-my-review',
      view: MyReviewsView,
      props: {contractorReviews: false}
    },
    {
      id: ProfileMenuType.RECEIVED_REVIEWS,
      label: 'fnd-menu-received-review',
      view: ReceivedReviewsView,
      props: {contractorReviews: false}
    }
  ]

  closeModals() {
    this.setState({
      editModal: false
    })
  }

  menuItemSelect(id: string) {
    this.props.navigate('/profile/' + id)
    this.setState({selectedMenu: id, focusedView: 'right'})
  }

  // onFocusLeftView() {
  //   this.setState({focusedView: 'left'})
  // }

  renderMenu() {
    return (
      <List
        component={'nav'}
        aria-label={'Profile menu'}
        aria-controls={'user-panel-details'}
      >
        {this.menu.map((item) => (
          <ListItem
            button
            key={item.label}
            selected={this.state.selectedMenu === item.id}
            onClick={this.menuItemSelect.bind(this, item.id)}
          >
            <ListItemText primary={this.props.t(item.label)} />
          </ListItem>
        ))}
      </List>
    )
  }

  renderDetails() {
    const item = this.menu.find((item) => item.id === this.state.selectedMenu)
    const El = item?.view ?? Box
    return (
      <Box
        aria-live={'polite'}
        id={'user-panel-details'}
        sx={{border: 'none'}}
        component={'fieldset'}
      >
        <Stack direction={'column'} spacing={2}>
          <Typography variant={'h2'} component={'legend'}>
            {this.props.t(item?.label ?? '')}
          </Typography>
          <El {...item?.props} />
        </Stack>
      </Box>
    )
  }

  showError(error?: string) {
    this.props.pushNotification({
      color: 'error',
      message: error ?? 'fnd-label-unexpected-error'
    })
  }

  saveSuccess() {
    this.props.pushNotification({
      color: 'success',
      message: 'common:fnd-common-label-profile-edit-success'
    })
    this.closeModals()
  }

  getLeftAction() {
    if (this.state.focusedView === 'right') {
      return (
        <LeftAction
          onClick={() => {
            this.setState({focusedView: 'left'})
          }}
        >
          {' '}
          Back{' '}
        </LeftAction>
      )
    }
    return undefined
  }

  render() {
    return (
      <>
        <Hidden {...responsiveBP.forDesktop}>
          <Navigation
            left={this.getLeftAction()}
            middle={
              this.state.focusedView === 'left' &&
              this.props.t('fnd-label-profile-settings')
            }
          />
        </Hidden>
        <Hidden {...responsiveBP.forMobile}>
          <Navigation middle={this.props.t('fnd-label-profile-settings')} />
        </Hidden>

        <SplitView
          leftView={this.renderMenu()}
          rightView={this.renderDetails()}
          focusedView={this.state.focusedView}
        />

        <AppModal
          title={'Edit'}
          open={this.state.editModal}
          onClose={this.closeModals.bind(this)}
        >
          <Box height={'20px'} />
        </AppModal>
      </>
    )
  }
}

export default ProfileView.exportDefault()
