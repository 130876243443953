import React from 'react'
import {Card, CardContent, Grid, Typography, styled} from '@mui/material'
import {withTranslation, WithTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import {UserNotificationsSettingsData} from 'web-common/models/Profile'
import {withAppContext, WithAppContext} from 'web-common/contexts/AppContext'
import Toggle from 'web-common/components/inputs/Toggle'
import NotificationService from 'web-common/services/NotificationService'

const ToggleGroup = styled('fieldset')({
  border: 'none'
})

interface UserNotificationsViewProps extends WithTranslation, WithAppContext {
  service: typeof NotificationService
}

interface UserNotificationsViewState {
  settings: UserNotificationsSettingsData
}

class UserNotificationsView extends React.Component<
  UserNotificationsViewProps,
  UserNotificationsViewState
> {
  state: UserNotificationsViewState = {
    settings: this.props.service.settings.user!
  }
  inRequest: boolean = false

  onChange(group: string, option: string) {
    if (this.inRequest) {
      return
    }
    this.inRequest = true
    this.props.setBackdrop(true)

    const settings: any = {...this.state.settings}
    settings[group][option] = !settings[group][option]

    this.props.service
      .updateUser(settings)
      .then((_) => {
        this.inRequest = false
        this.props.setBackdrop(false)
        this.setState({settings: settings})
        this.props.pushNotification({
          message: 'fnd-notification-settings-updated-success',
          color: 'success'
        })
      })
      .catch((error) => {
        this.inRequest = false
        this.props.setBackdrop(false)
        this.props.pushNotification({
          message: 'fnd-label-unexpected-error',
          color: 'error'
        })
        console.error(error)
      })
  }

  renderSettings() {
    const groups = Object.keys(this.state.settings!)
    return groups.map((group) => (
      <Grid item key={group}>
        {((_) => {
          const refCopy = this.state.settings as {[key: string]: any}
          const options = Object.keys(refCopy[group])
          return (
            <ToggleGroup key={`${group}-options`}>
              {/* GROUP TITLE */}
              <Typography
                variant={'subtitle2'}
                style={{margin: '10px 0 30px'}}
                component={'legend'}
              >
                {this.props.t('common:fnd-notification-title-' + group)}
              </Typography>

              {/* GROUP OPTIONS */}
              <Grid container spacing={2}>
                {options.map((option) => (
                  <React.Fragment key={`${group}-options-${option}`}>
                    <Grid item xs={6}>
                      {/* TITLE */}
                      <Typography
                        variant={'body2'}
                        component={'label'}
                        htmlFor={`toggle-option-${group}-${option}`}
                        sx={{cursor: 'pointer'}}
                      >
                        {this.props.t('common:fnd-notification-' + option)}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {/* HANDLER */}
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Toggle
                          checked={refCopy[group][option]}
                          id={`toggle-option-${group}-${option}`}
                          onClick={this.onChange.bind(this, group, option)}
                        />
                      </Box>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </ToggleGroup>
          )
        })()}
      </Grid>
    ))
  }

  render() {
    return (
      <Card>
        <CardContent>
          <Grid container direction={'column'} spacing={4}>
            {this.renderSettings()}
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

export default withAppContext(withTranslation()(UserNotificationsView))
