import React from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {Box, Typography} from '@mui/material'
import AppForm, {AppFormInput} from 'web-common/components/formBuilder/AppForm'
import AppValidator from 'web-common/components/formBuilder/AppValidator'
import CustomerService from 'web-common/services/CustomerService'
import {red} from '@mui/material/colors'
import {WithAppContext, withAppContext} from 'web-common/contexts/AppContext'
import kc from 'web-common/services/auth'
import NotificationService from 'web-common/services/NotificationService'

interface UserContactEditProps extends WithTranslation, WithAppContext {
  type: 'phone' | 'email'
  onSave: (type: 'phone' | 'email', contact: string) => void
  onCancel?: () => void
  currentContact?: string | null
  onError: (message?: string) => void
  disableBackdrop?: boolean
}

interface UserContactEditState {
  step: 'contact' | 'validation'
  contact?: string
  requestId?: string
  message?: {
    color: string
    message: string
  }
  lockForm: boolean
}

interface UserContactForm {
  type: 'phone' | 'email'
  contact: string
}

interface UserValidationForm {
  code: string
}

class UserContactEdit extends React.Component<
  UserContactEditProps,
  UserContactEditState
> {
  state: UserContactEditState = {
    step: 'contact',
    contact: this.props.currentContact ?? undefined,
    lockForm: false
  }

  contactFrom: AppFormInput[] = [
    {
      name: 'type',
      value: this.props.type,
      label: '',
      disabled: true,
      hidden: true,
      type: 'hidden'
    },
    {
      name: 'contact',
      value: this.state.contact,
      placeholder:
        this.props.type === 'phone'
          ? 'common:fnd-common-phone-placeholder'
          : 'common:fnd-common-email',
      type: this.props.type,
      label:
        this.props.type === 'phone'
          ? 'common:fnd-common-phone'
          : 'common:fnd-common-email',
      trim: true,
      size: {xs: 12},
      data: this.props.type === 'phone' ? '972' : undefined,
      validator:
        this.props.type === 'phone' ? AppValidator.phone : AppValidator.email,
      dynamicError: (value?: string, _?: RegExp) => {
        if (this.props.type === 'email') {
          return this.props.t('common:fnd-common-error-required-valid-email')
        }
        const badCode = new RegExp(/^9725[679]\d*/)
        if (badCode.test(value ?? '')) {
          return this.props.t('common:fnd-company-phone-bad-code')
        }
        return this.props.t('common:fnd-company-phone-invalid-format')
      }
    }
  ]
  inRequest = false

  generateValidationForm(): AppFormInput[] {
    return [
      {
        name: 'validation-title',
        label: (
          <Typography variant={'body1'} style={{textAlign: 'center'}}>
            {this.props.t(
              this.props.type === 'phone'
                ? 'common:fnd-common-code-is-to-phone'
                : 'common:fnd-common-code-is-to-email',
              {
                address: this.state.contact
              }
            )}
          </Typography>
        ),
        type: 'label',
        size: {xs: 12}
      },
      {
        name: 'code',
        label: 'code',
        type: 'code',
        size: {xs: 12},
        data: this.onResendCode.bind(this)
      }
    ]
  }

  // REQUEST CODE TO VALIDATE NEW CONTACT
  onRequestCode(data: UserContactForm, callback?: () => void) {
    if (!this.onCanStartRequest()) {
      return
    }
    this.setState({lockForm: true})
    CustomerService.requestVerificationCode(data.contact)
      .then((response) => {
        this.setState(
          {
            step: 'validation',
            contact: data.contact,
            requestId: new URL(response.location).pathname.split('/').pop()
          },
          () => {
            callback?.call(this)
            this.setState({lockForm: false})
            this.onFinishRequest()
          }
        )
      })
      .catch((error) => {
        this.setState(
          {
            message: {
              color: red[500],
              message:
                'common:fnd-common-' +
                (error?.data?.key ?? 'verification-unexpected-error')
            }
          },
          () => {
            this.setState({lockForm: false})
            this.onFinishRequest()
          }
        )
      })
  }

  // SEND RECEIVED VALIDATION CODE TO FINISH THE REQUEST
  async onSendValidationCode(data: UserValidationForm) {
    if (!this.state.requestId || !this.state.contact) {
      return
    }
    if (!this.onCanStartRequest()) {
      return
    }
    this.setState({lockForm: true})
    try {
      // VALIDATE EMAIL | PHONE WITH CODE AND GET TOKEN
      const response = await CustomerService.validateContact(
        this.state.requestId,
        this.state.contact,
        data.code
      )
      // UPDATE CONTACT WITH TOKEN
      await CustomerService.verifyContactRequest((response as any).token)
      await kc.instance.updateToken(Number.MAX_SAFE_INTEGER)
      await NotificationService.updateEmail()
      this.onFinishRequest()
      this.setState({lockForm: false})
      this.props.onSave(this.props.type, this.state.contact!)
    } catch (error: any) {
      this.setState({
        message: {
          color: red[500],
          message:
            'common:fnd-common-' +
            (error?.data?.key ?? 'verification-unexpected-error')
        }
      })
      this.setState({lockForm: false})
      this.onFinishRequest()
    }
  }

  // REQUEST NEW VALIDATION CODE
  onResendCode(callback: () => void) {
    if (!this.state.contact) {
      return
    }
    this.onRequestCode(
      {contact: this.state.contact, type: this.props.type},
      callback
    )
  }

  // SHOW UI OVERLAY TO BLOCK USER ACTIONS AND RETURN REQUEST STATE
  onCanStartRequest(): boolean {
    if (this.inRequest) {
      return false
    }
    this.onBackdropChange(true)
    this.inRequest = true
    return true
  }

  // FINISH STARTED REQUEST AND REMOVE UI OVERLAY RESTRICTIONS
  onFinishRequest() {
    this.inRequest = false
    this.onBackdropChange(false)
  }

  onBackdropChange(state: boolean) {
    if (!this.props.disableBackdrop) {
      this.props.setBackdrop(state)
    }
  }

  render() {
    return (
      <>
        {((_) => {
          if (this.state.message) {
            return (
              <Box
                bgcolor={this.state.message.color}
                padding={'20px 22px'}
                margin={'0px auto 30px'}
                maxWidth={'320px'}
                color={this.props.theme.palette.common.white}
              >
                <Typography variant={'body2'}>
                  {this.props.t(this.state.message.message)}
                </Typography>
              </Box>
            )
          }
        })()}
        {this.state.step === 'contact' ? (
          <AppForm
            form={this.contactFrom}
            key={this.state.step}
            spacing={3}
            lock={this.state.lockForm}
            saveLabel={'common:fnd-common-send-validation-code'}
            onSave={this.onRequestCode.bind(this)}
            onCancel={this.props.onCancel?.bind(this)}
            onError={this.props.onError.bind(this)}
          />
        ) : (
          <AppForm
            form={this.generateValidationForm()}
            key={this.state.step}
            spacing={3}
            lock={this.state.lockForm}
            saveLabel={'common:fnd-common-apply-code'}
            onSave={this.onSendValidationCode.bind(this)}
            onCancel={this.props.onCancel?.bind(this)}
            onError={this.props.onError.bind(this)}
          />
        )}
      </>
    )
  }
}

export default withAppContext(withTranslation()(UserContactEdit))
