import Grid, {GridProps} from '@mui/material/Grid'
import React, {FunctionComponent} from 'react'

interface GridComposerProps extends GridProps {
  customize?: GridProps
}

const GridComposer: FunctionComponent<GridComposerProps> = (props) => {
  const ch = React.Children.toArray(props.children)
  return (
    <Grid container {...props}>
      {ch.map((child, index) => (
        <Grid key={'composer' + index} item xs={12} {...props.customize}>
          {child}
        </Grid>
      ))}
    </Grid>
  )
}

export default GridComposer
