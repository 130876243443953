import React, {ReactNode} from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  styled,
  Typography
} from '@mui/material'
import AppRating from 'web-common/components/rating/AppRating'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import {
  FacebookRounded,
  InsertLinkRounded,
  Instagram,
  LinkedIn,
  LocalPhoneRounded,
  MailRounded,
  YouTube
} from '@mui/icons-material'
import Link from '@mui/material/Link'
import AppPhone from 'web-common/components/phone/AppPhone'
import AppGallery from 'web-common/components/gallery/AppGallery'
import AppModal from 'web-common/components/modal/AppModal'
import AppAvatar from 'web-common/components/avatar/AppAvatar'
import {WithTranslation, withTranslation} from 'react-i18next'
import {WithAppContext, withAppContext} from 'web-common/contexts/AppContext'
import FileStorage from 'web-common/services/files/FileStorage'
import {BusinessProfileData, RatingSummary} from 'web-common/models/Profile'

const ContactInfo = styled(Typography)({
  '& > *': {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem'
  }
})

interface ContractorDetailsProps extends WithTranslation, WithAppContext {
  contractorUserId: string
  profile: BusinessProfileData
  ratingSummary?: RatingSummary | null
  reviewsChild?: ReactNode
}

interface ContractorDetailsState {
  modal: boolean
  loading: boolean
  reviews: any[]
}

class ContractorDetails extends React.Component<
  ContractorDetailsProps,
  ContractorDetailsState
> {
  state: ContractorDetailsState = {
    modal: false,
    loading: false,
    reviews: []
  }

  onOpenReviews() {
    this.setState({modal: true})
  }

  onCloseReviews() {
    this.setState({modal: false})
  }

  renderContact() {
    const contractorProfile = this.props.profile
    const linkWrapper = (Icon: any, value: string) => {
      return (
        <Link href={value} target={'_blank'}>
          <Icon sx={{color: this.props.theme.palette.common.black}} />
          {value}
        </Link>
      )
    }
    const mailWrapper = (Icon: any, value: string) => {
      return (
        <Link href={`mailto:${value}`} target={'_blank'}>
          <Icon sx={{color: this.props.theme.palette.common.black}} />
          {value}
        </Link>
      )
    }
    const phoneWrapper = (Icon: any, value: string) => {
      return (
        <span>
          <Icon />
          <span>
            <AppPhone value={value} />
          </span>
        </span>
      )
    }
    const contactInfo = [
      {
        key: 'phone',
        value: contractorProfile.phone,
        icon: LocalPhoneRounded,
        wrapper: phoneWrapper
      },
      {
        key: 'email',
        value: contractorProfile.email,
        icon: MailRounded,
        wrapper: mailWrapper
      },
      {
        key: 'website',
        value: contractorProfile.social?.website,
        icon: InsertLinkRounded,
        wrapper: linkWrapper
      },
      {
        key: 'facebook',
        value: contractorProfile.social?.facebook,
        icon: FacebookRounded,
        wrapper: linkWrapper
      },
      {
        key: 'instagram',
        value: contractorProfile.social?.instagram,
        icon: Instagram,
        wrapper: linkWrapper
      },
      {
        key: 'youtube',
        value: contractorProfile.social?.youtube,
        icon: YouTube,
        wrapper: linkWrapper
      },
      {
        key: 'linkedin',
        value: contractorProfile.social?.linkedIn,
        icon: LinkedIn,
        wrapper: linkWrapper
      }
    ].filter((info) => info.value)

    return (
      <>
        <Divider />
        {/*CONTACT INFO*/}
        <CardContent>
          <Stack spacing={2} direction={'column'}>
            {contactInfo.map((info) => (
              <ContactInfo variant={'subtitle1'} key={info.key}>
                {info.wrapper(info.icon, info.value!)}
              </ContactInfo>
            ))}
          </Stack>
        </CardContent>
      </>
    )
  }

  renderAbout() {
    if (!this.props.profile.about) {
      return null
    }
    return (
      <>
        <Divider />
        {/*ABOUT*/}
        <CardContent>
          <Stack direction={'column'} spacing={1}>
            <Typography variant={'subtitle1'} component={'span'}>
              {this.props.t('fnd-label-about')}
            </Typography>
            <Typography variant={'body1'}>
              {this.props.profile.about}
            </Typography>
          </Stack>
        </CardContent>
      </>
    )
  }

  renderGallery() {
    const rawGalleries = this.props.profile.galleries?.filter((item) => {
      return item.images.length > 0
    })
    if (!rawGalleries || (rawGalleries?.length ?? 0) === 0) {
      return null
    }
    const galleries =
      rawGalleries.flatMap((item) =>
        item.images.map((url) => {
          return {
            name: url,
            thumb: FileStorage.getWellKnownResource(
              `${item.path}/thumb/${url}`
            ),
            standard: FileStorage.getWellKnownResource(`${item.path}/${url}`)
          }
        })
      ) ?? []
    return (
      <>
        <Divider />
        {/*GALLERY*/}
        <CardContent>
          <Typography style={{}} variant={'subtitle1'} component={'span'}>
            {this.props.t('fnd-label-work-and-experience')}
          </Typography>
          <AppGallery images={galleries} allowPreview={true} />
        </CardContent>
      </>
    )
  }

  render() {
    return (
      <>
        <Card>
          <CardHeader
            title={
              <Typography variant={'h3'} component={'span'}>
                {this.props.profile.name}
              </Typography>
            }
            subheader={
              <AppRating
                rating={this.props.ratingSummary?.rating ?? 0}
                variant={'small'}
                count={this.props.ratingSummary?.votes ?? 0}
              />
            }
            avatar={
              <AppAvatar
                userId={this.props.contractorUserId}
                type={'logo'}
                url={this.props.profile.picture}
                key={this.props.contractorUserId}
                size={64}
              />
            }
            action={
              this.props.reviewsChild && (
                <IconButton
                  aria-label={this.props.t('common:fnd-common-open-reviews')}
                  onClick={this.onOpenReviews.bind(this)}
                >
                  <KeyboardArrowRightRoundedIcon />
                </IconButton>
              )
            }
            disableTypography
          />
          {this.renderContact()}
          {this.renderAbout()}
          {this.renderGallery()}
        </Card>

        <AppModal
          open={this.state.modal}
          title={this.props.t('common:fnd-notification-reviews')}
          onClose={this.onCloseReviews.bind(this)}
        >
          {this.props.reviewsChild}
        </AppModal>
      </>
    )
  }
}

export default withTranslation()(withAppContext(ContractorDetails))
