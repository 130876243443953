import Keycloak, {
  KeycloakConfig,
  KeycloakInitOptions,
  KeycloakInstance,
  KeycloakTokenParsed
} from 'keycloak-js'
import {ReplaySubject} from 'rxjs'
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import {AppConfig} from 'AppConfig'
import {AppPreferences} from 'services/AppPreferences'
import i18n from 'i18next'

export const isMobileDevice = () => {
  const mobileDevices = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ]

  return mobileDevices.some((expr) => {
    return navigator.userAgent.match(expr)
  })
}

class OauthCode {
  constructor(
    public code: string,
    public redirectUri: string
  ) {}
}

interface AppKeycloakInstance extends KeycloakInstance {
  tokenParsed?: AppKeycloakTokenParsed
}

interface AppKeycloakTokenParsed extends KeycloakTokenParsed {
  translator?: string[]
  name?: string
}

export interface Role {
  isRealmRole: boolean
  roleName: string
}

class KeycloakService {
  onReady = new ReplaySubject<boolean>(1)
  onAuthSuccess = new ReplaySubject(1)
  onAuthLogout = new ReplaySubject(1)
  instance!: AppKeycloakInstance

  /**
   * When the user open the app for the first time and try to load a page that require a success login procedure,
   * the automaticLoginFlow method open a popups for the desktop devices, and because the event is fired from JS, the browser block the popup.
   * To work around this issue, we can flag if the use had already loaded a public page,
   * and if so all further navigation will be caused by the user itself.
   *
   * TLDR:
   * This flag take effect only for automaticLoginFlow method
   * If user had not set userVisitedPublicPage flag, automaticLoginFlow method will pick redirect method for login
   * If user had set userVisitedPublicPage flag, automaticLoginFlow method will try to open modal popup for login(depending on device)
   *
   * Other solutions:
   * In openPopup method:
   *     const popup =  window.open(url, target, features + pos);
   *     if(!popup || popup.closed || typeof popup.closed=='undefined') {
   *       window.location.href = kc.instance.createLoginUrl();
   *     }
   *     return popup
   * This will leave a blocked popup icon in browser
   */
  userVisitedPublicPage = false

  init(kcConfig: KeycloakConfig, initOptions: KeycloakInitOptions) {
    this.instance = Keycloak(kcConfig)
    this.instance.onReady = (authenticated) => {
      kc.onReady.next(!!authenticated)
    }
    this.instance.onAuthSuccess = () => {
      kc.onAuthSuccess.next(true)
    }
    this.instance.onAuthLogout = () => {
      kc.onAuthLogout.next(true)
    }

    this.instance.init(initOptions)
  }

  flagUserVisitPublicPage() {
    this.userVisitedPublicPage = true
  }

  /**
   * Automatically pick login flow based on device
   * Mobile devices will get redirect low
   * Desktop devices will get popup flow
   *
   * @param redirectUri available only for mobile devices
   */
  automaticLoginFlow(redirectUri?: string): Promise<any> {
    if (isMobileDevice() || !this.userVisitedPublicPage) {
      /**
       * return kc.instance.login({redirectUri: redirectUri});
       * Keycloak use location.replace instead of location.href
       * Since we are on mobile device we wish to be able to go back through browser history
       */
      window.location.href = kc.createLoginUrl(redirectUri)
      return new Promise(() => {})
    } else {
      return this.loginWithPopup()
    }
  }

  loginWithPopup() {
    return new Promise(async (resolve) => {
      try {
        const code = await this.getOauthCode()
        const auth = await this.loginWithCode(code)
        resolve(auth)
      } catch (e) {
        console.error(e)
      }
    })
  }

  getOauthCode(): Promise<OauthCode> {
    const redirectUri = 'urn:ietf:wg:oauth:2.0:oob'
    let url = this.createLoginUrl(redirectUri)
    url = url.replace('&response_mode=fragment', '&response_mode=query')
    return new Promise<OauthCode>((resolve) => {
      const loginPopup = KeycloakService.openPopup(
        url,
        AppConfig.appName || 'Finderella'
      )
      window.addEventListener('message', (e) => {
        if (e.source === loginPopup) {
          if (e.data.message === 'ready') {
            const message = {cmd: 'sendOauthCode', debug: true}
            loginPopup?.postMessage(message, '*')
          } else if (e.data.message === 'code') {
            const code = e.data.code
            loginPopup?.close()
            resolve(new OauthCode(code, redirectUri))
          }
        }
      })
    })
  }

  private createLoginUrl(redirectUri?: string) {
    const urlString = this.instance.createLoginUrl({redirectUri: redirectUri})
    const url = new URL(urlString)
    url.searchParams.set(
      'theme',
      AppPreferences.theme.default.type === 'accessibility'
        ? 'contrast'
        : 'default'
    )
    url.searchParams.set('lang', i18n.language)
    return url.toString()
  }

  private static openPopup(url: string, target?: string) {
    const w = 600
    const h = 760
    const left = window.screen.width / 2 - w / 2
    const top = window.screen.height / 2 - h / 2
    const pos =
      'width=' + w + ', height=' + h + ', top=' + top + ', left=' + left
    const features =
      'toolbar=no, location=no, directories=no, status=no, menubar=no, copyhistory=no, '
    return window.open(url, target, features + pos)
  }

  private loginWithCode(oauth: OauthCode) {
    return new Promise<boolean>((resolve, reject) => {
      const realmPath =
        '/realms/' + encodeURIComponent(this.instance.realm || '')
      const tokenPath = '/protocol/openid-connect/token'
      const url = this.instance.authServerUrl + realmPath + tokenPath

      const headers: any = {'Content-type': 'application/x-www-form-urlencoded'}
      const httpParams: any = {
        code: oauth.code,
        grant_type: 'authorization_code',
        redirect_uri: oauth.redirectUri
      }

      if (this.instance.clientId && this.instance.clientSecret) {
        headers['Authorization'] =
          'Basic ' +
          btoa(this.instance.clientId + ':' + this.instance.clientSecret)
      } else {
        httpParams['client_id'] = this.instance.clientId
      }

      axios
        .post(url, this.encodeForm(httpParams), {
          headers: headers
        })
        .then((rawResponse: AxiosResponse) => {
          const response = rawResponse.data
          const initOptions = {
            token: response['access_token'],
            refreshToken: response['refresh_token'],
            idToken: response['id_token']
          }
          this.instance
            .init(initOptions)
            .then((authenticated) => resolve(authenticated))
            .catch((error) => reject(error))
        })
    })
  }

  public hasRole(role: Role): boolean {
    if (!this.instance.authenticated) {
      return false
    }
    return role.isRealmRole
      ? this.instance.hasRealmRole(role.roleName)
      : this.instance.hasResourceRole(role.roleName)
  }

  public hasResourceAccess(): boolean {
    return this.resourceRoles().length > 0
  }

  public resourceRoles(): Array<string> {
    const access = this.instance.tokenParsed?.resource_access
    const resource = this.instance.clientId
    return access && resource ? access[resource]?.roles ?? [] : []
  }

  private encodeForm(data: any) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  public addBearerToken(req: AxiosRequestConfig) {
    if (
      req.headers === undefined ||
      !req.headers.hasOwnProperty('Authorization')
    ) {
      return new Promise<AxiosRequestConfig>((resolve, reject) => {
        kc.instance
          .updateToken(10)
          .then(() => {
            if (req.headers === undefined) {
              req.headers = {}
            }
            req.headers['Authorization'] = 'Bearer ' + kc.instance.token
            resolve(req)
          })
          .catch((err: any) => {
            reject(err)
          })
      })
    }
    return req
  }
}

const kc = new KeycloakService()
export default kc
