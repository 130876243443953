import React, {ReactNode} from 'react'
import {QuestionFileModel} from 'survey-react'
import defaultImage from 'web-common/assets/img/image_placeholder.jpg'
import {Button, Grid} from '@mui/material'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/AddRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import {WithTranslation, withTranslation} from 'react-i18next'
import ImageResizeService, {
  ImageSizeType
} from 'web-common/services/ImageResizeService'
import {createRoot} from 'react-dom/client'

interface ImageUploadProps extends WithTranslation {
  question: QuestionFileModel
}

export interface ImageSet {
  name: string
  thumb: string
  standard: string
}

interface ImageUploadState {
  gallery: ImageSet[]
}

class ImageUpload extends React.Component<ImageUploadProps, ImageUploadState> {
  fileInputRef = React.createRef<HTMLInputElement>()
  state: ImageUploadState = {
    gallery: []
  }

  async convertFileToBase64(event: React.ChangeEvent<HTMLInputElement>) {
    const files = Array.from(event.target.files!)
    const images: ImageSet[] = []
    for (let i = 0; i < files.length; i++) {
      images.push({
        name: files[i].name,
        thumb: await ImageResizeService.resizeFile(
          files[i],
          ImageSizeType.thumb
        ),
        standard: await ImageResizeService.resizeFile(
          files[i],
          ImageSizeType.standard
        )
      })
    }
    this.setState(
      (state) => ({
        gallery: state.gallery.concat(images)
      }),
      () => {
        // UPDATE SURVEY JS QUESTION VALUE
        this.props.question.value = this.state.gallery
        // CLEAR FILE INPUT IN CASE WHEN USER TRY TO RE-UPLOAD SAME FILE
        this.fileInputRef.current!.value = ''
      }
    )
  }

  private static renderGalleryItem(imageData: string, button: ReactNode) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Box
            width={'150px'}
            height={'113px'}
            boxShadow={
              '0 5px 15px -8px rgba(0,0,0,.24),0 8px 10px -5px rgba(0,0,0,.2)'
            }
            style={{
              background: `url(${imageData}) center center no-repeat`,
              backgroundSize: 'contain'
            }}
          />
        </Grid>
        <Grid item>{button}</Grid>
      </Grid>
    )
  }

  private removeFromGallery(index: number) {
    this.setState((state) => {
      state.gallery.splice(index, 1)
      return state
    })
  }

  render() {
    const gallery = this.state.gallery.map((imageData, index) => (
      <Grid item key={'gallery-item-' + index}>
        {ImageUpload.renderGalleryItem(
          imageData.thumb,
          <Button
            color={'secondary'}
            onClick={this.removeFromGallery.bind(this, index)}
          >
            <CloseIcon /> {this.props.t('common:fnd-common-remove')}
          </Button>
        )}
      </Grid>
    ))
    return (
      <>
        <input
          type="file"
          accept={'image/*'}
          onChange={this.convertFileToBase64.bind(this)}
          ref={this.fileInputRef}
          style={{display: 'none'}}
        />
        <Grid container direction={'row'} spacing={3}>
          {gallery}
          <Grid item>
            {ImageUpload.renderGalleryItem(
              defaultImage,
              <Button
                color={'primary'}
                onClick={() => {
                  this.fileInputRef.current?.click()
                }}
              >
                <AddIcon /> {this.props.t('common:fnd-common-add-image')}{' '}
              </Button>
            )}
          </Grid>
        </Grid>
      </>
    )
  }
}

const El = withTranslation()(ImageUpload)

const WIDGET_NAME = 'image-upload'

function widgetFileUpload(Survey: any) {
  return {
    name: WIDGET_NAME,
    title: 'Image Upload',
    iconName: 'icon-imagepicker',
    isFit: function (question: QuestionFileModel) {
      return question.getType() === WIDGET_NAME
    },
    widgetIsLoaded: () => true,
    isDefaultRender: false,
    htmlTemplate: '<div></div>',
    afterRender: function (question: QuestionFileModel, el: HTMLElement) {
      const root = createRoot(el)
      root.render(<El question={question} />)
    },
    activatedByChanged: () => {
      Survey.Serializer.addClass(WIDGET_NAME, [], undefined, 'empty')

      // Survey.Serializer.addProperty(WIDGET_NAME, {
      //   name: 'min',
      //   type: 'number',
      //   default: 0,
      //   category: 'general'
      // });
    }
  }
}

export default widgetFileUpload
