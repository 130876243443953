import React from 'react'
import {Box, Grid, Stack, Tab, Tabs, Typography} from '@mui/material'
import Navigation from 'web-common/components/navigation/Navigation'
import {PageQuery} from 'web-common/services/PageQuery'
import DemandCard from 'views/demands/listing/DemandCard'
import PageQueryContainer from 'web-common/components/pageQuery/PageQueryContainer'
import AppPage from 'web-common/utilities/AppPage'
import NotificationsChannelsStatusView from 'views/profile/notifications/NotificationsChannelsStatusView'
import {DemandChatPreview, DemandsQuery} from 'views/demands/DemandsQuery'
import DemandsService from 'views/demands/DemandsService'
import {DemandData} from 'models/Demands'

interface DemandsViewProps {}

interface DemandsViewState {
  demandsStats: {active: number; archived: number}
  tab: number
}

class DemandsView extends AppPage<DemandsViewProps, DemandsViewState> {
  static path = '/demands'

  title = this.props.t('fnd-menu-demands')
  state: DemandsViewState = {
    demandsStats: {active: 0, archived: 0},
    tab: 0
  }

  pageQuery: PageQuery<DemandChatPreview<DemandData>> = new DemandsQuery()

  async componentDidMount() {
    super.componentDidMount()
    const archivedJobRequests = await DemandsService.getAllDemands(true, 0)
    this.setState((prev) => {
      prev.demandsStats.archived = archivedJobRequests.found
      return prev
    })
    this.pageQuery.subscribe((data) => {
      this.setState({
        demandsStats: {
          active:
            this.state.tab === 0 ? data.length : this.state.demandsStats.active,
          archived:
            this.state.tab === 1
              ? data.length
              : this.state.demandsStats.archived
        }
      })
    })
    await (this.pageQuery as DemandsQuery).load()
  }

  componentWillUnmount() {
    this.pageQuery.complete()
  }

  componentDidUpdate(
    prevProps: Readonly<DemandsViewProps>,
    prevState: Readonly<DemandsViewState>,
    snapshot?: any
  ) {
    const locationState: any = this.props.location.state ?? {}
    if (locationState.reload) {
      this.props.navigate(DemandsView.getPath(true), {replace: true, state: {}})
      this.pageQuery.fetch()
    }
  }

  onChangeTab(_: any, tab: number) {
    this.setState({tab: tab}, () => {
      ;(this.pageQuery as DemandsQuery).load(tab === 1).then()
    })
  }

  onOpenDemand(demandId: string) {
    this.props.navigate(`${DemandsView.path}/${demandId}`)
  }

  renderNoResult() {
    return (
      <Box padding={'2rem'} textAlign={'center'}>
        <Typography variant={'body1'}>
          {this.props.t(
            this.state.tab
              ? 'fnd-demands-listing-archived-no-result'
              : 'fnd-demands-listing-no-result'
          )}
        </Typography>
      </Box>
    )
  }

  render() {
    return (
      <Stack direction={'column'} spacing={2}>
        <Navigation middle={this.props.t('fnd-label-demands')} />
        <NotificationsChannelsStatusView />
        <Tabs
          value={this.state.tab}
          indicatorColor={'primary'}
          textColor={'primary'}
          onChange={this.onChangeTab.bind(this)}
        >
          <Tab
            label={`${this.props.t('fnd-label-active')} (${
              this.state.demandsStats.active
            })`}
          />
          <Tab
            label={`${this.props.t('fnd-label-archived')} (${
              this.state.demandsStats.archived
            })`}
          />
        </Tabs>

        {/*RESULT SET*/}
        <div>
          <PageQueryContainer
            query={this.pageQuery}
            variant={'more'}
            noResults={this.renderNoResult()}
            render={(demands) => (
              <Grid container spacing={3}>
                {demands.map((d) => (
                  <Grid item key={d._id} xs={12} md={4}>
                    <DemandCard
                      demand={d}
                      onClick={this.onOpenDemand.bind(this)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          />
        </div>
      </Stack>
    )
  }
}

export default DemandsView.exportDefault()
