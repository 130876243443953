import React, {ChangeEvent} from 'react'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'
import {TextField} from '@mui/material'

interface AppFormTextareaProps extends AppFormComponent {}

export default class AppFormTextarea extends AppFormInputComponent<
  AppFormTextareaProps,
  any
> {
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const {onChangeCallback, ...rest} = {...this.props}
    const value: string = e.target.value
    this.props.onChangeCallback(rest, value)
  }

  render() {
    const error = this.props.errorText ?? 'Error'
    const label = this.props.label ?? ''
    return (
      <TextField
        onChange={this.onChange.bind(this)}
        variant={'outlined'}
        fullWidth
        placeholder={this.props.placeholder}
        name={this.props.name}
        id={this.props.id}
        value={this.props.value ?? ''}
        error={this.props.hasError}
        label={this.props.hasError ? error : label}
        disabled={this.props.disabled}
        hidden={this.props.hidden}
        InputProps={{
          multiline: true,
          rows: 4,
          startAdornment: this.props.icon
        }}
        {...this.props.customAttr}
      />
    )
  }
}
