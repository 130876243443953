import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom'
import React from 'react'

export interface RouteComponentProps<T = any> {
  location: Location
  navigate: NavigateFunction
  params: Readonly<T>
}

export default function withRouter<T extends RouteComponentProps>(
  Component: React.ComponentType<T>
): (props: Omit<T, keyof RouteComponentProps>) => JSX.Element {
  function ComponentWithRouterProp(props: Omit<T, keyof RouteComponentProps>) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams<RouteComponentProps['params']>()
    const p = {
      ...props,
      location: location,
      navigate: navigate,
      params: params
    } as unknown as T
    return <Component {...p} />
  }

  return ComponentWithRouterProp
}
