import {AppConfig} from 'AppConfig'
import {
  ContractorNotificationsSettingsData,
  UserNotificationsSettingsData
} from 'web-common/models/Profile'
import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'

export interface NotificationSettingsContactData {
  name?: string
  phone?: string | null
  email?: string | null
}

export interface NotificationSettingsOriginData {
  name: string
  ref?: string | null
  refs?: any
  tsLastSync?: number | null
  synced: boolean
  err?: string | null
}

export interface NotificationSettingsData {
  _id: string
  user?: UserNotificationsSettingsData | null
  contractor?: ContractorNotificationsSettingsData | null
  contact?: NotificationSettingsContactData
  origin: NotificationSettingsOriginData
  lang: string
}

export interface WhatsappNumberStatus {
  _id: string
  userId: string | null
  ticketId: string | null
  lastMessageReceivedAt: number
  conversationEndsAt: number
  dateCreated: number
  lastMessageLanguage: string
}

export interface WAActivationTriggerData {
  [lang: string]: {
    triggers: string[]
  }
}

const paths = {
  public: {
    triggers: {
      wa: {
        activation: {
          '*': {}
        }
      }
    },
    requireToken: false
  },
  settings: {},
  whatsapp: {
    status: {}
  },
  requireToken: true,
  toString: () => AppConfig.api.notifications!
}

class NotificationService extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: NotificationService
  private whatsApp = {
    api: AppConfig.whatsApp.api?.endsWith('/')
      ? AppConfig.whatsApp.api?.substring(0, AppConfig.whatsApp.api?.length - 1)
      : AppConfig.whatsApp.api,
    phone: AppConfig.whatsApp.phone
  }

  static shared() {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService()
    }
    return NotificationService.instance
  }

  public settings!: NotificationSettingsData

  async init() {
    this.settings = await this.get()
  }

  private get() {
    return this.api.paths.settings.get<NotificationSettingsData>(this.request)()
  }

  public getWhatsappStatus() {
    return this.api.paths.whatsapp.status.get<WhatsappNumberStatus>(
      this.request
    )()
  }

  public updateUser(notificationSettings: UserNotificationsSettingsData) {
    return this.api.paths.settings.put(this.request)({
      user: notificationSettings
    })
  }

  public updateContractor(
    notificationSettings: ContractorNotificationsSettingsData
  ) {
    return this.api.paths.settings.put(this.request)({
      contractor: notificationSettings
    })
  }

  public updateLanguage(language: string) {
    return this.api.paths.settings.put(this.request)({lang: language})
  }

  public updateEmail() {
    return this.api.paths.settings.put(this.request)({})
  }

  public getWhatsAppActivationTriggers(target: 'customer' | 'contractor') {
    return this.api.paths.public.triggers.wa.activation[
      '*'
    ].get<WAActivationTriggerData>(this.request, target)()
  }

  public createWhatsappLink(text: string): string {
    const encodedText = encodeURIComponent(text)
    return `${this.whatsApp.api}/send?phone=${this.whatsApp.phone}&text=${encodedText}`
  }

  async createWhatsappActivateConversationLink(
    lang: string,
    target: 'customer' | 'contractor'
  ) {
    const triggers = await this.getWhatsAppActivationTriggers(target)
    const trigger =
      triggers[lang]?.triggers?.[0] ?? triggers['he']?.triggers?.[0]
    //TODO handle trigger not found
    return this.createWhatsappLink(trigger)
  }

  getWhatsappActivateConversationCustomerLink(lang: string) {
    return this.createWhatsappActivateConversationLink(lang, 'customer')
  }

  getWhatsappActivateConversationContractorLink(lang: string) {
    return this.createWhatsappActivateConversationLink(lang, 'contractor')
  }
}

export default NotificationService.shared()
