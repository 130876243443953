import {startTransition, useState} from 'react'

const useInit = (initCallback: () => void) => {
  const [initialized, setInitialized] = useState(false)

  if (!initialized) {
    setInitialized(true)
    startTransition(() => {
      initCallback()
    })
  }
}

export default useInit
