import React from 'react'
import SearchResult from 'views/search/SearchResult'
import {Button, Grid, Typography} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import CantFindServiceView from 'views/search/CantFindServiceView'
import {SearchableService} from 'web-common/models/CategoriesAndServices'
import SearchService from 'web-common/components/services/SearchService'
import AppModal from 'web-common/components/modal/AppModal'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'
import QuestionnaireBus from 'services/QuestionnaireBus'

interface SearchViewProps extends WithTranslation, RouteComponentProps {
  compact?: boolean
  groupByCategory?: boolean
  onFocus?: () => void
  onBlur?: () => void
  size?: 'small' | 'medium'
}

interface SearchViewState {
  serviceNotAvailableModal: boolean
  suggestServiceWithQuery?: string
}

class SearchView extends React.Component<SearchViewProps, SearchViewState> {
  state: SearchViewState = {
    serviceNotAvailableModal: false
  }

  onSelectService(item: SearchableService) {
    if (item.active) {
      QuestionnaireBus.shared().request(item.service.id)
    } else {
      this.setState({
        serviceNotAvailableModal: true
      })
    }
  }

  onSuggestService(query: string) {
    this.setState({suggestServiceWithQuery: query})
  }

  onCloseSuggestService() {
    this.setState({suggestServiceWithQuery: undefined})
  }

  render() {
    const unavailableServiceModalTitle = this.props.t('fnd-label-warning')
    const unavailableServiceModalBody = (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant={'body1'}>
            {this.props.t('fnd-label-service-will-be-available-soon')}
          </Typography>
        </Grid>
        <Grid item>
          <br />
          <Button
            color={'secondary'}
            onClick={() => {
              this.setState({serviceNotAvailableModal: false})
            }}
          >
            {this.props.t('fnd-label-close')}
          </Button>
        </Grid>
      </Grid>
    )

    return (
      <>
        {/* SEARCH BAR */}
        <SearchService
          resultTemplate={SearchResult}
          onSelectService={this.onSelectService.bind(this)}
          onSuggestService={this.onSuggestService.bind(this)}
          onFocus={this.props.onFocus?.bind(this)}
          onBlur={this.props.onBlur?.bind(this)}
          suggestService={true}
          logResult={true}
          size={this.props.size}
          groupByCategory={this.props.groupByCategory}
          compact={this.props.compact}
        />

        {/* INACTIVE SERVICE */}
        <AppModal
          title={unavailableServiceModalTitle}
          body={unavailableServiceModalBody}
          open={this.state.serviceNotAvailableModal}
          onClose={() => {
            this.setState({serviceNotAvailableModal: false})
          }}
        />

        {/* USER DIDNT FOUND SERVICE */}
        <AppModal
          title={this.props.t('fnd-label-service-not-found-title')}
          onClose={this.onCloseSuggestService.bind(this)}
          open={this.state.suggestServiceWithQuery !== undefined}
          maxWidth={'md'}
        >
          <CantFindServiceView
            onClose={this.onCloseSuggestService.bind(this)}
            queryString={this.state.suggestServiceWithQuery}
          />
        </AppModal>
      </>
    )
  }
}

const routed = withRouter(SearchView)
export default withTranslation()(routed)
