const AppValidator = {
  name: new RegExp(/\S/),
  password: new RegExp(/\S{6,}/),
  code: new RegExp(/[a-z0-9]{6}/i),
  email: new RegExp(
    /^[a-z0-9.!#$%&'*+\-/=?^_`{|}~]{1,64}@[a-z0-9\-_]+?\.[a-z]{2,32}(\.[a-z]{2,32})?$/i
  ),
  phone: new RegExp(/^9725[0123458]\d{7}$/),
  facebook: new RegExp(/^(https?:\/\/)?(www\.)?facebook.com\/.+/i),
  linkedin: new RegExp(/^(https?:\/\/)?(www\.)?linkedin.com\/.+/i),
  instagram: new RegExp(
    /^(https?:\/\/)?(www\.)?instagram.com\/[a-zA-Z0-9.?]+/i
  ),
  youtube: new RegExp(/^(https?:\/\/)?(www\.)?(youtube.com|youtu.be)\/.+/i),
  website: new RegExp(/^(https?:\/\/)?(www\.)?(.{2,})\.(.{2,})/i),
  nonEmpty: new RegExp(/\S/g)
}

export default AppValidator
