import React from 'react'
import congratulations from './assets/congratulations.png'
import {styled, Button, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'

const Wrapper = styled('div')((props) => ({
  position: 'relative',
  top: '-70px',
  left: '-24px',
  right: '-24px',
  width: '100%',
  padding: '24px',
  borderRadius: '8px',
  backgroundColor: 'white',
  backgroundImage: `url(${congratulations})`,
  backgroundPosition: 'top center',
  backgroundRepeat: 'repeat-x',
  textAlign: 'center',
  color: props.theme.palette.grey[800],
  paddingTop: '120px',
  marginBottom: '-108px'
}))

interface QuestionnairesCongratulationsViewProps {
  onClick?: () => void
}

const QuestionnairesCongratulationsView = (
  props: QuestionnairesCongratulationsViewProps
) => {
  const [t] = useTranslation()
  return (
    <Wrapper>
      <Typography variant={'h5'}>{t('fnd-survey-complete-title')}</Typography>
      <Typography
        variant={'h6'}
        style={{marginTop: '20px', marginBottom: '60px'}}
      >
        {t('fnd-survey-complete-description')}
      </Typography>
      {((_) => {
        if (props.onClick) {
          return (
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={props.onClick}
            >
              {t('fnd-label-go-to-demands')}
            </Button>
          )
        }
      })()}
    </Wrapper>
  )
}

export default QuestionnairesCongratulationsView
