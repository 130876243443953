import React, {ReactNode} from 'react'
import {Box, Grid, IconButton, Typography} from '@mui/material'
import EditIcon from '@mui/icons-material/EditRounded'

interface ProfileEditSectionProps {
  titleLabel?: string
  subtitle: ReactNode
  ariaLabel?: string
  onActionClick?: () => void
}

class ProfileEditSection extends React.Component<ProfileEditSectionProps, any> {
  render() {
    const title =
      this.props.titleLabel !== undefined ? (
        <Grid item>
          <Typography variant={'body1'}>{this.props.titleLabel}</Typography>
        </Grid>
      ) : null
    const basis = this.props.onActionClick
      ? {flexBasis: 'calc(100% - 44px - 1.5rem)'}
      : {}
    return (
      <Grid container justifyContent="space-between">
        <Grid item style={basis}>
          <Grid container direction={'column'} spacing={2}>
            {title}
            <Grid item xs={12}>
              {this.props.subtitle}
            </Grid>
          </Grid>
        </Grid>
        {((_) => {
          if (this.props.onActionClick) {
            return (
              <Grid item style={{flexBasis: '44px'}}>
                <Box textAlign={'end'}>
                  <IconButton
                    color={'primary'}
                    onClick={this.props.onActionClick?.bind(this)}
                    aria-label={this.props.ariaLabel}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Grid>
            )
          }
        })()}
      </Grid>
    )
  }
}

export default ProfileEditSection
