import React from 'react'
import {useTranslation} from 'react-i18next'
import {Card, CardContent, CardHeader} from '@mui/material'
import Canceled from 'views/demands/demand/assets/canceled.png'
import NoMatches from 'views/demands/demand/assets/no-matches.png'
import Created from 'views/demands/demand/assets/created.png'
import Clock from 'views/demands/demand/assets/clock.png'
import {DemandData} from 'models/Demands'
import {
  isTimeInWorkingHours,
  isShabbat
} from 'web-common/utilities/working-hours'

interface NewDemandViewProps {
  demand: DemandData
}

const NoOffers = (props: NewDemandViewProps) => {
  const [t] = useTranslation()
  let title = t('fnd-demand-no-offer-title-created')
  let subtitle = t('fnd-demand-no-offer-subtitle-created', {
    cnt: props.demand.stats.assignments
  })
  let image = Created
  // TODO We need setup all projects to work by client country
  const countryCode = 'he'
  const isInWorkingHours =
    isTimeInWorkingHours(props.demand.dateCreated, countryCode) &&
    (countryCode !== 'he' || !isShabbat(props.demand.dateCreated))
  const noAssignment = (props.demand?.stats?.assignments ?? 0) === 0

  switch (true) {
    // DEMAND IS ARCHIVED OR CANCELED
    case props.demand.archived:
      title = t('fnd-demand-no-offer-title-cancel')
      subtitle = t('fnd-demand-no-offer-subtitle-cancel')
      image = Canceled
      break

    // IN WORKING HOURS
    case isInWorkingHours:
      // noAssignment ? NO MATCHES : DEMAND WAITING FOR OFFERS
      title = noAssignment
        ? t('fnd-demand-no-offer-title-no-matchers')
        : t('fnd-demand-no-offer-title-created')
      subtitle = noAssignment
        ? t('fnd-demand-no-offer-subtitle-no-matchers')
        : t('fnd-demand-no-offer-subtitle-created', {
            cnt: props.demand.stats.assignments
          })
      image = noAssignment ? NoMatches : Created
      break

    // IN SHABAT
    case !isInWorkingHours:
      // noAssignment ? NO MATCHES : DEMAND WAITING FOR OFFERS
      title = noAssignment
        ? t('fnd-demand-no-offer-title-no-matchers')
        : t('fnd-demand-no-offer-title-created')
      subtitle = noAssignment
        ? t('fnd-demand-no-offer-subtitle-no-matchers-shabat')
        : t('fnd-demand-no-offer-subtitle-created-shabat', {
            cnt: props.demand.stats.assignments
          })
      image = Clock
      break
  }

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subtitle}
        sx={{whiteSpace: 'pre-line'}}
      />
      <CardContent
        sx={{display: 'flex', justifyContent: 'center'}}
        aria-hidden={true}
      >
        <img
          src={image}
          style={{margin: '3rem 0'}}
          alt={'demand state'}
          width={'210px'}
        />
      </CardContent>
    </Card>
  )
}

export default NoOffers
