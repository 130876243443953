import React from 'react'
import {Box, Typography, styled} from '@mui/material'
import Grid from '@mui/material/Grid'
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import {WithTranslation, withTranslation} from 'react-i18next'
import {RatingType} from 'web-common/models/RatingModels'
import {spreadAriaButton} from 'web-common/utilities/utilites'
import {WithAppContext, withAppContext} from 'web-common/contexts/AppContext'

// export type RatingType = 1 | 2 | 3 | 4 | 5
const StarLabel = styled('label')((_) => ({
  transition: 'all .2s ease-in-out',
  '&:focus svg, &:hover svg': {
    transform: 'scale(1.1)'
  }
}))

interface AppRatingControlProps extends WithTranslation, WithAppContext {
  onRatingChange: (rating: RatingType) => void
  rating?: RatingType
}

interface AppRatingControlState {
  rating?: RatingType
  text: string
}

/**
 * This component is used when you want to write a review
 */
class AppRatingControl extends React.Component<
  AppRatingControlProps,
  AppRatingControlState
> {
  colorPalette = [this.props.theme.palette.primary.main, '#3c4858']

  ratingToText = [
    'common:fnd-common-rate-start-1',
    'common:fnd-common-rate-start-2',
    'common:fnd-common-rate-start-3',
    'common:fnd-common-rate-start-4',
    'common:fnd-common-rate-start-5'
  ]

  state: AppRatingControlState = {
    rating: this.props.rating,
    text: '\u00a0'
  }

  onStartClick(index: RatingType) {
    this.setState(
      {
        rating: index,
        text: this.props.t(this.ratingToText[index - 1])
      },
      this.props.onRatingChange.bind(this, index)
    )
  }

  renderStars() {
    const rating = this.state.rating ?? 0
    return ([1, 2, 3, 4, 5] as RatingType[]).map((index) => {
      const StarType = index > rating ? StarBorderRoundedIcon : StarRoundedIcon
      const starColor =
        index > rating ? this.colorPalette[1] : this.colorPalette[0]
      return (
        <Grid item key={'rating-start-' + index}>
          <StarLabel
            {...spreadAriaButton(
              this.props.t('common:fnd-common-aria-star-out-of-five', {
                n: index
              }),
              index !== rating ? this.onStartClick.bind(this, index) : () => {}
            )}
          >
            <StarType fontSize={'inherit'} style={{color: starColor}} />
          </StarLabel>
        </Grid>
      )
    })
  }

  render() {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction={'column'}
      >
        <Grid item xs={12}>
          <Typography
            children={this.state.text}
            aria-live={'polite'}
            variant={'subtitle2'}
          />
        </Grid>
        <Grid item>
          <Box fontSize={'40px'} width={'100%'}>
            <Grid container spacing={1}>
              {this.renderStars()}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation()(withAppContext(AppRatingControl))
