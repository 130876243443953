import React, {ReactNode} from 'react'
import {createTheme, Dialog, ThemeProvider, Typography} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import Close from '@mui/icons-material/CloseRounded'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import {MainTheme} from 'AppTheme'

// TODO: check theme break points
const galleryModal = createTheme({
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: MainTheme.palette.common.black,
          // [theme.breakpoints.up('sm')]: {
          //   // maxWidth: "500px",
          //   margin: 'auto',
          //   //marginTop: '130px !important'
          // },
          borderRadius: '6px',
          overflow: 'visible',
          maxHeight: 'unset',
          width: '100%'
        }
      }
    },

    MuiTypography: {
      variants: [
        {
          props: {variant: 'h4'},
          style: {
            margin: '0',
            lineHeight: '1.5',
            textAlign: 'center',
            fontSize: '1.125rem',
            fontWeight: 300,
            color: MainTheme.palette.common.white
          }
        }
      ]
    }
  }
})

interface AppGalleryModalProps {
  onClose: () => void
  open: boolean
  title: ReactNode
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  children?: ReactNode
}

class AppGalleryModal extends React.Component<AppGalleryModalProps, any> {
  render() {
    const fullScreen =
      document.body.clientWidth < 600 || window.innerHeight < 400
    return (
      <ThemeProvider theme={galleryModal}>
        <Dialog
          fullScreen={fullScreen}
          open={this.props.open}
          onClose={this.props.onClose}
          maxWidth={this.props.maxWidth}
        >
          <DialogTitle>
            <Button onClick={this.props.onClose}>
              <Close />
            </Button>
            <Typography variant={'h4'} component="span" sx={{display: 'block'}}>
              {this.props.title}
            </Typography>
          </DialogTitle>
          <DialogContent>{this.props.children}</DialogContent>
        </Dialog>
      </ThemeProvider>
    )
  }
}

export default AppGalleryModal
