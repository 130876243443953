import React, {ChangeEvent} from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'
import {TextField} from '@mui/material'

interface AppFormTextProps extends AppFormComponent, WithTranslation {}

class AppFormText extends AppFormInputComponent<AppFormTextProps, any> {
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const {onChangeCallback, ...rest} = {...this.props}
    const value: string = e.target.value
    this.props.onChangeCallback(rest, value)
  }

  render() {
    let error: string = 'Error'
    if (this.props.dynamicError) {
      error = this.props.dynamicError(this.props.value, this.props.validator)
    } else if (this.props.errorText) {
      error = this.props.t(this.props.errorText)
    }
    let label = this.props.label ?? ''
    if (typeof label === 'string') {
      label = this.props.t(label)
    }
    const placeholder = this.props.placeholder
      ? this.props.t(this.props.placeholder)
      : undefined
    return (
      <TextField
        onChange={this.onChange.bind(this)}
        variant={'outlined'}
        fullWidth
        placeholder={placeholder}
        name={this.props.name}
        id={this.props.id}
        value={this.props.value ?? ''}
        error={this.props.hasError}
        label={this.props.hasError ? error : label}
        disabled={this.props.disabled}
        hidden={this.props.hidden}
        InputProps={{
          startAdornment: this.props.icon
        }}
        {...this.props.customAttr}
      />
    )
  }
}

export default withTranslation()(AppFormText)
