import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {AppConfig} from 'AppConfig'
import Base64 from 'web-common/services/base64/Base64'

const paths = {
  registration: {
    tokens: {}
  },
  requireToken: false,
  toString: () => AppConfig.api.base!
}

export interface QLoginData {
  redirectUri: string
}

class QuickLinksService extends RequestService {
  protected api: ApiConfig<typeof paths> = new ApiConfig<typeof paths>(paths)

  protected static instance: QuickLinksService

  static shared() {
    return (
      QuickLinksService.instance ??
      (QuickLinksService.instance = new QuickLinksService())
    )
  }

  loginWithToken(token: string) {
    this.validateLoginToken(token)
    return this.api.paths.registration.tokens.post<QLoginData>(this.request)({
      token: token,
      redirect: window.location.origin
    })
  }

  validateLoginToken(token?: string | null) {
    if (!token) {
      throw new Error('Token is missing')
    }
    const tokenData = JSON.parse(Base64.decode(token!.split('.')[1]))
    const nowInSeconds = Date.now() / 1000
    if (tokenData.exp < nowInSeconds) {
      throw new Error('Token is expired')
    }
  }
}

export default QuickLinksService
