import {withTranslation, WithTranslation} from 'react-i18next'
import React, {ChangeEvent} from 'react'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'
import AppCheckbox from 'web-common/components/inputs/AppCheckbox'
import {FormControl, FormHelperText} from '@mui/material'

interface AppFormCheckboxProps extends AppFormComponent, WithTranslation {}

/**
 * Default unchecked example:
 *  type: 'checkbox',
 *  label: 'I want also to join as a service supplier',
 *  name: 'isSupplier',
 *  data: 'yes',
 *  excludeIfEmpty: true,
 *  size: {
 *    xs: 12
 *  }
 *
 * Default checked example:
 *  type: 'checkbox',
 *  label: 'I want also to join as a service supplier',
 *  name: 'isSupplier',
 *  value: 'yes',
 *  data: 'yes',
 *  checked: true,
 *  excludeIfEmpty: true,
 *  size: {
 *    xs: 12
 *  }
 */
class AppFormCheckbox extends AppFormInputComponent<AppFormCheckboxProps, any> {
  onChange(e: ChangeEvent<HTMLInputElement>) {
    const isChecked = e.target.checked
    const {onChangeCallback, ...rest} = {...this.props}
    if (isChecked) {
      this.props.onChangeCallback(rest, this.props.data ?? '')
    } else {
      this.props.onChangeCallback(rest, '')
    }
  }

  render() {
    let error: string = 'Error'
    if (this.props.dynamicError) {
      error = this.props.dynamicError(this.props.value, this.props.validator)
    } else if (this.props.errorText) {
      error = this.props.t(this.props.errorText)
    }
    return (
      <FormControl>
        <AppCheckbox
          defaultChecked={this.props.checked}
          disabled={this.props.disabled}
          hidden={this.props.hidden}
          onChange={this.onChange.bind(this)}
          label={this.props.label}
        />
        <FormHelperText error>
          {this.props.hasError ? error : ''}
        </FormHelperText>
      </FormControl>
    )
  }
}

export default withTranslation()(AppFormCheckbox)
