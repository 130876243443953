import {Box, styled, ThemeProvider} from '@mui/material'
import React from 'react'
import LayoutMain from 'components/layout/LayoutMain'
import {Route, Routes} from 'react-router-dom'
import UserContextProvider from 'web-common/contexts/UserContext'
import ContextCompose from 'web-common/contexts/ContextCompose'
import AppContextProvider, {AppContext} from 'web-common/contexts/AppContext'
import routes, {LayoutType} from 'routes/routes'
import {useTranslation} from 'react-i18next'
import LayoutHeadless from 'components/layout/LayoutHeadless'
import 'web-common/surveyjs-customization/custom-survey.css'
import {CacheProvider} from '@emotion/react'
import ServicesService from 'web-common/services/ServicesService'
import {SnackbarProvider} from 'notistack'
import RouteChecker from 'routes/GuardedRoute'
import {LoginRoute, LogoutRoute} from 'components/login/LoginRoute'
import {themeDirection} from 'web-common/theme/AppTheme'
import NotFound from 'views/system/NotFound'

const NotiWrapper = styled(Box)((props) => ({
  '& ~ div .SnackbarItem-variantInfo': {
    background: props.theme.palette.primary.main
  },
  '& ~ div .SnackbarItem-variantSuccess': {
    background: props.theme.palette.success.main
  },
  '& ~ div .SnackbarItem-variantError': {
    background: props.theme.palette.error.main
  },
  '& ~ div .SnackbarItem-variantWarning': {
    background: props.theme.palette.warning.main
  }
}))

export default function App() {
  const [, i18n] = useTranslation()
  i18n.on('languageChanged', () => {
    ServicesService.rebuildSearchable(i18n.language)
  })

  const renderRoutes = (type: 'main' | 'headless' | 'full') => {
    const Layout = type === 'headless' ? LayoutHeadless : LayoutMain
    return routes
      .filter((i) => i.layout === type)
      .map((P) => {
        const tester = new RegExp(/:\w+\?/gm)
        const optionalParams = P.path.match(tester) ?? []
        if (optionalParams.length > 0) {
          return [
            ...optionalParams.map((param) =>
              P.path.split(param)[0].replace(/\?/, '')
            ),
            P.path.replace(/\?/gm, '')
          ].map((path) => (
            <Route
              path={path}
              element={
                <Layout fullWidth={type === 'full'}>
                  <RouteChecker route={P} content={<P.component />} />
                </Layout>
              }
            />
          ))
        }
        return (
          <Route
            path={P.path}
            element={
              <Layout fullWidth={type === 'full'}>
                <RouteChecker route={P} content={<P.component />} />
              </Layout>
            }
            key={P.path + type}
          />
        )
      })
  }

  return (
    <ContextCompose
      components={[SnackbarProvider, UserContextProvider, AppContextProvider]}
    >
      <AppContext.Consumer>
        {(app) => (
          <CacheProvider value={themeDirection[app.theme.direction]}>
            <ThemeProvider theme={app.theme}>
              <NotiWrapper>
                {/*ROUTER SWITCH*/}
                <Routes>
                  {/* LOGIN */}
                  <Route path="/login" element={<LoginRoute />} />

                  {/* LOGOUT */}
                  <Route path="/logout" element={<LogoutRoute />} />

                  {/*RENDER ALL HEADLESS ROUTES*/}
                  {renderRoutes(LayoutType.headless)}

                  {/*RENDER ALL MAIN ROUTES*/}
                  {renderRoutes(LayoutType.main)}

                  {/*RENDER ALL FULL ROUTES*/}
                  {renderRoutes(LayoutType.full)}

                  {/*NOT FOUND*/}
                  <Route
                    path="*"
                    element={
                      <LayoutMain>
                        <NotFound />
                      </LayoutMain>
                    }
                  />
                </Routes>
              </NotiWrapper>
            </ThemeProvider>
          </CacheProvider>
        )}
      </AppContext.Consumer>
    </ContextCompose>
  )
}
