import {forwardRef, useContext, useState} from 'react'
import {DayPicker, Matcher} from 'react-day-picker'
import he from 'date-fns/locale/he'
import en from 'date-fns/locale/en-US'
import ar from 'date-fns/locale/ar'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'
import {styled} from '@mui/material'
import {SurveyValueType} from '@fnd/survey'

const CustomCalendar = styled(DayPicker)({
  '& .rdp-day_range_start': {
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px'
  },
  '& .rdp-day_range_end': {
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px'
  },
  '& .rdp-day_range_start.rdp-day_range_end': {
    borderTopRightRadius: '100%',
    borderBottomRightRadius: '100%',
    borderTopLeftRadius: '100%',
    borderBottomLeftRadius: '100%'
  },
  '& .rdp-nav_button': {
    transform: 'rotateZ(90deg)'
  },
  '& *': {
    fontFamily: 'Arimo, Roboto, sans-serif'
  }
})

export interface CalendarWrapperProps {
  onBlur: () => void
  onChange: (...e: unknown[]) => void
  value: SurveyValueType
  ref: unknown
  multiple?: boolean
}

const CalendarWrapper = forwardRef<HTMLDivElement, CalendarWrapperProps>(
  (props, ref) => {
    const context = useContext(SRContext)
    const [range, setRange] = useState<number[]>([])

    function onDayClick(rawDate: Date, modifiers: {disabled: boolean}) {
      if (modifiers.disabled) {
        return false
      }
      if (props.multiple) {
        let rangeBuffer = [...range]
        if (range.length >= 2) {
          rangeBuffer = []
        }
        rangeBuffer.push(rawDate.getTime())
        props.onChange(rangeBuffer.sort((a, b) => a - b))
        setRange(rangeBuffer)
      } else {
        props.onChange(rawDate.getTime())
      }
    }

    function extractSelectedDays(): Matcher | Matcher[] {
      if (props.multiple) {
        const value = props.value as number[] | undefined
        return {
          from: value?.[0] ? new Date(value[0]) : undefined,
          to: value?.[1] ? new Date(value[1]) : undefined
        }
      }
      return new Date(props.value as number)
    }

    const locale: Record<string, Locale> = {
      he: he,
      en: en,
      ar: ar
    }
    return (
      <div ref={ref}>
        {/*@ts-expect-error Day picker mode prop cannot detect single as valid value*/}
        <CustomCalendar
          mode={props.multiple ? 'range' : 'single'}
          selected={extractSelectedDays()}
          onSelect={(_: unknown, date: Date) => {
            onDayClick(date, {disabled: false})
          }}
          disabled={{before: new Date()}}
          locale={locale[context.language]!}
          className={'survey-widget-custom-datepicker'}
        />
      </div>
    )
  }
)

export default CalendarWrapper
