import React from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'
import {IconButton, InputAdornment, TextField} from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOffRounded'
import DayPickerInput from 'web-common/components/date/DayPickerInput'

interface AppFormDatepickerProps extends AppFormComponent, WithTranslation {}

class AppFormDatepicker extends AppFormInputComponent<
  AppFormDatepickerProps,
  any
> {
  onPreChange(date?: Date) {
    const {onChangeCallback, ...rest} = {...this.props}
    this.props.onChangeCallback(rest, date ? date.getTime().toString() : '')
  }

  render() {
    let label = this.props.label ?? ''
    if (typeof label === 'string') {
      label = this.props.t(label)
    }
    let error: string = 'Error'
    if (this.props.errorText) {
      error = this.props.t(this.props.errorText)
    }
    const placeholder = this.props.placeholder
      ? this.props.t(this.props.placeholder)
      : undefined
    const CustomInput = (props: any) => {
      return (
        <TextField
          fullWidth
          placeholder={placeholder}
          name={this.props.name}
          id={this.props.id}
          error={this.props.hasError}
          label={this.props.hasError ? error : label}
          disabled={this.props.disabled}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={(e) => {
                    const {onChangeCallback, ...rest} = {...this.props}
                    this.props.onChangeCallback(rest, '')
                    e.stopPropagation()
                  }}
                  edge="end"
                >
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          hidden={this.props.hidden}
          {...props}
        />
      )
    }
    let pickerMinMax = {}
    if (this.props.data && this.props.data.min && this.props.data.max) {
      pickerMinMax = {before: this.props.data.min, after: this.props.data.max}
    }
    const date = this.props.value
      ? new Date(parseInt(this.props.value))
      : undefined
    return (
      <DayPickerInput
        format={'yyyy-MM-dd'}
        component={CustomInput}
        value={date}
        dayPickerProps={pickerMinMax}
        onDayChange={this.onPreChange.bind(this)}
      />
    )
  }
}

export default withTranslation()(AppFormDatepicker)
