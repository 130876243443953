import React, {useContext, useEffect} from 'react'
import {Card, CardContent, useMediaQuery, styled} from '@mui/material'
import TimelineView from 'web-common/views/timeline/TimelineView'
import CustomerEventProducer from 'views/demands/demand/timeline/CustomerEventProducer'
import FocusView from 'web-common/components/layouts/FocusView'
import Loading from 'web-common/components/loading/Loading'
import {AssignmentData} from 'views/demands/DemandsService'
import CustomerActionManager from 'views/demands/demand/timeline/CustomerActionManager'
import {UserData} from 'web-common/models/Profile'
import {Theme} from '@mui/material/styles/createTheme'
import {
  AppContext,
  WithAppContext,
  withAppContext
} from 'web-common/contexts/AppContext'
import {DemandData} from 'models/Demands'
import {TimelineViewConfig} from 'web-common/views/timeline/TimelineViewConfig'
import ContractorDetails from 'web-common/components/users/ContractorDetails'
import ContractorRatingList from 'views/demands/demand/timeline/ContactorRatingList'

const TimelineWrapper = styled(Card)((props) => ({
  position: 'sticky',
  top: '64px',
  height: `calc(100vh - 192px)`,
  marginBottom: '1.5rem',
  '& > .MuiCardContent-root': {
    paddingTop: '0px',
    height: 'calc(100% - 1rem)'
  },
  [props.theme.breakpoints.down('md')]: {
    margin: '-2rem -1rem',
    height: `calc( var(--vh) - 69px)`,
    '& > .MuiCardContent-root > div > .MuiPaper-root': {
      padding: '0px',
      border: '0px'
    }
  }
}))

interface CustomerTimelineProps extends WithAppContext {
  demand: DemandData
  assignment: AssignmentData
  assignmentsCount: number
  user?: UserData
}

interface CustomerTimelineState {
  loading: boolean
  assignment: AssignmentData
}

function TimelineMobileUIEnhancer() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const {setFixedHeader} = useContext(AppContext)

  useEffect(() => {
    if (isMobile) {
      document.documentElement.style.setProperty('overflow', 'hidden')
      setFixedHeader(false)
    } else {
      document.documentElement.style.removeProperty('overflow')
      setFixedHeader(false)
    }
    return () => {
      document.documentElement.style.removeProperty('overflow')
      setFixedHeader(true)
    }
  }, [isMobile, setFixedHeader])

  return null
}

class CustomerTimeline extends React.Component<
  CustomerTimelineProps,
  CustomerTimelineState
> {
  producer: CustomerEventProducer | undefined
  actionManager: CustomerActionManager | undefined
  state: CustomerTimelineState = {
    loading: true,
    assignment: this.props.assignment
  }

  readonly timelineConfig: Partial<TimelineViewConfig> = {
    accessibility: {
      messageCreator: {
        autoFocus: this.props.theme.type !== 'accessibility'
      }
    }
  }

  componentDidMount() {
    this.initTimeline()
  }

  componentDidUpdate(
    prevProps: Readonly<CustomerTimelineProps>,
    prevState: Readonly<CustomerTimelineState>,
    snapshot?: any
  ) {
    if (
      this.props.assignment?._id &&
      prevProps.assignment?._id &&
      this.props.assignment?._id !== prevProps.assignment._id
    ) {
      this.initTimeline()
    }
  }

  componentWillUnmount() {
    this.producer?.complete()
  }

  initTimeline() {
    this.setState({loading: true}, async () => {
      this.producer?.complete()
      this.producer = undefined
      this.actionManager = undefined
      this.actionManager = new CustomerActionManager(
        this.props.assignment,
        this.props.assignmentsCount
      )
      this.producer = new CustomerEventProducer(
        this.props.assignment,
        this.actionManager,
        this.props.demand,
        this.props.user
      )
      this.setState({loading: false, assignment: this.props.assignment})
    })
  }

  renderDetails() {
    const reviews = <ContractorRatingList assignment={this.state.assignment} />
    return (
      <ContractorDetails
        contractorUserId={this.state.assignment.contractor.userId}
        profile={this.state.assignment.contractor as any}
        ratingSummary={this.state.assignment.contractor.ratingSummary}
        reviewsChild={reviews}
      />
    )
  }

  renderTimeline() {
    return (
      <TimelineWrapper variant={'outlined'} id={'customer-timeline-wrapper'}>
        <TimelineMobileUIEnhancer />
        <CardContent>
          <TimelineView
            producer={this.producer!}
            actionManager={this.actionManager!}
            config={this.timelineConfig}
          />
        </CardContent>
      </TimelineWrapper>
    )
  }

  render() {
    return (
      <Loading
        loading={this.state.loading}
        render={() => (
          <FocusView
            start={this.renderTimeline()}
            end={this.renderDetails()}
            focus={'start'}
            startSize={8}
            style={{alignItems: 'stretch'}}
            endSize={4}
          />
        )}
      />
    )
  }
}

export default withAppContext(CustomerTimeline)
