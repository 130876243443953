import React, {PropsWithChildren} from 'react'
import Header from 'components/navigation/Header'
import Footer from 'components/navigation/Footer'
import {Button, Container, Hidden, styled} from '@mui/material'
import {backgroundColor, responsiveBP} from 'web-common/theme/AppTheme'
import ErrorBoundary from 'ErrorBoundary'
import {withTranslation, WithTranslation} from 'react-i18next'
import AccessibilityControl from 'components/accessibility/AccessibilityControl'
import Banner from '../banner/Banner'

const LayoutContainer = styled(Container)((props) => ({
  minHeight: 'calc(100vh - 283px)',
  display: 'flex',
  flexDirection: 'column',
  [props.theme.breakpoints.down('sm')]: {
    minHeight: ['calc(100vh - 56px)', 'calc(var(--vh) - 56px)']
  }
}))
const Main = styled('main')({
  background: backgroundColor
})
const SkipHeader = styled(Button)({
  border: '0px',
  clip: 'rect(0,0,0,0)',
  height: '1px',
  width: '1px',
  margin: '-1px',
  overflow: 'hidden',
  position: 'absolute',
  '&:focus': {
    clip: 'auto',
    right: 'auto',
    height: 'auto',
    width: 'auto',
    visibility: 'visible',
    zIndex: '9999',
    margin: '0.5rem'
  }
})

export interface LayoutMainProps
  extends PropsWithChildren<any>,
    WithTranslation {
  // Set children content max width
  fullWidth?: boolean
  children?: JSX.Element
}

interface LayoutMainState {}

// For mobile devices when keyboard is shown or when url input bar affect the height if the page view
const updateCSSHeight = function () {
  document
    .getElementById('main-container')!
    .style.setProperty('--vh', `${window.innerHeight}px`)
}

class LayoutMain extends React.Component<LayoutMainProps, LayoutMainState> {
  componentDidMount() {
    updateCSSHeight()
    window.addEventListener('resize', updateCSSHeight)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', updateCSSHeight)
  }

  render() {
    return (
      <Main>
        {/*HEADER FOR DESKTOP DEVICES*/}
        <Hidden {...responsiveBP.forMobile}>
          <SkipHeader color={'primary'} href={'#main-container'}>
            {this.props.t('fnd-aria-skip-to-content')}
          </SkipHeader>
          <AccessibilityControl />
          <Header />
        </Hidden>

        {/*MAINTENANCE*/}
        {false && <Banner />}

        {/*PAGE CONTENT*/}
        <LayoutContainer
          disableGutters={this.props.fullWidth}
          id={'main-container'}
          maxWidth={this.props.fullWidth ? false : 'lg'}
        >
          <ErrorBoundary>{this.props.children!}</ErrorBoundary>
        </LayoutContainer>

        {/*FOOTER FOR DESKTOP*/}
        <Hidden {...responsiveBP.forMobile}>
          <Footer />
        </Hidden>

        {/*BOTTOM NAVIGATION FOR MOBILE DEVICES*/}
        <Hidden {...responsiveBP.forDesktop}>
          <Header />
        </Hidden>
      </Main>
    )
  }
}

export default withTranslation()(LayoutMain)
