import AppPage from 'web-common/utilities/AppPage'
import Loading from 'web-common/components/loading/Loading'
import React from 'react'
import Box from '@mui/material/Box'
import QuickLinksService from 'views/magic-links/QuickLinksService'

export interface LoginPasswordLessViewProps {}

export interface LoginPasswordLessViewState {}

class LoginPasswordLessView extends AppPage<
  LoginPasswordLessViewProps,
  LoginPasswordLessViewState
> {
  title: string = ''

  static path = '/quick-login'

  componentDidMount() {
    super.componentDidMount()
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')

    try {
      QuickLinksService.shared()
        .loginWithToken(token!)
        .then((e: any) => {
          window.location = e.redirectUri
        })
    } catch (e) {
      console.log('Pwdsless->', e)
      window.location.href = '/'
    }
  }

  render() {
    return (
      <Box minHeight={'70vh'} display={'flex'} alignItems={'center'}>
        <Loading loading={true} render={() => <></>} />
      </Box>
    )
  }
}

export default LoginPasswordLessView.exportDefault()
