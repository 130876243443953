import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import {Checkbox, CheckboxProps} from '@mui/material'

export interface AppCheckboxProps extends CheckboxProps {
  classes?: any
  label?: string | React.ReactElement // | number
}

class AppCheckbox extends React.Component<AppCheckboxProps, any> {
  render() {
    const {label, ...rest} = this.props
    return (
      <FormControlLabel
        control={<Checkbox {...rest} color={'primary'} />}
        label={label ?? ''}
      />
    )
  }
}

export default AppCheckbox
