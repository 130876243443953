import React from 'react'
import {ItemValue, QuestionDropdownModel} from 'survey-react'
import MenuItem from '@mui/material/MenuItem'
import {Select} from '@mui/material'
import {createRoot} from 'react-dom/client'

interface SurveyDropdownState {
  value: string
}

interface SurveyDropdownProps {
  onValueChange: (value: string) => void
  question: QuestionDropdownModel
}

class SurveyDropdown extends React.Component<
  SurveyDropdownProps,
  SurveyDropdownState
> {
  defaultValue = '--'
  defaultText = 'Select Option'

  constructor(props: SurveyDropdownProps, state: SurveyDropdownState) {
    super(props, state)
    const choices: ItemValue[] =
      typeof this.props.question.propertyHash.choices === 'function'
        ? this.props.question.propertyHash.choices()
        : this.props.question.propertyHash.choices
    const value =
      choices.find((item) => item.value === this.props.question.value)?.value ||
      this.defaultValue
    this.state = {
      value: value
    }
  }

  render() {
    let choices: ItemValue[] =
      typeof this.props.question.propertyHash.choices === 'function'
        ? this.props.question.propertyHash.choices()
        : this.props.question.propertyHash.choices
    if (this.props.question.choicesOrder === 'asc') {
      choices = choices.sort((a, b) => a.text.localeCompare(b.text))
    } else if (this.props.question.choicesOrder === 'desc') {
      choices = choices.sort((a, b) => b.text.localeCompare(a.text))
    }
    return (
      <Select
        variant={'outlined'}
        value={this.state.value || this.defaultValue}
        onChange={(e) => {
          const value: string = e.target.value as string
          this.setState(
            {
              value:
                choices.find((i: ItemValue) => i.value === value)?.text ?? ''
            },
            () => {
              if (value === this.defaultValue) {
                this.props.onValueChange('')
              } else {
                this.props.onValueChange(value)
              }
            }
          )
        }}
      >
        <MenuItem value={this.defaultValue}>
          {this.props.question.optionsCaption ?? this.defaultText}
        </MenuItem>
        {choices.map((item: ItemValue, i: number) => (
          <MenuItem value={item.value} key={item.value + i}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    )
  }
}

const WIDGET_NAME = 'dropdown'
const widgetReactDropdown = {
  name: WIDGET_NAME,
  title: 'React Dropdown',
  iconName: '',
  isFit: function (question: any) {
    return question.getType() === WIDGET_NAME
  },
  isDefaultRender: false,
  htmlTemplate: '<div></div>',
  afterRender: function (question: QuestionDropdownModel, el: HTMLElement) {
    const root = createRoot(el)
    root.render(
      <SurveyDropdown
        question={question}
        onValueChange={(newValue) => {
          question.value = newValue
        }}
      />
    )
  }
}

export default widgetReactDropdown
