import {AssignmentAdapter} from 'views/demands/demand/timeline/adapters/AssignmentAdapter'
import {AssignmentData} from 'views/demands/DemandsService'
import {ReviewAdapter} from 'views/demands/demand/timeline/adapters/ReviewAdapter'
import CustomerActionManager from 'views/demands/demand/timeline/CustomerActionManager'
import {UserData} from 'web-common/models/Profile'
import {OfferStatus} from 'web-common/models/OfferModel'
import {AssignmentReviewStatusData} from 'web-common/models/AssignmentModel'
import {DemandData} from 'models/Demands'
import {
  ChatAdapter,
  CreateEventBase,
  TimelineAdapter,
  TimelineEventData,
  TimelineEventProducer
} from '@fnd/timeline'
import EventBus from 'web-common/services/EventBus'
import {getAvatarURL} from 'web-common/components/avatar/AppAvatarHelper'
import MessageEvent from 'web-common/views/timeline/events/MessageEvent'

class CustomerEventProducer extends TimelineEventProducer {
  private assignmentAdapter = new AssignmentAdapter(this, this.assignment)
  private chatAdapter = new ChatAdapter(
    MessageEvent,
    EventBus,
    this,
    this.assignment._id,
    'CUSTOMER',
    getAvatarURL({
      userId: this.user?._id,
      type: 'avatar',
      url: this.user?.picture
    }),
    getAvatarURL({
      userId: this.assignment._refs.userId,
      type: 'logo',
      url: this.assignment.contractor.picture
    })
  )
  private reviewAdapter = new ReviewAdapter(
    this,
    this.assignment.customerReview?._id,
    this.assignment.contractorReview?._id
  )
  protected dataSourceServices: TimelineAdapter<any>[] = [
    this.chatAdapter,
    this.assignmentAdapter,
    this.reviewAdapter
  ]

  constructor(
    public assignment: AssignmentData,
    public actionManager: CustomerActionManager,
    public demand: DemandData,
    public user?: UserData
  ) {
    super()
  }

  protected evaluateInitialLoading(_: TimelineEventData<any>[]) {
    // Chat settings
    if (this.demand.status === 'CANCELED') {
      this.actionManager.disableChat()
      return
    } else {
      this.actionManager.enableChat()
    }

    // Chat actions
    if (this.assignment.offer?.status === OfferStatus.ACCEPTED) {
      if (
        this.assignment.customerReview?.status ===
        AssignmentReviewStatusData.PENDING
      ) {
        this.actionManager.addReview(this.assignment)
      }
    }
    // You can request new quote
    else if (
      this.assignment.offer?.pricing?.items &&
      this.assignment.offer?.status !== OfferStatus.REJECTED
    ) {
      this.actionManager.addAccept(this.assignment)
    }
    // TODO remove this after migrate to new pricing model
    else if (
      (this.assignment.offer?.price || this.assignment.offer?.hourRate) &&
      this.assignment.offer?.status !== OfferStatus.REJECTED
    ) {
      this.actionManager.addAccept(this.assignment)
    }
    // Check if assignment is completed and if is not show compare
    else if (['ACTIVE', 'NEW', 'ACCEPTED'].includes(this.assignment.status)) {
      this.actionManager.tryAddCompareOffer(this.assignment)
    }
    this.actionManager.notify()
  }

  async createEvent(
    event: CreateEventBase<any>
  ): Promise<TimelineEventData<any>> {
    let data!: TimelineEventData<any>
    // CREATE CHAT EVENT
    if (event.adapterId === ChatAdapter.id) {
      data = await this.chatAdapter.sendMessage({
        text: event.message ?? '',
        assignmentId: this.assignment._id,
        recipientId: this.assignment.contractor.userId
      })
    }
    // CREATE REVIEW EVENT
    if (event.adapterId === ReviewAdapter.id) {
      data = this.reviewAdapter.rateDataToEvent(event.data)!
    }
    // CREATE ACCEPT OFFER EVENT
    if (event.adapterId === AssignmentAdapter.id) {
      data = await this.assignmentAdapter.acceptOffer(event.data)
    }
    this.update([data])
    return Promise.resolve(data)
  }

  async reCreateEvent(eventId: string): Promise<boolean> {
    let data!: TimelineEventData<any>
    const event = this.getHistory().find((e) => e._id === eventId)
    if (!event) {
      return false
    }
    if (event.adapterId === ChatAdapter.id) {
      data = await this.chatAdapter.sendMessage({
        text: (event.props as any).message ?? '',
        assignmentId: this.assignment._id,
        recipientId: this.assignment.contractor.userId
      })
      this.remove(event._id)
      this.update([data])
      return true
    }
    return false
  }
}

export default CustomerEventProducer
