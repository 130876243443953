import {AppConfig} from 'AppConfig'
import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {
  Survey,
  SurveyAnswers,
  SurveyAnswersMapperItem,
  SurveyAnswersSimple,
  SurveyData,
  SurveyElementLocationPicker,
  SurveyElementLocationPickerValue,
  SurveyElementRegions,
  SurveyAnswersMapper,
  SurveyTranslationSyncer
} from '@fnd/survey'
import {
  DemandMatchingFilters,
  DemandMatchingLocationFilter,
  DemandRequestData
} from 'models/Demands'
import ImageResizeService, {
  ImageSizes
} from 'web-common/services/ImageResizeService'
import {ImageSet} from 'web-common/surveyjs-customization/image-upload'
import FileStorage from 'web-common/services/files/FileStorage'
import kc from 'web-common/services/auth'
import BrowserStorage from 'web-common/services/BrowserStorage'

const paths = {
  customers: {
    services: {
      '*': {
        questionnaire: {}
      }
    },

    demands: {},
    toString: () => AppConfig.api.adminCustomer!
  },

  registration: {
    tokens: {},
    requireToken: false,
    toString: () => AppConfig.api.base!
  },

  sessions: {
    '*': {},
    toString: () => AppConfig.whatsApp.bot!
  },

  demands: {
    toString: () => AppConfig.api.base!
  },
  requireToken: true,
  toString: () => ''
}

class QuestionnairesService extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: QuestionnairesService

  static shared() {
    if (!QuestionnairesService.instance) {
      QuestionnairesService.instance = new QuestionnairesService()
    }
    return QuestionnairesService.instance
  }

  private readonly autoBeginCookieKey = 'questionnaire-begin'

  path = AppConfig.api.public

  get(serviceId: string) {
    return this.api.paths.customers.services['*'].questionnaire.get<SurveyData>(
      this.request,
      serviceId
    )()
  }

  private extractLocations(
    survey: Survey,
    answers: SurveyAnswers,
    type: 'OFFICES' | 'REGIONS'
  ) {
    const locations: string[] = []
    const elementMultiple = type === 'OFFICES'
    const mapper = new SurveyAnswersMapper(survey, answers)
    const result = mapper.queryAnswers(
      (element, v) =>
        !!v?.value &&
        element.system === true &&
        element.type === 'regions' &&
        (element as SurveyElementRegions).multiple === elementMultiple
    )
    Object.keys(result).forEach((pageName) => {
      Object.keys(result[pageName]).forEach((elementName) => {
        const value = result[pageName][elementName].value
        if (typeof value === 'string') {
          locations.push(value)
        } else if (Array.isArray(value)) {
          locations.push(...(value as any[]))
        }
      })
    })
    return locations
  }

  private extractOnline(survey: Survey, answers: SurveyAnswers) {
    const mapper = new SurveyAnswersMapper(survey, answers)
    const result = mapper.queryAnswers(
      (element, v) =>
        element.system === true &&
        element.type === 'location-type' &&
        v?.value === 'online'
    )
    return Object.keys(result).length > 0
  }

  private extractLocation(
    survey: Survey,
    answers: SurveyAnswers
  ): DemandMatchingLocationFilter | undefined {
    const mapper = new SurveyAnswersMapper(survey, answers)
    const query = mapper.queryAnswers(
      (element, v) =>
        !!v?.value &&
        element.system === true &&
        element.type === 'location-picker'
    )
    const result = mapper.getQuestionAnswerMeta(query)
    if (result.length === 0) {
      return undefined
    }
    const question = result[0].question as SurveyElementLocationPicker
    const answer = result[0].answer.value as SurveyElementLocationPickerValue
    return {
      regions: question.variant === 'location' ? answer.regions : undefined,
      offices: question.variant === 'range' ? answer.regions : undefined,
      lon: answer.lon,
      lat: answer.lat,
      address: answer.address ?? '',
      range: question.variant === 'range' ? answer.range : undefined
    }
  }

  private exportBudget(survey: Survey, answers: SurveyAnswers) {
    const mapper = new SurveyAnswersMapper(survey, answers)
    const query = mapper.queryAnswers(
      (element) => element.system === true && element.type === 'budget'
    )
    const result = mapper.getQuestionAnswerMeta(query)
    if (result.length === 0) {
      return undefined
    }
    return (result[0].answer.value as unknown as number) > -1
      ? result[0].answer.value!.toString()
      : undefined
  }

  protected exportFilters(
    serviceId: string,
    survey: Survey,
    answers: SurveyAnswers
  ): DemandMatchingFilters {
    return {
      serviceId: serviceId,
      budget: this.exportBudget(survey, answers),
      ...this.exportLocationRelatedFilters(survey, answers)
    }
  }

  protected exportLocationRelatedFilters(
    survey: Survey,
    answers: SurveyAnswers
  ): Pick<
    DemandMatchingFilters,
    'offices' | 'regions' | 'location' | 'online'
  > {
    const offices = this.extractLocations(survey, answers, 'OFFICES')
    const regions = this.extractLocations(survey, answers, 'REGIONS')
    const location = this.extractLocation(survey, answers)
    const online =
      !offices.length && !regions.length && !location
        ? true
        : this.extractOnline(survey, answers)
    return {
      offices,
      regions,
      location,
      online
    }
  }

  createDemand(
    serviceId: string,
    questionnaireId: string,
    survey: Survey,
    answers: any,
    waSessionId?: string
  ) {
    const data: DemandRequestData = {
      questionnaireId: questionnaireId,
      survey: {...survey, version: '2.0.0'},
      answers: answers,
      origin: {
        name: waSessionId ? 'bot' : 'web',
        _refs: waSessionId
          ? {
              botSessionId: waSessionId
            }
          : {}
      },
      filters: this.exportFilters(serviceId, survey, answers)
    }
    return this.api.paths.demands.postAndGetLocation(this.request)(data)
  }

  registerWithToken(token: string) {
    return this.api.paths.registration.tokens.postAndGetLocation(this.request)({
      token: token,
      redirect: window.location.origin + window.location.pathname
    })
  }

  getLocalizedSurvey(data: SurveyData) {
    return SurveyTranslationSyncer.fullLocalization(data.model, data.l10n ?? {})
  }

  async uploadSurveyFiles(
    files: SurveyAnswersMapperItem[],
    questionnaireId: string,
    answersRef: SurveyAnswers
  ): Promise<boolean> {
    const userId = kc.instance.tokenParsed!.sub
    let uploadSuccess = false
    await Promise.all(
      files.map(async (meta) => {
        if (!answersRef[meta.pageName]) {
          answersRef[meta.pageName] = {
            [meta.elementName]: {value: [], otherValue: undefined}
          }
        }
        answersRef[meta.pageName][meta.elementName].value = await Promise.all(
          (meta?.answer?.value as string[]).map((value) => {
            return new Promise(async (r) => {
              const [name, bs64] = value.split('###')
              if (
                ImageResizeService.getTypeFromBase64(bs64) !== 'application/pdf'
              ) {
                // Upload image
                const image: ImageSet = {
                  name: name,
                  thumb: await ImageResizeService.resizeBase64(
                    bs64,
                    ImageSizes['thumb']
                  ),
                  standard: await ImageResizeService.resizeBase64(
                    bs64,
                    ImageSizes['standard']
                  )
                }
                // Upload standard sized image
                await FileStorage.uploadResource(
                  image.standard,
                  `/user/${userId}/${questionnaireId}`,
                  image.name,
                  true
                )
                // Upload thumb sized image
                r(
                  await FileStorage.uploadResource(
                    image.thumb,
                    `/user/${userId}/${questionnaireId}/thumb`,
                    image.name,
                    true
                  )
                )
              } else {
                // Upload document
                r(
                  await FileStorage.uploadResource(
                    bs64,
                    `/user/${userId}/${questionnaireId}`,
                    name,
                    true
                  )
                )
              }
            })
          })
        )
        uploadSuccess = true
      })
    )
    return uploadSuccess
  }

  getWASessionAnswers(waSession: string) {
    return this.api.paths.sessions['*'].get<WASessionData>(
      this.request,
      waSession
    )()
  }

  getSurveyAnswersFromSimple(
    answersSimple: SurveyAnswersSimple
  ): SurveyAnswers {
    let surveyAnswers: SurveyAnswers = {}
    Object.keys(answersSimple).forEach((pageName) => {
      if (!surveyAnswers[pageName]) {
        surveyAnswers[pageName] = {}
      }
      Object.keys(answersSimple[pageName]).forEach((elementName) => {
        surveyAnswers[pageName][elementName] = {
          value: answersSimple[pageName][elementName],
          otherValue: undefined
        }
      })
    })
    return surveyAnswers
  }

  setAutoBeginCookie(value: string) {
    BrowserStorage.cookie.set(this.autoBeginCookieKey, value)
  }

  getAutoBeginCookie() {
    return BrowserStorage.cookie.get(this.autoBeginCookieKey)
  }

  deleteAutoBeginCookie() {
    BrowserStorage.cookie.delete(this.autoBeginCookieKey)
  }
}

export default QuestionnairesService

export interface WASessionData {
  serviceId: string
  answers: SurveyAnswersSimple
}
