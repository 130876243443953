import React from 'react'
import {QuestionTextModel} from 'survey-react'
import {TextField} from '@mui/material'
import {createRoot} from 'react-dom/client'

const WIDGET_NAME = 'text'
const widgetReactText = {
  //the widget name. It should be unique and written in lowercase.
  name: WIDGET_NAME,
  //the widget title. It is how it will appear on the toolbox of the SurveyJS Editor/Builder
  title: 'React Text',
  //the name of the icon on the toolbox. We will leave it empty to use the standard one
  iconName: '',
  //SurveyJS library calls this function for every question to check, if it should use this widget instead of default rendering/behavior
  isFit: function (question: any) {
    return question.getType() === WIDGET_NAME
    //return question.getType() === 'text' && question.inputType === "date";
  },
  isDefaultRender: false,
  htmlTemplate: '<div></div>',
  afterRender: function (question: QuestionTextModel, el: HTMLElement) {
    //el is our root element in htmlTemplate, is "div" in our case
    // Render react component into el
    const root = createRoot(el)
    root.render(
      <TextField
        inputProps={{
          defaultValue: question.value,
          placeholder: question.placeHolder,
          type: question.type,
          onBlur: (event: any) => {
            question.value = event.target.value
          }
        }}
        fullWidth
      />
    )
  }
}

export default widgetReactText
