import React from 'react'

interface CountdownProps {
  // Timestamp
  finish: number
  onCountdownFinish: () => void
}

interface CountdownState {
  count: string
}

export default class Countdown extends React.Component<
  CountdownProps,
  CountdownState
> {
  state: CountdownState = {
    count: '00:00'
  }
  intervalHandler: any

  componentDidMount() {
    this.count()
    this.intervalHandler = window.setInterval(this.count.bind(this), 1000)
  }

  componentWillUnmount() {
    window.clearInterval(this.intervalHandler)
  }

  count() {
    const delta = this.props.finish - Date.now()
    if (delta <= 0) {
      this.props.onCountdownFinish()
      window.clearInterval(this.intervalHandler)
      this.setState({count: `00:00`})
    } else {
      const minutes = Math.floor((delta % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((delta % (1000 * 60)) / 1000)
      this.setState({count: `${minutes}:${seconds}`})
    }
  }

  render() {
    return <>{this.state.count}</>
  }
}
