class Base64 {
  encode(str: string) {
    return btoa(unescape(encodeURIComponent(str)))
  }

  decode(str: string) {
    return decodeURIComponent(escape(atob(str)))
  }
}

const base64 = new Base64()
export default base64
