import Grid from '@mui/material/Grid'
import React from 'react'
import Rating from 'web-common/components/rating/Rating'
import Typography from '@mui/material/Typography'
import {Variant} from '@mui/material/styles/createTypography'
import {WithTranslation, withTranslation} from 'react-i18next'
import {SC} from 'web-common/utilities/utilites'

export interface AppRatingProps extends WithTranslation {
  rating: number
  count: number
  variant?: 'small' | 'large'
}

interface AppRatingState {}

class AppRating extends React.Component<AppRatingProps, AppRatingState> {
  render() {
    const fontRating: Variant = this.props.variant === 'large' ? 'h5' : 'body2'
    return (
      <>
        <SC
          text={this.props.t(
            'common:fnd-common-aria-rating-from-votes',
            this.props
          )}
        />
        <Grid
          container
          direction={'row'}
          wrap={'nowrap'}
          alignItems={'center'}
          spacing={1}
          aria-hidden={true}
        >
          <Grid item>
            <Typography variant={fontRating} aria-hidden={true}>
              {this.props.rating.toFixed(1)}
            </Typography>
          </Grid>

          <Grid item>
            <Rating rating={this.props.rating} />
          </Grid>

          <Grid item>
            <Typography
              variant={'body2'}
              color={'textSecondary'}
              aria-hidden={true}
            >
              ({this.props.count})
            </Typography>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default withTranslation()(AppRating)
