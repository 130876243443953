import React from 'react'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import {RadioProps} from '@mui/material/Radio/Radio'

export interface AppRadioProps extends RadioProps {
  label: string
}

class AppRadio extends React.Component<AppRadioProps, any> {
  render() {
    const {label, ...rest} = this.props
    return (
      <FormControlLabel
        control={<Radio {...rest} color={'primary'} />}
        label={label}
      />
    )
  }
}

export default AppRadio
