import {RouteItem} from 'routes/routes'
import React, {ReactNode, useEffect, useState} from 'react'
import Loading from 'web-common/components/loading/Loading'
import kc from 'web-common/services/auth'
import {useLocation, useNavigate} from 'react-router-dom'

interface RouteCheckerProps {
  route: RouteItem
  content: ReactNode
}

const Redirect = ({to}: any) => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to.pathname, {state: to.state})
  })
  return null
}
const RouteChecker = ({route, content}: RouteCheckerProps) => {
  const [loading, setLoading] = useState(true)
  const [hasPermission, setPermission] = useState(true)
  const location = useLocation()

  useEffect(() => {
    if (route.guard) {
      let result = route.guard.hasPermission()
      if (typeof result === 'boolean') {
        setLoading(false)
        setPermission(result)
      } else {
        result.then((per) => {
          setLoading(false)
          setPermission(per)
        })
      }
    } else {
      kc.flagUserVisitPublicPage()
      setLoading(false)
      setPermission(true)
    }
  }, [route.guard])

  return (
    <Loading
      loading={loading}
      render={() => {
        return hasPermission ? (
          content
        ) : (
          <Redirect
            to={{
              pathname: route.guard?.getFallbackUrl(location),
              state: {from: location}
            }}
          />
        )
      }}
    />
  )
}

export default RouteChecker
