import {
  HttpParams,
  IPageQueryConfig,
  PageQuery
} from 'web-common/services/PageQuery'

export type DataQueryFilter<E> = {
  [key: string]: (rawData: E[], value: string) => E[]
}

export interface DataQueryConfig<E> {
  data: E[]
  filtersFN: DataQueryFilter<E>
}

export class DataQuery<E> extends PageQuery<E> {
  public rawData: E[] = [] // rawData from service
  public filtersFN: DataQueryFilter<E> = {
    limit: (data, value) => {
      this.data!.limit = parseInt(value)
      return data
    },
    offset: (data, value) => {
      this.data!.offset = parseInt(value)
      return data
    }
  }

  constructor(config: Partial<IPageQueryConfig> & DataQueryConfig<E>) {
    super({
      path: '',
      enableURLFilter: config.enableURLFilter,
      params: config.params
    })
    this.rawData = config.data
    this.filtersFN = Object.assign(this.filtersFN, config.filtersFN)
    this.data = {
      data: config.data,
      offset: 0,
      found: config.data.length,
      limit: 20
    }
    // if (config.params) {
    //   this.params = config.params
    // }
  }

  /**
   * Fetch data with params
   * Push data to all subscribers
   */
  public fetch() {
    // Apply filters
    this.onFetch.next(true)
    let data = [...this.rawData]
    this.params.params.forEach((item) => {
      const key = Object.keys(item)[0]!
      const value = Object.values(item)[0]
      if (this.filtersFN[key]) {
        data = this.filtersFN[key](data, value)
      }
    })
    this.data!.found = data.length
    this.data!.data = data.splice(this.data!.offset, this.data!.limit)
    this.page.next(this.data!.data)
  }

  /**
   * Calculate results for current filter
   * If filter is not presented will return the data found property without fetching it again
   * @param filter
   */
  async calcResults(filter?: HttpParams): Promise<number> {
    if (!filter) {
      return this.data?.found ?? 0
    }
    let data: E[] = [...this.rawData]
    filter.params.forEach((item) => {
      const key = Object.keys(item)[0]!
      const value = Object.values(item)[0]
      if (this.filtersFN[key]) {
        data = this.filtersFN[key](data, value)
      }
    })
    return data.length
  }
}
