import {FormControlLabel, Radio, RadioGroup, TextField} from '@mui/material'
import {ChangeEvent, startTransition, useContext, useState} from 'react'
import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementBudget} from '@fnd/survey'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'
import {useTranslation} from 'react-i18next'

const elementKeyboardEvent = [
  'Backspace',
  'Shift',
  'ArrowLeft',
  'ArrowRight',
  'Tab',
  'Escape',
  'Control'
]

export default function SurveyElementUIBudget({
  field,
  element
}: SurveyFactoryElementBaseProps<SurveyElementBudget>) {
  const context = useContext(SRContext)
  const [t] = useTranslation()
  const [value, setValue] = useState(
    element.value && element.value > -1 ? element.value : ''
  )
  const defaultValue = element.value ? (element.value > -1 ? 'yes' : 'no') : ''
  const [hasBudget, setHasBudget] = useState<string | undefined>(defaultValue)
  const onChangeHasBudget = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value === 'no') {
      element.value = -1
      field.onChange({
        target: {value: -1}
      })
    } else if (element.value === -1) {
      element.value = 0
      field.onChange({
        target: {value: 0}
      })
    }
    setHasBudget(value)
  }

  return (
    <>
      <RadioGroup
        value={hasBudget}
        onChange={onChangeHasBudget}
        id={element.name}
      >
        <FormControlLabel
          value={'yes'}
          control={<Radio />}
          label={t('common:fnd-common-yes', {lng: context.language})}
        />
        {hasBudget === 'yes' && (
          <TextField
            {...field}
            onKeyDown={(e) => {
              if (
                !new RegExp(/^[\d.]*$/).test(e.key) &&
                !elementKeyboardEvent.includes(e.key) &&
                !e.ctrlKey &&
                !e.metaKey
              ) {
                e.preventDefault()
                e.stopPropagation()
                return false
              }
            }}
            inputMode={'decimal'}
            onChange={(e) => {
              const value = !isNaN(parseFloat(e.target.value))
                ? e.target.value
                : ''
              field.onChange(parseFloat(value ?? 0))
              startTransition(() => {
                setValue(value)
              })
            }}
            value={value}
            placeholder={t('common:fnd-common-widget-budget-type-budget')}
          />
        )}
        <FormControlLabel
          value={'no'}
          control={<Radio />}
          label={t('common:fnd-common-no', {lng: context.language})}
        />
      </RadioGroup>
    </>
  )
}
