import {
  Column,
  Row,
  TableOptions,
  useExpanded,
  useSortBy,
  useTable
} from 'react-table'
import React, {ReactNode} from 'react'
import {Grid, Paper, styled, Table, TableContainer} from '@mui/material'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TablePagination from '@mui/material/TablePagination'
import TableFooter from '@mui/material/TableFooter'
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom'
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop'

const ATable = styled(Table)((_) => ({
  marginBottom: '0',
  width: '100%',
  maxWidth: '100%',
  backgroundColor: 'transparent',
  borderSpacing: '0',
  borderCollapse: 'collapse',
  overflow: 'auto',
  '& > tbody > tr, & > thead > tr': {
    height: 'auto'
  }
}))

const ATableHeader = styled(TableHead)((props) => ({
  backgroundColor: props.theme.palette.grey[100]
}))

const ATableHeaderCell = styled(TableCell)((props) => ({
  lineHeight: '1.5em',
  padding: '12px 8px',
  verticalAlign: 'middle',
  borderBottom: 'none',
  borderTop: '1px solid ' + props.theme.palette.grey[300],
  position: 'relative',
  fontSize: '1.063rem',
  borderBottomWidth: '1px',
  fontWeight: 300,
  color: props.theme.palette.grey[800],
  borderTopWidth: '0 !important',
  paddingTop: '37px'
}))

const ATableCell = styled(TableCell)((props) => ({
  lineHeight: '1.5em',
  padding: '12px 8px',
  verticalAlign: 'middle',
  fontSize: '0.875rem',
  borderBottom: 'none',
  borderTop: '1px solid ' + props.theme.palette.grey[300],
  position: 'relative',
  color: props.theme.palette.grey[700]
}))

const ATableRow = styled(TableRow)((props) => ({
  '&:hover': {
    backgroundColor: props.theme.palette.grey[300]
  },
  '&:nth-child(even)': {
    backgroundColor: props.theme.palette.grey[100]
  }
}))

export interface ReactTableProps {
  columns: Column<any>[]
  data: any[]
  tableHeaderColor:
    | 'successRow'
    | 'dangerRow'
    | 'warningRow'
    | 'tableStripedRow'
    | 'whiteRow'
  tableBodyColor:
    | 'successRow'
    | 'dangerRow'
    | 'warningRow'
    | 'tableStripedRow'
    | 'whiteRow'
  cellProps?: any
  showTotalRows?: boolean

  // pagination
  showPagination?: boolean
  count?: number
  size?: number
  page?: number
  nextPage?: () => void
  prevPage?: () => void
  onPageSet?: (page: number) => void
  onSizeSet?: (size: number) => void
  renderRowSubComponent?: (row: Row<any>) => ReactNode
}

function ReactTable(props: ReactTableProps) {
  const tableOptions: TableOptions<any> = {
    columns: props.columns,
    data: props.data
  }
  const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} =
    useTable(tableOptions, useSortBy, useExpanded)

  let pagination = null
  if (props.showPagination) {
    pagination = (
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            count={props.count ?? 0}
            rowsPerPage={props.size ?? 20}
            page={props.page ?? 0}
            SelectProps={{
              inputProps: {'aria-label': 'rows per page'},
              native: true
            }}
            onPageChange={(e: any, page: number) => {
              props.onPageSet?.call(null, page)
            }}
            onRowsPerPageChange={(e: any) => {
              props.onSizeSet?.call(null, parseInt(e.target.value))
            }}
          />
        </TableRow>
      </TableFooter>
    )
  }

  return (
    <TableContainer component={Paper} elevation={0}>
      <ATable {...getTableProps()} size={'small'}>
        {props.showTotalRows && (
          <TableHead>
            <TableRow>
              <TableCell colSpan={headerGroups[0].headers.length}>
                <Grid container justifyContent={'space-between'}>
                  <Grid item>Total Rows: {props.count ?? 0}</Grid>

                  <Grid item>
                    Showing {props.page! * props.size! + 1} -{' '}
                    {props.page! * props.size! + props.size!}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <ATableHeader>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <ATableHeaderCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Grid
                    container
                    direction={'row'}
                    wrap={'nowrap'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <b>{column.render('Header')}</b>
                    </Grid>

                    <Grid item>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <VerticalAlignBottomIcon />
                        ) : (
                          <VerticalAlignTopIcon />
                        )
                      ) : (
                        ''
                      )}
                    </Grid>
                  </Grid>
                </ATableHeaderCell>
              ))}
            </TableRow>
          ))}
        </ATableHeader>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, key) => {
            prepareRow(row)
            return (
              <React.Fragment key={'complex-row-' + key}>
                <ATableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <ATableCell {...cell.getCellProps()}>
                        {cell.render('Cell', {props: props.cellProps})}
                      </ATableCell>
                    )
                  })}
                </ATableRow>
                {
                  // @ts-ignore
                  row.isExpanded ? (
                    <TableRow>
                      <TableCell colSpan={row.cells.length}>
                        {props.renderRowSubComponent?.(row)}{' '}
                      </TableCell>
                    </TableRow>
                  ) : null
                }
              </React.Fragment>
            )
          })}
        </TableBody>
        {pagination}
      </ATable>
    </TableContainer>
  )
}

export default ReactTable
