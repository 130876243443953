import React, {ReactElement, ReactNode} from 'react'
import {PageQuery, PageQueryOperator} from 'web-common/services/PageQuery'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Pagination,
  Stack,
  Typography
} from '@mui/material'
import {Subscription} from 'rxjs'
import {withTranslation, WithTranslation} from 'react-i18next'
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded'

export interface PageQueryContainerProps<E> extends WithTranslation {
  // PageQuery Instance
  query: PageQuery<E>
  // Function to render received data from page query
  render: (data: E[]) => ReactNode
  // Container to render all the filters
  filterRender?: (pq: PageQuery<E>) => ReactNode
  // Fire event when filter button is clicked
  onFilterClick?: () => void
  // Should add pagination or show more controls
  variant?: 'pagination' | 'more'
  // Default position is bottom
  paginationPosition?: 'top' | 'bottom' | 'both'
  // Custom  no result message
  noResults?: ReactNode
  // Show filter by default
  showFilters?: boolean
  // Excluding these filter when showing the filter count badge
  excludeFiltersCount?: string[]
  // Automatically fetch data
  autoLoad?: boolean
}

interface PageQueryContainerState<E> {
  data: E[]
  loading: boolean
  showFilters: boolean
}

class PageQueryContainer<E> extends React.Component<
  PageQueryContainerProps<E>,
  PageQueryContainerState<E>
> {
  state: PageQueryContainerState<E> = {
    data: [],
    loading: this.props.autoLoad ? true : false,
    showFilters: !!this.props.showFilters
  }
  subscription?: Subscription

  componentDidMount() {
    this.subscription = this.props.query.subscribe((data) =>
      this.setState((state) => ({
        data: (() => {
          // APPEND DATA ONLY IF OFFSET IS NOT 0 NAD VARIANT IS 'more'
          if (
            this.props.variant === 'more' &&
            this.props.query.data?.offset !== 0
          ) {
            return [...state.data, ...data]
          }
          return data
        })(),
        loading: false
      }))
    )
    this.props.query.onFetch.subscribe((_) => {
      this.setState({loading: true})
    })
    if (this.props.autoLoad) {
      this.props.query.fetch()
    }
  }

  componentWillUnmount() {
    this.subscription?.unsubscribe()
  }

  onShowMore() {
    this.setState(
      {
        loading: true
      },
      () => {
        this.props.query.nextPage()
      }
    )
  }

  onPageChange(page: number) {
    this.setState(
      {
        loading: true
      },
      () => {
        const limit = this.props.query.data?.limit ?? 10
        this.props.query.setFilters(PageQueryOperator.SET, {
          offset: page * limit
        })
      }
    )
  }

  // Default exclude filter params are limit and offset
  renderFiltersCount(...exclude: string[]) {
    const excluded = ['limit', 'offset', ...exclude]
    const filters = Array.from(this.props.query.getFilters().params.values())
    return filters
      .flatMap((item) => Object.keys(item))
      .filter(
        (key) => !excluded.includes(key) && this.props.query.getFilter(key)
      ).length
  }

  renderFilters() {
    const exclude = this.props.excludeFiltersCount ?? ['sort']
    return (
      <Badge
        badgeContent={this.renderFiltersCount(...exclude)}
        color={'primary'}
      >
        <Button
          startIcon={<FilterListRoundedIcon />}
          onClick={() => {
            this.props.onFilterClick?.()
            if (this.props.filterRender) {
              this.setState((prev) => ({showFilters: !prev.showFilters}))
            }
          }}
          color={'primary'}
          variant={'text'}
          fullWidth={false}
        >
          {this.props.t('common:fnd-common-filters')}
        </Button>
      </Badge>
    )
  }

  renderPagination() {
    const page = Math.floor(
      (this.props.query.data?.offset ?? 0) /
        (this.props.query.data?.limit ?? 10)
    )
    const pages = Math.ceil(
      (this.props.query.data?.found ?? 0) / (this.props.query.data?.limit ?? 10)
    )
    return (
      <Stack
        flexDirection={'row'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        columnGap={3}
        style={{width: '100%'}}
      >
        <Typography variant={'body1'} color={'textSecondary'}>
          {this.props.t('common:fnd-common-results', {
            count: this.props.query.data?.found ?? 0
          })}
        </Typography>
        {/* Only render the pagination if we have more then 1 page */}
        {pages > 1 ? (
          <Pagination
            count={pages}
            color="primary"
            page={page + 1}
            onChange={(_, i) => this.onPageChange(i - 1)}
          />
        ) : null}
      </Stack>
    )
  }

  renderControls() {
    const children = []
    let bwJustify = 0
    let justify: string | undefined = undefined
    const FILTER = 1 << 1,
      PAGINATION = 1 << 2
    if (!!this.props.filterRender || !!this.props.onFilterClick) {
      children.push(this.renderFilters())
      bwJustify |= FILTER
    }
    if (this.props.variant === 'pagination') {
      children.push(this.renderPagination())
      bwJustify |= PAGINATION
    }

    if ((bwJustify & FILTER) > 0 && (bwJustify & PAGINATION) > 0) {
      justify = 'space-between'
    } else if ((bwJustify & FILTER) > 0) {
      justify = 'flex-start'
    } else if ((bwJustify & PAGINATION) > 0) {
      justify = 'flex-end'
    }

    return (
      <>
        <Stack
          flexDirection="row"
          justifyContent={justify}
          alignItems={'center'}
          style={{width: '100%'}}
        >
          {children.map((ctrl, index) => (
            <React.Fragment key={'ctrl' + index}>{ctrl}</React.Fragment>
          ))}
        </Stack>
        <Collapse
          in={this.state.showFilters}
          style={{marginTop: '0', width: '100%'}}
        >
          {this.props.filterRender !== undefined &&
            this.props.filterRender!(this.props.query)}
        </Collapse>
      </>
    )
  }

  // RENDER PAGINATION VARIANT
  renderPaginationVariant() {
    const conditions: {[key: string]: any[]} = {
      top: ['top', 'both'],
      bottom: [undefined, 'bottom', 'both']
    }
    return (
      <Stack
        flexDirection={'column'}
        alignItems={'flex-start'}
        spacing={3}
        style={{width: '100%'}}
      >
        {/*PAGINATION & FILTERS*/}
        {this.renderControls()}
        {/*NO RESULT*/}
        {this.renderNoResults()}
        {/*RENDER DATA*/}
        <Box width={'100%'}>{this.props.render(this.state.data)}</Box>
        {/*LOADING*/}
        {this.state.loading && <CircularProgress color="inherit" />}
        {/*PAGINATION*/}
        {conditions.bottom.includes(this.props.paginationPosition) &&
          this.renderPagination()}
      </Stack>
    )
  }

  renderShowMoreVariant() {
    const canLoadMore =
      this.state.data.length < (this.props.query.data?.found ?? 0)
    return (
      <>
        {this.renderControls()}
        {this.renderNoResults()}
        {this.props.render(this.state.data)}
        {this.state.loading && this.renderLoading()}
        <br />
        {canLoadMore && (
          <Button
            color={'primary'}
            disabled={this.state.loading}
            fullWidth
            onClick={this.onShowMore.bind(this)}
          >
            {this.props.t('fnd-label-show-more')}
          </Button>
        )}
      </>
    )
  }

  renderLoading() {
    return (
      <Box padding={'2rem'} textAlign={'center'}>
        <CircularProgress color="inherit" />
      </Box>
    )
  }

  renderNoResults() {
    if (this.state.data.length === 0 && !this.state.loading) {
      if (this.props.noResults) {
        return this.props.noResults
      }
      return (
        <Box padding={'2rem'} textAlign={'center'}>
          <Typography variant={'body1'}>
            {this.props.t('common:fnd-common-search-no-result')}
          </Typography>
        </Box>
      )
    }
    return null
  }

  render() {
    // DATA IS READY
    if (this.props.variant === 'pagination') {
      return this.renderPaginationVariant()
    } else if (this.props.variant === 'more') {
      return this.renderShowMoreVariant()
    }
    // RENDER LOADING WITH DEFAULT RESULT SET
    if (this.state.loading) {
      return this.renderLoading()
    }
    //return this.props.render(this.state.data)
    return null
  }
}

export default withTranslation()(PageQueryContainer) as <T>(
  props: Omit<PageQueryContainerProps<T>, keyof WithTranslation>
) => ReactElement
