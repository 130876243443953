import {AppConfig} from 'AppConfig'
import {
  BusinessAddressData,
  BusinessGalleryType,
  BusinessSocialData,
  ContractorData,
  ContractorNotificationsSettingsData,
  ContractorOfficeData,
  ContractorRegionData,
  DashboardStats,
  Subscription
} from 'web-common/models/Profile'
import {IPageResult} from 'web-common/services/PageQuery'
import {ReviewData} from 'web-common/models/RatingModels'
import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {CachedRequest} from 'web-common/services/request/Request'

const paths = {
  contractor: {
    profile: {
      onboarding: {
        start: {},
        complete: {}
      },

      subscriptions: {
        '*': {}
      },

      profile: {
        galleries: {
          '*': {}
        }
      },

      regions: {
        '*': {}
      },
      offices: {},

      settings: {
        notifications: {}
      },
      dashboard: {},
      credits: {
        bonusreward: {}
      }
    },
    reviews: {
      find: {},
      '*': {}
    }
  },
  requireToken: true,
  toString: () => AppConfig.api.base!
}

export type ServiceType = 'online' | 'onsite' | 'offsite'

class ContractorService extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: ContractorService

  static shared() {
    if (!ContractorService.instance) {
      ContractorService.instance = new ContractorService()
    }
    return ContractorService.instance
  }

  private cachedRequest = this.enhancedRequest(CachedRequest.create)

  get() {
    return this.api.paths.contractor.profile.get<ContractorData>(
      this.cachedRequest
    )()
  }

  clearCache() {
    this.cachedRequest.clearCache(this.api.paths.contractor.profile)
  }

  startOnboarding() {
    return this.api.paths.contractor.profile.onboarding.start.put(
      this.request
    )()
  }

  completeOnboarding() {
    this.cachedRequest.clearCache(this.api.paths.contractor.profile)
    return this.api.paths.contractor.profile.onboarding.complete.put(
      this.request
    )()
  }

  editCompanyDetails(hasAvatar: boolean, name: string) {
    return this.patchContractor({
      profile: {
        picture: hasAvatar ? '' : null,
        name: name
      }
    })
  }

  editCompanyDetailsExtended(name: string, type: string, defaultLogo?: string) {
    const profile = defaultLogo
      ? {name: name, picture: defaultLogo}
      : {name: name}
    return this.patchContractor({
      profile: profile,
      businessInfo: {
        type: type
      }
    })
  }

  editCompanyAddress(data: BusinessAddressData) {
    return this.patchContractor({businessInfo: {address: data}})
  }

  editServiceType(type: ServiceType, isActive: boolean) {
    return this.patchContractor({serviceType: {[type]: isActive}})
  }

  editCompanySocial(email: string, social: BusinessSocialData) {
    return this.patchContractor({profile: {email: email, social: social}})
  }

  editBusinessInfo(businessInfo: any) {
    return this.patchContractor({businessInfo: businessInfo})
  }

  editCompanyPhone(phone: string) {
    return this.patchContractor({profile: {phone: phone}})
  }

  editCompanyAbout(about: string) {
    return this.patchContractor({profile: {about: about}})
  }

  private patchContractor(data: any) {
    this.cachedRequest.clearCache(this.api.paths.contractor.profile)
    console.log('patchContractor: ', data)
    return this.api.paths.contractor.profile.patch(this.request)(data)
  }

  // Fetch subscribed categories
  getSubscriptions() {
    return this.api.paths.contractor.profile.subscriptions.get<Subscription[]>(
      this.request
    )()
  }

  // Add new category
  createSubscription(data: Subscription) {
    this.cachedRequest.clearCache(this.api.paths.contractor.profile)
    return this.api.paths.contractor.profile.subscriptions.post(this.request)(
      data
    )
  }

  // Edit subscribed services for current category
  updateSubscription(data: Subscription) {
    this.cachedRequest.clearCache(this.api.paths.contractor.profile)
    return this.api.paths.contractor.profile.subscriptions['*'].put(
      this.request,
      data.categoryId
    )(data)
  }

  // Remove category from
  deleteSubscription(categoryId: string) {
    this.cachedRequest.clearCache(this.api.paths.contractor.profile)
    return this.api.paths.contractor.profile.subscriptions['*'].delete(
      this.request,
      categoryId
    )()
  }

  getMyPendingReviews() {
    const params = {
      q: 'PENDING',
      offset: 0,
      limit: 100
    }
    return this.api.paths.contractor.reviews.find.get<IPageResult<ReviewData>>(
      this.request
    )({params: params})
  }

  rateCustomer(reviewId: string, rating: number, comment: string) {
    return this.api.paths.contractor.reviews['*'].put(
      this.request,
      reviewId
    )({rating: rating, comment: comment})
  }

  /**
   * Creates an office for the contractor
   * @param data ContractorOfficeLocationPayload
   * @param updateExisting? boolean - if set to true, the request will be PUT, else it will be POST
   * @returns Promise
   */
  createOrUpdateOffice(
    data: ContractorOfficeData,
    updateExisting: boolean = false
  ) {
    this.cachedRequest.clearCache(this.api.paths.contractor.profile.offices)
    const method = updateExisting ? 'put' : 'post'
    const payload = updateExisting ? [data] : data
    return this.api.paths.contractor.profile.offices[method](this.request)(
      payload
    )
  }

  // Create/Override/ contractor regions
  createAresOfOperations(data: ContractorRegionData[]) {
    this.cachedRequest.clearCache(this.api.paths.contractor.profile)
    this.cachedRequest.clearCache(this.api.paths.contractor.profile.regions)
    return this.api.paths.contractor.profile.regions.put(this.request)(data)
  }

  createGallery(type: BusinessGalleryType, path: string, images: string[]) {
    return this.api.paths.contractor.profile.profile.galleries.post(
      this.request
    )({
      name: type,
      path: path,
      images: images
    })
  }

  updateGallery(type: BusinessGalleryType, path: string, images: string[]) {
    return this.api.paths.contractor.profile.profile.galleries['*'].put(
      this.request,
      type
    )({
      name: type,
      path: path,
      images: images
    })
  }

  getNotificationsSettings() {
    return this.api.paths.contractor.profile.settings.notifications.get<ContractorNotificationsSettingsData>(
      this.request
    )()
  }

  updateNotificationSettings(notificationSettings: any) {
    return this.api.paths.contractor.profile.settings.notifications.put(
      this.request
    )(notificationSettings)
  }

  getDashboardStats() {
    return this.api.paths.contractor.profile.dashboard.get<DashboardStats>(
      this.request
    )()
  }

  requestBonusReward() {
    return this.api.paths.contractor.profile.credits.bonusreward.put(
      this.request
    )()
  }
}

export default ContractorService.shared()
