import React from 'react'
import logoNonBeta from 'web-common/assets/img/logo.svg'
import {Box, Button, Link, styled, Typography} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import {AppConfig} from 'AppConfig'
import {getWhatsAppLink} from 'web-common/utilities/whatsappUtilities'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'

const StyledLink = styled(Link)((props) => ({
  '&:hover': {
    color: props.theme.palette.primary.main
  }
}))

interface InvalidExpiredCodeProps
  extends WithTranslation,
    RouteComponentProps {}

interface InvalidExpiredCodeState {}

class InvalidExpiredCode extends React.Component<
  InvalidExpiredCodeProps,
  InvalidExpiredCodeState
> {
  chatURL = getWhatsAppLink('fnd-register-whatsapp-link')

  onGoToFinderella() {
    window.location.href = AppConfig.contractor.base!
  }

  render() {
    return (
      <Box textAlign={'center'}>
        {/* LOGO */}
        <img
          src={logoNonBeta}
          alt={'finderella'}
          width={'259px'}
          style={{marginTop: '30px'}}
        />

        {/* SHOW ERROR MESSAGE */}
        <Typography
          variant={'h6'}
          style={{
            maxWidth: '540px',
            margin: '30px auto 30px auto',
            fontWeight: 300
          }}
        >
          {this.props.t('fnd-register-invalid-expire-code')}
        </Typography>

        {/* LOGIN */}
        <Button
          style={{marginBottom: '30px'}}
          color={'primary'}
          variant={'contained'}
          onClick={this.onGoToFinderella.bind(this)}
        >
          {this.props.t('fnd-register-continue-to-login')}
        </Button>

        {/* MISTAKE TEXT */}
        <Typography
          variant={'body1'}
          style={{maxWidth: '540px', margin: '0 auto', fontWeight: 300}}
        >
          {this.props.t('fnd-register-if-mistake-contact-us')}{' '}
          <StyledLink href={this.chatURL} target={'_blank'}>
            WhatsApp
          </StyledLink>
        </Typography>
        <br />
      </Box>
    )
  }
}

export default withTranslation()(withRouter(InvalidExpiredCode))
