import React from 'react'
import {
  AvatarGroup,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Stack,
  styled
} from '@mui/material'
import Typography from '@mui/material/Typography'
import AppDate from 'web-common/components/date/AppDate'
import AppAvatar from 'web-common/components/avatar/AppAvatar'
import {WithTranslation, withTranslation} from 'react-i18next'
import ServicesService from 'web-common/services/ServicesService'
import {DemandChatPreview} from 'views/demands/DemandsQuery'
import ServiceImageService from 'services/ServiceImageService'
import {DemandData} from 'models/Demands'

// https://smarty.atlassian.net/browse/FIND-249
const ArbitraryCardActionAreaHeight = styled(CardActionArea)({
  minHeight: '348px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start'
})

const AvatarGroupRow = styled(CardContent)({
  display: 'flex'
})

const StyledCard = styled(Card)({
  '&.archived': {
    filter: 'grayscale()'
  }
})

interface DemandCardProps extends WithTranslation {
  demand: DemandChatPreview<DemandData>
  onClick: (demandId: string) => void
}

interface DemandCardState {}

class DemandCard extends React.Component<DemandCardProps, DemandCardState> {
  renderPreviewCounts() {
    if (this.props.demand.counters.deals) {
      return (
        <Stack direction={'row'} spacing={1}>
          <Chip
            label={
              <Typography variant={'caption'}>
                {this.props.t(`fnd-badge-deal`)}
              </Typography>
            }
            size={'small'}
            color={'primary'}
          />
          {this.props.demand.counters.reviews > 0 && (
            <Chip
              label={
                <Typography variant={'caption'}>
                  {this.props.t(`fnd-badge-review`)}
                </Typography>
              }
              size={'small'}
              color={'secondary'}
            />
          )}
        </Stack>
      )
    }
    return (
      <Stack direction={'row'} spacing={1}>
        <Typography variant={'body1'} color={'textPrimary'} component={'span'}>
          {this.props.t('fnd-label-n-offers', {
            count: this.props.demand.counters.offers
          })}
          {this.props.demand.counters.later > 0 &&
            ` / ${this.props.t('fnd-label-n-offers-later', {
              count: this.props.demand.counters.later
            })}`}
        </Typography>
        {this.props.demand.counters.unread +
          this.props.demand.counters.unseenOffer >
          0 && (
          <Chip
            label={
              <Typography variant={'caption'}>
                {this.props.t(`fnd-badge-new`)}
              </Typography>
            }
            size={'small'}
            color={'secondary'}
          />
        )}
      </Stack>
    )
  }

  renderOffersPreview() {
    if (this.props.demand.archived) {
      return (
        <Chip
          label={
            <Typography variant={'caption'}>
              {this.props.t(this.props.demand.status)}
            </Typography>
          }
          size={'small'}
          color={'primary'}
        />
      )
    }
    if ((this.props.demand.offersPreview?.length ?? 0) > 0) {
      return this.renderPreviewCounts()
    }
    return (
      <Typography variant={'body1'} color={'textSecondary'}>
        {this.props.t('fnd-label-new-demand-waiting-contractors')}
      </Typography>
    )
  }

  render() {
    const service = ServicesService.getServiceById(this.props.demand.serviceId)
    let imageURL = ''
    if (service) {
      imageURL = ServiceImageService.getImage(service)
    }
    return (
      <StyledCard className={this.props.demand.archived ? 'archived' : ''}>
        <ArbitraryCardActionAreaHeight
          onClick={this.props.onClick.bind(this, this.props.demand._id)}
        >
          {/*SERVICE IMAGE*/}
          <CardMedia
            aria-hidden={true}
            title={'Category'}
            image={imageURL}
            style={{height: '140px', width: '100%'}}
          />
          <CardContent>
            {/* SERVICE NAME */}
            <Typography variant={'h2'} color={'primary'}>
              {service?.service.name ?? ''}
            </Typography>

            {/*DATE CREATED*/}
            <Typography variant={'body1'} color={'textSecondary'}>
              <AppDate
                timestamp={this.props.demand.dateCreated}
                format={'date-time'}
              />
            </Typography>
          </CardContent>

          {/*OFFERS*/}
          <CardContent>{this.renderOffersPreview()}</CardContent>

          <AvatarGroupRow>
            {/*AVATARS*/}
            <AvatarGroup max={10} aria-hidden={true}>
              {this.props.demand.offersPreview?.map((p) => (
                <AppAvatar
                  userId={p.contractor!.userId}
                  type={'logo'}
                  url={p.contractor!.picture}
                  key={p._id}
                  size={30}
                />
              ))}
            </AvatarGroup>
          </AvatarGroupRow>
        </ArbitraryCardActionAreaHeight>
      </StyledCard>
    )
  }
}

export default withTranslation()(DemandCard)
