import React from 'react'
import AppDate from 'web-common/components/date/AppDate'
import {Box, Stack, styled, Typography} from '@mui/material'
import Hidden from 'web-common/components/hidden/Hidden'
import {responsiveBP} from 'web-common/theme/AppTheme'
import AppAvatar from 'web-common/components/avatar/AppAvatar'
import {TimelineEventData} from '@fnd/timeline'

const EventStack = styled(Stack)({
  width: 'calc(100% - 32px)'
})
const NonAvatar = styled(Box)({
  width: '32px',
  height: '32px'
})

interface TimelineEventProps<E> {
  event: TimelineEventData<E>
}

// BASE WRAPPER FOR THE ALL RENDERED EVENTS
const TimelineEvent: React.FunctionComponent<TimelineEventProps<any>> = (
  props
) => {
  const Event = props.event.element

  if (props.event.detached) {
    return <Event {...props.event.props} producer={props.event.producer} />
  }

  return (
    <EventStack
      flexDirection={
        props.event.alignment === 'flex-start' ? 'row' : 'row-reverse'
      }
      columnGap={1}
      alignItems={'flex-start'}
      alignSelf={props.event.alignment}
    >
      <Hidden {...responsiveBP.forMobile}>
        {/*USER AVATAR*/}
        {props.event.avatar && <AppAvatar size={32} url={props.event.avatar} />}
        {/*VOID SPACE*/}
        {!props.event.avatar && <NonAvatar />}
      </Hidden>

      {/*GROUP EVENT + TIME CARD*/}
      <Stack
        flexDirection={'column'}
        spacing={1}
        alignItems={props.event.alignment}
        style={{width: '100%'}}
      >
        {/*RENDER EVENT*/}
        <Event {...props.event.props} producer={props.event.producer} />
        {/*RENDER TIME*/}
        <Typography variant={'body2'} color={'textSecondary'}>
          <AppDate timestamp={props.event.date} format={'time'} />
        </Typography>
      </Stack>
    </EventStack>
  )
}

export default TimelineEvent
