import {
  Checkbox,
  CheckboxProps,
  createChainedFunction,
  FormGroup,
  useControlled
} from '@mui/material'
import RadioGroupContext from '@mui/material/RadioGroup/RadioGroupContext'
import {ChangeEvent, forwardRef, ReactNode, useContext, useId} from 'react'
import {RefCallBack} from 'react-hook-form'
import {SurveyValueType} from '@fnd/survey'

export const CheckboxGrouping = forwardRef<HTMLButtonElement, CheckboxProps>(
  (props, ref) => {
    const radioGroup = useContext(RadioGroupContext)
    let checked = props.checked
    let name = props.name
    const onChange = createChainedFunction(
      // @ts-expect-error cant guess mui types
      props.onChange,
      radioGroup && radioGroup.onChange
    )

    if (radioGroup) {
      if (typeof checked === 'undefined') {
        checked = radioGroup.value.includes(props.value)
      }
      if (typeof name === 'undefined') {
        name = radioGroup.name
      }
    }
    return (
      <Checkbox
        {...props}
        ref={ref}
        name={name}
        checked={checked}
        onChange={onChange}
      />
    )
  }
)

export interface CheckboxGroupProps {
  value: SurveyValueType
  ref: RefCallBack
  onChange: (...e: unknown[]) => void
  children?: ReactNode
  id: string
}

const CheckboxGroup = forwardRef((props: CheckboxGroupProps, ref) => {
  const [value, setValueState] = useControlled({
    controlled: props.value,
    default: [],
    name: 'CheckboxGroup'
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    const v = e.target.value
    const _value = [...(value as unknown[])]
    const index = _value.indexOf(v)
    if (index !== -1 && !checked) {
      _value.splice(index, 1)
    } else if (index === -1 && checked) {
      _value.push(v)
    }
    props.onChange(_value)
    setValueState(_value)
  }

  return (
    <RadioGroupContext.Provider
      value={{onChange: handleChange, value, name: useId()}}
    >
      <FormGroup role="checkbox" ref={ref}>
        {props.children}
      </FormGroup>
    </RadioGroupContext.Provider>
  )
})

export default CheckboxGroup
