import kc from 'web-common/services/auth'
import {useLocation, useNavigate} from 'react-router-dom'
import {useEffect} from 'react'

export function LoginRoute() {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    if (!kc.instance.authenticated) {
      kc.automaticLoginFlow().finally(() => {
        if (location.state && (location.state as any).from) {
          // Go to previous page
          navigate((location.state as any).from)
        } else {
          // Go to default page
          navigate('/')
        }
      })
    } else {
      // User should not be here
      navigate('/')
    }
  })
  return null
}

export function LogoutRoute() {
  useEffect(() => {
    window.location.href = kc.instance.createLogoutUrl({
      redirectUri: window.location.origin
    })
  })
  return null
}
