import {FormControlLabel, Radio, RadioGroup, TextField} from '@mui/material'
import React, {ChangeEvent, useContext, useEffect, useState} from 'react'
import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementChoices} from '@fnd/survey'
import CheckboxGroup, {
  CheckboxGrouping,
  CheckboxGroupProps
} from 'web-common/components/survey/ui/elements/helpers/CheckboxGroup'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'
import {useTranslation} from 'react-i18next'

interface SurveyElementUIOtherProps {
  control: React.ReactElement<any, any>
  form: {
    field: Omit<CheckboxGroupProps, 'id'>
    element: SurveyElementChoices
  }
}

function SurveyElementUIOther(props: SurveyElementUIOtherProps) {
  const context = useContext(SRContext)
  const [t] = useTranslation()
  const [value, setValue] = useState(props.form.element.otherValue ?? '')

  useEffect(() => {
    return () => {
      if (
        !(props.form.field.value as string[] | undefined)?.includes('other')
      ) {
        delete props.form.element.otherValue
      }
    }
  })

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const v = e.target.value
    props.form.element.otherValue = v
    setValue(v)
  }
  return (
    <>
      <FormControlLabel
        control={props.control}
        value={'other'}
        label={t('common:fnd-common-other', {lng: context.language})}
      />
      {(props.form.field.value as string[] | undefined)?.includes('other') && (
        <TextField onChange={onChange} value={value} />
      )}
    </>
  )
}

export default function SurveyElementUIChoices({
  field,
  element
}: SurveyFactoryElementBaseProps<SurveyElementChoices>) {
  const context = useContext(SRContext)
  const name = `${element.name}`

  function renderCheckbox(
    field: Omit<CheckboxGroupProps, 'id'>,
    element: SurveyElementChoices
  ) {
    return (
      <CheckboxGroup {...field} id={element.name}>
        {element.choices?.map((option) => (
          <FormControlLabel
            value={option.value}
            key={
              name + ' ' + option.value + ' ' + option.label[context.language]
            }
            control={<CheckboxGrouping />}
            label={option.label[context.language]}
          />
        ))}
        {element.hasOther && (
          <SurveyElementUIOther
            form={{field, element}}
            control={<CheckboxGrouping />}
          />
        )}
      </CheckboxGroup>
    )
  }

  function renderRadio(
    field: Omit<CheckboxGroupProps, 'id'>,
    element: SurveyElementChoices
  ) {
    return (
      <RadioGroup {...field} id={element.name}>
        {element.choices?.map((option) => (
          <FormControlLabel
            value={option.value}
            key={
              name + ' ' + option.value + ' ' + option.label[context.language]
            }
            control={<Radio />}
            label={option.label[context.language]}
          />
        ))}
        {element.hasOther && (
          <SurveyElementUIOther form={{field, element}} control={<Radio />} />
        )}
      </RadioGroup>
    )
  }

  const renderMethod = element.multiple ? renderCheckbox : renderRadio
  return renderMethod(field, element)
}
