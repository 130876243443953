import {SurveyFactory} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {useContext, useEffect} from 'react'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'
import {Stack} from '@mui/material'
import {DeepRequired, FieldValues, useFormContext} from 'react-hook-form'

function SurveyPageView() {
  const context = useContext(SRContext)
  const formContext = useFormContext()

  // Update survey
  useEffect(() => {
    const subscription = formContext.watch(async (form, {name}) => {
      const [page, element] = (name ?? '.').split('.')
      const updatedPage = context.renderer.updateValues({
        [page]: {
          [element]: form[page][element]
        }
      })
      context.setPage(updatedPage)
    })
    return subscription.unsubscribe
  }, [formContext, context])

  return (
    <Stack direction={'column'} spacing={1} alignItems={'stretch'}>
      {context.page.elements.map((element) => {
        const name = `${context.page.name}.${element.name}`
        const hasError = !!(
          formContext.formState.errors?.[
            context.page.name
          ] as DeepRequired<FieldValues>
        )?.[element.name]
        return (
          <div key={name}>
            {SurveyFactory.createFormElement(
              context.page,
              element,
              formContext.control,
              context.renderer.validate.bind(context.renderer),
              hasError
            )}
          </div>
        )
      })}
    </Stack>
  )
}

export default SurveyPageView
