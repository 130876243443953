import React, {ReactNode, useMemo} from 'react'
import {Dialog, Stack, Typography, styled} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import Close from '@mui/icons-material/CloseRounded'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import {useTranslation} from 'react-i18next'

export interface AppModalProps {
  onClose?: () => void
  open: boolean
  title?: ReactNode
  body?: ReactNode
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  disableBackdropClick?: boolean
  disableEscapeKeyDown?: boolean
  children?: ReactNode
  fullWidth?: boolean
  fullScreen?: boolean
}

const CustomModal = styled(Dialog)((_) => ({
  '& .MuiDialogContent-root': {
    overflowY: 'initial !important'
  }
}))

const AppModal = (props: AppModalProps) => {
  const min = useMemo(() => Math.min(window.innerWidth, window.innerHeight), [])
  const max = useMemo(() => Math.max(window.innerWidth, window.innerHeight), [])
  const fullScreen = useMemo(
    () => props.fullScreen ?? (min < 600 && max < 1280),
    [min, max, props.fullScreen]
  )
  const [t] = useTranslation()
  return (
    <CustomModal
      scroll={'body'}
      fullScreen={fullScreen}
      disableEscapeKeyDown={props.disableEscapeKeyDown}
      open={props.open}
      onClose={(event, reason) => {
        if (props.disableBackdropClick && reason === 'backdropClick') {
          return
        }
        props.onClose?.call(this)
      }}
      maxWidth={props.maxWidth}
      fullWidth={props.fullWidth}
      aria-details="dialog-desc"
      aria-labelledby="dialog-title-text"
    >
      {/*TITLE*/}
      <DialogTitle component={'div'}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant={'h6'} component={'h2'} id={'dialog-title-text'}>
            {props.title}
          </Typography>
          <IconButton
            onClick={props.onClose}
            size={'small'}
            aria-label={t('fnd-label-close')}
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>

      {/*CONTENT*/}
      <DialogContent id={'dialog-desc'}>
        {props.body ?? props.children}
      </DialogContent>
      <br />
    </CustomModal>
  )
}

export default AppModal
