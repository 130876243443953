import React from 'react'
import Arrow from 'web-common/components/arrow/Arrow'
import {Button, ButtonProps} from '@mui/material'
import {useTranslation} from 'react-i18next'

export interface LeftActionProps extends ButtonProps {
  icon?: SVGElement
}

function LeftAction(props: LeftActionProps) {
  const [t] = useTranslation()
  let {icon, children, ...rest}: any = {...props}
  if (icon === undefined) {
    icon = <Arrow direction={'left'} />
  }
  return (
    <Button
      startIcon={icon}
      variant={'text'}
      color={'primary'}
      {...rest}
      aria-label={t('fnd-label-back')}
    >
      {children}
    </Button>
  )
}

export default LeftAction
