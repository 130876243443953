// GET FROM API
import {AppConfig} from 'AppConfig'
import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'

export interface RegistrationRequestData {
  firstName: string
  lastName?: string | null
  phone: string
  email: string
  contractor?: boolean | null
  businessName?: string | null
}

// USED FOR API POST
export interface RegistrationData {
  firstName: string
  lastName?: string | null
  phone: string
  email: string
}

// FORM BUILDER DATA
export interface RegistrationFormData extends RegistrationData {
  toc?: string | null
}

const paths = {
  registration: {
    links: {
      '*': {
        register: {}
      }
    }
  },

  requireToken: false,
  toString: () => AppConfig.api.base!
}

class PreRegistrationService extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: PreRegistrationService

  static shared() {
    if (!PreRegistrationService.instance) {
      PreRegistrationService.instance = new PreRegistrationService()
    }
    return PreRegistrationService.instance
  }

  url = AppConfig.api.base + '/registration/links'

  getData(code: string) {
    return this.api.paths.registration.links['*'].get<RegistrationRequestData>(
      this.request,
      code
    )()
  }

  create(code: string, data: RegistrationData) {
    return this.api.paths.registration.links['*'].register.post<any>(
      this.request,
      code
    )(data)
  }
}

export default PreRegistrationService.shared()
