import React, {createRef} from 'react'
import GoogleMapsLoader from 'web-common/components/regions/GoogleMapsLoader'
import {WithTranslation, withTranslation} from 'react-i18next'
import {Box, Slider, Typography} from '@mui/material'
import {Subscription} from 'rxjs'
import AppModal from 'web-common/components/modal/AppModal'
import LocationPickerService, {
  LocationPickerValue,
  OnLocationChange
} from 'web-common/components/location/LocationPickerService'
import {
  LocationPickerHint,
  LocationPickerInput,
  LocationPickerWrapper
} from 'web-common/components/location/LocationPickerStyles'
import './google-maps.extend.css'

export interface LocationPickerProps extends WithTranslation {
  value?: LocationPickerValue
  country: 'bg' | 'il'
  onChange: OnLocationChange
  variant: 'location' | 'range'
}

interface LocationPickerState {
  invalidAddressModal: boolean
  range: number
}

class LocationPicker extends React.Component<
  LocationPickerProps,
  LocationPickerState
> {
  private readonly RANGE = {
    min: 1,
    max: 20,
    step: 1
  }

  state: LocationPickerState = {
    invalidAddressModal: false,
    range: (this.props.value?.range ?? 0) / 1000
  }

  mapRef = createRef<HTMLDivElement>()
  cardRef = createRef<HTMLDivElement>()
  sliderRef =
    this.props.variant === 'range' ? createRef<HTMLDivElement>() : undefined

  readonly countryCenter = {
    il: {location: {lat: 31.41171045, lng: 35.0812614}, zoom: 7},
    bg: {location: {lat: 42, lng: 24}, zoom: 7}
  }

  gMapLoader?: Subscription
  autocompleteListener?: google.maps.MapsEventListener

  async componentDidMount() {
    this.gMapLoader = GoogleMapsLoader.instance(
      this.props.i18n.language
    ).ready.subscribe(async (loaded) => {
      if (loaded) {
        const valueCenter = this.props.value?.lat
          ? {lat: this.props.value.lat, lng: this.props.value.lon}
          : undefined
        const map = new LocationPickerService(
          this.mapRef.current!,
          {
            zoom: valueCenter
              ? 13
              : this.countryCenter[this.props.country].zoom,
            center:
              valueCenter ?? this.countryCenter[this.props.country].location
          },
          {
            onChange: this.onChange.bind(this),
            onError: this.onInvalidAddress.bind(this),
            onReady: () => {
              if (this.props.value) {
                map.loadValue(this.props.value!).then()
              }
            }
          },
          this.props.variant
        )
        if (this.props.variant === 'range' && this.sliderRef) {
          map.createRangeSlider(this.sliderRef.current!)
        }
        this.autocompleteListener = map.createAutocomplete(
          this.cardRef.current!,
          {componentRestrictions: {country: this.props.country}}
        )
      }
    })
  }

  componentWillUnmount() {
    this.gMapLoader?.unsubscribe()
    this.autocompleteListener?.remove()
  }

  onInvalidAddress() {
    this.setState({invalidAddressModal: true})
  }

  onChange(
    location: {lat: number; lon: number},
    address: string,
    regions?: string[],
    range?: number
  ) {
    this.props.onChange(location, address, regions, range)
  }

  render() {
    return (
      <Box position={'relative'} width={'100%'} height={'100%'}>
        {/*SLIDER TEMPLATE*/}
        {this.props.variant === 'range' && (
          <LocationPickerWrapper ref={this.sliderRef}>
            <LocationPickerHint variant={'body1'}>
              Select range
            </LocationPickerHint>
            <Slider
              value={this.state.range}
              step={this.RANGE.step}
              min={this.RANGE.min}
              max={this.RANGE.max}
              onChange={(_, value) => {
                this.setState({range: value as number})
              }}
              valueLabelFormat={(v) => `${v}km`}
              valueLabelDisplay={'on'}
            />
          </LocationPickerWrapper>
        )}

        {/*AUTOCOMPLETE TEMPLATE*/}
        <LocationPickerInput
          placeholder={this.props.t('common:fnd-common-search-for-address')}
          fullWidth
          ref={this.cardRef}
          defaultValue={this.props.value?.address}
        />
        {/*GOOGLE MAPS*/}
        <div
          ref={this.mapRef}
          style={{width: '100%', height: '100%'}}
          className={'map-wrapper'}
        />
        {/*ERROR MODAL*/}
        <AppModal
          open={this.state.invalidAddressModal}
          title={'Error'}
          body={
            <Typography variant={'body1'}>
              {this.props.t('common:fnd-common-search-for-address-failed')}
            </Typography>
          }
          onClose={() => this.setState({invalidAddressModal: false})}
        />
      </Box>
    )
  }
}

export default withTranslation()(LocationPicker)
