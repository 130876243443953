import React from 'react'
import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementLocationPicker} from '@fnd/survey'
import LocationPicker from 'web-common/components/location/LocationPicker'
import {Box} from '@mui/material'

export default function SurveyElementUILocationPicker({
  field,
  element
}: SurveyFactoryElementBaseProps<SurveyElementLocationPicker>) {
  return (
    <Box width={'100%'} height={'calc(100vh * 2/3)'}>
      <LocationPicker
        country={'il'}
        onChange={(location, address, regions, range) => {
          field.onChange({
            ...location,
            address: address,
            regions: regions,
            range: range
          })
        }}
        variant={element.variant}
        value={element.value}
      />
    </Box>
  )
}
