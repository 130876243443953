import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementLocationType} from '@fnd/survey'
import {FormControlLabel, Radio, RadioGroup} from '@mui/material'
import {useMemo} from 'react'
import SurveyElementUILocationTypeService from 'web-common/components/survey/ui/elements/helpers/SurveyElementUILocationTypeService'
import {useTranslation} from 'react-i18next'

export default function SurveyElementUILocationType({
  field,
  element
}: SurveyFactoryElementBaseProps<SurveyElementLocationType>) {
  const [t] = useTranslation()
  const mask = useMemo(
    () => new SurveyElementUILocationTypeService(element.mask),
    [element.mask]
  )

  const checkboxes = [
    {
      label: 'common:fnd-common-survey-widget-location-type-location',
      value: SurveyElementUILocationTypeService.LOCATION_VALUE,
      visible: mask.isChecked(SurveyElementUILocationTypeService.LOCATION)
    },
    {
      label: 'common:fnd-common-survey-widget-location-type-office',
      value: SurveyElementUILocationTypeService.OFFICE_VALUE,
      visible: mask.isChecked(SurveyElementUILocationTypeService.OFFICE)
    },
    {
      label: 'common:fnd-common-survey-widget-location-type-online',
      value: SurveyElementUILocationTypeService.ONLINE_VALUE,
      visible: mask.isChecked(SurveyElementUILocationTypeService.ONLINE)
    }
  ].filter((c) => c.visible)

  return (
    <RadioGroup {...field}>
      {checkboxes.map((ch) => (
        <FormControlLabel
          control={<Radio />}
          label={t(ch.label)}
          value={ch.value}
          key={ch.value}
        />
      ))}
    </RadioGroup>
  )
}
