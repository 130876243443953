import React from 'react'
import {AppFormInput} from 'web-common/components/formBuilder/AppForm'

export interface AppFormComponent extends AppFormInput {
  onChangeCallback: (cfg: AppFormInput, value: string) => void
}

export abstract class AppFormInputComponent<E, T> extends React.Component<
  E,
  T
> {
  onChange(e: any): void {
    throw new Error('Method not implemented')
  }
}
