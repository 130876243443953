import Divider from '@mui/material/Divider'
import React from 'react'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'

export default class AppFormDivider extends AppFormInputComponent<
  AppFormComponent,
  any
> {
  onChange(_: any) {}

  render() {
    return <Divider style={{margin: '16px 0px'}} />
  }
}
