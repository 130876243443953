import React from 'react'
import {QuestionMultipleTextModel, QuestionTextModel} from 'survey-react'
import RegionsMapSelection from 'web-common/components/regions/RegionsMapSelection'
import {createRoot} from 'react-dom/client'

const WIDGET_NAME = 'region'

function widgetReactRegion(Survey: any) {
  return {
    value: '',
    name: WIDGET_NAME,
    title: 'Region Single',
    iconName: 'icon-drag-item',
    isFit: function (question: QuestionTextModel) {
      return question.getType() === WIDGET_NAME
    },
    widgetIsLoaded: () => true,
    isDefaultRender: false,
    htmlTemplate: '<div></div>',
    afterRender: function (question: QuestionTextModel, el: HTMLElement) {
      const root = createRoot(el)
      root.render(
        <RegionsMapSelection
          onSelect={(regionsId) => {
            question.updateValueFromSurvey(regionsId[0])
            this.value = regionsId[0]
          }}
          selected={question.value ? [question.value] : []}
          maxRegions={1}
        />
      )
    },
    activatedByChanged: () => {
      Survey.Serializer.addClass(WIDGET_NAME, [], undefined, 'text')
    },
    willUnmount: function (question: QuestionMultipleTextModel) {
      if (this.value) {
        question.value = this.value
      }
    }
  }
}

export default widgetReactRegion
