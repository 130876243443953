import {AssignmentData} from 'views/demands/DemandsService'
import {Button, CardActions, CardContent, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import ServicesService from 'web-common/services/ServicesService'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'
import React, {useState} from 'react'
import AppModal from 'web-common/components/modal/AppModal'
import DemandDetailsView from 'views/demands/demand/DemandDetailsView'

export interface NewJobEventProps {
  assignment: AssignmentData
}

export const NewJobEvent = (props: NewJobEventProps) => {
  const {t} = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const serviceName =
    ServicesService.getServiceById(props.assignment._refs.serviceId)?.service
      .name ?? ''
  return (
    <>
      <CardContent>
        <Typography variant={'body1'}>
          {t('fnd-event-body-demand-created', {serviceName: serviceName})}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          color={'primary'}
          size={'small'}
          variant={'text'}
          onClick={() => {
            setShowModal(true)
          }}
          startIcon={<AssignmentRoundedIcon />}
        >
          {t('fnd-label-preview-demand')}
        </Button>
      </CardActions>
      <AppModal
        open={showModal}
        onClose={() => setShowModal(false)}
        title={t('fnd-label-demand-preview')}
      >
        {showModal && (
          <DemandDetailsView
            demandId={props.assignment._refs.demandId}
            serviceId={props.assignment._refs.serviceId}
            dateCreated={props.assignment.dateCreated}
          />
        )}
      </AppModal>
    </>
  )
}
