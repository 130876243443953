import React, {ReactNode} from 'react'
import Hidden from '@mui/material/Hidden'
import {Grid} from '@mui/material'
import Box from '@mui/material/Box'
import {responsiveBP} from 'web-common/theme/AppTheme'

export interface SplitViewProps {
  leftView: ReactNode
  rightView: ReactNode
  focusedView: 'left' | 'right'
  isSingleView?: boolean
  customScroll?: boolean
  leftViewProportion?: SplitViewProportionType
}

type SplitViewProportionType = 3 | 4 | 5 | 7 | 8 | 9

class SplitView extends React.Component<SplitViewProps> {
  // Change proportion of the list&details
  leftViewProportion: SplitViewProportionType =
    this.props.leftViewProportion ?? 5
  rightViewProportion: SplitViewProportionType = (12 -
    this.leftViewProportion) as SplitViewProportionType
  // Reference to detailed view
  detailsRef = React.createRef<HTMLDivElement>()
  // Ref to top marker of the list view
  topMarkerRef = React.createRef<HTMLDivElement>()
  // Ref to bottom marker of the list view
  bottomMarkerRef = React.createRef<HTMLDivElement>()
  // Last scroll position used to detect scroll direction
  previousPosition: number = 0
  // Safe padding when scroll is triggered
  scrollPadding: number = 80
  // Scroll details to Y offset
  scrollTo: number = 0
  animation: any = null

  componentDidMount() {
    if (this.props.customScroll) {
      window.addEventListener('scroll', this.customScroll.bind(this))
    }
  }

  customScroll() {
    if (!this.detailsRef.current) {
      // if there is no right view - ignore scroll event
      return
    }
    if (
      this.detailsRef.current.offsetHeight >
      (this.bottomMarkerRef.current?.offsetTop ?? 0)
    ) {
      // if left view is smaller than right view - disable scroll
      return
    }
    const details: HTMLDivElement = this.detailsRef.current!
    const topMarker: number = this.topMarkerRef.current?.offsetTop ?? 110
    const bottomMarker: number = this.bottomMarkerRef.current?.offsetTop ?? 110
    // Height if the window
    const height = window.innerHeight
    // Current scroll position
    const scrollY = window.scrollY
    let yPosition: number | undefined
    details.style.position = 'relative'
    details.style.transition = 'top 0.3s'
    if (scrollY < topMarker) {
      // ANCHOR AT TOP
      yPosition = 0
    } else if (scrollY + height > bottomMarker) {
      // ANCHOR AT BOTTOM
      yPosition = bottomMarker - details.offsetHeight - topMarker
    } else if (
      scrollY - this.previousPosition > 0 &&
      scrollY + height >
        topMarker +
          details.offsetTop +
          details.offsetHeight +
          this.scrollPadding
    ) {
      // SCROLL DOWN
      yPosition =
        scrollY + height - this.scrollPadding - details.offsetHeight - topMarker
    } else if (
      scrollY - this.previousPosition < 0 &&
      scrollY < details.offsetTop + topMarker - this.scrollPadding
    ) {
      // SCROLL UP
      yPosition = scrollY + this.scrollPadding - topMarker
    }
    // Update details
    if (yPosition !== undefined) {
      this.scrollTo = yPosition
      if (this.animation !== null) {
        window.clearInterval(this.animation)
        this.animation = null
      }
      this.animation = setTimeout(
        () => {
          if (this.detailsRef.current) {
            this.detailsRef.current.style.top = yPosition + 'px'
          }
        },
        Math.abs(this.previousPosition - scrollY) / 1000
      )
    }
    // Update last scroll position
    this.previousPosition = scrollY
  }

  componentDidUpdate(
    prevProps: Readonly<SplitViewProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (
      prevProps.isSingleView !== this.props.isSingleView &&
      this.props.customScroll
    ) {
      if (window.innerWidth > 960) {
        this.customScroll()
      } else {
        window.scrollTo(0, 0)
      }
    }
  }

  render() {
    return (
      <>
        <Hidden {...responsiveBP.forDesktop}>{this.renderMobile()}</Hidden>
        <Hidden {...responsiveBP.forMobile}>{this.renderDesktop()}</Hidden>
      </>
    )
  }

  renderDesktop() {
    return this.props.isSingleView ? this.props.leftView : this.renderBothView()
  }

  renderMobile() {
    return this.props.focusedView === 'left'
      ? this.props.leftView
      : this.props.rightView
  }

  renderBothView() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={this.leftViewProportion}>
          <div ref={this.topMarkerRef} />
          {this.props.leftView}
          <div ref={this.bottomMarkerRef} />
        </Grid>

        <Grid item xs={this.rightViewProportion}>
          <Box position={'relative'}>
            <div ref={this.detailsRef}>{this.props.rightView}</div>
          </Box>
        </Grid>
      </Grid>
    )
  }
}

export default SplitView
