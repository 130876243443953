import React from 'react'
import logoNonBeta from 'web-common/assets/img/logo.svg'
import {getWhatsAppRegisterLink} from 'web-common/utilities/whatsappUtilities'
import AppPage from 'web-common/utilities/AppPage'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography
} from '@mui/material'
import WhatsAppRegistrationGenericService, {
  RegistrationTriggerData
} from 'views/registration/whatsapp/WhatsAppRegistrationGenericService'
import LanguageSelector from 'components/LanguageSelector'
import AccessibilityControl from 'components/accessibility/AccessibilityControl'

interface WhatsAppRegistrationGenericProps {}

interface WhatsAppRegistrationGenericState {
  hasError: boolean
  loading: boolean
  registrationType: string
  triggers?: RegistrationTriggerData
}

class WhatsAppRegistrationGeneric extends AppPage<
  WhatsAppRegistrationGenericProps,
  WhatsAppRegistrationGenericState
> {
  static path = '/registration/wa'

  title = 'Registration'

  service = WhatsAppRegistrationGenericService

  state: WhatsAppRegistrationGenericState = {
    hasError: false,
    registrationType: '',
    loading: true
  }

  async componentDidMount() {
    super.componentDidMount()
    // GET PARAMS VALUES
    const data = new URLSearchParams(this.props.location.search)
    if (!data.has('registrationType')) {
      console.error('registrationType is missing')
      this.setState({hasError: true})
      return
    }
    const config = {
      registrationType: data.get('registrationType')!,
      lang: data.get('lang')
    }
    // GET ALL AVAILABLE
    this.service
      .get(config.registrationType)
      .then((data) => {
        this.setState(
          {
            loading: false,
            registrationType: config.registrationType!,
            triggers: data
          },
          this.updateLanguage.bind(this, config.lang)
        )
      })
      .catch((e) => {
        console.error(e)
        this.setState(
          {hasError: true},
          this.updateLanguage.bind(this, config.lang)
        )
      })
  }

  async updateLanguage(lang: string | null) {
    if (lang) {
      await this.props.i18n.changeLanguage(lang)
    }
  }

  getWhatsAppLink(): string {
    if (!this.state.triggers) {
      throw Error(
        'Incorrect calling `getWhatsAppLink` without loading triggers'
      )
    }
    const language = this.props.i18n.language
    let whatsAppTriggers
    if (this.state.triggers[language]) {
      whatsAppTriggers = this.state.triggers[language]
    }
    if (!whatsAppTriggers) {
      throw Error(
        'These is no suitable triggers for that language. Redirect to home screen. Data:'
      )
    }
    return getWhatsAppRegisterLink(
      whatsAppTriggers.triggers[whatsAppTriggers.triggers.length - 1]
    )
  }

  renderContent() {
    // RENDER ERROR
    if (this.state.hasError) {
      return (
        <Box
          alignItems={'center'}
          display={'flex'}
          justifyContent={'center'}
          maxWidth={'540px'}
          flexDirection={'column'}
        >
          {/* LOGO */}
          <img
            src={logoNonBeta}
            alt={'finderella'}
            width={'259px'}
            style={{margin: '30px 0'}}
          />
          {/*ERROR*/}
          <h3>{this.props.t('fnd-whatsapp-registration-unexpected-error')}</h3>
        </Box>
      )
    }

    // RENDER LOADING
    if (this.state.loading) {
      return (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          height={'100vh'}
          width={'100%'}
        >
          <CircularProgress />
        </Box>
      )
    }

    let whatsAppLink = ''
    try {
      whatsAppLink = this.getWhatsAppLink()
    } catch (e) {
      console.log(e)
      this.setState({hasError: true})
    }
    const type = this.state.registrationType
      .split(/(?=[A-Z])/)
      .map((s) => s.toLowerCase())
      .join('-')

    // RENDER REGISTRATION INSTRUCTIONS
    return (
      <Box textAlign={'center'} display={'flex'} justifyContent={'center'}>
        <Box maxWidth={'540px'}>
          {/* LOGO */}
          <img
            src={logoNonBeta}
            alt={'finderella'}
            width={'259px'}
            style={{marginTop: '30px'}}
          />
          {/*TITLE*/}
          <Typography variant={'h1'} style={{margin: '30px auto 0 auto'}}>
            {this.props.t([
              `fnd-whatsapp-registration-${type}-title`,
              'fnd-whatsapp-registration-title'
            ])}
          </Typography>
          {/*DESCRIPTION*/}
          <Typography variant={'subtitle1'} style={{marginTop: '30px'}}>
            {this.props.t([
              `fnd-whatsapp-registration-${type}-description`,
              'fnd-whatsapp-registration-description'
            ])}
          </Typography>
          {/*BUTTON*/}
          <Button
            color={'primary'}
            variant={'contained'}
            style={{marginTop: '30px'}}
            href={whatsAppLink}
            target={'_blank'}
          >
            {this.props.t([
              `fnd-whatsapp-registration-${type}-button`,
              'fnd-whatsapp-registration-button'
            ])}
          </Button>
          {/*FOOTER - HINT TEXT*/}
          <Typography variant={'body1'} style={{margin: '30px auto 0 auto'}}>
            {this.props.t([
              `fnd-whatsapp-registration-${type}-footer`,
              'fnd-whatsapp-registration-footer'
            ])}
          </Typography>
        </Box>
      </Box>
    )
  }

  render() {
    return (
      <Container maxWidth={'md'}>
        <Stack
          direction={'column'}
          spacing={12}
          alignItems={'center'}
          justifyContent={'stretch'}
        >
          {/* RENDER MAIN CONTENT */}
          <span>{this.renderContent()}</span>

          {/*RENDER LANGUAGE DROPDOWN*/}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={{width: '100%'}}
          >
            <LanguageSelector
              onLanguageChange={this.props.i18n.changeLanguage.bind(
                this.props.i18n
              )}
              defaultValue={this.props.i18n.language}
            />
            <AccessibilityControl stationary />
          </Stack>
        </Stack>
      </Container>
    )
  }
}

export default WhatsAppRegistrationGeneric.exportDefault()
