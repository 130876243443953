import React from 'react'
import {Grid} from '@mui/material'
import browse from './browse.jpg'
import CantFindServiceView from 'views/search/CantFindServiceView'
import HeaderImageContent from 'components/HeaderImageContent'
import ServicesService from 'web-common/services/ServicesService'
import {
  CategoryData,
  ServiceData
} from 'web-common/models/CategoriesAndServices'
import BrowseCard from 'views/browse/BrowseCard'
import AppModal from 'web-common/components/modal/AppModal'
import AppPage from 'web-common/utilities/AppPage'

interface BrowseViewProps {}

interface BrowseViewState {
  categories: CategoryData[]
  selectedCategory?: string
  mainCategory?: string
  showMissingModal: boolean
  missingServiceInCategory?: string
}

class BrowseView extends AppPage<BrowseViewProps, BrowseViewState> {
  title = 'Browse Categories & Services'

  static path = '/browse/:main?/:category?'

  state: BrowseViewState = {
    showMissingModal: false,
    categories: []
  }

  componentDidMount() {
    super.componentDidMount()
    ServicesService.get().then((data) => {
      const update: any = {categories: data.children}
      if (this.props.params.category) {
        update['selectedCategory'] = this.props.params.category
        update['mainCategory'] = this.props.params.main
      } else if (this.props.params.main) {
        update['mainCategory'] = this.props.params.main
      }
      this.setState(update)
    })
  }

  onCategorySelect(category: CategoryData) {
    this.props.navigate('/browse/' + category.parentId! + '/' + category._id)
  }

  onServiceSelect(service: ServiceData) {
    this.props.navigate('/survey/' + service._id)
  }

  onUserNotFoundService(category: CategoryData) {
    const name: string =
      category.l10n?.[this.props.i18n.language]?.name ?? category.name
    this.setState({missingServiceInCategory: name})
  }

  onCloseSuggestService() {
    this.setState({missingServiceInCategory: undefined})
  }

  render() {
    return (
      <>
        <HeaderImageContent
          image={browse}
          title={this.props.t('fnd-browse-title')}
        >
          {/* CATEGORIES */}
          <Grid container spacing={4}>
            {this.state.categories.map((category) => (
              <Grid item xs={12} key={category._id}>
                <BrowseCard
                  category={category}
                  mainCategory={this.state.mainCategory}
                  selectedCategory={this.state.selectedCategory}
                  onCategorySelect={this.onCategorySelect.bind(this)}
                  onServiceSelect={this.onServiceSelect.bind(this)}
                  onUserNotFoundService={this.onUserNotFoundService.bind(this)}
                />
              </Grid>
            ))}
          </Grid>
        </HeaderImageContent>

        {/* USER DIDNT FOUND SERVICE */}
        <AppModal
          title={this.props.t('fnd-label-service-not-found-title')}
          onClose={this.onCloseSuggestService.bind(this)}
          open={this.state.missingServiceInCategory !== undefined}
          maxWidth={'md'}
        >
          <CantFindServiceView
            onClose={this.onCloseSuggestService.bind(this)}
            queryString={this.state.missingServiceInCategory}
          />
        </AppModal>
      </>
    )
  }
}

export default BrowseView.exportDefault()
