import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {AppConfig} from 'AppConfig'

const paths = {
  regions: {
    'match-pin': {}
  },
  requireToken: true,
  toString: () => AppConfig.api.base!
}

export default class MapGeometryService extends RequestService {
  protected api: ApiConfig<typeof paths> = new ApiConfig<typeof paths>(paths)

  protected static instance: MapGeometryService

  static shared() {
    return (
      MapGeometryService.instance ??
      (MapGeometryService.instance = new MapGeometryService())
    )
  }

  getRegionsFromLocation(o: google.maps.LatLng, r?: number) {
    const params: any = {
      lat: o.lat(),
      lon: o.lng()
    }
    if (r !== undefined) {
      // BACKEND COULD NOT HANDLE ZERO AS RADIUS, WE NEED TO SEND AT LEAST ONE METER
      params.radius = r || 1000
    }
    return this.api.paths.regions['match-pin'].get<string[]>(this.request)({
      params: params
    })
  }
}
