import React from 'react'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'
import {FormControlLabel, Switch, Tooltip} from '@mui/material'

export interface AppFormSwitchData {
  checked: boolean
  value: string
}

interface AppFormSwitchProps extends AppFormComponent {}

interface AppFormSwitchState {}

class AppFormSwitch extends AppFormInputComponent<
  AppFormSwitchProps,
  AppFormSwitchState
> {
  componentDidMount() {
    if (
      !this.props.data ||
      typeof this.props.data.checked !== 'boolean' ||
      typeof this.props.data.value !== 'string'
    ) {
      throw new Error('AppFormSwitch property data should be AppFormSwitchData')
    }
  }

  onChange(e: React.ChangeEvent<any>) {
    const {onChangeCallback, ...rest} = {...this.props}
    this.props.onChangeCallback(
      rest,
      e.target.checked ? this.props.data.value : ''
    )
  }

  render() {
    const input = (
      <FormControlLabel
        control={
          <Switch
            color={'primary'}
            defaultChecked={this.props.data.checked}
            disabled={this.props.disabled}
          />
        }
        onChange={this.onChange.bind(this)}
        label={this.props.label}
      />
    )
    if (this.props.placeholder) {
      return <Tooltip title={this.props.placeholder}>{input}</Tooltip>
    }
    return input
  }
}

export default AppFormSwitch
