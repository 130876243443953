import React from 'react'
import {Button, Grid, Typography} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import CustomerService, {
  UserDetailsData
} from 'web-common/services/CustomerService'
import AppForm, {
  AppFormData,
  AppFormInput
} from 'web-common/components/formBuilder/AppForm'
import {UserData} from 'web-common/models/Profile'
import ImageResizeService, {
  ImageSizes
} from 'web-common/services/ImageResizeService'
import AppCropper from 'web-common/components/cropper/AppCropper'
import AppAvatar from 'web-common/components/avatar/AppAvatar'
import kc from 'web-common/services/auth'
import FileStorage from 'web-common/services/files/FileStorage'

interface UserDetailsEditProps extends WithTranslation {
  onSave: (userDetailsData: UserDetailsData) => void
  onCancel: () => void
  onError: (message?: string) => void
}

interface UserDetailsEditState {
  // Is form lock
  lock: boolean
  // Raw image from user device
  rawBase64Image?: string
  // Cropped & resized image
  base64Image?: string
  // Form config
  form: AppFormInput[]
  // user profile
  user?: UserData
}

class UserDetailsEdit extends React.Component<
  UserDetailsEditProps,
  UserDetailsEditState
> {
  state: UserDetailsEditState = {
    lock: false,
    form: []
  }

  componentDidMount() {
    CustomerService.get().then((user) => {
      this.setState({
        user: user,
        form: [
          {
            validator: new RegExp(/\S/),
            errorText: this.props.t('common:fnd-common-validation-first-name'),
            label: this.props.t('common:fnd-common-first-name'),
            name: 'firstName',
            trim: true,
            value: user.contact.firstName ?? '',
            type: 'text',
            size: {
              xs: 12,
              md: 6
            }
          },
          {
            validator: new RegExp(/\S/),
            errorText: this.props.t('common:fnd-common-validation-last-name'),
            label: this.props.t('common:fnd-common-last-name'),
            name: 'lastName',
            trim: true,
            value: user.contact.lastName ?? '',
            type: 'text',
            size: {
              xs: 12,
              md: 6
            }
          }
          // {
          //   validator: new RegExp(/^\w+@[a-z_]+?\.[a-z]{2,3}(\.[a-z]{2,3})?$/i),
          //   errorText: this.props.t('common:fnd-common-required-valid-email'),
          //   label: this.props.t('common:fnd-common-email'),
          //   name: 'email',
          //   value: user.contact.email ?? '',
          //   type: 'text',
          //   size: {
          //     xs: 12
          //   },
          // }
        ]
      })
    })
  }

  fileInputRef = React.createRef<HTMLInputElement>()

  async onSave(data: AppFormData) {
    this.setState({lock: true})

    let image = this.state.user?.picture ?? null
    if (this.state.base64Image) {
      // Upload image
      image = await FileStorage.uploadResource(
        this.state.base64Image,
        `/user/${this.state.user!._id}/avatar/`,
        'avatar.jpg',
        true
      )
    }

    // Save form
    const userDetailsData = {
      hasAvatar: image !== null,
      firstName: data.firstName as string,
      lastName: data.lastName as string
    }
    CustomerService.editUserDetails(userDetailsData)
      .then(async () => {
        await kc.instance.updateToken(Number.MAX_SAFE_INTEGER)
        this.setState({lock: false})
        this.props.onSave(userDetailsData)
      })
      .catch((error) => {
        console.error(error)
        this.setState({lock: false})
        this.props.onError.call(this, 'common:fnd-common-unexpected-error')
      })
  }

  async convertFileToBase64(event: React.ChangeEvent<HTMLInputElement>) {
    const files = Array.from(event.target.files!)
    const file = files[0]
    const image = {
      name: file.name,
      logo: await ImageResizeService.resizeFileProportionately(
        file,
        ImageSizes.gallery
      )
    }
    this.setState({rawBase64Image: image.logo}, () => {
      // CLEAR FILE INPUT IN CASE WHEN USER TRY TO RE-UPLOAD SAME FILE
      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = ''
      }
    })
  }

  render() {
    if (this.state.form.length === 0) {
      return null
    }

    if (this.state.rawBase64Image) {
      // Show cropper
      return (
        <AppCropper
          src={this.state.rawBase64Image}
          size={ImageSizes.logo}
          onCrop={(image) =>
            this.setState({base64Image: image, rawBase64Image: undefined})
          }
          onCancel={() => this.setState({rawBase64Image: undefined})}
        />
      )
    }

    // Show form
    return (
      <Grid container spacing={4}>
        {/* PHOTO TITLE */}
        <Grid item xs={12}>
          <Typography variant={'body1'}>
            {this.props.t('common:fnd-common-profile-photo')}
          </Typography>
        </Grid>

        {/* PHOTO */}
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems={'center'}>
            {/* CURRENT AVATAR */}
            <Grid item>
              <AppAvatar
                url={this.state.base64Image ?? this.state.user?.picture}
                userName={`${this.state.user?.contact.firstName ?? ' '} ${
                  this.state.user?.contact.lastName ?? ' '
                }`}
                userId={this.state.user?._id}
                type={'avatar'}
              />
            </Grid>

            {/* UPLOAD PHOTO */}
            <Grid item>
              <input
                type="file"
                accept={'image/*'}
                onChange={this.convertFileToBase64.bind(this)}
                ref={this.fileInputRef}
                style={{display: 'none'}}
              />
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={() => this.fileInputRef.current?.click()}
              >
                {this.props.t('common:fnd-common-upload-photo')}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {/* VOID - DESIGN ISSUES */}
        </Grid>

        {/* USER NAME, EMAIL */}
        <Grid item xs={12}>
          <AppForm
            form={this.state.form}
            spacing={3}
            lock={this.state.lock}
            onSave={this.onSave.bind(this)}
            onCancel={this.props.onCancel.bind(this)}
            onError={this.props.onError.bind(this)}
          />
        </Grid>
      </Grid>
    )
  }
}

const translated = withTranslation()(UserDetailsEdit)
export default translated
