import React from 'react'
import Grid from '@mui/material/Grid'
import QuestionnairesWelcomeView from './QuestionnairesWelcomeView'
import QuestionnairesService from './QuestionnairesService'
import kc from 'web-common/services/auth'
import {Button, Stack} from '@mui/material'
import AppPage from 'web-common/utilities/AppPage'
import NotificationPreferencesView from 'views/profile/notifications/NotificationPreferencesView'
import QuestionnairesCongratulationsView from 'views/questionnaires/QuestionnairesCongratulationsView'
import SurveyRendererView from 'web-common/components/survey/ui/view/SurveyRendererView'
import QuestionnaireAnswers from 'web-common/components/survey/QuestionnaireAnswers'
import {QuestionnairesAnalytics} from './QuestionnairesAnalytics'
import {
  SurveyAnswersMapper,
  SurveySessionStorage,
  SurveyAnalytics,
  SurveyAnswers,
  SurveyData,
  SurveyRendererService
} from '@fnd/survey'
import {AppSurveySessionStorage} from 'web-common/components/survey/services/SurveySessionStorage'
import browserStorage from 'web-common/services/BrowserStorage'
import {BEHAVIOR_STORAGE_KEY} from './config/constants'
import BehaviorOptions from './survey-behavior-selector/BehaviorOptions.type'

interface QuestionnairesViewState {
  questionnaire?: SurveyData
  inRequest: boolean
  answers?: SurveyAnswers
  step: 'welcome' | 'questionnaire' | 'answers' | 'congratulations'
  demandId?: string
  sessionStorage?: SurveySessionStorage
  questionnaireStats?: SurveyAnalytics
}

interface QuestionnairesViewProps {
  serviceId?: string
  onBegin?: () => void
  onComplete?: () => void
}

interface QuestionnairesViewRouteParams {
  serviceId: string
}

class QuestionnairesView extends AppPage<
  QuestionnairesViewProps,
  QuestionnairesViewState,
  QuestionnairesViewRouteParams
> {
  // /questionnaires/accounting
  static path = '/questionnaires/:serviceId'

  // No Title
  title = ''
  // Service
  service = QuestionnairesService.shared()
  // Service ID:
  serviceId: string = this.props.serviceId ?? this.props.params.serviceId

  state: QuestionnairesViewState = {
    inRequest: false,
    step: 'welcome'
  }

  // sessionId if he came from WA with token
  waSessionId?: string

  editSurvey() {
    // Reset answers to load the surveyComponent
    this.setState({
      answers: undefined,
      step: 'questionnaire'
    })
  }

  onSurveyComplete(answers: any) {
    this.setState({
      answers: answers,
      step: 'answers'
    })
  }

  async loadSurvey() {
    this.service.get(this.serviceId).then(async (questionnaire) => {
      // When accessibility theme is enabled, disable the auto advanced option
      if (this.props.theme.type === 'accessibility') {
        questionnaire.model.autoAdvance = false
      } else {
        // Part of the experiment for setting survey mode by the user
        const surveyBehaviorUserPreference = browserStorage.get(
          BEHAVIOR_STORAGE_KEY
        ) as BehaviorOptions | undefined

        if (
          !surveyBehaviorUserPreference ||
          surveyBehaviorUserPreference === 'classic'
        ) {
          questionnaire.model.autoAdvance = false
        } else if (surveyBehaviorUserPreference === 'quick') {
          questionnaire.model.autoAdvance = true
        }
      }
      // Get wa session answers
      let sessionAnswers: SurveyAnswers | undefined
      const sessionStorage = new AppSurveySessionStorage(this.serviceId)
      if (this.waSessionId) {
        const session = await this.service
          .getWASessionAnswers(this.waSessionId)
          .catch((_) => ({answers: undefined}))
        sessionAnswers =
          session.answers &&
          this.service.getSurveyAnswersFromSimple(session.answers)
        if (sessionAnswers) {
          sessionStorage.update(sessionAnswers)
        }
      }
      this.setState(
        {
          questionnaire: questionnaire,
          sessionStorage: sessionStorage,
          questionnaireStats: new QuestionnairesAnalytics(
            this.serviceId,
            questionnaire._id ?? '',
            questionnaire.rev ?? 0
          ),
          step: 'questionnaire'
        },
        () => this.props.onBegin?.()
      )
    })
  }

  submitSurvey() {
    if (this.state.inRequest) {
      return
    }
    // Prepare data for submitting
    const localizedSurvey = this.service.getLocalizedSurvey(
      this.state.questionnaire!
    )
    const localizedTemplates = this.state.questionnaire?.templates?.map((t) =>
      this.service.getLocalizedSurvey(t)
    )
    const survey = SurveyRendererService.buildSurvey(
      localizedSurvey,
      localizedTemplates ?? []
    )
    let answers = this.state.answers

    this.setState(
      {
        inRequest: true
      },
      async () => {
        this.props.setBackdrop(true)
        // Get all question with images
        const mapper = new SurveyAnswersMapper(survey, answers!)
        const files = mapper.getQuestionAnswerMeta(
          mapper.getAnswersFromType('file-uploader')
        )
        const questionnaireId = this.state.questionnaire!._id!
        // Await to image to upload
        if (files.length > 0) {
          const uploadingCompleteSuccessfully =
            await this.service.uploadSurveyFiles(
              files,
              questionnaireId,
              answers!
            )
          if (!uploadingCompleteSuccessfully) {
            // Upload fail, cancel survey submitting
            this.props.pushNotification({
              color: 'error',
              message: 'Unexpected error'
            })
            this.props.setBackdrop(false)
            this.setState({inRequest: false})
            return
          }
        }
        // Submit the survey answers
        this.service
          .createDemand(
            this.serviceId,
            this.state.questionnaire!._id!,
            survey,
            answers,
            this.waSessionId
          )
          .then((response) => {
            this.props.setBackdrop(false)
            const demandId = response.location.substring(
              response.location.lastIndexOf('/') + 1,
              response.location.length
            )
            this.waSessionId = undefined
            this.state.questionnaireStats?.completeSessionWithReason(demandId)
            this.setState(
              {step: 'congratulations', demandId: demandId, inRequest: false},
              () => {
                this.state.sessionStorage?.delete()
              }
            )
          })
          .catch((_) => {
            this.props.pushNotification({
              color: 'error',
              message: 'Unexpected error'
            })
            this.props.setBackdrop(false)
            this.setState({inRequest: false})
          })
      }
    )
  }

  loadDemands() {
    this.props.onComplete?.call(this)
    this.props.navigate(`/demands/${this.state.demandId}`, {
      state: {redirectToWhatsApp: true}
    })
  }

  onBeginQuestionnaire(waSessionId?: string) {
    this.waSessionId = waSessionId
    if (kc.instance.authenticated) {
      // User authenticated - proceed to survey
      this.loadSurvey().then()
    } else {
      if (this.props.profile.customer) {
        this.loadSurvey().then()
      } else {
        this.setState({step: 'welcome'})
      }
      // Show login popup
      kc.loginWithPopup().then()
    }
  }

  render() {
    switch (this.state.step) {
      case 'welcome':
        return (
          <QuestionnairesWelcomeView
            serviceId={this.serviceId}
            onBegin={this.onBeginQuestionnaire.bind(this)}
          />
        )
      case 'answers':
        return (
          <>
            <QuestionnaireAnswers
              language={this.props.i18n.language}
              surveyData={this.state.questionnaire!}
              answers={this.state.answers!}
            />
            <br />
            <Grid container justifyContent={'space-between'}>
              <Grid item>
                <Button
                  onClick={this.editSurvey.bind(this)}
                  color={'secondary'}
                  variant={'outlined'}
                >
                  {this.props.t('fnd-label-edit-answers')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={this.submitSurvey.bind(this)}
                  color={'primary'}
                  variant={'contained'}
                >
                  {this.props.t('fnd-label-submit-survey')}
                </Button>
              </Grid>
            </Grid>
          </>
        )
      case 'questionnaire':
        return (
          <>
            <br />
            <SurveyRendererView
              survey={this.service.getLocalizedSurvey(
                this.state.questionnaire!
              )}
              templates={this.state.questionnaire?.templates?.map((t) =>
                this.service.getLocalizedSurvey(t)
              )}
              sessionStorage={this.state.sessionStorage}
              surveyAnalytics={this.state.questionnaireStats}
              language={this.props.i18n.language}
              onComplete={this.onSurveyComplete.bind(this)}
            />
            <br />
          </>
        )
      case 'congratulations':
        return (
          <Stack direction={'column'} spacing={4}>
            <QuestionnairesCongratulationsView />
            <NotificationPreferencesView
              onSuccess={this.loadDemands.bind(this)}
            />
          </Stack>
        )
    }
  }
}

export default QuestionnairesView.exportDefault()
