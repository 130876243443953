import React from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import NotificationService from 'web-common/services/NotificationService'
import {Card, CardContent, CardHeader, Grid, Typography} from '@mui/material'
import {WithAppContext, withAppContext} from 'web-common/contexts/AppContext'
import LanguageSelector from 'components/LanguageSelector'

interface NotificationLanguageViewProps
  extends WithTranslation,
    WithAppContext {
  service: typeof NotificationService
}

interface NotificationLanguageViewState {
  selectedLanguage: string
}

class NotificationLanguageView extends React.Component<
  NotificationLanguageViewProps,
  NotificationLanguageViewState
> {
  state: NotificationLanguageViewState = {
    selectedLanguage: this.props.service.settings.lang
  }

  onLanguageChange(lang: string) {
    this.props.service
      .updateLanguage(lang)
      .then((_) => {
        this.setState({selectedLanguage: lang})
        this.props.pushNotification({
          message: 'fnd-notification-settings-updated-success',
          color: 'success'
        })
      })
      .catch((_) => {
        this.props.pushNotification({
          message: 'fnd-label-unexpected-error',
          color: 'error'
        })
      })
  }

  render() {
    return (
      <Card>
        {this.props.title && (
          <CardHeader title={this.props.t('fnd-notification-language-title')} />
        )}
        <CardContent>
          <Grid
            container
            spacing={2}
            justifyContent={'space-between'}
            flexWrap={'nowrap'}
            alignItems={'center'}
          >
            <Grid item>
              <Typography variant={'body1'}>
                {this.props.t('fnd-choose-preferred-notification-language')}
              </Typography>
            </Grid>
            <Grid item>
              <LanguageSelector
                defaultValue={this.state.selectedLanguage}
                onLanguageChange={this.onLanguageChange.bind(this)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

export default withTranslation()(withAppContext(NotificationLanguageView))
