import {v4 as uuid} from 'uuid'
import QuestionnairesStatsService from './QuestionnairesStatsService'
import {SurveyAnalytics, SurveyAnalyticsData} from '@fnd/survey'

export class QuestionnairesAnalytics extends SurveyAnalytics {
  constructor(
    private serviceId: string,
    private surveyId: string,
    private rev: number
  ) {
    super(serviceId + ':' + surveyId)
  }

  completeSessionWithReason<R = string | undefined>(demandId: R): void {
    if (demandId) {
      QuestionnairesStatsService.shared().completeLog(
        this.getSession(),
        demandId as string
      )
    }
    this.completeSession()
  }

  completeSession(): void {
    window.sessionStorage.removeItem(this.getStorageKey())
  }

  createSession(): string {
    const sessionId = uuid()
    window.sessionStorage.setItem(this.getStorageKey(), sessionId)
    return sessionId
  }

  getSession(): string {
    const previousSession = window.sessionStorage.getItem(this.getStorageKey())
    if (previousSession) return previousSession
    return this.createSession()
  }

  sendLog(data: SurveyAnalyticsData): void {
    QuestionnairesStatsService.shared().log(data, {
      rev: this.rev,
      questionnaireId: this.surveyId,
      serviceId: this.serviceId
    })
  }
}
