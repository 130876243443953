import React from 'react'
import {Box, Container, Typography} from '@mui/material'

interface HeaderImageContentProps {
  image: string
  title: string
  children?: JSX.Element
}

interface HeaderImageContentState {}

class HeaderImageContent extends React.Component<
  HeaderImageContentProps,
  HeaderImageContentState
> {
  render() {
    const height = window.innerWidth < 600 ? '250px' : '500px'
    return (
      <Box position={'relative'} marginBottom={'13%'}>
        {/* BACKGROUND IMAGE */}
        <Box
          width={'100%'}
          height={height}
          style={{
            backgroundImage: `url(${this.props.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'bottom right',
            backgroundRepeat: 'no-repeat'
          }}
          position={'absolute'}
          left={'0px'}
        />

        <Container maxWidth={'md'} style={{position: 'relative'}}>
          {/* TITLE */}
          <Box padding={'11% 0 13%'} textAlign={'center'}>
            <Typography
              variant={'h5'}
              component={'h1'}
              style={{
                display: 'inline-block',
                background: 'rgba(255, 255, 255, 0.8)',
                padding: '10px 20px',
                borderRadius: '8px'
              }}
            >
              {this.props.title}
            </Typography>
          </Box>

          {/* CONTENT */}
          {this.props.children}
        </Container>
      </Box>
    )
  }
}

export default HeaderImageContent
