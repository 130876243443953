import React, {ChangeEvent} from 'react'
import {IconButton, InputAdornment, TextField} from '@mui/material'
import {Visibility, VisibilityOff} from '@mui/icons-material'
import {WithTranslation, withTranslation} from 'react-i18next'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'

interface AppFormPasswordProps extends AppFormComponent, WithTranslation {}

interface AppFormPasswordState {
  passwordShow: boolean
}

class AppFormPassword extends AppFormInputComponent<
  AppFormPasswordProps,
  AppFormPasswordState
> {
  state: AppFormPasswordState = {
    passwordShow: false
  }

  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const {onChangeCallback, ...rest} = {...this.props}
    const value: string = e.target.value
    this.props.onChangeCallback(rest, value)
  }

  render() {
    let error: string = 'fnd-login-error'
    if (this.props.dynamicError) {
      error = this.props.dynamicError(this.props.value, this.props.validator)
    } else if (this.props.errorText) {
      error = this.props.t(this.props.errorText)
    }
    let label = this.props.label ?? ''
    if (typeof label === 'string') {
      label = this.props.t(label)
    }
    const placeholder = this.props.placeholder
      ? this.props.t(this.props.placeholder)
      : undefined
    const inputLabel = this.props.hasError ? error : label

    return (
      <TextField
        onChange={this.onChange.bind(this)}
        fullWidth
        placeholder={placeholder}
        name={this.props.name}
        id={this.props.id}
        value={this.props.value ?? ''}
        error={this.props.hasError}
        label={inputLabel}
        disabled={this.props.disabled}
        hidden={this.props.hidden}
        type={this.state.passwordShow ? 'text' : 'password'}
        inputProps={{
          dir: 'ltr'
        }}
        InputProps={{
          [this.props.i18n.dir() === 'ltr' ? 'startAdornment' : 'endAdornment']:
            this.props.icon,
          [this.props.i18n.dir() !== 'ltr' ? 'startAdornment' : 'endAdornment']:
            (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() =>
                    this.setState((state) => ({
                      passwordShow: !state.passwordShow
                    }))
                  }
                  aria-label={
                    this.state.passwordShow ? 'Hide Password' : 'Show password'
                  }
                >
                  {this.state.passwordShow ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
        }}
        variant={'outlined'}
      />
    )
  }
}

export default withTranslation()(AppFormPassword)
