import React from 'react'
import {DemandChatPreview, LiveAssignmentData} from 'views/demands/DemandsQuery'
import {
  Badge,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Stack,
  styled,
  Typography
} from '@mui/material'
import {spreadAriaButton} from 'web-common/utilities/utilites'
import AppAvatar from 'web-common/components/avatar/AppAvatar'
import AppRating from 'web-common/components/rating/AppRating'
import AppDate from 'web-common/components/date/AppDate'
import {OfferPreview as JobOfferPreview} from 'web-common/views/timeline/components/OfferPreview'
import {withTranslation, WithTranslation} from 'react-i18next'
import {DemandData} from 'models/Demands'

const StyledCard = styled(Card)({
  animationName: 'fadeInDown',
  animationDuration: '0.4s',
  '&.archived': {
    filter: 'grayscale()'
  }
})

interface OfferProps extends WithTranslation {
  assignment: DemandChatPreview<LiveAssignmentData>
  demand: DemandData
  onSelect: (offerId: string) => void
}

interface OfferState {}

class Offer extends React.Component<OfferProps, OfferState> {
  renderLastOffer() {
    return (
      <Stack spacing={2}>
        <Typography variant={'body1'} component={'span'}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={1}>
              {this.props.assignment.counters.unseenOffer > 0 && (
                <Chip
                  label={
                    <Typography variant={'caption'}>
                      {this.props.t('fnd-demand-offer-list-label-new')}
                    </Typography>
                  }
                  size={'small'}
                  color={'secondary'}
                />
              )}
              <span>
                {this.props.t('fnd-demand-offer-listing-last-offer-date')}
              </span>
            </Stack>
            <AppDate
              timestamp={this.props.assignment.offer!.dateCreated}
              format={'date-time'}
            />
          </Stack>
        </Typography>
        <JobOfferPreview offer={this.props.assignment.offer!} />
      </Stack>
    )
  }

  renderWillSendOfferLater() {
    return (
      <Stack spacing={2}>
        <Typography variant={'body1'} component={'span'}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={1}>
              {this.props.assignment.counters.unseenOffer > 0 && (
                <Chip
                  label={
                    <Typography variant={'caption'}>
                      {this.props.t('fnd-demand-offer-list-label-new')}
                    </Typography>
                  }
                  size={'small'}
                  color={'secondary'}
                />
              )}
              <span>
                {this.props.t(
                  'fnd-demand-offer-list-label-offer-will-be-sent-later'
                )}
              </span>
            </Stack>
            <AppDate
              timestamp={this.props.assignment.offer!.dateCreated}
              format={'date-time'}
            />
          </Stack>
        </Typography>
        <JobOfferPreview offer={this.props.assignment.offer!} />
      </Stack>
    )
  }

  renderNoOfferFromContractor() {
    return (
      <Stack spacing={2}>
        <Typography variant={'body1'} component={'span'}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <span>
              {this.props.t(
                'fnd-demand-assignment-list-item-will-send-offer-later'
              )}
            </span>
            <AppDate
              timestamp={this.props.assignment.dateCreated}
              format={'date-time'}
            />
          </Stack>
        </Typography>
      </Stack>
    )
  }

  renderPreviewCounts() {
    // Offer is archived or canceled
    if (this.props.demand.archived) {
      return (
        <Chip
          label={
            <Typography variant={'caption'}>
              {this.props.t(
                `fnd-badge-${this.props.demand.status.toLowerCase()}`
              )}
            </Typography>
          }
          size={'small'}
          color={'primary'}
        />
      )
    }
    // DEALS & REVIEWS CHIP
    if (this.props.assignment.counters.deals > 0) {
      return (
        <Stack direction={'row'} spacing={1}>
          <Chip
            label={
              <Typography variant={'caption'}>
                {this.props.t(`fnd-badge-deal`)}
              </Typography>
            }
            size={'small'}
            color={'primary'}
          />
          {this.props.assignment.counters.reviews > 0 && (
            <Chip
              label={
                <Typography variant={'caption'}>
                  {this.props.t(`fnd-badge-review`)}
                </Typography>
              }
              size={'small'}
              color={'secondary'}
            />
          )}
        </Stack>
      )
    }
    // LAST OFFER
    if (
      this.props.assignment.counters.offers > 0 &&
      this.props.assignment.offer
    ) {
      return this.renderLastOffer()
    }
    // OFFER WITH NO PRICING REASON
    if (
      this.props.assignment.counters.later > 0 &&
      this.props.assignment.offer
    ) {
      return this.renderWillSendOfferLater()
    }
    // NO OFFER FROM CONTRACTOR
    if (!this.props.assignment.offer) {
      return this.renderNoOfferFromContractor()
    }
    return null
  }

  render() {
    return (
      <StyledCard className={this.props.demand.archived ? 'archived' : ''}>
        <CardActionArea
          {...spreadAriaButton(
            'Open offer',
            this.props.onSelect.bind(this, this.props.assignment._id)
          )}
        >
          <CardHeader
            avatar={
              <Badge
                badgeContent={this.props.assignment.counters.unread}
                color="primary"
                overlap="circular"
                aria-hidden={true}
              >
                <AppAvatar
                  userId={this.props.assignment.contractor!.userId}
                  type={'logo'}
                  url={this.props.assignment.contractor!.picture}
                  key={this.props.assignment._id}
                  size={64}
                />
              </Badge>
            }
            title={
              <Typography variant={'h3'} component={'span'}>
                {this.props.assignment.contractor?.name ?? ''}
              </Typography>
            }
            subheader={
              <AppRating
                rating={
                  this.props.assignment.contractor?.ratingSummary?.rating ?? 0
                }
                count={
                  this.props.assignment.contractor?.ratingSummary?.votes ?? 0
                }
                variant={'small'}
              />
            }
            disableTypography
          />
          <Divider />
          <CardContent>{this.renderPreviewCounts()}</CardContent>
        </CardActionArea>
      </StyledCard>
    )
  }
}

export default withTranslation()(Offer)
