import ImageResizeService, {
  ImageSizes
} from 'web-common/services/ImageResizeService'
import FileStorage from 'web-common/services/files/FileStorage'

export interface DocumentSet {
  link: string
  name: string
}

export interface ImageSet {
  name: string
  thumb: string
  standard: string
}

export abstract class AppFile<T> {
  abstract type: string
  protected readonly metaSeparator = '###'

  constructor(protected file: string | File) {}

  // Get app resource from formatted string
  abstract toResource(): Promise<T>

  // Format string in {name}{separator}{base64} format
  protected formatString(name: string, bs64: string) {
    return `${name}${this.metaSeparator}${bs64}`
  }

  protected isStringValidFormat(file: string) {
    return file.includes(this.metaSeparator)
  }

  // Convert File to formatted string
  toString(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (typeof this.file === 'string') {
        resolve(this.file)
        return
      }
      const name = this.file.name
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.onload = () =>
        resolve(this.formatString(name, reader.result as string))
      reader.onerror = (error) => reject(error)
    })
  }

  upload(path: string, name: string, secured: boolean) {
    return FileStorage.uploadResource(this.file, path, name, secured)
  }
}

export class AppFileGalleryImage extends AppFile<ImageSet> {
  type = 'gallery-image'

  async toResource(): Promise<ImageSet> {
    const file = await this.toString()
    if (this.isStringValidFormat(file)) {
      const index = file.lastIndexOf('###')
      const [fileName, bs64] = [file.slice(0, index), file.slice(index + 3)]
      return {
        name: fileName,
        thumb: await ImageResizeService.resizeBase64(bs64, ImageSizes.thumb),
        standard: await ImageResizeService.resizeBase64(
          bs64,
          ImageSizes.standard
        )
      }
    }
    const imageURL = await FileStorage.getResource(file, true)
    return {
      thumb: imageURL,
      standard: imageURL.replace('%2Fthumb%2F', '%2F'),
      name: imageURL
    }
  }
}

export class AppFileDocument extends AppFile<DocumentSet> {
  type = 'document'

  async toResource(): Promise<DocumentSet> {
    const file = await this.toString()
    if (this.isStringValidFormat(file)) {
      const index = file.lastIndexOf('###')
      const [fileName, bs64] = [file.slice(0, index), file.slice(index + 3)]
      return {
        name: fileName,
        link: bs64
      }
    }
    const fileURL = await FileStorage.getResource(file, true)
    return {
      link: fileURL,
      name: fileURL
    }
  }
}
