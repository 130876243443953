import {TextField} from '@mui/material'
import {useContext} from 'react'
import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementText} from '@fnd/survey'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'

export default function SurveyElementUIText({
  field,
  element
}: SurveyFactoryElementBaseProps<SurveyElementText>) {
  const context = useContext(SRContext)
  return (
    <TextField
      {...field}
      placeholder={element.placeholder?.[context.language]}
      fullWidth
      id={element.name}
    />
  )
}
