export interface ReviewRefsData {
  assignmentId: string
  contractorId: string
  contractorUserId: string
  customerUserId: string | null
  demandId: string
  serviceId: string
}

export interface ReviewData {
  _id: string
  author: string
  receiver: string | null
  canImprove?: string[]
  didWell?: string[]
  comment?: string
  dateCreated: number
  datePublished: number
  dateCompleted: number
  rating: number
  status: ReviewStatusData
  createdBy: string
  _refs: ReviewRefsData
}

export interface RateData {
  rating: RatingType
  comment?: string
  didWell?: string[]
  canImprove?: string[]
}

export enum ReviewStatusData {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  PUBLISHED = 'PUBLISHED'
}

export type RatingType = 1 | 2 | 3 | 4 | 5
