import React from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import DemandsService, {AssignmentData} from 'views/demands/DemandsService'
import {ReviewData} from 'web-common/models/RatingModels'
import {
  HttpParams,
  PageQuery,
  PageQueryOperator
} from 'web-common/services/PageQuery'
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled
} from '@mui/material'
import AppRating from 'web-common/components/rating/AppRating'
import AppReview from 'web-common/views/reviews/AppReview'
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded'
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import Loading from 'web-common/components/loading/Loading'
import AppAvatar from 'web-common/components/avatar/AppAvatar'

const RatingHeader = styled(CardContent)((props) => ({
  background: props.theme.palette.grey['50']
}))

type ListTab = 'all' | 'my'

interface ContractorRatingProps extends WithTranslation {
  assignment: AssignmentData
}

interface ContractorRatingState {
  reviews: ReviewData[]
  myReviews: ReviewData[]
  tab: ListTab
  loading: boolean
}

class ContractorRatingList extends React.Component<
  ContractorRatingProps,
  ContractorRatingState
> {
  state: ContractorRatingState = {
    reviews: [],
    myReviews: [],
    loading: true,
    tab: 'all'
  }
  limit = 20
  offset = 0
  reviews: PageQuery<ReviewData> = new PageQuery<ReviewData>({
    path: DemandsService.getFindPathReviews(
      this.props.assignment.contractor._id
    ),
    params: new HttpParams().set('limit', this.limit.toString())
  })

  async componentDidMount() {
    const my = await DemandsService.getContractorMyReviews(
      this.props.assignment.contractor._id
    )
    this.reviews.subscribe((data) => {
      this.setState((state) => ({
        reviews: state.reviews.concat(data),
        myReviews: my.data,
        loading: false
      }))
    })
    this.reviews.fetch()
  }

  switchTab(event: React.SyntheticEvent, tab: ListTab) {
    this.setState({tab: tab})
  }

  loadMore() {
    this.setState({loading: true}, () => {
      this.reviews.setFilters(
        PageQueryOperator.SET,
        {
          offset: ++this.offset * this.limit,
          limit: this.limit
        },
        true
      )
    })
  }

  renderRating() {
    const rating = this.props.assignment.contractor.ratingSummary?.rating ?? 0
    const reviews = this.props.assignment.contractor.ratingSummary?.votes ?? 0
    const list =
      this.state.tab === 'my' ? this.state.myReviews : this.state.reviews
    return (
      <Stack spacing={2} alignItems={'center'}>
        <AppAvatar
          url={this.props.assignment.contractor.picture}
          size={128}
          userId={this.props.assignment.contractor.userId}
          type={'logo'}
        />
        <Typography variant={'h1'} component={'div'}>
          {this.props.assignment.contractor.name}
        </Typography>
        <Card style={{width: '100%'}}>
          {/* SUMMARY RATING */}
          <RatingHeader>
            <AppRating rating={rating} count={reviews} variant={'large'} />
          </RatingHeader>

          <Divider />

          <CardContent>
            <Stack spacing={3}>
              {/* ALL | MY RATING */}
              <Tabs value={this.state.tab} onChange={this.switchTab.bind(this)}>
                <Tab
                  label={this.props.t('fnd-label-all-reviews')}
                  value={'all'}
                  id={'all'}
                />
                <Tab
                  label={this.props.t('fnd-label-my-reviews')}
                  value={'my'}
                  id={'my'}
                />
              </Tabs>

              {/* RATING LIST */}
              <Stack spacing={2} divider={<Divider />}>
                {list.map((review) => (
                  <AppReview
                    review={review}
                    key={review._id}
                    isMyReview={this.state.tab === 'my'}
                    detailed={this.state.tab === 'my'}
                  />
                ))}
              </Stack>

              {/* SHOW LOADING SCREEN */}
              {(() => {
                if (this.state.loading) {
                  return (
                    <Box textAlign={'center'}>
                      <CircularProgress size={20} />
                    </Box>
                  )
                }
              })()}

              {/* LOAD MORE REVIEWS */}
              {(() => {
                if (
                  this.state.tab === 'all' &&
                  (this.reviews.data?.offset ?? 0) +
                    (this.reviews.data?.data.length ?? 0) <
                    (this.reviews.data?.found ?? 0)
                ) {
                  return (
                    <Box
                      style={{cursor: 'pointer'}}
                      fontSize={'14px'}
                      onClick={this.loadMore.bind(this)}
                      marginTop={'32px'}
                      marginBottom={'10px'}
                    >
                      {this.props.t('fnd-label-more-reviews')}
                      <Box
                        fontSize={'18px'}
                        display={'inline'}
                        position={'relative'}
                        top={'2px'}
                        left={'4px'}
                        right={'4px'}
                      >
                        {((_) => {
                          return this.props.i18n.dir() === 'ltr' ? (
                            <ChevronRightRoundedIcon fontSize={'inherit'} />
                          ) : (
                            <ChevronLeftRoundedIcon fontSize={'inherit'} />
                          )
                        })()}
                      </Box>
                    </Box>
                  )
                }
              })()}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    )
  }

  renderEmptyRating() {
    return (
      <Typography variant={'subtitle1'}>
        {this.props.t('fnd-contractor-has-no-reviews')}
      </Typography>
    )
  }

  render() {
    return (
      <Loading
        loading={this.state.loading}
        render={() => {
          return this.props.assignment.contractor.ratingSummary
            ? this.renderRating()
            : this.renderEmptyRating()
        }}
      />
    )
  }
}

export default withTranslation()(ContractorRatingList)
