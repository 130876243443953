import Grid from '@mui/material/Grid'
import React, {ReactNode} from 'react'
import {Divider, Paper, styled, Typography} from '@mui/material'

const NavigationGrid = styled(Grid)({
  padding: '0.75rem 0',
  '& > .MuiGrid-item': {
    display: 'flex'
  }
})
const NavigationLeftItem = styled(Grid)({
  justifyContent: 'flex-start'
})
const NavigationMiddleItem = styled(Grid)({
  justifyContent: 'center',
  flex: '1',
  minWidth: '0'
})
const NavigationRightItem = styled(Grid)({
  justifyContent: 'flex-end'
})
const NavigationTitle = styled(Typography)(({theme}) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('md')]: {
    // MOBILE
    fontSize: '1.75rem'
  }
}))
const NavigationWrapper = styled(Paper)(({theme}) => ({
  background: 'transparent',
  borderRadius: '0px',
  padding: '0 1rem',
  margin: '0 -1rem 2rem -1rem',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    // DESKTOP
    boxShadow: 'none'
  },
  [theme.breakpoints.down('md')]: {
    // MOBILE
  }
}))
const CustomDivider = styled(Divider)({
  position: 'absolute',
  bottom: '0px',
  left: 'calc(50% - 50vw)',
  height: '2px',
  width: '100vw'
})

export interface NavigationProps extends React.ComponentProps<any> {
  left?: ReactNode
  middle?: ReactNode
  right?: ReactNode
}

const Navigation: React.FC<NavigationProps> = (props: NavigationProps) => {
  return (
    <nav title={typeof props.middle === 'string' ? props.middle : undefined}>
      <NavigationWrapper>
        <NavigationGrid
          container
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap={'nowrap'}
          columnSpacing={3}
        >
          {/* LEFT */}
          {(props.left || props.right) && (
            <NavigationLeftItem item xs={'auto'} sm={2}>
              {typeof props.left === 'boolean' ? '' : props.left}
            </NavigationLeftItem>
          )}

          {/* MIDDLE */}
          {props.middle && (
            <NavigationMiddleItem item xs={'auto'}>
              {typeof props.middle === 'string' && (
                <NavigationTitle variant={'h1'} color={'textSecondary'}>
                  {props.middle}
                </NavigationTitle>
              )}
              {typeof props.middle !== 'string' && props.middle}
            </NavigationMiddleItem>
          )}

          {/* RIGHT */}
          {(props.left || props.right) && (
            <NavigationRightItem item xs={'auto'} sm={2}>
              {typeof props.right === 'boolean' ? '' : props.right}
            </NavigationRightItem>
          )}
        </NavigationGrid>
        <CustomDivider />
      </NavigationWrapper>
    </nav>
  )
}

export default Navigation
