import React, {ChangeEvent} from 'react'
import {
  Box,
  Button,
  CircularProgress,
  OutlinedInput,
  Paper,
  Stack,
  styled
} from '@mui/material'
import SendRoundedIcon from '@mui/icons-material/SendRounded'
import {TimelineViewConfig} from 'web-common/views/timeline/TimelineViewConfig'
import {
  TimelineAction,
  TimelineActionManager,
  TimelineEventProducer
} from '@fnd/timeline'

const InputWrapper = styled(Paper)((props) => ({
  padding: '1rem',
  background: props.theme.palette.grey[100],
  display: 'flex',
  flexGrow: 0,
  border: `1px solid ${props.theme.palette.grey[300]}`
}))
const ActionsWrapper = styled(Box)((props) => ({
  padding: '0.5rem 0',
  background: props.theme.palette.common.white,
  borderTop: `1px solid ${props.theme.palette.grey[300]}`
}))
const MessageField = styled(OutlinedInput)((props) => ({
  width: 'calc(100% - 60px)',
  background: props.theme.palette.common.white,
  transition: '-webkit-text-fill-color 0.5s',
  '&.MuiInputBase-root': {
    transition: 'background 0.5s'
  },
  '&.Mui-disabled': {
    background: props.theme.palette.action.disabledBackground,
    cursor: 'not-allowed'
  },
  'textarea.Mui-disabled': {
    cursor: 'not-allowed',
    webkitTextFillColor: props.theme.palette.common.black
  }
}))

const SendRoundedIconWrapper = styled(SendRoundedIcon)({
  'body[dir=rtl] &': {
    transform: 'scale(-1, 1)'
  }
})

interface TimelineMessageCreatorProps {
  actionManager: TimelineActionManager
  producer: TimelineEventProducer
  config: TimelineViewConfig
}

interface TimelineMessageCreatorState {
  message: string
  sending: boolean
  disabled: boolean
  actions: TimelineAction<any>[]
}

class TimelineMessageCreator extends React.Component<
  TimelineMessageCreatorProps,
  TimelineMessageCreatorState
> {
  state: TimelineMessageCreatorState = {
    message: '',
    disabled: false,
    sending: false,
    actions: []
  }
  ref = React.createRef<HTMLInputElement>()
  inputSending: boolean = false

  componentDidMount() {
    this.props.actionManager.subscribe((actions) => {
      this.setState({actions: actions})
    })
    this.props.actionManager.settings((settings) => {
      this.setState({disabled: settings.disabled})
    })
  }

  onSendMessage() {
    if (this.inputSending) {
      return
    }
    if (this.state.message.trim() === '') {
      return
    }
    this.inputSending = true
    this.setState({sending: true}, async () => {
      await this.props.producer.createEvent({
        message: this.state.message.trim(),
        adapterId: 'message'
      })
      this.setState({message: '', sending: false}, () => {
        this.ref.current?.focus()
        this.inputSending = false
      })
    })
  }

  onUpdateMessage(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.setState({message: e.target.value})
  }

  onMessageEnter(e: React.KeyboardEvent) {
    if (e.key === 'Enter' && !e.shiftKey) {
      this.onSendMessage()
      e.preventDefault()
    }
  }

  renderMessageController() {
    return (
      <InputWrapper variant={'outlined'}>
        <Stack
          flexDirection={'row'}
          alignItems={'stretch'}
          style={{width: '100%'}}
        >
          <MessageField
            size={'medium'}
            value={this.state.message}
            placeholder={
              this.state.disabled ? 'Chat is disabled' : 'Type your message'
            }
            multiline
            maxRows={2}
            autoFocus={this.props.config.accessibility.messageCreator.autoFocus}
            inputProps={{ref: this.ref}}
            disabled={this.state.sending || this.state.disabled}
            onChange={this.onUpdateMessage.bind(this)}
            onKeyPress={this.onMessageEnter.bind(this)}
          />
          <Button
            aria-label={'Send the message'}
            variant={'text'}
            size={'small'}
            color={'primary'}
            disabled={this.state.sending || this.state.disabled}
            onClick={this.onSendMessage.bind(this)}
          >
            {this.state.sending && <CircularProgress />}
            {!this.state.sending && <SendRoundedIconWrapper />}
          </Button>
        </Stack>
      </InputWrapper>
    )
  }

  renderActionController() {
    if (this.state.actions.length > 0) {
      return (
        <ActionsWrapper>
          <Stack flexDirection={'row'} justifyContent={'center'} columnGap={1}>
            {this.state.actions.map((a) => {
              const El = a.node
              return (
                <El {...a.props} producer={this.props.producer} key={a.id} />
              )
            })}
          </Stack>
        </ActionsWrapper>
      )
    }
    return null
  }

  render() {
    return (
      <>
        {this.renderActionController()}
        {this.renderMessageController()}
      </>
    )
  }
}

export default TimelineMessageCreator
