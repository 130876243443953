import React, {ChangeEvent} from 'react'
import {Input} from '@mui/material'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'

interface AppFormTextProps extends AppFormComponent {}

export default class AppFormHidden extends AppFormInputComponent<
  AppFormTextProps,
  any
> {
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {}

  render() {
    return (
      <Input
        value={this.props.value ?? ''}
        name={this.props.name}
        id={this.props.id}
        type={'hidden'}
      />
    )
  }
}
