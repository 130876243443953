import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementFileUploader} from '@fnd/survey'
import FileUploaderWrapper from 'web-common/components/survey/ui/elements/helpers/FileUploadereWrapper'

export default function SurveyElementUIFileUploader({
  field,
  element
}: SurveyFactoryElementBaseProps<SurveyElementFileUploader>) {
  return <FileUploaderWrapper {...field} element={element} />
}
