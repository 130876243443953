import MapGeometryService from 'web-common/components/location/MapGeometryService'
import {AppConfig} from 'AppConfig'

export interface LocationPickerValue {
  lat: number
  lon: number
  address: string
  regions?: string[]
  range?: number
}

export type OnLocationChange = (
  location: {lat: number; lon: number},
  address: string,
  regions?: string[],
  range?: number
) => void
export type OnInvalidLocation = () => void

class LocationPickerService {
  readonly boundPadding = 50

  private readonly defaultMapOptions: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    panControl: false,
    rotateControl: false,
    scaleControl: false
  }
  private readonly defaultAutocompleteOptions: google.maps.places.AutocompleteOptions =
    {
      types: ['geocode'],
      componentRestrictions: {country: 'il'}
    }
  private readonly map: google.maps.Map
  private marker?: google.maps.marker.AdvancedMarkerElement
  private range?: google.maps.Circle
  private place?: google.maps.places.PlaceResult
  private regions?: string[]
  private geoService: MapGeometryService = MapGeometryService.shared()
  private autoCompleteRef?: HTMLDivElement
  private rangeSliderRef?: HTMLDivElement

  constructor(
    wrapper: HTMLDivElement,
    mapConfig: Partial<google.maps.MapOptions>,
    private cb: {
      onChange: OnLocationChange
      onError: OnInvalidLocation
      onReady: () => void
    },
    private strategy: 'location' | 'range'
  ) {
    this.map = new google.maps.Map(wrapper, {
      ...this.defaultMapOptions,
      ...mapConfig,
      mapId: AppConfig.googleMapsMapId
    })

    this.map.addListener('tilesloaded', () => {
      this.cb.onReady()
      google.maps.event.clearListeners(this.map, 'tilesloaded')
    })
  }

  async loadValue(value: LocationPickerValue) {
    await this.createPlace({lat: value.lat, lng: value.lon})
    this.createMarker({lat: value.lat, lng: value.lon}, true)
    await this.updateMarker()
    if (value.range && this.strategy === 'range') {
      this.createRange(value.range)
    }
    this.centerMap()
  }

  createAutocomplete(
    node: HTMLDivElement,
    opt: Partial<google.maps.places.AutocompleteOptions>
  ) {
    const wrapper = node.cloneNode(true) as HTMLDivElement
    const input = wrapper.querySelector('input')!
    this.autoCompleteRef = wrapper
    // DO NOT SUBMIT FORMS ON ENTER
    input.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
    })
    const autocomplete = new google.maps.places.Autocomplete(input, {
      ...this.defaultAutocompleteOptions,
      ...opt
    })
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(wrapper)
    this.createMarker(this.map.getCenter()!.toJSON())
    return autocomplete.addListener('place_changed', async () => {
      this.place = autocomplete.getPlace()
      await this.updateMarker()
      if (this.shouldChange()) {
        this.onChange()
        this.centerMap()
      }
    })
  }

  createRangeSlider(node: HTMLDivElement) {
    this.rangeSliderRef = node
    const input: HTMLInputElement = node.querySelector('input')!
    this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(node)
    node.style.display = 'none'
    node.style.opacity = '0'
    this.createRange(0)
    const self = this

    Object.defineProperty(input, 'value', {
      get() {
        return this.proxyValue
      },
      set(v: string) {
        this.proxyValue = v
        self.updateRange(parseInt(v)).then()
      }
    })
  }

  private centerMap() {
    if (this.shouldEnableSlider()) {
      this.enableSlider()
    }
    const markerCenter = this.marker?.position
    const rangeBounds = this.strategy === 'range' && this.range?.getBounds()
    if (markerCenter) {
      this.map.setCenter(markerCenter)
    }
    if (rangeBounds) {
      this.map.fitBounds(rangeBounds, this.boundPadding)
    }
  }

  private async updateMarker() {
    this.marker!.position = undefined
    if (
      !this.place ||
      !this.place?.geometry ||
      !this.place?.geometry?.location
    ) {
      this.cb.onError()
      return
    }
    this.regions = await this.extractRegions()
    if (this.regions.length === 0) {
      this.cb.onError()
      return
    }
    if (this.place.geometry.viewport) {
      this.map.fitBounds(this.place.geometry.viewport, this.boundPadding)
    } else {
      this.map.setCenter(this.place.geometry.location)
      this.map.setZoom(17)
    }
    this.marker!.position = this.place.geometry.location
    this.range?.setCenter(this.marker!.position)
  }

  private async updateRange(radius: number) {
    if (
      this.strategy !== 'range' ||
      !this.range ||
      !this.marker ||
      !this.place
    ) {
      return
    }
    this.range.setRadius((radius || 0.001) * 1000)
    this.range.setCenter(this.marker.position!)
    this.regions = await this.extractRegions()
    if (this.shouldChange()) {
      this.onChange()
      this.centerMap()
    }
  }

  private async extractRegions(): Promise<string[]> {
    if (this.strategy === 'location') {
      return await this.geoService
        .getRegionsFromLocation(this.place!.geometry!.location!)
        .catch((_) => [])
    } else if (this.strategy === 'range' && this.range) {
      return await this.geoService
        .getRegionsFromLocation(
          this.place!.geometry!.location!,
          this.range.getRadius()
        )
        .catch((_) => [])
    }
    return []
  }

  private createPlace(location: any): Promise<void> {
    return new Promise((resolve) => {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({location: location}, (results, status) => {
        if (status === 'OK' && results && results[0]) {
          this.place = results[0] as unknown as google.maps.places.PlaceResult
          resolve()
        }
      })
    })
  }

  private createMarker(
    location: {lat: number; lng: number},
    visible: boolean = false
  ) {
    this.marker = new google.maps.marker.AdvancedMarkerElement({
      map: this.map,
      position: visible ? location : undefined
    })
  }

  private createRange(range: number) {
    this.range = new google.maps.Circle({
      map: this.map,
      radius: range,
      fillColor: '#AA0000',
      center: this.marker?.position
    })
  }

  private shouldChange() {
    if (!this.place) {
      return false
    }
    if ((this.regions?.length ?? 0) === 0) {
      return false
    }
    if (this.strategy === 'location') {
      return true
    }
    return !!(this.strategy === 'range' && this.range)
  }

  private onChange() {
    // backend-a raboti samo s lon
    const nenormalenWorkAround = this.place!.geometry!.location!.toJSON()
    this.cb.onChange(
      {
        lat: nenormalenWorkAround.lat,
        lon: nenormalenWorkAround.lng
      },
      this.place!.formatted_address ?? '',
      this.regions ?? [],
      this.range?.getRadius()
    )
  }

  private shouldEnableSlider() {
    return this.strategy === 'range' && this.rangeSliderRef
  }

  private enableSlider() {
    this.rangeSliderRef!.style.display = 'block'
    this.rangeSliderRef!.style.opacity = '1'
  }
}

export default LocationPickerService
