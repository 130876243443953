import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementDropdown} from '@fnd/survey'
import DropdownWrapper from 'web-common/components/survey/ui/elements/helpers/DropdownWrapper'

export default function SurveyElementUIDropdown({
  field,
  element
}: SurveyFactoryElementBaseProps<SurveyElementDropdown>) {
  return (
    <DropdownWrapper
      {...field}
      placeholder={element.placeholder}
      choices={element.choices ?? []}
      multiple={element.multiple}
      searchable={element.searchable}
    />
  )
}
