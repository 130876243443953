import React from 'react'

interface AppPhoneProps {
  value?: string | null
}

interface AppPhoneState {}

class AppPhone extends React.Component<AppPhoneProps, AppPhoneState> {
  countryCode = '972'
  separator = '-'

  render() {
    const s = this.separator
    return (this.props.value ?? '')
      .replace(new RegExp(`^${972}`), '0')
      .replace(/^(\d{3})(\d{3})(\d{4})$/, `$1${s}$2${s}$3`)
  }
}

export default AppPhone
