import React from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Grid, styled, Typography} from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import Hidden from '@mui/material/Hidden'
import operator from './assets/pose-5-w-250.png'
import operator2x from './assets/pose-5-w-250@2x.png'
import operator3x from './assets/pose-5-w-250@3x.png'
import {getWhatsAppLink} from 'web-common/utilities/whatsappUtilities'

const MainForm = styled(Grid)((props) => ({
  textAlign: 'start',
  [props.theme.breakpoints.down('xs')]: {
    textAlign: 'center'
  }
}))

const ButtonContainer = styled(Grid)((props) => ({
  justifyContent: 'start',
  [props.theme.breakpoints.down('xs')]: {
    justifyContent: 'center'
  }
}))

export default function ContactUsView() {
  const [t] = useTranslation()
  const emailURL =
    'mailto:support@finderella.co.il?subject=' +
    t('common:fnd-whatsapp-contact-us-email-subject')
  const chatURL = getWhatsAppLink('common:fnd-whatsapp-contact-us-title')
  return (
    <MainForm container direction={'row'}>
      {/* IMAGE */}
      <Grid item xs={12} sm={3}>
        <img
          src={operator}
          alt={''}
          srcSet={`${operator3x} 3x, ${operator2x} 2x, ${operator} 1x`}
        />
      </Grid>

      {/* FORM */}
      <Grid item xs={12} sm={9}>
        <Typography variant={'body1'} id={'contactus'}>
          {t('common:fnd-if-you-need-help-contact-us')}
        </Typography>
        <br />
        {/* BUTTON GRID */}
        <ButtonContainer container spacing={2} alignItems={'center'}>
          {/* EMAIL */}
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              target={'_blank'}
              href={emailURL}
              startIcon={<EmailIcon />}
              aria-describedby="contactus"
            >
              {t('common:fnd-label-email')}
            </Button>
          </Grid>

          {/* OR LABEL */}
          <Hidden xsDown>
            <Grid item>
              <Typography variant={'caption'}>
                {t('common:fnd-label-or')}
              </Typography>
            </Grid>
          </Hidden>

          {/* WHATSAPP */}
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              target={'_blank'}
              href={chatURL}
              startIcon={<WhatsAppIcon />}
              aria-describedby="contactus"
            >
              {t('common:fnd-label-chat')}
            </Button>
          </Grid>
        </ButtonContainer>
      </Grid>
    </MainForm>
  )
}
