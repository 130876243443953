import React, {useContext} from 'react'
import {styled, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {AppContext} from 'web-common/contexts/AppContext'

interface QuoteProps {
  text: string
}

export default function Quote({text}: QuoteProps) {
  const [, i18n] = useTranslation()
  const appContext = useContext(AppContext)
  const Quote = styled('blockquote')((_) => ({
    fontStyle: 'italic',
    padding: '0.625rem 1.25rem',
    margin: '0'
  }))
  const style = `5px solid ${appContext.theme.palette.primary.main}`
  const key = i18n.dir() === 'ltr' ? 'borderLeft' : 'borderRight'
  return (
    <Quote style={{[key]: style}}>
      <Typography variant={'caption'}>{text}</Typography>
    </Quote>
  )
}
