import React from 'react'
import {withTranslation, WithTranslation} from 'react-i18next'
import {withAppContext, WithAppContext} from 'web-common/contexts/AppContext'
import {withUserContext, WithUserContext} from 'web-common/contexts/UserContext'
import {AppConfig} from 'AppConfig'
import withRouter, {RouteComponentProps} from './router'

export const AppMetaService = {
  defaultTitle: AppConfig.appName,
  setTitle: function (title?: string) {
    if (title && title !== '') {
      document.title = `${this.defaultTitle} | ${title}`
    }
  }
}

// Default props
export interface AppPageProps<R>
  extends RouteComponentProps<R>,
    WithTranslation,
    WithAppContext,
    WithUserContext {}

// AppPage default props + inherit class props
export type AppPageType<T, R = any> = T & AppPageProps<R>

/**
 * Create React Class Component that extend translation, router, app & user.
 */
export default abstract class AppPage<P, S, R = any> extends React.Component<
  AppPageType<P, R>,
  S
> {
  // Required field for page title
  abstract title: string

  // Optional path variable for more consistent code
  static path?: string

  // When component load change the page title
  componentDidMount() {
    this.updateTitle()
  }

  // Update title of the page
  protected updateTitle() {
    AppMetaService.setTitle(this.title)
    this.props.setTitle(this.title)
  }

  // Default export page with HOC: translation, router, app & user
  static exportDefault<P, R>(this: React.ComponentClass<AppPageType<P, R>>) {
    return withAppPage(
      (this as any).getPath.bind(this),
      withTranslation()(withRouter(withUserContext(withAppContext(this))))
    )
  }

  /**
   * Replace all params from the path with the given arguments
   * If the first argument is a boolean type and its value is equal to true, it will remove all optional params from path
   * @param args
   */
  static getPath(...args: string[] | boolean[]): string {
    let rawPath: string = this.path ?? ''
    if (args.length > 0) {
      if (typeof args[0] === 'boolean' && args[0]) {
        rawPath = rawPath.replace(/(\/:\w+\?)/gi, '')
      } else {
        rawPath = rawPath.replace(/\/:\w+/gi, () => '/' + (args.shift() ?? ''))
        rawPath = rawPath.replace(/(\/:\w+\?)/gi, '')
      }
    }
    return rawPath
  }
}

function withAppPage<T>(
  path: (...args: string[] | boolean[]) => string,
  WrappedComponent: React.ComponentType<T>
) {
  return class extends React.Component<T> {
    /**
     * Exported from AppPage.getPath
     * @param args
     * @see AppPage.getPath
     */
    static getPath(...args: string[] | boolean[]): string {
      return path(...args)
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}
