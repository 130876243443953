import * as Survey from 'survey-react'
import widgetReactBudget from 'web-common/surveyjs-customization/budget'
import widgetReactText from 'web-common/surveyjs-customization/input'
import widgetReactRadio from 'web-common/surveyjs-customization/radio'
import widgetReactDropdown from 'web-common/surveyjs-customization/dropdown'
import widgetReactCheckbox from 'web-common/surveyjs-customization/checkbox'
import widgetReactRegion from 'web-common/surveyjs-customization/region'
import widgetReactRegions from 'web-common/surveyjs-customization/regions'
import widgetReactDatepicker from 'web-common/surveyjs-customization/datepicker'
import widgetFileUpload from 'web-common/surveyjs-customization/image-upload'

const SurveyApplyMaterialUI = () => {
  Survey.CustomWidgetCollection.Instance.addCustomWidget(
    widgetReactBudget(Survey),
    'customtype'
  )
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widgetReactText)
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widgetReactRadio)
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widgetReactDropdown)
  Survey.CustomWidgetCollection.Instance.addCustomWidget(widgetReactCheckbox)
  Survey.CustomWidgetCollection.Instance.addCustomWidget(
    widgetReactRegion(Survey),
    'customtype'
  )
  Survey.CustomWidgetCollection.Instance.addCustomWidget(
    widgetReactRegions(Survey),
    'customtype'
  )
  Survey.CustomWidgetCollection.Instance.addCustomWidget(
    widgetFileUpload(Survey),
    'customtype'
  )
  Survey.CustomWidgetCollection.Instance.addCustomWidget(
    widgetReactDatepicker(Survey),
    'customtype'
  )
}

Survey.Serializer.addProperty('questionbase', {
  name: 'filter',
  type: 'boolean',
  default: false,
  category: 'general'
})
Survey.Serializer.addProperty('questionbase', {
  name: 'filterType',
  type: 'string',
  category: 'general'
})
Survey.Serializer.addProperty('selectbase', {
  name: 'goToNextPage',
  type: 'value',
  category: 'general'
})

export default SurveyApplyMaterialUI
