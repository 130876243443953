import {
  CategoryData,
  SearchableService,
  ServiceData,
  ServiceMatchConfig,
  ServiceMatchScore
} from 'web-common/models/CategoriesAndServices'

class SearchService {
  /**
   * Get score from service matchConfig and words. Method will return score only if every word in query is matched
   * @param matchConfig
   * @param query
   * @private
   */
  private getScore(
    matchConfig: ServiceMatchConfig[],
    query: string
  ): ServiceMatchScore[] {
    // Check if every word from query has at least one match
    const counter: {[word: string]: number} = {}
    const words = query.split(' ')
    const results = words
      .flatMap((word) => {
        return matchConfig.map((cfg) => {
          const match = cfg.index.match(
            new RegExp(`(^|\\s|-)${word}[^\\s\\-]*(?=-|\\s|$)`, 'i')
          )
          if (!counter[word]) {
            counter[word] = match?.[0] ? 1 : 0
          }
          return {
            word: match?.[0] ?? '',
            score: match?.[0] ? cfg.weight : 0
          }
        })
      })
      .filter((r) => r.score > 0)
    const validator = Object.values(counter).reduce((a, c) => a + c, 0)
    return validator === words.length ? results : []
  }

  search(
    forQuery: string,
    inList: SearchableService[],
    onMatch?: (serviceId: string, match: ServiceMatchScore[]) => void
  ): SearchableService[] {
    const score: {[key: string]: number} = {}
    return inList
      .filter((item) => {
        const matches = this.getScore(item.matchesOn, forQuery)
        const totalScore = matches
          .map((m) => m.score)
          .reduce((a, c) => a + c, 0)
        score[item.service.id] = totalScore
        if (totalScore > 0) {
          onMatch?.call(this, item.service.id, matches)
        }
        return totalScore > 0
      })
      .sort((first, _) => (first.active ? -1 : 1))
      .sort(
        (first, second) => score[second.service.id] - score[first.service.id]
      )
      .splice(0, 15)
  }

  // Extract service name in all available languages
  extractAllName(item: ServiceData): string {
    const separator = '-'
    const names: string[] = [item.name]
    for (const key in item.l10n) {
      if (item.l10n.hasOwnProperty(key)) {
        names.push(item.l10n[key].name)
      }
    }
    return names.join(separator)
  }

  // Extract service key words in all available languages
  extractAllKeywords(item: ServiceData) {
    const separator = '-'
    let words: string[] = []
    for (const key in item.l10n) {
      if (item.l10n.hasOwnProperty(key)) {
        words = words.concat(item.l10n[key].words)
      }
    }
    return words.join(separator)
  }

  // Get the name of the service/categories for current lang
  extractNameString(
    item: ServiceData | CategoryData,
    forLanguage: string,
    fallback: string = 'he'
  ): string {
    if (item.l10n?.[forLanguage]?.name) {
      return item.l10n?.[forLanguage]?.name
    } else if (item.l10n?.[fallback]?.name) {
      return item.l10n?.[fallback]?.name
    }
    return item.name
  }

  // Convert Category tree to SearchableService list
  categoriesToSearchable(
    data: CategoryData,
    language: string,
    fallbackLanguage: string = 'he'
  ): SearchableService[] {
    const categories: SearchableService[] = []
    const craw = (children: CategoryData[], parent: CategoryData) => {
      ;(children ?? []).forEach((category) => {
        ;(category.services ?? []).forEach((service) => {
          categories.push({
            service: {
              id: service._id,
              name: this.extractNameString(service, language, fallbackLanguage)
            },
            category: {
              id: category._id,
              name: this.extractNameString(category, language, fallbackLanguage)
            },
            parentCategory: {
              id: parent._id,
              name: this.extractNameString(parent, language, fallbackLanguage)
            },
            matchesOn: [
              {
                index: this.extractAllName(service),
                weight: 10
              },
              {
                index: this.extractAllKeywords(service),
                weight: 1
              }
            ],
            active: service.active
          })
        })
        craw(category.children, category)
      })
    }
    craw(data.children, data)
    return categories
  }
}

const searchService = new SearchService()
export default searchService
