import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import {Button} from '@mui/material'
import {Cropper} from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import CircularProgress from '@mui/material/CircularProgress'
import {withTranslation, WithTranslation} from 'react-i18next'
import ImageResizeService, {
  ImageSize,
  ImageSizes
} from 'web-common/services/ImageResizeService'

interface AppCropperProps extends WithTranslation {
  src: string
  size: ImageSize
  onCrop: (imageBase64: string) => void
  onCancel?: () => void
  type?: 'image/jpg' | 'image/png'
}

interface AppCropperState {
  ready: boolean
  cropInstance?: Cropper
  progress: boolean
}

class AppCropper extends React.Component<AppCropperProps, AppCropperState> {
  state: AppCropperState = {
    ready: false,
    progress: false
  }
  imagePreview = {
    maxWidth: window.innerWidth - 48,
    maxHeight: window.innerHeight - 200
  }
  inRequest = false

  async getCroppedImg() {
    if (this.inRequest) {
      return
    }
    this.inRequest = true
    this.setState({progress: true}, async () => {
      const rawBase64Image = this.state.cropInstance
        ?.getCroppedCanvas({
          fillColor: '#000',
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high'
        })
        ?.toDataURL()

      if (!rawBase64Image) {
        this.setState({progress: false}, () => {
          alert('Unexpected error')
          this.inRequest = false
        })
        return
      }

      const base64Image = await ImageResizeService.resizeBase64(
        rawBase64Image!,
        this.props.size,
        this.props.type ?? 'image/jpg'
      )
      this.setState({progress: false}, () => {
        this.inRequest = false
        this.props.onCrop(base64Image)
      })
    })
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            bgcolor={'#000'}
            display={'flex'}
            justifyContent={'center'}
            position={'relative'}
          >
            <Cropper
              style={{
                height: '100%',
                width: '100%',
                maxWidth: ImageSizes.standard.w,
                maxHeight: ImageSizes.standard.h
              }}
              initialAspectRatio={this.props.size.w / this.props.size.h}
              aspectRatio={this.props.size.w / this.props.size.h}
              src={this.props.src}
              dragMode={'move'}
              movable={true}
              viewMode={2}
              guides={true}
              zoomable={false}
              cropBoxMovable={true}
              cropBoxResizable={true}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance: any) =>
                this.setState({cropInstance: instance})
              }
            />
            {((_) => {
              if (this.state.progress) {
                return (
                  <Box
                    position={'absolute'}
                    width={'100%'}
                    height={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    bgcolor={'rgba(0,0,0,0.5)'}
                  >
                    <CircularProgress />
                  </Box>
                )
              }
            })()}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={'center'} spacing={1}>
            {/* CANCEL */}
            {this.props.onCancel && (
              <Grid item>
                <Button
                  color={'primary'}
                  variant={'text'}
                  onClick={this.props.onCancel.bind(this)}
                >
                  {this.props.t('common:fnd-common-cancel')}
                </Button>
              </Grid>
            )}

            {/* SAVE */}
            <Grid item>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={this.getCroppedImg.bind(this)}
                disabled={this.state.progress}
              >
                {this.props.t('common:fnd-common-crop')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation()(AppCropper)
