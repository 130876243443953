interface Env {
  REACT_APP_PRODUCT_NAME: string | undefined
  REACT_APP_WEB_DEPLOYMENT: string | undefined
  REACT_APP_API_PATH: string | undefined
  REACT_APP_API_NOTIFICATIONS: string | undefined
  REACT_APP_AUTH_SERVER_URL: string | undefined
  REACT_APP_AUTH_REALM: string | undefined
  REACT_APP_AUTH_CLIENT_ID: string | undefined
  REACT_APP_CUSTOMER_PATH: string | undefined
  REACT_APP_CONTRACTOR_URL: string | undefined
  REACT_APP_WHATSAPP_PATH: string | undefined
  REACT_APP_WHATSAPP_PHONE: string | undefined
  REACT_APP_WS_HOST: string | undefined
  REACT_APP_CHAT_BOT_PATH: string | undefined
  REACT_APP_ADMIN_CUSTOMER_PATH: string | undefined
  REACT_APP_STORAGE_API: string | undefined
}

declare global {
  interface Window {
    env: Env
  }
}

const AppEnv: Env = {
  REACT_APP_PRODUCT_NAME:
    window.env?.REACT_APP_PRODUCT_NAME ?? process.env['REACT_APP_PRODUCT_NAME'],
  REACT_APP_WEB_DEPLOYMENT:
    window.env?.REACT_APP_WEB_DEPLOYMENT ??
    process.env['REACT_APP_WEB_DEPLOYMENT'],
  REACT_APP_API_PATH:
    window.env?.REACT_APP_API_PATH ?? process.env['REACT_APP_API_PATH'],
  REACT_APP_API_NOTIFICATIONS:
    window.env?.REACT_APP_API_NOTIFICATIONS ??
    process.env['REACT_APP_API_NOTIFICATIONS'],
  REACT_APP_AUTH_SERVER_URL:
    window.env?.REACT_APP_AUTH_SERVER_URL ??
    process.env['REACT_APP_AUTH_SERVER_URL'],
  REACT_APP_AUTH_REALM:
    window.env?.REACT_APP_AUTH_REALM ?? process.env['REACT_APP_AUTH_REALM'],
  REACT_APP_AUTH_CLIENT_ID:
    window.env?.REACT_APP_AUTH_CLIENT_ID ??
    process.env['REACT_APP_AUTH_CLIENT_ID'],
  REACT_APP_CUSTOMER_PATH:
    window.env?.REACT_APP_CUSTOMER_PATH ??
    process.env['REACT_APP_CUSTOMER_PATH'],
  REACT_APP_CONTRACTOR_URL:
    window.env?.REACT_APP_CONTRACTOR_URL ??
    process.env['REACT_APP_CONTRACTOR_URL'],
  REACT_APP_WHATSAPP_PATH:
    window.env?.REACT_APP_WHATSAPP_PATH ??
    process.env['REACT_APP_WHATSAPP_PATH'],
  REACT_APP_WHATSAPP_PHONE:
    window.env?.REACT_APP_WHATSAPP_PHONE ??
    process.env['REACT_APP_WHATSAPP_PHONE'],
  REACT_APP_WS_HOST:
    window.env?.REACT_APP_WS_HOST ?? process.env['REACT_APP_WS_HOST'],
  REACT_APP_CHAT_BOT_PATH:
    window.env?.REACT_APP_CHAT_BOT_PATH ??
    process.env['REACT_APP_CHAT_BOT_PATH'],
  REACT_APP_ADMIN_CUSTOMER_PATH:
    window.env?.REACT_APP_ADMIN_CUSTOMER_PATH ??
    process.env['REACT_APP_ADMIN_CUSTOMER_PATH'],
  REACT_APP_STORAGE_API:
    window.env?.REACT_APP_STORAGE_API ?? process.env['REACT_APP_STORAGE_API']
}

export const AppConfig = {
  header: 'fixed',
  appName: AppEnv.REACT_APP_PRODUCT_NAME,
  deployment: AppEnv.REACT_APP_WEB_DEPLOYMENT,
  isDevelopment: process.env.NODE_ENV !== 'production',
  isStaging: AppEnv.REACT_APP_WEB_DEPLOYMENT === 'staging',
  isProduction: AppEnv.REACT_APP_WEB_DEPLOYMENT === 'production',
  api: {
    base: AppEnv.REACT_APP_API_PATH,
    public: AppEnv.REACT_APP_API_PATH,
    notifications: AppEnv.REACT_APP_API_NOTIFICATIONS,
    adminCustomer: AppEnv.REACT_APP_ADMIN_CUSTOMER_PATH,
    storage: AppEnv.REACT_APP_STORAGE_API
  },
  ws: {
    host: AppEnv.REACT_APP_WS_HOST ?? '',
    ssl: true,
    initPath: 'ws/init',
    connectPath: 'ws/connect',
    connectAdminPath: 'ws/connect/admin'
  },
  kc: {
    authUrl: AppEnv.REACT_APP_AUTH_SERVER_URL,
    realm: AppEnv.REACT_APP_AUTH_REALM,
    clientId: AppEnv.REACT_APP_AUTH_CLIENT_ID
  },
  customer: {base: AppEnv.REACT_APP_CUSTOMER_PATH},
  contractor: {base: AppEnv.REACT_APP_CONTRACTOR_URL},
  publicDir: process.env['PUBLIC_URL'],
  googleMapsApiKey:
    process.env.NODE_ENV !== 'production' && false
      ? ''
      : 'AIzaSyBHCmtX6rqGULbFTqxtgNJe9nGA_cIUu6s',
  // Since is only for styling the map, we will hardcoded it for now
  googleMapsMapId: 'dcbbd965fef06cf8',
  whatsApp: {
    phone: AppEnv.REACT_APP_WHATSAPP_PHONE,
    api: AppEnv.REACT_APP_WHATSAPP_PATH,
    bot: AppEnv.REACT_APP_CHAT_BOT_PATH
  }
}
