import {EventBus as TEventBus, WsClient, WSConfig, WsUser} from '@fnd/timeline'
import {AppConfig} from 'AppConfig'

class AppWSClient extends WsClient {
  private readonly onFocusFnRef: any

  constructor(protected config: WSConfig) {
    super(config)
    this.onFocusFnRef = this.onWindowFocus.bind(this)
  }

  connect(user: WsUser, enableDebug?: boolean): Promise<void> {
    this.detachOnFocus()
    this.attachOnFocus()
    return super.connect(user, enableDebug)
  }

  private attachOnFocus() {
    window.addEventListener('focus', this.onFocusFnRef)
  }
  private detachOnFocus() {
    window.removeEventListener('focus', this.onFocusFnRef)
  }

  // Try to re-connect if ws was closed due inactivity - tab was in background
  private async onWindowFocus() {
    if (!this.isOpen() && this.lastCloseEvent?.event?.code === 1006) {
      setTimeout(() => {
        if (!this.isOpen()) {
          this.tryReconnect().then()
        }
      }, 1000)
    }
  }
}

const EventBus = new TEventBus(new AppWSClient(AppConfig.ws))
export default EventBus
