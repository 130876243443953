import {styled} from '@mui/material'

// SOURCE ONLY TAG
const SourceOnly = styled('span')({
  position: 'absolute',
  width: '1px',
  height: '1px',
  padding: '0',
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whitespace: 'nowrap' /* added line */,
  border: 0
})
interface SCProps {
  text: string
}
export function SC(props: SCProps) {
  return <SourceOnly>{props.text}</SourceOnly>
}

// GENERATE TAB INDEX FOR NEXT ELEMENT - CURRENTLY ALL INDEXES ARE 0
export const tabIndex = (function () {
  // function* getNextTabIndexGenerator() {
  //   let index = 0;
  //
  //   while (true) {
  //     yield index++;
  //   }
  // }
  // let getNextTabIndexInstance: Generator<number> = getNextTabIndexGenerator()
  return {
    // reset: () => { getNextTabIndexInstance = getNextTabIndexGenerator() },
    value: () => {
      return 0
    } // getNextTabIndexInstance.next().value }
  }
})()

// GENERATE PSEUDO BUTTON ATTR FOR REACT COMPONENTS
export function spreadAriaButton(label: string, onClick: () => void) {
  return {
    role: 'button',
    tabIndex: tabIndex.value(),
    'aria-label': label,
    onClick: onClick,
    onKeyPress: (event: any) => {
      if (event.key === 'Enter' || event.key === ' ') {
        onClick()
        event.stopPropagation()
        event.preventDefault()
        return false
      }
    }
  }
}

/**
 * Format a string so it can be used as part of a URI
 * Replaces spaces with "-", removes special characters.
 * Non-latin letters are kept as is.
 *
 * @param input string
 * @returns string
 */
export function slugify(input: string): string {
  return input
    .replace(/\s/g, '-') // Replace spaces with "-"
    .replace(/[^\w\u0400-\u04FF\u0590-\u05FF-]/g, '') // Remove specific special characters, excluding non-Latin letters and hyphen
    .replace(/_/g, '') // Remove underscores
    .toLowerCase() // Convert to lowercase
    .replace(/-+/g, '-') // Replace consecutive hyphens with a single hyphen
    .replace(/^-|-$/g, '') // Remove single hyphens at the beginning or end of the string
}

export function isEmptyObject(object: Record<any, any>) {
  return Object.keys(object).length === 0 && object.constructor === Object
}

export function areObjectsTheSame(
  objectA: Record<any, any>,
  objectB: Record<any, any>
) {
  return JSON.stringify(objectA) === JSON.stringify(objectB)
}
