import React, {ReactNode} from 'react'
import {Dialog, IconButton, Stack, Typography} from '@mui/material'
import DialogTitle from '@mui/material/DialogTitle'
import Close from '@mui/icons-material/CloseRounded'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import {useTranslation} from 'react-i18next'

interface ConfirmProps {
  onClose: () => void
  onConfirm: () => void
  confirmLabel?: string
  closeLabel?: string
  open: boolean
  title: ReactNode
  body?: ReactNode
  children?: JSX.Element
}

const Confirm = (props: ConfirmProps) => {
  const [t] = useTranslation()
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-details="dialog-desc"
        aria-labelledby="dialog-title-text"
      >
        <DialogTitle component={'div'}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography
              variant={'h6'}
              component={'h2'}
              id={'dialog-title-text'}
            >
              {props.title}
            </Typography>
            <IconButton
              onClick={props.onClose}
              size={'small'}
              aria-label={t('fnd-label-close')}
            >
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent id={'dialog-desc'}>
          {props.body ?? props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary" variant={'text'}>
            {props.closeLabel ?? t('common:fnd-common-close')}
          </Button>
          <Button onClick={props.onConfirm} color="primary">
            {props.confirmLabel ?? t('common:fnd-common-confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default Confirm
