import React, {createRef} from 'react'
import TimelineEvent from 'web-common/views/timeline/TimelineEvent'
import {Box, Stack, styled, Typography} from '@mui/material'
import AppDate from 'web-common/components/date/AppDate'
import TimelineMessageCreator from 'web-common/views/timeline/TimelineMessageCreator'
import Loading from 'web-common/components/loading/Loading'
import {
  TimelineViewConfig,
  TimelineViewConfigDefault
} from './TimelineViewConfig'
import {
  TimelineActionManager,
  TimelineEventData,
  TimelineEventProducer
} from '@fnd/timeline'

const Wrapper = styled(Stack)({
  height: '100%',
  width: '100%'
})
const ScrollArea = styled(Stack)({
  display: 'flex',
  flexGrow: 1,
  overflowX: 'hidden',
  overflowY: 'auto',

  '& > p:first-of-type': {
    marginTop: '1rem'
  },

  paddingBottom: '1rem'
})

const hiddenScrollStyles = {
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}

const customScrollStyles = {
  scrollbarWidth: 'thin',
  /* width */
  '&::-webkit-scrollbar': {
    width: '10px'
  },

  /* Track */
  '&::-webkit-scrollbar-track': {
    borderRadius: '20px',
    background: 'rgba(0, 0, 0, 0.3)'
  },

  /* Handle */
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '20px',
    background: 'rgba(0, 0, 0, 0.6)'
  },

  /* Handle on hover */
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'rgba(0, 0, 0, 0.8)'
  }
}

interface TimelineViewProps {
  actionManager: TimelineActionManager
  producer: TimelineEventProducer
  config?: Partial<TimelineViewConfig>
  hideMessageCreator?: boolean
  scrollBarStyle?: 'native' | 'hidden' | 'custom'
}

interface TimelineViewState {
  events: TimelineEventData<any>[]
  loading: boolean
}

class TimelineView extends React.Component<
  TimelineViewProps,
  TimelineViewState
> {
  state: TimelineViewState = {
    events: [],
    loading: true
  }
  timelineRef = createRef<HTMLDivElement>()
  readonly config: TimelineViewConfig = {
    ...TimelineViewConfigDefault,
    ...this.props.config
  }

  componentDidMount() {
    this.props.producer.subscribe((data) => {
      this.setState(
        {loading: false, events: data},
        this.onScrollToBottom.bind(this)
      )
    })
  }

  onScrollToBottom() {
    setTimeout(() => {
      const node = this.timelineRef.current
      if (node) {
        node.scroll({
          top: node.scrollHeight,
          behavior: 'smooth'
        })
      }
    })
  }

  componentWillUnmount() {
    this.props.producer.complete()
    this.props.actionManager.complete()
  }

  differentDates(d1: Date, d2: Date) {
    return (
      d1.getFullYear() !== d2.getFullYear() ||
      d1.getMonth() !== d2.getMonth() ||
      d1.getDate() !== d2.getDate()
    )
  }

  shouldShowDate(index: number) {
    if (
      index === 0 ||
      !this.state.events[index] ||
      !this.state.events[index - 1]
    ) {
      return true
    }
    const current = new Date(this.state.events[index]?.date ?? 0)
    const previous = new Date(this.state.events[index - 1]?.date ?? 0)
    return this.differentDates(current, previous)
  }

  renderEvent(event: TimelineEventData<any>, index: number) {
    return (
      <React.Fragment key={event._id + index}>
        {/*SHOW DATE ONLY AT THE FIRST EVENT IN THIS GROUP*/}
        {this.shouldShowDate(index) && (
          <Typography
            variant={'body2'}
            color={'textSecondary'}
            style={{alignSelf: 'center'}}
          >
            <AppDate timestamp={event.date} format={'date'} />
          </Typography>
        )}
        {/*RENDER EVENT*/}
        <TimelineEvent event={event} />
      </React.Fragment>
    )
  }

  renderScrollArea() {
    this.onScrollToBottom()
    let scrollBarStyles = undefined

    if (!this.props.scrollBarStyle || this.props.scrollBarStyle === 'hidden') {
      scrollBarStyles = hiddenScrollStyles
    } else if (this.props.scrollBarStyle === 'custom') {
      scrollBarStyles = customScrollStyles
    }

    return (
      <ScrollArea
        flexDirection="column"
        justifyContent="flex-start"
        spacing={3}
        ref={this.timelineRef}
        sx={scrollBarStyles}
      >
        {this.state.events.map(this.renderEvent.bind(this))}
      </ScrollArea>
    )
  }

  render() {
    const lastEvent = this.state.events[this.state.events.length - 1]
    return (
      <Wrapper flexDirection={'column'} alignItems={'stretch'}>
        {/*ACCESSIBILITY*/}
        <Box className={'sr-only'} aria-live={'polite'} role={'alert'}>
          {lastEvent && this.renderEvent(lastEvent, Date.now())}
        </Box>

        {/*TIMELINE SCROLL*/}
        <Loading
          loading={this.state.loading}
          render={this.renderScrollArea.bind(this)}
        />

        {/*CHAT INPUT*/}
        {!this.props.hideMessageCreator && (
          <TimelineMessageCreator
            producer={this.props.producer}
            actionManager={this.props.actionManager}
            config={this.config}
          />
        )}
      </Wrapper>
    )
  }
}

export default TimelineView
