import Guard from './Guard'
import kc from 'web-common/services/auth'

class LoginGuard extends Guard {
  fallbackURL = '/login'

  hasPermission(): Promise<boolean> | boolean {
    // return !!kc.instance.authenticated
    if (kc.instance.authenticated) {
      return true
    }
    return new Promise((resolve) => {
      kc.automaticLoginFlow()
        .then((_) => {
          resolve(true)
        })
        .catch((_) => {
          resolve(false)
        })
    })
  }
}

const loginGuard = new LoginGuard()
export default loginGuard
