import React, {ChangeEvent} from 'react'
import {AppInputProps} from 'web-common/components/inputs/AppInput'
import {TextField} from '@mui/material'

interface AppDebounceInputProps extends AppInputProps {
  onFinish: (newValue: string) => void
  value?: string
  debounce?: number // default 300
}

interface AppDebounceInputState {
  value: string
}

class AppDebounceInput extends React.Component<
  AppDebounceInputProps,
  AppDebounceInputState
> {
  state: AppDebounceInputState = {
    value: this.props.value ?? ''
  }
  timer?: number

  // componentDidMount() {
  //   if (this.props.value) {
  //     this.setState({value: this.props.value})
  //   }
  // }

  componentDidUpdate(
    prevProps: Readonly<AppDebounceInputProps>,
    prevState: Readonly<AppDebounceInputState>,
    snapshot?: any
  ) {
    if (
      this.props.value !== undefined &&
      this.props.value !== prevProps.value
    ) {
      this.setState({value: this.props.value})
    }
  }

  componentWillUnmount() {
    this.clearTimer()
  }

  clearTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer)
      this.timer = undefined
    }
  }

  onType(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.clearTimer()
    const value = e.target.value
    this.setState({value: value})
    this.timer = window.setTimeout(
      this.props.onFinish.bind(this, value),
      this.props.debounce ?? 300
    )
  }

  render() {
    const {ref, onChange, value, onFinish, debounce, ...rest} = this.props
    return (
      <TextField
        {...rest}
        value={this.state.value}
        onChange={this.onType.bind(this)}
      />
    )
  }
}

export default AppDebounceInput
