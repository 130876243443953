import React from 'react'
import AppPage from 'web-common/utilities/AppPage'
import InstallPrompt from 'views/install/InstallPrompt'
import {AppConfig} from 'AppConfig'

interface InstallViewRouteParams {}

interface InstallViewState {}

interface InstallViewProps {}

class InstallView extends AppPage<
  InstallViewProps,
  InstallViewState,
  InstallViewRouteParams
> {
  static path = '/install-app'
  title = 'Install Finderella on your device'

  componentDidMount() {
    super.componentDidMount()
    console.log('Public path', AppConfig.publicDir)
  }

  render() {
    return <InstallPrompt />
  }
}

export default InstallView.exportDefault()
