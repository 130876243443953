import TimelineEventTemplate from 'web-common/views/timeline/TimelineEventTemplate'
import {Button, CircularProgress, Divider, Stack} from '@mui/material'
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded'
import React from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import Confirm from 'web-common/components/modal/Confirm'
import {AssignmentAdapter} from 'views/demands/demand/timeline/adapters/AssignmentAdapter'
import {AssignmentData} from 'views/demands/DemandsService'
import {OfferPreview} from 'web-common/views/timeline/components/OfferPreview'

export interface AcceptOfferProps {
  assignment: AssignmentData
}

interface InternalProps extends AcceptOfferProps, WithTranslation {}

interface AcceptOfferState {
  modal: boolean
  inRequest: boolean
}

class AcceptOffer extends TimelineEventTemplate<
  InternalProps,
  AcceptOfferState
> {
  state: AcceptOfferState = {
    modal: false,
    inRequest: false
  }

  onOpenModal() {
    this.setState({modal: true})
  }

  onCloseModal() {
    this.setState({modal: false})
  }

  onAcceptOffer() {
    if (this.state.inRequest) {
      return
    }
    this.setState({inRequest: true, modal: false}, async () => {
      await this.props.producer.createEvent({
        adapterId: AssignmentAdapter.id,
        data: this.props.assignment
      })
      this.setState({inRequest: false})
    })
  }

  render() {
    return (
      <>
        <Button
          startIcon={<AssignmentTurnedInRoundedIcon />}
          color={'primary'}
          onClick={this.onOpenModal.bind(this)}
          disabled={this.state.inRequest}
        >
          {this.state.inRequest && <CircularProgress />}
          {this.props.t('fnd-label-accept-offer')}
        </Button>

        <Confirm
          open={this.state.modal}
          title={this.props.t('fnd-label-confirm-offer-accept')}
          onClose={this.onCloseModal.bind(this)}
          onConfirm={this.onAcceptOffer.bind(this)}
        >
          <Stack direction={'column'} spacing={1}>
            <Divider />
            <OfferPreview offer={this.props.assignment.offer!} />
          </Stack>
        </Confirm>
      </>
    )
  }
}

export default withTranslation()(AcceptOffer)
