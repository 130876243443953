import {AppConfig} from 'AppConfig'
import {BusinessGalleryData} from 'web-common/models/Profile'
import {OfferData, OfferStatus} from 'web-common/models/OfferModel'
import {AssignmentReviewData} from 'web-common/models/AssignmentModel'
import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {ReviewData} from 'web-common/models/RatingModels'
import {CachedRequest} from 'web-common/services/request/Request'
import {IPageResult} from 'web-common/services/PageQuery'
import {DemandData, DemandRequestData} from 'models/Demands'

export interface OfferContractorRatingSummaryData {
  rating: number
  ratings: number[]
  score: number
  votes: number
}

export interface OfferContractorData {
  _id: string
  name: string
  ratingSummary?: OfferContractorRatingSummaryData | null
  picture?: string | null
  userId: string
  about: string | null
  phone?: string | null // company phone
  email?: string | null // company email
  contact: {
    name?: string
    email?: string
    phone?: string
  } | null
  galleries?: BusinessGalleryData[]
  social?: {[key: string]: string} | null
}

export interface OfferPreview {
  _id: string
  contractorId: string
  contractor: OfferContractorData | null
  // for contractor - AssignmentStatus
  status: 'ACTIVE' | 'ACCEPTED' | 'NEW' | string
  // for client
  offer: {
    dateExpire: number | null
    dateSeen: number | null
    dateCreated: number | null
    status: OfferStatus
  }

  contractorReview: AssignmentReviewData & {
    datePublished: number | null | undefined
  }
  customerReview: AssignmentReviewData & {
    datePublished: number | null | undefined
  }
}

export interface LeadContactData {
  email: string | null
  name: string | null
  phone: string | null
  revealReason?: string | null
  revealDate?: number | null
}

export type AssignmentStatus =
  | 'NEW'
  | 'ACCEPTED'
  | 'ACTIVE'
  | 'COMPLETED'
  | 'REJECTED'
  | 'WITHDRAW'
  | 'DISCARDED'
  | string

export interface AssignmentData {
  _id: string
  _refs: any
  contact: LeadContactData
  dateCreated: number
  demand: any
  preview: {[key: string]: any}
  offer: OfferData | null
  offerHistory?: OfferData[] | null
  contractor: OfferContractorData
  status: AssignmentStatus
  seen: boolean
  dateSeen: number | null
  dateAccepted: number | null
  dateActivated: number | null
  customerReview?: AssignmentReviewData
  contractorReview?: AssignmentReviewData
}

export enum DemandsCancelReason {
  DONT_NEED_ANYMORE = 'dont-need-any-more',
  WANTED_RESEARCH = 'wanted-research',
  NOT_FOUND_RIGHT_SUPPLIER = 'not-found-right-supplier',
  OTHER = 'OTHER'
}

export interface DemandOfferData {
  _id: string
  status: AssignmentStatus
  offer?: OfferData
}

const paths = {
  demands: {
    find: {},
    '*': {
      status: {},
      request: {},
      archive: {},
      offers: {
        '*': {}
      }
    }
  },
  customer: {
    reviews: {
      '*': {}
    }
  },
  contractors: {
    '*': {
      profile: {
        reviews: {
          find: {}
        }
      }
    }
  },
  requireToken: true,
  toString: () => AppConfig.api.base!
}

class DemandsService extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: DemandsService
  protected cachedRequest = this.enhancedRequest(CachedRequest.create)

  public static shared() {
    if (!DemandsService.instance) {
      DemandsService.instance = new DemandsService()
    }
    return DemandsService.instance
  }

  getFindPath() {
    return this.api.paths.demands.find.toString()
  }

  getAllDemands(archived: boolean = false, limit: number = 1000) {
    return this.api.paths.demands.find.get<IPageResult<DemandData>>(
      this.request
    )({
      params: {
        limit: limit,
        archived: archived
      }
    })
  }

  getFindPathReviews(contractorId: string) {
    return this.api.paths.contractors['*'].profile.reviews.find.toString(
      contractorId
    )
  }

  getDemand(demandId: string) {
    return this.api.paths.demands['*'].get<DemandData>(this.request, demandId)()
  }

  cancelDemand(
    demandId: string,
    cancelReason: DemandsCancelReason,
    comment?: string
  ) {
    const data = {status: 'CANCELED', reason: cancelReason, comment: comment}
    return this.api.paths.demands['*'].status.put(this.request, demandId)(data)
  }

  getDemandByAssignmentId(assignmentId: string) {
    return this.api.paths.demands.get<DemandData[]>(
      this.request,
      assignmentId
    )({params: {assignmentId: assignmentId}})
  }

  getDemandQuestionnaireData(demandId: string) {
    return this.api.paths.demands['*'].request.get<DemandRequestData>(
      this.request,
      demandId
    )()
  }

  getOffer(demandId: string, assignmentId: string) {
    return this.api.paths.demands['*'].offers['*'].get<DemandOfferData>(
      this.request,
      demandId,
      assignmentId
    )()
  }

  getReview(reviewId: string) {
    return this.api.paths.customer.reviews['*'].get<ReviewData>(
      this.request,
      reviewId
    )()
  }

  getContractorMyReviews(contractorId: string) {
    return this.api.paths.contractors['*'].profile.reviews.find.get<
      IPageResult<ReviewData>
    >(
      this.cachedRequest,
      contractorId
    )({
      params: {
        q: 'my_reviews',
        offset: 0,
        limit: 100
      }
    })
  }

  acceptOffer(
    demandId: string,
    assignmentId: string,
    offerDateCreated: number
  ) {
    const body = {
      accepted: true,
      dateCreated: offerDateCreated
    }
    return this.api.paths.demands['*'].offers['*'].put<AssignmentReviewData>(
      this.request,
      demandId,
      assignmentId
    )(body)
  }

  markOfferAsSeen(
    demandId: string,
    assignmentId: string,
    offerDateCreated: number
  ) {
    const body = {
      seen: true,
      dateCreated: offerDateCreated
    }
    return this.api.paths.demands['*'].offers['*'].put(
      this.request,
      demandId,
      assignmentId
    )(body)
  }
}

export default DemandsService.shared()
