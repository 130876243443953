import {OfferData} from 'web-common/models/OfferModel'
import {RatingType} from 'web-common/models/RatingModels'

export interface AssignmentModelData {
  _id: string
  _refs: AssignmentRefsData
  status: AssignmentStatusData
  preview: AssignmentPreviewData
  contact: AssignmentContactData
  contractor: AssignmentContractorData | null
  offer: OfferData | null
  price: number

  dateCreated: number
  seen: boolean
  dateSeen: number | null
  dateAccepted: number | null

  customerReview: AssignmentReviewData | null
  contractorReview: AssignmentReviewData | null
}

interface AssignmentRefsData {
  demandId: string
  serviceId: string
  contractorId: string
  userId: string
  customerUserId: string
}

export interface AssignmentPreviewData {
  regions: string[]
  offices: string[]
  budget: string
}

export interface AssignmentContactData {
  email: string | null
  name: string | null
  phone: string | null
}

export interface AssignmentContractorData {
  _id: string
  name: string
  ratingSummary?: AssignmentContractorRatingSummaryData | null
  picture?: string | null
  userId: string
  about: string | null
  contact: {
    name?: string
    email?: string
    phone?: string
  } | null
}

export interface AssignmentContractorRatingSummaryData {
  rating: number
  ratings: number[]
  score: number
  votes: number
}

export enum AssignmentStatusData {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  DISCARDED = 'DISCARDED',
  WITHDRAWN = 'WITHDRAWN',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED'
}

export enum AssignmentReviewStatusData {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  PUBLISHED = 'PUBLISHED'
}

export interface AssignmentReviewData {
  _id: string
  status: AssignmentReviewStatusData
  rating?: RatingType | null
}
