import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {AppConfig} from 'AppConfig'
import {CachedRequest} from 'web-common/services/request/Request'

export interface GlobalPriceUnits {
  units: PriceUnit[]
}

export interface PriceUnit {
  id: string
  name?: string
  l10n: {[key: string]: PriceUnitLocalization} | null
}

export interface PriceUnitLocalization {
  name: string
  nameShort: string
}

const paths = {
  nomenclatures: {
    'price-units': {}
  },
  toString: () => AppConfig.api.base
}

class PriceUnitsService extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: PriceUnitsService
  public priceUnits: PriceUnit[] = []

  static shared() {
    if (!PriceUnitsService.instance) {
      PriceUnitsService.instance = new PriceUnitsService()
    }
    return PriceUnitsService.instance
  }

  async init() {
    const globalPriceUnits = await this.getGlobalPriceUnits()
    this.priceUnits = globalPriceUnits.units
  }

  private cachedRequest = this.enhancedRequest(CachedRequest.create)

  private getGlobalPriceUnits() {
    return this.api.paths.nomenclatures['price-units'].get<GlobalPriceUnits>(
      this.cachedRequest
    )()
  }
}

export default PriceUnitsService.shared()
