import {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig
} from 'axios'
import {PATH} from 'web-common/services/request/ApiEndpoint'
import {Observable} from 'rxjs'

export interface PostResponseLocation<T> {
  data: T
  location: string
}

export class EndpointRequest {
  constructor(protected client: AxiosInstance) {}

  public get<T>(
    url: PATH,
    config?: AxiosRequestConfig,
    ...params: string[]
  ): Promise<T>
  public get<T>(url: PATH, ...params: string[]): Promise<T>
  public get<T>(url: PATH, custom: any, ...params: string[]): Promise<T> {
    if (typeof custom !== 'object' && custom !== undefined) {
      return this.parseRequest<T>(
        this.client.get,
        url.toString(...[custom, ...params])
      )
    }
    return this.parseRequest<T>(
      this.client.get,
      url.toString(...params),
      custom
    )
  }

  public post<T>(
    url: PATH,
    data?: any,
    config?: AxiosRequestConfig,
    ...params: string[]
  ): Promise<T>
  public post<T>(url: PATH, data?: any, ...params: string[]): Promise<T>
  public post<T>(
    url: PATH,
    data?: any,
    custom?: any,
    ...params: string[]
  ): Promise<T> {
    if (typeof custom !== 'object' && custom !== undefined) {
      return this.parseRequest<T>(
        this.client.post,
        url.toString(...[custom, ...params]),
        data
      )
    }
    return this.parseRequest<T>(
      this.client.post,
      url.toString(...params),
      data,
      custom
    )
  }

  public put<T>(
    url: PATH,
    data?: any,
    config?: AxiosRequestConfig,
    ...params: string[]
  ): Promise<T>
  public put<T>(url: PATH, data?: any, ...params: string[]): Promise<T>
  public put<T>(
    url: PATH,
    data?: any,
    custom?: any,
    ...params: string[]
  ): Promise<T> {
    if (typeof custom !== 'object' && custom !== undefined) {
      return this.parseRequest<T>(
        this.client.put,
        url.toString(...[custom, ...params]),
        data
      )
    }
    return this.parseRequest<T>(
      this.client.put,
      url.toString(...params),
      data,
      custom
    )
  }

  public patch<T>(
    url: PATH,
    data?: any,
    config?: AxiosRequestConfig,
    ...params: string[]
  ): Promise<T>
  public patch<T>(url: PATH, data?: any, ...params: string[]): Promise<T>
  public patch<T>(
    url: PATH,
    data?: any,
    custom?: any,
    ...params: string[]
  ): Promise<T> {
    if (typeof custom !== 'object' && custom !== undefined) {
      return this.parseRequest<T>(
        this.client.patch,
        url.toString(...[custom, ...params]),
        data
      )
    }
    return this.parseRequest<T>(
      this.client.patch,
      url.toString(...params),
      data,
      custom
    )
  }

  public delete<T>(
    url: PATH,
    config?: AxiosRequestConfig,
    ...params: string[]
  ): Promise<T>
  public delete<T>(url: PATH, ...params: string[]): Promise<T>
  public delete<T>(url: PATH, custom: any, ...params: string[]): Promise<T> {
    if (typeof custom !== 'object' && custom !== undefined) {
      return this.parseRequest<T>(
        this.client.delete,
        url.toString(...[custom, ...params])
      )
    }
    return this.parseRequest<T>(
      this.client.delete,
      url.toString(...params),
      custom
    )
  }

  public postAndGetLocation<T>(
    url: PATH,
    data?: any,
    config?: AxiosRequestConfig,
    ...params: string[]
  ): Promise<PostResponseLocation<T>>
  public postAndGetLocation<T>(
    url: PATH,
    data?: any,
    ...params: string[]
  ): Promise<PostResponseLocation<T>>
  public postAndGetLocation<T>(
    url: PATH,
    data?: any,
    custom?: any,
    ...params: string[]
  ): Promise<PostResponseLocation<T>> {
    return new Promise(async (resolve, reject) => {
      let promise
      if (typeof custom !== 'object' && custom !== undefined) {
        promise = this.client.post(url.toString(...[custom, ...params]), data)
      } else {
        promise = this.client.post(url.toString(...params), data, custom)
      }
      try {
        const response = await promise
        resolve({
          data: response.data,
          location: response.headers['location'] ?? ''
        })
      } catch (e: any) {
        reject(e?.response ?? e)
      }
    })
  }

  protected parseRequest<T>(
    callablePromise: (...args: any[]) => AxiosPromise<T>,
    ...args: any[]
  ): any {
    return new Promise<T>(async (resolve, reject) => {
      try {
        const response = await callablePromise.apply(this.client, args)
        resolve(response.data)
      } catch (e: any) {
        reject(e?.response ?? e)
      }
    })
  }

  static create(_: AxiosInstance) {
    throw new Error(`
    All inherit classes should extend 'create' static method:
        static create(client: AxiosInstance) {
          return new EnhancedRequest(client)
        }
    `)
  }
}

export class ObservableRequest extends EndpointRequest {
  protected parseRequest<T>(
    callablePromise: (...args: any[]) => AxiosPromise<T>,
    ...args: any[]
  ): any {
    return new Observable<T>((observer) => {
      callablePromise
        .apply(this.client, args)
        .then((r) => {
          observer.next(r.data)
        })
        .catch((error: AxiosError) => {
          observer.error(error?.response ?? error)
        })
        .finally(() => observer.complete())
    })
  }

  static create(client: AxiosInstance) {
    return new ObservableRequest(client)
  }
}

export class CachedRequest extends EndpointRequest {
  private sessionCache: {[url: string]: any} = {}

  protected async parseRequest<T>(
    callablePromise: (...args: any[]) => AxiosPromise<T>,
    ...args: any[]
  ) {
    const url = args[0]
    if (!this.sessionCache[url]) {
      this.sessionCache[url] = await new Promise<T>(async (resolve, reject) => {
        try {
          const response = await callablePromise.apply(this.client, args)
          resolve(response.data)
        } catch (e) {
          console.error('CachedRequest:', e, args)
          reject(e)
        }
      })
    }
    if (!this.sessionCache[url]) {
      throw new Error('No cache found for url ' + url)
    }
    return this.sessionCache[url] as T
  }

  public clearCache(url: PATH, ...params: string[]) {
    delete this.sessionCache[url.toString(...params)]
  }

  static create(client: AxiosInstance) {
    return new CachedRequest(client)
  }
}
