import Box from '@mui/material/Box'
import React from 'react'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'

export default class AppFormLabel extends AppFormInputComponent<
  AppFormComponent,
  any
> {
  onChange(_: any) {}

  render() {
    return (
      <Box children={this.props.label} display="block" component={'span'} />
    )
  }
}
