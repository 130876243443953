import TimelineEventTemplate from 'web-common/views/timeline/TimelineEventTemplate'
import {Button} from '@mui/material'
import StarRoundedIcon from '@mui/icons-material/StarRounded'
import RateUserView from 'web-common/views/reviews/RateUserView'
import {AssignmentData} from 'views/demands/DemandsService'
import {WithTranslation, withTranslation} from 'react-i18next'
import {ReviewAdapter} from 'views/demands/demand/timeline/adapters/ReviewAdapter'

export interface LeaveReviewActionProps {
  assignment: AssignmentData
}

interface InternalProps extends LeaveReviewActionProps, WithTranslation {}

interface LeaveReviewActionState {
  modal: boolean
}

class LeaveReviewAction extends TimelineEventTemplate<
  InternalProps,
  LeaveReviewActionState
> {
  state: LeaveReviewActionState = {
    modal: false
  }

  onOpenModal() {
    this.setState({modal: true})
  }

  onCloseModal() {
    this.setState({modal: false})
  }

  render() {
    return (
      <>
        <Button
          startIcon={<StarRoundedIcon />}
          color={'primary'}
          onClick={this.onOpenModal.bind(this)}
        >
          {this.props.t('fnd-label-write-review')}
        </Button>

        <RateUserView
          open={this.state.modal}
          reviewId={this.props.assignment.customerReview?._id}
          reviewReceiver={this.props.assignment.contractor.name ?? ''}
          rateContractor={true}
          onReviewSuccess={async (data) => {
            await this.props.producer.createEvent({
              adapterId: ReviewAdapter.id,
              data: data
            })
          }}
          onClose={this.onCloseModal.bind(this)}
        />
      </>
    )
  }
}

export default withTranslation()(LeaveReviewAction)
