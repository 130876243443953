import {AppNotificationData} from 'web-common/contexts/AppContext'
import DemandView from 'views/demands/demand/DemandView'
import {
  ASSIGNMENT_OFFER_NEW_TYPE,
  WS_ASSIGNMENT_OFFER_NEW_RECEIVE_DATA
} from 'web-common/models/ws/assignments'
import {BusEvent, Dispatcher, DispatcherPriority} from '@fnd/timeline'
import EventBus from 'web-common/services/EventBus'

export type DemandEventData = WS_ASSIGNMENT_OFFER_NEW_RECEIVE_DATA

class DemandDispatcher extends Dispatcher<DemandEventData, undefined> {
  protected consumable: boolean = true

  public criteria(event: BusEvent<DemandEventData, undefined>): boolean {
    return (
      event.type === ASSIGNMENT_OFFER_NEW_TYPE &&
      (this.assignmentId === undefined ||
        this.assignmentId === event.data?.assignment?._id)
    )
  }

  public static criteriaByDemand(
    event: BusEvent<DemandEventData, undefined>,
    demandId?: string
  ): boolean {
    return (
      event.type === ASSIGNMENT_OFFER_NEW_TYPE &&
      (demandId === undefined ||
        demandId === event.data?.assignment?._refs?.demandId)
    )
  }

  protected priority: DispatcherPriority = 'MEDIUM'

  public static eventToNotification(
    event: BusEvent<DemandEventData, any>
  ): AppNotificationData | undefined {
    if (event.type === ASSIGNMENT_OFFER_NEW_TYPE) {
      return DemandDispatcher.newOfferNotification(
        event as BusEvent<WS_ASSIGNMENT_OFFER_NEW_RECEIVE_DATA, undefined>
      )
    }
    return undefined
  }

  private static newOfferNotification(
    event: BusEvent<WS_ASSIGNMENT_OFFER_NEW_RECEIVE_DATA, undefined>
  ): AppNotificationData {
    const message =
      event.data?.assignment.status === 'ACCEPTED'
        ? 'fnd-notification-new-offer'
        : 'fnd-notification-later-offer'
    return {
      color: 'info',
      message: message,
      action: (navigate) =>
        navigate(
          DemandView.getPath(
            event.data!.assignment._refs.demandId,
            event.data!.assignment._id
          )
        )
    }
  }

  constructor(private assignmentId?: string) {
    super(EventBus)
  }
}

export default DemandDispatcher
