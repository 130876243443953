import {AppConfig} from 'AppConfig'
import {PublicProfileData, UserData} from 'web-common/models/Profile'
import {IPageResult} from 'web-common/services/PageQuery'
import {RateData, ReviewData} from 'web-common/models/RatingModels'
import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'
import {CachedRequest} from 'web-common/services/request/Request'

export type UserDetailsData = {
  hasAvatar: boolean
  firstName: string
  lastName: string
}

const paths = {
  customers: {
    '*': {
      profile: {
        reviews: {
          find: {}
        }
      }
    }
  },

  customer: {
    profile: {
      contact: {},
      credentials: {
        password: {}
      }
    },
    reviews: {
      '*': {},
      find: {}
    },
    upgrades: {
      contractor: {}
    }
  },

  verifications: {
    '*': {}
  },
  requireToken: true,
  toString: () => AppConfig.api.base!
}

class CustomerService extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: CustomerService

  static shared() {
    if (!CustomerService.instance) {
      CustomerService.instance = new CustomerService()
    }
    return CustomerService.instance
  }

  private cachedRequest = this.enhancedRequest(CachedRequest.create)

  // public: string = AppConfig.api.base?.replace(/\/$/, '') + '/customers'
  // url: string = AppConfig.api.base?.replace(/\/$/, '') + '/customer/profile'
  // reviewsUrl: string = AppConfig.api.base?.replace(/\/$/, '') + '/customer/reviews'
  // verification: string = AppConfig.api.base?.replace(/\/$/, '') + '/verifications'
  // upgrades: string = AppConfig.api.base?.replace(/\/$/, '') + '/customer/upgrades'

  get() {
    return this.api.paths.customer.profile.get<UserData>(this.cachedRequest)()
  }

  editUserDetails(user: UserDetailsData) {
    this.cachedRequest.clearCache(this.api.paths.customer.profile)
    return this.api.paths.customer.profile.contact.patch(this.request)({
      picture: user.hasAvatar ? '' : null,
      firstName: user.firstName,
      lastName: user.lastName
    })
  }

  getMyPendingReviews() {
    const params = {
      q: 'PENDING',
      offset: 0,
      limit: 100
    }
    return this.api.paths.customer.reviews.find.get<IPageResult<ReviewData>>(
      this.request
    )({params: params})
  }

  rateContractor(reviewId: string, data: RateData) {
    return this.api.paths.customer.reviews['*'].put(
      this.request,
      reviewId
    )(data)
  }

  // REQUEST VALIDATION CODE FOR NEW CONTACT EITHER PHONE OR EMAIL
  requestVerificationCode(contact: string) {
    // GET LOCATION HEADER
    return this.request.postAndGetLocation(this.api.paths.verifications, {
      credential: contact
    })
  }

  validateContact(verificationId: string, contact: string, code: string) {
    this.cachedRequest.clearCache(this.api.paths.customer.profile)
    return this.api.paths.verifications['*'].put(
      this.request,
      verificationId
    )({credential: contact, code: code})
  }

  verifyContactRequest(token: string) {
    this.cachedRequest.clearCache(this.api.paths.customer.profile)
    return this.api.paths.customer.profile.credentials.put(this.request)({
      token: token
    })
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.api.paths.customer.profile.credentials.password.put(
      this.request
    )({
      password: oldPassword,
      newPassword: newPassword
    })
  }

  getPublicProfile(customerId: string) {
    return this.api.paths.customers['*'].profile.get<PublicProfileData>(
      this.request,
      customerId
    )()
  }

  getPublicReviews(customerId: string) {
    // const url = `${this.public}/${customerId}/profile/reviews/find?q=my_reviews&offset=0&limit=100`
    return this.api.paths.customers['*'].profile.reviews.find.get(
      this.cachedRequest,
      customerId
    )()
  }

  requestUpgradeToBusiness() {
    return this.api.paths.customer.upgrades.contractor.post(this.request)({})
  }
}

export default CustomerService.shared()
