import {
  Card,
  CardProps,
  CardContent,
  Typography,
  Box,
  useTheme,
  alpha
} from '@mui/material'
import {useTranslation} from 'react-i18next'
import BehaviorOptions from './BehaviorOptions.type'
import classicModeIllustrationLtr from './assets/classic-mode-illustration-ltr.svg'
import classicModeIllustrationRtl from './assets/classic-mode-illustration-rtl.svg'
import quickModeIllustrationLtr from './assets/quick-mode-illustration-ltr.svg'
import quickModeIllustrationRtl from './assets/quick-mode-illustration-rtl.svg'

export type BehaviorOptionProps = {
  title: string
  description: string
  value: BehaviorOptions
  onBehaviorSelection: (value: BehaviorOptions) => void
  selectedBehavior?: BehaviorOptions
} & CardProps

function BehaviorOption({
  title,
  description,
  value,
  onBehaviorSelection,
  selectedBehavior,
  sx,
  ...props
}: BehaviorOptionProps) {
  const {i18n} = useTranslation()
  const theme = useTheme()
  const isSelected = value === selectedBehavior
  let illustrationSrc = ''

  if (value === 'classic' && i18n.dir() === 'ltr') {
    illustrationSrc = classicModeIllustrationLtr
  } else if (value === 'classic' && i18n.dir() === 'rtl') {
    illustrationSrc = classicModeIllustrationRtl
  } else if (value === 'quick' && i18n.dir() === 'ltr') {
    illustrationSrc = quickModeIllustrationLtr
  } else if (value === 'quick' && i18n.dir() === 'rtl') {
    illustrationSrc = quickModeIllustrationRtl
  }

  return (
    <Card
      {...props}
      variant="outlined"
      data-recording-click-props={`{"surveyBehaviorSelection": "${value}"}`}
      sx={{
        ...sx,
        height: '100%',
        cursor: 'pointer',
        borderColor: isSelected ? theme.palette.primary.main : undefined,
        backgroundColor: isSelected
          ? alpha(theme.palette.primary.main, 0.08)
          : undefined
      }}
      tabIndex={0}
      onClick={() => {
        onBehaviorSelection(value)
      }}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          onBehaviorSelection(value)
        }
      }}
    >
      {/* TODO: We can outsource this into a generic <AspectRatio /> component, right now it's just an experiment so no need to overdo it... */}
      <Box
        component="figure"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          padding: `${(9 / 16) * 100}% 0 0 0`,
          margin: 0,
          height: 0,
          '> *': {
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }
        }}
      >
        <img src={illustrationSrc} alt="" />
      </Box>
      <CardContent>
        <Typography gutterBottom variant="h5" sx={{fontWeight: 400}}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default BehaviorOption
