import React from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import GoogleMapsLoader from 'web-common/components/regions/GoogleMapsLoader'
import {red} from '@mui/material/colors'
import {WithAppContext, withAppContext} from 'web-common/contexts/AppContext'
import {AppConfig} from 'AppConfig'

type MouseEvent = {feature: google.maps.Data.Feature}

export interface GoogleMapsProps extends WithTranslation, WithAppContext {
  center?: {
    lat: number
    lng: number
  }
  zoom?: number
  geoJsons: any[]

  // Feature Ids
  focusOn?: string[]

  // Change selected features color
  selectedFeatures?: string[]

  // Allow user to tap/click on region
  onRegionSelect?: (regionId: string) => void
}

interface GoogleMapsState {
  scriptLoaded: boolean
  mapReady: boolean
}

class RegionGoogleMaps extends React.Component<
  GoogleMapsProps,
  GoogleMapsState
> {
  state: GoogleMapsState = {
    scriptLoaded: false,
    mapReady: false
  }
  // MAP ELEMENT REF
  mapRef = React.createRef<HTMLDivElement>()
  map?: google.maps.Map = undefined
  mapBoundary?: google.maps.LatLngBounds
  mapMinZoomLevel: number = 6.4

  componentDidMount() {
    GoogleMapsLoader.instance(this.props.i18n.language).ready.subscribe(
      (loaded) => {
        if (loaded) {
          // Init map
          this.map = new google.maps.Map(this.mapRef.current!, {
            zoom: this.props.zoom ?? 7,
            center: this.props.center ?? {lat: 0, lng: 0},
            mapTypeId: 'roadmap',
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            minZoom: this.mapMinZoomLevel,
            mapId: AppConfig.googleMapsMapId
          })

          // Config map
          this.drawRegions()
          this.focusRegions()
          this.applyGeoJSONStyle()

          if (this.props.onRegionSelect) {
            this.map!.data.addListener('click', (e: MouseEvent) => {
              this.props.onRegionSelect?.call(
                this,
                e.feature.getProperty('_id') as string
              )
            })
          }
        }
      }
    )
  }

  componentDidUpdate(
    prevProps: Readonly<GoogleMapsProps>,
    prevState: Readonly<GoogleMapsState>,
    snapshot?: any
  ) {
    if (
      typeof google !== 'undefined' &&
      this.props.geoJsons.length > 0 &&
      !this.deepCompare(prevProps.geoJsons, this.props.geoJsons)
    ) {
      // UPDATE GEO JSON
      this.drawRegions()
    }

    if (
      typeof google !== 'undefined' &&
      this.props.focusOn &&
      !this.compareArrays(prevProps.focusOn, this.props.focusOn)
    ) {
      // Center map on specific feature
      this.focusRegions()
    }

    if (
      this.props.selectedFeatures &&
      !this.compareArrays(
        prevProps.selectedFeatures,
        this.props.selectedFeatures
      )
    ) {
      this.applyGeoJSONStyle()
    }
  }

  focusRegions() {
    // GOOGLE CANT ZOOM BECAUSE OF THE MAP BOUNDARY RESTRICTION - TEMPORARY OFF
    this.unlockMap()
    const bounds = new google.maps.LatLngBounds()
    this.map!.data.forEach((feature) => {
      if (this.props.focusOn?.includes(feature.getProperty('_id') as string)) {
        feature.getGeometry()?.forEachLatLng((latLng) => {
          bounds.extend(latLng)
        })
      }
    })
    this.map!.fitBounds(bounds)
    // MAP IS FOCUSED ON REGION - LOCK MAP AGAIN BUT MAKE IT ASYNC TO GIVE TIME TO GOOGLE TO FINISH STUPID ANIMATION
    setTimeout(() => {
      this.lockMap()
    })
  }

  drawRegions() {
    const bounds = new google.maps.LatLngBounds()
    const geoJsons = this.props.geoJsons.filter((item) => item.level === 6)
    geoJsons.forEach((item) => {
      const features = this.map!.data.addGeoJson(item.geojson)
      features.forEach((feature) => {
        // EXTRACT BOUND TO FIT THE MAP
        feature.getGeometry()?.forEachLatLng((latLng) => {
          bounds.extend(latLng)
        })
      })
    })
    this.map!.fitBounds(bounds)
    if (geoJsons.length > 0) {
      // IF GEO JSON IS READY - STORE MAP BOUNDARY AND ZOOM LEVEL
      this.mapBoundary = bounds //this.map!.getBounds() ?? undefined
      this.mapMinZoomLevel = this.map!.getZoom()! - 0.8
      // RESTRICT USER ZOOM AND PAN OPTIONS
      this.lockMap()
    }
    this.mapIsReadyToRender()
  }

  lockMap() {
    if (this.mapBoundary) {
      // LOCK MAP ONLY IF BOUNDARY IS SET
      this.map!.setOptions({
        minZoom: this.mapMinZoomLevel,
        restriction: {
          latLngBounds: this.mapBoundary,
          strictBounds: false
        }
      })
    }
  }

  unlockMap() {
    this.map!.setOptions({
      minZoom: this.mapMinZoomLevel,
      restriction: undefined
    })
  }

  deepCompare<E>(o1: E, o2: E) {
    return JSON.stringify(o1) === JSON.stringify(o2)
  }

  compareArrays(pf?: string[], cf?: string[]) {
    return (
      Array.isArray(pf) &&
      Array.isArray(cf) &&
      pf.length === cf.length &&
      pf.every((v, i) => v === cf[i])
    )
  }

  applyGeoJSONStyle() {
    this.map!.data.setStyle((feature) => {
      return {
        fillColor: this.props.selectedFeatures?.includes(
          feature.getProperty('_id') as string
        )
          ? red.A100
          : this.props.theme.palette.primary.main,
        strokeWeight: 1,
        clickable: true,
        cursor: 'pointer'
      }
    })
  }

  mapIsReadyToRender() {
    if (!this.state.mapReady) {
      this.setState({mapReady: true})
    }
  }

  render() {
    // HOLDER
    return (
      <Box position={'relative'} width={'100%'} height={'100%'}>
        {/* LOADER */}
        <Box
          position={'absolute'}
          width={'100%'}
          height={'100%'}
          bgcolor={'white'}
          top={'0px'}
          left={'0px'}
          paddingTop={'150px'}
          textAlign={'center'}
          display={this.state.mapReady ? 'none' : 'block'}
          zIndex={'9999'}
        >
          <CircularProgress />
        </Box>
        {/* GOOGLE MAPS */}
        <a className={'sr-only'} href={'#skip-map'}>
          {this.props.t('common:fnd-common-aria-skip-map')}
        </a>
        <div ref={this.mapRef} style={{width: '100%', height: '100%'}} />
        <span
          id={'skip-map'}
          tabIndex={0}
          className={'sr-only'}
          onFocus={() => {
            // this.props.navigate({hash: undefined}, {replace: true})
          }}
        >
          {this.props.t('common:fnd-common-aria-skip-map-success')}
        </span>
      </Box>
    )
  }
}

export default withTranslation()(withAppContext(RegionGoogleMaps))
