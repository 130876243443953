import BrowserStorage from 'web-common/services/BrowserStorage'
import {AppTheme, ContrastTheme, MainTheme} from 'AppTheme'

type DemandsFilters = {[filter: string]: string}
type DemandsResultType = 'card' | 'list'

export const AppPreferences = {
  theme: {
    get default(): AppTheme {
      let type = BrowserStorage.get('app.preferences.theme')
      return type === 'accessibility' ? ContrastTheme : MainTheme
    },
    set default(theme: AppTheme) {
      BrowserStorage.set('app.preferences.theme', theme.type)
    }
  },

  demands: {
    // SEARCH RESULT TYPE - CARD OR LIST
    get resultType(): DemandsResultType {
      let type = BrowserStorage.get('app.preferences.demands.resultType')
      if (type) {
        return type as DemandsResultType
      } else {
        if (window.innerWidth < 678) {
          return 'card'
        } else {
          return 'list'
        }
      }
    },
    set resultType(type: DemandsResultType) {
      BrowserStorage.set('app.preferences.demands.resultType', type)
    },

    // STORED FILTERS
    get filters(): DemandsFilters {
      return (
        BrowserStorage.getAsObject<DemandsFilters>(
          'app.preferences.demands.filters'
        ) ?? {}
      )
    },
    set filters(filters: DemandsFilters) {
      BrowserStorage.setObject('app.preferences.demands.filters', filters)
    }
  }
}
