import * as H from 'history'

abstract class Guard {
  // default url to fallback is hasPermission resolve/return false
  fallbackURL: string = '/'

  /**
   * location is react route location prop
   * @param location
   */
  getFallbackUrl(location: H.Location): string {
    return this.fallbackURL
  }

  // check if user has permission ot open route
  abstract hasPermission(): Promise<boolean> | boolean
}

export default Guard
