import {FormControlLabel, Radio} from '@mui/material'
import React from 'react'
import AppCheckbox from 'web-common/components/inputs/AppCheckbox'

function generateCheckbox(
  checked: boolean,
  value: string,
  label: string,
  onClick: (values: string) => void,
  id: string
) {
  return (
    <div key={id}>
      <FormControlLabel
        control={
          <AppCheckbox
            checked={checked}
            onClick={() => {
              onClick(value)
            }}
            value={value}
          />
        }
        label={label}
      />
    </div>
  )
}

function generateRadio(
  checked: boolean,
  value: string,
  label: string,
  onClick: (values: string) => void,
  id: string
) {
  return (
    <div key={id}>
      <FormControlLabel
        control={
          <Radio
            color={'primary'}
            checked={checked}
            onChange={() => {
              onClick(value)
            }}
            value={value}
          />
        }
        label={label}
      />
    </div>
  )
}

const surveyInput = (
  type: 'radio' | 'checkbox',
  checked: boolean,
  value: string,
  label: string,
  onClick: (value: string) => void,
  id: string
) => {
  const args: [boolean, string, string, (value: string) => void, string] = [
    checked,
    value,
    label,
    onClick,
    id
  ]
  if (type === 'radio') {
    return generateRadio.apply(null, args)
  } else {
    return generateCheckbox.apply(null, args)
  }
}

export default surveyInput
