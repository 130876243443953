import {AppConfig} from 'AppConfig'
import {ContractorOfficeAddressData} from 'web-common/models/Profile'
import {ApiConfig} from 'web-common/services/request/ApiEndpoint'
import {RequestService} from 'web-common/services/request/RequestService'
import {CachedRequest} from 'web-common/services/request/Request'

export interface RegionSelectionData {
  _id: string
  name: string
  'name:en': string
  'name:he': string
  'name:ar': string
  level: number
  admin_level: string
  parentId: string | null
  children?: RegionSelectionData[]
  uiOrder?: number | null
}

export interface OfficeSelectionData {
  region: RegionSelectionData
  parentRegion: RegionSelectionData
  addresses: ContractorOfficeAddressData[]
}

export interface RegionGeoJSONData {
  level: number
  geojson: any
}

const paths = {
  regions: {
    '*': {},
    geojson: {}
  },
  requireToken: false,
  toString: () => AppConfig.api.base
}

class RegionService extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: RegionService
  protected cachedRequest = this.enhancedRequest(CachedRequest.create)
  protected language = 'en'

  public regions!: RegionSelectionData[]

  static shared() {
    if (!RegionService.instance) {
      RegionService.instance = new RegionService()
    }
    return RegionService.instance
  }

  changeLanguage(language: string) {
    this.language = language
  }

  async load(language: string) {
    this.changeLanguage(language)
    this.regions = await this.getAll()
  }

  getAll() {
    const params = {
      level: 6
    }
    return this.api.paths.regions.get<RegionSelectionData[]>(
      this.cachedRequest
    )({params: params})
  }

  get(regionId: string) {
    return this.api.paths.regions['*'].get<RegionSelectionData>(
      this.cachedRequest,
      regionId
    )()
  }

  getGeoJSONs() {
    const params = {
      level: 6
    }
    return this.api.paths.regions.geojson.get<RegionGeoJSONData[]>(
      this.cachedRequest
    )({params: params})
  }

  getRegionNameLocale(region: RegionSelectionData): string {
    const key = `name:${this.language}` as keyof RegionSelectionData
    if (region[key]) {
      return region[key] as string
    }
    return region.name
  }
}

export default RegionService.shared()
