import React from 'react'
import {Container, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import logo from 'web-common/assets/img/logo.svg'
import finderellaMan from './assets/pose-1-with-white-background.png'
import finderellaMan2x from './assets/pose-1-with-white-background@2x.png'
import finderellaMan3x from './assets/pose-1-with-white-background@3x.png'
import Grid from '@mui/material/Grid'
import FinderellaIcon from 'web-common/assets/icons/Finderella'
import {
  AccountBalanceWallet,
  AssignmentTurnedIn,
  QueryBuilder,
  Stars
} from '@mui/icons-material'
import Divider from '@mui/material/Divider'
import {
  AboutFocusGrayBoxContainer,
  AboutFocusWhiteBoxContainer,
  AboutFocusWhiteBoxImage,
  AboutFocusWhiteBoxText,
  AboutFooter,
  AboutHomeBox,
  AboutHomeTextContainer,
  AboutMenCallImageGrid,
  AboutMenCallTextGrid,
  cssClasses
} from 'views/about/AboutStyles'
import Hidden from '@mui/material/Hidden'
import {purple} from '@mui/material/colors'
import Quote from 'web-common/components/quote/Quote'
import AppPage from 'web-common/utilities/AppPage'

interface AboutViewProps {}

interface AboutViewState {}

class AboutView extends AppPage<AboutViewProps, AboutViewState> {
  title = 'About'

  static path = '/about'

  graySectionContent = [
    {
      color: this.props.theme.palette.primary.main,
      icon: Stars,
      text: 'fnd-about-superior-quality'
    },
    {
      color: this.props.theme.palette.primary.light,
      icon: AccountBalanceWallet,
      text: 'fnd-about-reduce-cost'
    },
    {
      color: this.props.theme.palette.grey[700],
      icon: QueryBuilder,
      text: 'fnd-about-faster-hire'
    },
    {
      color: purple[500],
      icon: AssignmentTurnedIn,
      text: 'fnd-about-complete-compliance'
    }
  ]

  render() {
    const homeBoxRTLClass =
      this.props.i18n.dir() === 'rtl' ? cssClasses.homeBoxRTL : {}
    return (
      <>
        {/* TITLE BOX */}
        <AboutHomeBox sx={homeBoxRTLClass}>
          <Container maxWidth={'md'} style={{paddingTop: '0px'}}>
            <Grid container justifyContent={'flex-end'}>
              <AboutHomeTextContainer item xs={12} sm={10} md={6}>
                {/* OUR STORY */}
                <Typography
                  variant={'h1'}
                  style={{fontWeight: 300, marginBottom: '6.5rem'}}
                >
                  {this.props.t('fnd-about-this-is-our-story')}
                </Typography>

                {/* BACK TO THE FEATURE */}
                <Typography variant={'h5'} component={'h2'}>
                  {this.props.t('fnd-about-back-in-2015-mission')}
                </Typography>

                {/* QUOTE FROM FINDERELLA */}
                <Box whiteSpace={'pre-line'}>
                  <Quote text={this.props.t('fnd-about-quote', {br: '\n'})} />
                </Box>

                {/* SOME CRAZY HINT */}
                <Typography variant={'body2'}>
                  {this.props.t('fnd-about-in-era-of-instant-everything')}
                </Typography>
              </AboutHomeTextContainer>
            </Grid>
          </Container>
        </AboutHomeBox>

        {/* FINDERELLA FOCUS WHITE BOX */}
        <AboutFocusWhiteBoxContainer maxWidth={'md'}>
          <Grid
            container
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            spacing={4}
          >
            {/* ICON & LOGO */}
            <AboutFocusWhiteBoxImage item>
              <Hidden mdDown>
                <Box
                  fontSize={'186px'}
                  color={this.props.theme.palette.primary.main}
                >
                  <FinderellaIcon fontSize={'inherit'} color={'inherit'} />
                </Box>
              </Hidden>
              <img src={logo} alt={''} style={{width: '186px'}} />
            </AboutFocusWhiteBoxImage>

            {/* TEXT */}
            <AboutFocusWhiteBoxText item>
              <Typography variant={'body2'}>
                {this.props.t('fnd-about-finderella-aim')}
                <br />
                <br />
                {this.props.t('fnd-about-finderella-aim-with-technology')}
              </Typography>
            </AboutFocusWhiteBoxText>
          </Grid>
        </AboutFocusWhiteBoxContainer>

        {/* FINDERELLA FOCUS GRAY BOX */}
        <AboutFocusGrayBoxContainer>
          <Container maxWidth={'md'}>
            <Typography
              variant={'h5'}
              component={'h3'}
              style={{maxWidth: '678px', margin: '0 auto 60px'}}
            >
              {this.props.t('fnd-about-finderella-focus')}
            </Typography>

            <Grid container spacing={7}>
              {this.graySectionContent.map((item) => {
                const Icon = item.icon
                return (
                  <Grid item xs={12} sm={6} md={3} key={item.text}>
                    <Box fontSize={'41px'} color={item.color}>
                      <Icon fontSize={'inherit'} color={'inherit'} />

                      <Typography
                        variant={'body1'}
                        style={{marginTop: ' 30px'}}
                      >
                        {this.props.t(item.text)}
                      </Typography>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Container>
        </AboutFocusGrayBoxContainer>

        {/* FINDERELLA MAN BOX */}
        <Box sx={{background: '#ffffff', margin: '-40px 0 -62px'}}>
          <Container maxWidth={'md'} style={{marginTop: '40px'}}>
            <Grid
              container
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              {/* TEXT */}
              <AboutMenCallTextGrid item>
                <Typography variant={'h5'} component={'h4'}>
                  {this.props.t('fnd-about-what-get-us-morning')}
                </Typography>
                <br />
                <br />
                <Typography variant={'body2'}>
                  {this.props.t('fnd-about-helping-leaders')}
                  <br />
                  <br />
                  {this.props.t('fnd-about-busy-people')}
                  <br />
                  <br />
                  {this.props.t('fnd-about-finderella-more-than-product')}
                </Typography>
              </AboutMenCallTextGrid>

              {/* FINDERELLA MAN */}
              <AboutMenCallImageGrid item>
                <img
                  width="100%"
                  src={finderellaMan}
                  alt={''}
                  srcSet={`
            ${finderellaMan3x} 3x,
            ${finderellaMan2x} 2x,
            ${finderellaMan} 1x,
            `}
                />
              </AboutMenCallImageGrid>
            </Grid>
          </Container>
        </Box>

        {/* WHY SEARCH WHEN YOU CAN ASK */}
        <AboutFooter>
          <Box>
            {/* FINDERELLA BRINGS A NEW LINE */}
            <Typography variant={'body2'}>
              {this.props.t('fnd-about-finderella-bring-new-line')}
            </Typography>

            {/* WHY SEARCH WHEN YOU CAN ASK */}
            <Typography variant={'h5'}>
              {this.props.t('fnd-about-why-search-when-ask')}
            </Typography>

            <Divider />

            {/* TELL US WHAT YOU NEED */}
            <Typography variant={'body2'}>
              {this.props.t('fnd-about-finderella-for-everyone')}
            </Typography>
          </Box>
        </AboutFooter>
      </>
    )
  }
}

export default AboutView.exportDefault()
