import {useContext, useMemo, useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {
  Alert,
  Box,
  Button,
  Link,
  Stack,
  styled,
  Typography
} from '@mui/material'
import ServicesService from 'web-common/services/ServicesService'
import browserStorage from 'web-common/services/BrowserStorage'
import ServiceImageService from 'services/ServiceImageService'
import {AppContext} from 'web-common/contexts/AppContext'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {SearchableService} from 'web-common/models/CategoriesAndServices'
import Base64 from 'web-common/services/base64/Base64'
import kc from 'web-common/services/auth'
import Loading from 'web-common/components/loading/Loading'
import {AppConfig} from 'AppConfig'
import QuestionnairesService from 'views/questionnaires/QuestionnairesService'
import Hidden from 'web-common/components/hidden/Hidden'
import {responsiveBP} from 'web-common/theme/AppTheme'
import AccessibilityControl from 'components/accessibility/AccessibilityControl'
import useInit from 'web-common/utilities/hooks/useInit'
import {AppSurveySessionStorage} from 'web-common/components/survey/services/SurveySessionStorage'
import SurveyBehaviorSelector from './survey-behavior-selector/SurveyBehaviorSelector'
import BehaviorOptions from './survey-behavior-selector/BehaviorOptions.type'
import {BEHAVIOR_STORAGE_KEY} from './config/constants'

const textMaxWidth = '376px'
const textBoxMargin = '2rem'

const StyledTitle = styled(Typography)((_) => ({
  display: 'flex',
  maxWidth: 'calc(100% - 36px)',
  flexShrink: 1,
  textAlign: 'center'
}))

const CustomLoading = styled(Box)({
  flexShrink: 0,
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
})

const TextParagraph = styled(Typography)({
  maxWidth: textMaxWidth,
  textAlign: 'start'
})

const Illustration = styled('img')({
  borderRadius: '4px',
  width: '100%',
  maxWidth: textMaxWidth
})

const StyledAlert = styled(Alert)({
  maxWidth: `calc(${textMaxWidth} - 2rem)`,
  textAlign: 'start'
})

interface WAToken {
  exp: number
  iat: number
  iss: string
  sub?: string
  lang: string
  name: string
  phone: string
  serviceId: string
  sessionId?: string
}

interface QuestionnairesWelcomeViewProps {
  serviceId: string
  onBegin: (sessionId?: string) => void
}

type URLTokenFlows =
  | 'valid-token-new-user'
  | 'user-need-logout'
  | 'default'
  | 'invalid-token'
  | undefined

const QuestionnairesWelcomeView = (props: QuestionnairesWelcomeViewProps) => {
  const appContext = useContext(AppContext)
  const [t, i18n] = useTranslation()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [flow, setFlow] = useState<URLTokenFlows>(undefined)
  const [sessionId, setSessionId] = useState<string | undefined>()
  const autoBeingCookieValue = '1'
  const [metaService, imageURL, Icon] = useMemo((): [
    SearchableService | undefined,
    string,
    any
  ] => {
    const service = ServicesService.getServiceById(props.serviceId)
    if (!service) {
      return [undefined, '', undefined]
    }
    const imageURL = ServiceImageService.getImage(service!)
    const Icon = ServicesService.iconFromCategory(service!.parentCategory.id)
    return [service, imageURL, Icon]
  }, [props.serviceId])
  const [service, setService] = useState(metaService)
  // Behavior selector experiment
  const isAccessibilityTheme = appContext.theme.type === 'accessibility'
  // Showing the selector doesn't make sense in accessibility mode because in that case we disable auto-advance
  // we can extend these criteria if need be...
  const showSurveyBehaviorSelector = !isAccessibilityTheme
  const [behavior, setBehavior] = useState<BehaviorOptions | undefined>(
    undefined
  )
  const [wasBehaviorSelectorAlreadyUsed, setBehaviorSelectorAlreadyUsed] =
    useState(false)
  const [isBehaviorSelectionError, setBehaviorSelectionError] = useState(false)

  function onSelectHandler(value: BehaviorOptions) {
    setBehavior(value)
    browserStorage.set(BEHAVIOR_STORAGE_KEY, value)
  }

  useEffect(() => {
    // don't do anything if we're not gonna show the selector...
    if (!showSurveyBehaviorSelector) {
      return
    }

    const savedBehavior = browserStorage.get(BEHAVIOR_STORAGE_KEY) as
      | BehaviorOptions
      | undefined
    if (savedBehavior) {
      setBehaviorSelectorAlreadyUsed(true)
      setBehavior(savedBehavior)
    }
  }, [showSurveyBehaviorSelector])

  useInit(() => {
    // Check if there is a valid service
    if (!service) {
      navigate('/404')
      return
    }

    const codeToToken = (): WAToken => {
      const tokenData = JSON.parse(
        Base64.decode(params.get('token')!.split('.')[1])
      )
      const nowInSeconds = Date.now() / 1000
      if (tokenData.exp < nowInSeconds) {
        throw new Error('Token is expired')
      }
      return tokenData
    }

    const updateAppLanguage = async (lang: string) => {
      if (i18n.language === lang) {
        return
      }
      await i18n.changeLanguage(lang)
      await ServicesService.load(lang)
      setService(ServicesService.getServiceById(props.serviceId))
    }

    // Check if user can start questionnaire right away - whatsapp
    const autoBeginWithCode =
      QuestionnairesService.shared().getAutoBeginCookie()
    if (autoBeginWithCode) {
      const [serviceId, sessionId] = autoBeginWithCode.split('###')
      QuestionnairesService.shared().deleteAutoBeginCookie()
      if (serviceId === props.serviceId) {
        props.onBegin(
          serviceId === service.service.id && sessionId !== autoBeingCookieValue
            ? sessionId
            : undefined
        )
        return
      }
    }
    // Check if user can start questionnaire right away from previous session
    const hasSession = !new AppSurveySessionStorage(props.serviceId).isEmpty()
    if (hasSession) {
      props.onBegin(undefined)
      return
    }

    // Proceed normal loading
    ;(async () => {
      // Default flow - user need to click on begin
      if (!params.has('token')) {
        setFlow('default')
        return
      }
      let tokenData: WAToken
      try {
        tokenData = codeToToken()
      } catch (e) {
        setFlow('invalid-token')
        return
      }

      // Update language
      await updateAppLanguage(tokenData.lang)
      // Store session token
      setSessionId(tokenData.sessionId)
      if (kc.instance.tokenParsed && tokenData.sub) {
        // USER IS LOGGED
        if (tokenData.sub === kc.instance.tokenParsed.sub) {
          // USER IS THE SAME FROM THE URL CODE
          setFlow('default')
        } else {
          // USER IS DIFFERENT FROM THE URL CODE
          setFlow('user-need-logout')
        }
      } else {
        // TRY TO REGISTER
        setFlow('valid-token-new-user')
      }
    })()
  })

  const onBegin = async () => {
    if (flow === 'valid-token-new-user') {
      try {
        const meta: any =
          await QuestionnairesService.shared().registerWithToken(
            params.get('token')!
          )
        QuestionnairesService.shared().setAutoBeginCookie(
          service?.service.id + '###' + (sessionId ?? autoBeingCookieValue)
        )
        window.location.href = meta.data.redirectUri
      } catch (e) {
        console.error(e)
        appContext.pushNotification({
          color: 'error',
          message: 'fnd-label-unexpected-error'
        })
      }
    } else {
      props.onBegin(sessionId)
    }
  }

  return (
    <CustomLoading>
      <Loading
        loading={flow === undefined}
        render={() => (
          <>
            <Box
              sx={{
                fontSize: '36px',
                color: appContext.theme.palette.primary.main,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'center',
                marginBottom: '30px',
                paddingTop: '1rem'
              }}
            >
              {/* ICON */}
              <Icon
                sx={{
                  margin: '0 10px',
                  position: 'relative',
                  top: '4px',
                  fontSize: 'inherit'
                }}
              />

              {/* TEXT */}
              <StyledTitle variant="h4">{service!.service.name}</StyledTitle>
            </Box>

            <Box sx={{margin: `0 auto ${textBoxMargin}`}}>
              <Stack direction="column" spacing={3}>
                {/* IMAGE */}
                <Illustration alt="service questioner" src={imageURL} />

                {/* TEXT */}
                <TextParagraph>
                  {t('fnd-survey-welcome-by-answering')}
                </TextParagraph>

                <TextParagraph>
                  {t('fnd-survey-welcome-do-your-best')}
                </TextParagraph>

                {/*TERMS AND CONDITIONS ONLY WHEN USER NEED TO REGISTER*/}
                {flow === 'valid-token-new-user' && (
                  <TextParagraph variant="caption">
                    <Link
                      href={
                        AppConfig.publicDir +
                        '/docs/he/Terms_and_Conditions_of_Use-Hebrew.html'
                      }
                      target="_blank"
                    >
                      {t('fnd-wa-demand-link-agree-terms')}
                    </Link>
                  </TextParagraph>
                )}

                {/*THE CODE AND THE  USER DID NOT MATCH*/}
                {flow === 'user-need-logout' && (
                  <StyledAlert severity="warning">
                    {t('fnd-wa-demand-link-user-already-logged')}
                  </StyledAlert>
                )}

                {/*THE CODE AND THE  USER DID NOT MATCH*/}
                {flow === 'invalid-token' && (
                  <StyledAlert severity="error">
                    {t('fnd-wa-demand-link-invalid-token')}
                  </StyledAlert>
                )}
              </Stack>
            </Box>

            {/* SELECT THE DEFAULT BEHAVIOR OF THE SURVEY */}
            {showSurveyBehaviorSelector && (
              <SurveyBehaviorSelector
                onBehaviorSelection={onSelectHandler}
                behavior={behavior}
                isError={isBehaviorSelectionError}
                wasUsed={wasBehaviorSelectorAlreadyUsed}
                sx={{
                  width: '100%',
                  maxWidth: textMaxWidth,
                  margin: '0 auto',
                  paddingBottom: '1rem'
                }}
              />
            )}

            <Box sx={{textAlign: 'center', marginTop: '20px'}}>
              <Button
                variant="contained"
                onClick={() => {
                  // Must not start the survey without a behavior being selected
                  // can be removed or refactored (not be inlined) after the experiment
                  if (isAccessibilityTheme) {
                    onBegin()
                  } else {
                    if (behavior) {
                      onBegin()
                    } else {
                      setBehaviorSelectionError(true)
                    }
                  }
                }}
                color="primary"
              >
                {t('fnd-label-begin')}
              </Button>
            </Box>

            <Hidden {...responsiveBP.forDesktop}>
              <Box sx={{marginTop: '32px', display: 'flex'}}>
                <AccessibilityControl stationary />
              </Box>
            </Hidden>
          </>
        )}
      />
    </CustomLoading>
  )
}
export default QuestionnairesWelcomeView
