// MOVED FROM ASSIGNMENT SERVICES FROM CONTRACTOR PROJECT
export interface PreviewData {
  regions: string[]
  offices: string[]
  budget: string
}

export interface OfferData {
  pricing?: Pricing | null
  noPricingReason?: NoPricingReason | null
  price?: number | null
  hourRate?: number | null
  comment?: string | null
  isEstimate?: boolean
  dateCreated: number
  dateExpire?: number | null
  dateSeen?: number | null
  dateChanged?: number | null
  status: OfferStatus
  changeReason?: OfferChangeReasonData | null
}

export interface OfferRequest {
  pricing?: PricingItem[]
  noPricingReason?: NoPricingReason
  comment?: string
}

export enum NotProvidePriceReason {
  NEED_MORE_TIME = 'NEED_MORE_TIME',
  MEET_CLIENT = 'MEET_CLIENT',
  VISIT_PLACE = 'VISIT_PLACE',
  NEED_ATTACHMENTS_FILES = 'NEED_ATTACHMENTS_FILES',
  MORE_QUESTIONS = 'MORE_QUESTIONS',
  OTHER = 'OTHER'
}

export interface NoPricingReason {
  reason: NotProvidePriceReason
  descriptions?: string[]
  tax?: number
}

export interface PreviousOfferData {
  pricing?: Pricing
  notProvideReason?: {[key: string]: any}
  comment?: string
  dateCreated: number
  customerName: string | null
  preview: PreviewData
}

export interface Pricing {
  items: PricingItem[]
}

export interface PricingItem {
  _id?: string
  _refs: {unitId?: string}
  price: number
  unit?: string
  description?: string
}

export enum OfferStatus {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export interface OfferChangeReasonData {
  reason: OfferRejectReason
  comment?: string
}

export enum OfferRejectReason {
  PRICE_MISMATCH_BUDGET = 'PRICE_MISMATCH_BUDGET',
  INACCURATE_DESCRIPTION = 'INACCURATE_DESCRIPTION',
  RECEIVED_BETTER_OFFER = 'RECEIVED_BETTER_OFFER',
  REQUEST_UPDATE = 'REQUEST_UPDATE',
  REQUEST_NEW = 'REQUEST_NEW',
  DEMAND_CLOSED = 'DEMAND_CLOSED',
  OTHER = 'OTHER'
}
