import CustomerEventProducer from 'views/demands/demand/timeline/CustomerEventProducer'
import DemandsService from 'views/demands/DemandsService'
import RatingEvent, {
  RatingEventProps
} from 'views/demands/demand/timeline/events/RatingEvent'
import {
  RateData,
  ReviewData,
  ReviewStatusData
} from 'web-common/models/RatingModels'
import kc from 'web-common/services/auth'
import {TimelineAdapter, TimelineEventData} from '@fnd/timeline'

export class ReviewAdapter extends TimelineAdapter<RatingEventProps> {
  static id = 'review'

  constructor(
    public producer: CustomerEventProducer,
    private customerReviewId?: string,
    private contractorReviewId?: string
  ) {
    super()
  }

  async load(): Promise<TimelineEventData<RatingEventProps>[]> {
    const actions: TimelineEventData<RatingEventProps>[] = []
    if (this.customerReviewId) {
      const review = await DemandsService.getReview(this.customerReviewId)
      const event = this.eventCustomer(this.customerReviewId, review)
      if (
        event &&
        (review.status === ReviewStatusData.COMPLETED ||
          review.status === ReviewStatusData.PUBLISHED)
      ) {
        actions.push(event)
      }
    }
    if (this.contractorReviewId) {
      const review = await DemandsService.getReview(this.contractorReviewId)
      const event = this.eventContractor(this.contractorReviewId, review)
      if (event && review.status === ReviewStatusData.PUBLISHED) {
        actions.push(event)
      }
    }
    return actions
  }

  public rateDataToEvent(
    rate?: RateData
  ): TimelineEventData<RatingEventProps> | undefined {
    const review = {
      datePublished: Date.now(),
      dateCompleted: Date.now(),
      status: ReviewStatusData.PUBLISHED,
      author: kc.instance.tokenParsed?.name ?? '',
      rating: rate!.rating,
      comment: rate?.comment
    } as ReviewData
    return this.eventCustomer('new-id', review)
  }

  // CUSTOMER LEFT REVIEW
  public eventCustomer(
    id?: string,
    review?: ReviewData
  ): TimelineEventData<RatingEventProps> | undefined {
    if (!id || !review) {
      return undefined
    }
    if (review.status !== 'PENDING') {
      this.producer.actionManager.removeAction('write-review')
      this.producer.actionManager.removeAction('compare-offers')
    }
    return {
      _id: id + 'customer-review',
      date: review.dateCompleted,
      producer: this.producer,
      alignment: 'flex-end',
      props: {
        from: 'customer',
        review: review
      },
      element: RatingEvent,
      adapterId: ReviewAdapter.id
    }
  }

  // CONTRACTOR LEFT REVIEW
  public eventContractor(
    id?: string,
    review?: ReviewData
  ): TimelineEventData<RatingEventProps> | undefined {
    if (!id || !review || !review.datePublished) {
      return undefined
    }
    return {
      _id: id + 'contractor-review',
      date: review.datePublished,
      producer: this.producer,
      alignment: 'flex-start',
      props: {
        from: 'contractor',
        review: review
      },
      element: RatingEvent,
      adapterId: ReviewAdapter.id
    }
  }

  complete() {}
}
