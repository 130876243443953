import TimelineEventTemplate from 'web-common/views/timeline/TimelineEventTemplate'
import {OfferData} from 'web-common/models/OfferModel'
import {CardContent, CardHeader, Divider} from '@mui/material'
import {SystemCard} from 'web-common/views/timeline/styled/SystemCard'
import React from 'react'
import {OfferPreview} from 'web-common/views/timeline/components/OfferPreview'
import {withTranslation, WithTranslation} from 'react-i18next'

export interface NewOfferEventProps {
  offer: OfferData
}

interface InternalProps extends NewOfferEventProps, WithTranslation {}

class NewOfferEvent extends TimelineEventTemplate<InternalProps, any> {
  render() {
    const title = this.props.offer.noPricingReason
      ? 'fnd-event-title-contractor-send-new-offer-later'
      : 'fnd-event-title-contractor-send-new-offer'
    return (
      <SystemCard>
        <CardHeader
          title={this.props.t(title)}
          titleTypographyProps={{variant: 'subtitle2'}}
        />
        <Divider />
        <CardContent>
          <OfferPreview offer={this.props.offer} />
        </CardContent>
      </SystemCard>
    )
  }
}

export default withTranslation()(NewOfferEvent)
