import React from 'react'
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  styled,
  Typography
} from '@mui/material'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import {WithTranslation, withTranslation} from 'react-i18next'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  CategoryData,
  ServiceData
} from 'web-common/models/CategoriesAndServices'
import ServicesService from 'web-common/services/ServicesService'
import {spreadAriaButton} from 'web-common/utilities/whatsappUtilities'
import {CancelButton} from 'web-common/components/inputs/CancelButton'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'
import {purple, red} from '@mui/material/colors'
import {WithAppContext, withAppContext} from 'web-common/contexts/AppContext'

const IconWrapper = styled('span')((_) => ({
  margin: '0px', //theme.direction === 'ltr' ? '0 12px 0 0' : '0 0 0 12px',
  width: '24px',
  height: '24px',
  position: 'relative',
  top: '4px'
}))
const BreadcrumbsCustom = styled(Breadcrumbs)((_) => ({
  '& li': {height: '24px', display: 'flex', alignItems: 'flex-end'}
}))
const ServiceIconWrapper = styled('span')((props) => ({
  width: '18px',
  height: '18px',
  margin: '0 5px',
  position: 'relative',
  top: '1px',
  color: props.theme.palette.grey[800]
}))

interface BrowseCardProps
  extends WithTranslation,
    RouteComponentProps,
    WithAppContext {
  category: CategoryData
  selectedCategory?: string
  mainCategory?: string
  onCategorySelect: (category: CategoryData) => void
  onServiceSelect: (category: ServiceData) => void
  onUserNotFoundService: (category: CategoryData) => void
  fallbackLanguage?: string
}

interface BrowseCardState {
  mainCategory?: string
  selectedCategory?: string
  showAll: boolean
}

class BrowseCard extends React.Component<BrowseCardProps, BrowseCardState> {
  state: BrowseCardState = {
    mainCategory: this.props.mainCategory,
    selectedCategory: this.props.selectedCategory,
    showAll: false //window.innerWidth > 600
  }
  categoryLimitHandlerVisible = true //!(window.innerWidth > 600)
  scrollMarker = React.createRef<HTMLDivElement>()
  mobileLimit = 9

  readonly categoryToColor: {[id: string]: string} = {
    business: this.props.theme.palette.primary.main,
    classes_and_lessons: this.props.theme.palette.warning.dark,
    events: purple[600],
    health_and_wellness: this.props.theme.palette.success.dark,
    home_services: red[400]
  }

  componentDidMount() {
    if (this.props.selectedCategory) {
      const selected = this.props.category.children.find(
        (c) => c._id === this.props.selectedCategory
      )
      if (selected && this.scrollMarker.current) {
        const headerHeight = window.innerWidth > 600 ? 120 : 20
        const rect = this.scrollMarker.current.getBoundingClientRect()
        window.scrollTo(0, window.scrollY + rect.y - headerHeight)
      }
    } else if (this.props.mainCategory) {
      const selected = this.props.category.children.find(
        (c) => c.parentId === this.props.mainCategory
      )
      if (selected && this.scrollMarker.current) {
        const headerHeight = window.innerWidth > 600 ? 120 : 20
        const rect = this.scrollMarker.current.getBoundingClientRect()
        window.scrollTo(0, window.scrollY + rect.y - headerHeight)
      }
    }
  }

  scrollToTop(y: number) {
    const scrollStep = -window.scrollY / (200 / 15),
      scrollInterval = setInterval(function () {
        if (window.scrollY > y) {
          window.scrollBy(0, scrollStep)
        } else clearInterval(scrollInterval)
      }, 15)
  }

  onCategorySelect(category: CategoryData) {
    this.setState(
      {selectedCategory: category._id, mainCategory: category.parentId!},
      () => {
        this.props.onCategorySelect(category)
        if (this.scrollMarker.current) {
          const rect = this.scrollMarker.current.getBoundingClientRect()
          const headerHeight = window.innerWidth > 600 ? 120 : 20
          this.scrollToTop(window.scrollY + rect.y - headerHeight)
        }
      }
    )
  }

  onServiceSelect(service: ServiceData) {
    this.props.onServiceSelect(service)
  }

  onCategoryBack() {
    this.setState({selectedCategory: undefined})
  }

  extractNameString(
    item: ServiceData | CategoryData,
    fb: string = 'he'
  ): string {
    let fallback = this.props.fallbackLanguage ?? fb
    if (item.l10n?.[this.props.i18n.language]?.name) {
      return item.l10n?.[this.props.i18n.language]?.name
    } else if (item.l10n?.[fallback]?.name) {
      return item.l10n?.[fallback]?.name
    }
    return item.name
  }

  renderCardHeader(category: CategoryData) {
    const Icon = ServicesService.iconFromCategory(category._id)
    const color = this.categoryToColor[category._id]
    const separator = (
      <Typography variant={'body1'}>
        {this.props.i18n.dir() === 'ltr' ? '»' : '«'}
      </Typography>
    )
    const bcStyle = {color: color, padding: '4px 4px 0 4px'}
    const selected = category.children.find(
      (c) => c._id === this.state.selectedCategory
    )
    const mainStyle = selected
      ? {cursor: 'pointer', color: color, textDecoration: 'underline'}
      : {color: color}
    return (
      <BreadcrumbsCustom separator={separator} style={bcStyle}>
        {/* MAIN CATEGORY */}
        <Box
          style={mainStyle}
          tabIndex={-1}
          display={'flex'}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            this.onCategoryBack()
          }}
        >
          <Typography variant={'body1'} component={'h2'}>
            <IconWrapper aria-hidden>
              <Icon />
            </IconWrapper>
            <Box display="inline" margin={'0 12px'} component={'span'}>
              {this.extractNameString(category)}
            </Box>
          </Typography>
        </Box>

        {/* SUB CATEGORY */}
        {((_) => {
          if (selected !== undefined) {
            return (
              <Typography variant={'body1'} style={{color: color}}>
                {this.extractNameString(selected)}
              </Typography>
            )
          }
        })()}
      </BreadcrumbsCustom>
    )
  }

  renderCardBody(parentCategory: CategoryData) {
    const selected = parentCategory.children.find(
      (c) => c._id === this.state.selectedCategory
    )
    return selected !== undefined
      ? this.renderServicesLists(selected)
      : this.renderCategoriesList(parentCategory)
  }

  renderCategoriesList(parentCategory: CategoryData) {
    let categories: CategoryData[] = [...parentCategory.children].sort(
      (a, b) => {
        return (
          (b.services.filter((s) => s.active).length ?? 0) -
          (a.services.filter((s) => s.active).length ?? 0)
        )
      }
    )
    let exceedLimit = categories.length > this.mobileLimit
    if (!this.state.showAll && exceedLimit) {
      categories = categories.slice(0, this.mobileLimit)
    }

    return (
      <Box padding={'20px 0px'}>
        <Grid container spacing={4}>
          {categories.map((category) => (
            <Grid item xs={12} md={4} key={category._id}>
              <Box
                {...spreadAriaButton(
                  this.extractNameString(category),
                  this.onCategorySelect.bind(this, category)
                )}
              >
                <Typography variant={'body1'} style={{cursor: 'pointer'}}>
                  {this.extractNameString(category)}
                  {((_) => {
                    const activeServices =
                      category.services.filter((s) => s.active).length ?? 0
                    if (activeServices > 0) {
                      return ` (${activeServices}) `
                    }
                  })()}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>

        {((_) => {
          if (this.categoryLimitHandlerVisible && exceedLimit) {
            return (
              <Box
                onClick={() =>
                  this.setState(
                    (state) => ({
                      showAll: !state.showAll
                    }),
                    () => {
                      if (this.scrollMarker.current && !this.state.showAll) {
                        const rect =
                          this.scrollMarker.current.getBoundingClientRect()
                        const headerHeight = window.innerWidth > 600 ? 120 : 20
                        this.scrollToTop(window.scrollY + rect.y - headerHeight)
                      }
                    }
                  )
                }
                marginTop={'20px'}
                style={{cursor: 'pointer'}}
              >
                <Typography variant={'body2'} color={'textSecondary'}>
                  {this.props.t(
                    this.state.showAll
                      ? 'fnd-label-show-less'
                      : 'fnd-label-show-more'
                  )}
                </Typography>
              </Box>
            )
          }
        })()}
      </Box>
    )
  }

  renderServicesLists(category: CategoryData) {
    const active: ServiceData[] = []
    const inactive: ServiceData[] = []
    const BackIcon =
      this.props.i18n.dir() === 'ltr' ? ChevronLeftIcon : ChevronRightIcon
    category.services.forEach((service) =>
      service.active ? active.push(service) : inactive.push(service)
    )
    return (
      <>
        {/* ACTIVE LIST */}
        {this.renderActiveServices(active)}
        {((_) => {
          // DIVIDER
          if (active.length > 0 && inactive.length > 0) {
            return <Divider style={{margin: '30px 0'}} />
          }
        })()}
        {/* INACTIVE LIST */}
        {this.renderInactiveServices(inactive)}

        <Divider style={{margin: '2rem 0 1rem'}} />

        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <CancelButton
              variant={'text'}
              onClick={this.onCategoryBack.bind(this)}
            >
              <BackIcon /> {this.props.t('fnd-label-back')}
            </CancelButton>
          </Grid>
          <Grid item>
            <Button
              color={'primary'}
              onClick={this.props.onUserNotFoundService.bind(this, category)}
            >
              {this.props.t('fnd-label-didnt-find-service')}
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  renderActiveServices(services: ServiceData[]) {
    if (services.length === 0) return null
    return (
      <>
        <Box margin={'30px 0'} display={'flex'} justifyContent={'center'}>
          <ServiceIconWrapper>
            <CheckCircleIcon fontSize={'inherit'} />
          </ServiceIconWrapper>
          <Typography variant={'body2'}>
            {this.props.t('fnd-label-active-services')} ({services.length})
          </Typography>
        </Box>
        <Grid container>
          {services.map((s) => (
            <Grid item xs={12} md={4} key={s._id}>
              <Typography
                variant={'body2'}
                color={'textSecondary'}
                style={{cursor: 'pointer', padding: '8px 0'}}
                {...spreadAriaButton(
                  this.extractNameString(s),
                  this.onServiceSelect.bind(this, s)
                )}
              >
                {this.extractNameString(s)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </>
    )
  }

  renderInactiveServices(services: ServiceData[]) {
    if (services.length === 0) return null
    return (
      <>
        <Box margin={'30px 0 10px'} display={'flex'} justifyContent={'center'}>
          <ServiceIconWrapper>
            <QueryBuilderIcon fontSize={'inherit'} />
          </ServiceIconWrapper>
          <Typography variant={'body2'}>
            {this.props.t('fnd-label-coming-soon-services')}
          </Typography>
        </Box>

        <Typography
          variant={'body2'}
          color={'textSecondary'}
          style={{textAlign: 'center'}}
        >
          {services.map((s) => this.extractNameString(s)).join(' . ')}
        </Typography>
      </>
    )
  }

  render() {
    return (
      <>
        <div ref={this.scrollMarker} />
        <Card>
          <CardHeader title={this.renderCardHeader(this.props.category)} />
          <Divider variant={'middle'} />
          <CardContent aria-live={'polite'}>
            {this.renderCardBody(this.props.category)}
          </CardContent>
        </Card>
      </>
    )
  }
}

export default withRouter(withTranslation()(withAppContext(BrowseCard)))
