import React from 'react'
import {Button, Stack} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import {DemandChatPreview, LiveAssignmentData} from 'views/demands/DemandsQuery'
import AppModal from 'web-common/components/modal/AppModal'
import CompareOffers from 'views/demands/demand/comparation/CompareOffers'
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded'
import Offer from 'views/demands/demand/list/Offer'
import NoOffers from 'views/demands/demand/list/NoOffers'
import {DemandData} from 'models/Demands'

interface OffersProps extends WithTranslation {
  assignments: DemandChatPreview<LiveAssignmentData>[]
  demand: DemandData
  onSelect: (offerId: string) => void
}

interface OffersState {
  reload: number
  compareOffersModal: boolean
}

class Offers extends React.Component<OffersProps, OffersState> {
  state: OffersState = {
    reload: Date.now(),
    compareOffersModal: false
  }

  onCompareOffersOpen() {
    this.setState({compareOffersModal: true})
  }

  onCompareOfferClose() {
    this.setState({compareOffersModal: false})
  }

  render() {
    const hasAssignments = this.props.assignments.length > 0
    return (
      <>
        <Stack spacing={2}>
          {/*NO OFFERS*/}
          {!hasAssignments && <NoOffers demand={this.props.demand} />}
          {this.props.assignments.length > 1 && (
            <Button
              variant={'text'}
              color={'primary'}
              onClick={this.onCompareOffersOpen.bind(this)}
              startIcon={<FormatListNumberedRoundedIcon />}
              size={'small'}
              sx={{alignSelf: 'flex-end'}}
            >
              {this.props.t('fnd-demand-compare-offers')}
            </Button>
          )}

          {/*OFFERS*/}
          {hasAssignments && (
            <Stack spacing={1} direction={'column'}>
              {this.props.assignments.map((item) => (
                <Offer
                  assignment={item}
                  demand={this.props.demand}
                  onSelect={this.props.onSelect}
                  key={item._id}
                />
              ))}
            </Stack>
          )}
        </Stack>
        {/*COMPARE OFFERS*/}
        <AppModal
          open={this.state.compareOffersModal}
          title={this.props.t('fnd-demand-compare-offers')}
          onClose={this.onCompareOfferClose.bind(this)}
          maxWidth={'lg'}
        >
          <CompareOffers demandId={this.props.demand._id} />
        </AppModal>
      </>
    )
  }
}

export default withTranslation()(Offers)
