import React from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded'
import {withTranslation, WithTranslation} from 'react-i18next'
import {AppConfig} from 'AppConfig'
import {ReviewData} from 'web-common/models/RatingModels'
import {RatingSummary} from 'web-common/models/Profile'
import {
  HttpParams,
  PageQuery,
  PageQueryOperator
} from 'web-common/services/PageQuery'
import ContractorService from 'web-common/services/ContractorService'
import CustomerService from 'web-common/services/CustomerService'
import AppRating from 'web-common/components/rating/AppRating'
import AppReview from 'web-common/views/reviews/AppReview'
import {withAppContext, WithAppContext} from 'web-common/contexts/AppContext'

interface ReviewsViewProps extends WithTranslation, WithAppContext {
  contractorReviews: boolean
}

interface ReceivedReviewsViewState {
  reviews: ReviewData[]
  ratingSummary?: RatingSummary
  loading: boolean
}

class ReceivedReviewsView extends React.Component<
  ReviewsViewProps,
  ReceivedReviewsViewState
> {
  state: ReceivedReviewsViewState = {
    reviews: [],
    ratingSummary: undefined,
    loading: false
  }

  httpParams = new HttpParams()
  limit = 3
  offset = 0

  memberPath = this.props.contractorReviews ? 'contractor' : 'customer'

  pageQuery: PageQuery<any> = new PageQuery({
    path: `${AppConfig.api.base}/${this.memberPath}/reviews/received/find`,
    params: new HttpParams().set('limit', this.limit.toString())
  })

  async componentDidMount() {
    const profile = this.props.contractorReviews
      ? await ContractorService.get()
      : await CustomerService.get()
    this.setState({
      ratingSummary: profile.ratingSummary
    })
    this.pageQuery.subscribe((data) => {
      this.setState((state) => ({
        reviews: state.reviews.concat(data),
        loading: false
      }))
    })
    this.pageQuery.fetch()
  }

  loadMore() {
    this.pageQuery.setFilters(PageQueryOperator.SET, {
      offset: (this.pageQuery.data?.offset ?? 0) + this.limit,
      limit: this.limit
    })
  }

  renderRatingSummary() {
    const rating = this.state.ratingSummary?.rating ?? 0
    const votes = this.state.ratingSummary?.votes ?? 0
    return (
      <Box bgcolor={this.props.theme.palette.grey[50]} padding={'20px'}>
        <AppRating rating={rating} count={votes} />
      </Box>
    )
  }

  renderReceivedReviews() {
    if (this.state.reviews.length === 0) {
      const message = this.props.contractorReviews
        ? 'common:fnd-common-empty-received-review-contractor'
        : 'common:fnd-common-empty-received-review-customer'
      return <Typography variant={'body2'}>{this.props.t(message)}</Typography>
    } else {
      return (
        <>
          <Grid container spacing={3}>
            {this.state.reviews.map((review) => (
              <Grid item xs={12} key={review._id}>
                <AppReview review={review} isMyReview={false} />
              </Grid>
            ))}
          </Grid>

          {/* SHOW LOADING SCREEN */}
          {(() => {
            if (this.state.loading) {
              return (
                <Box textAlign={'center'}>
                  <CircularProgress size={20} />
                </Box>
              )
            }
          })()}

          {/* LOAD MORE REVIEWS */}
          {(() => {
            if (
              (this.pageQuery.data?.offset ?? 0) +
                (this.pageQuery.data?.data.length ?? 0) <
              (this.pageQuery.data?.found ?? 0)
            ) {
              return (
                <Box
                  style={{cursor: 'pointer'}}
                  fontSize={'14px'}
                  onClick={this.loadMore.bind(this)}
                  marginTop={'32px'}
                  marginBottom={'10px'}
                >
                  {this.props.t('fnd-label-more-reviews')}
                  <Box
                    fontSize={'18px'}
                    display={'inline'}
                    position={'relative'}
                    top={'2px'}
                    left={'4px'}
                    right={'4px'}
                  >
                    {((_) => {
                      return this.props.i18n.dir() === 'ltr' ? (
                        <ChevronRightIcon fontSize={'inherit'} />
                      ) : (
                        <ChevronLeftIcon fontSize={'inherit'} />
                      )
                    })()}
                  </Box>
                </Box>
              )
            }
          })()}
        </>
      )
    }
  }

  render() {
    return (
      <Stack flexDirection={'column'} spacing={3}>
        <Card>
          <CardHeader
            title={this.props.t('common:fnd-common-your-overall-rating')}
          />
          <Divider />
          <CardContent>{this.renderRatingSummary()}</CardContent>
        </Card>

        <Card>
          <CardHeader
            title={this.props.t('common:fnd-common-received-reviews')}
          />
          <Divider />
          <CardContent>{this.renderReceivedReviews()}</CardContent>
        </Card>
      </Stack>
    )
  }
}

export default withTranslation()(withAppContext(ReceivedReviewsView))
