import React from 'react'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'
import {FormControlLabel, Switch, Tooltip} from '@mui/material'

interface AppFormToggleProps extends AppFormComponent {}

interface AppFormToggleState {}

class AppFormToggle extends AppFormInputComponent<
  AppFormToggleProps,
  AppFormToggleState
> {
  onChange(e: React.ChangeEvent<any>) {
    const {onChangeCallback, ...rest} = {...this.props}
    this.props.onChangeCallback(rest, e.target.checked)
  }

  render() {
    const input = (
      <FormControlLabel
        control={
          <Switch
            color={'primary'}
            defaultChecked={this.props.checked}
            disabled={this.props.disabled}
          />
        }
        onChange={this.onChange.bind(this)}
        label={this.props.label}
      />
    )
    if (this.props.placeholder) {
      return <Tooltip title={this.props.placeholder}>{input}</Tooltip>
    }
    return input
  }
}

export default AppFormToggle
