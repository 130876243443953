import Switch, {SwitchProps} from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'

interface ToggleProps extends SwitchProps {
  label?: string | React.ReactElement // | number
}

class ToggleForm extends React.Component<ToggleProps, any> {
  getSwitch(rest: any) {
    return <Switch {...rest} color={'primary'} />
  }

  render() {
    const {classes, label, ...rest} = {...this.props}
    if (!label) {
      return this.getSwitch(rest)
    }
    return <FormControlLabel control={this.getSwitch(rest)} label={label} />
  }
}

export default ToggleForm
