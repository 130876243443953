export interface TimelineViewConfig {
  accessibility: {
    messageCreator: {
      autoFocus: boolean
    }
  }
}

export const TimelineViewConfigDefault: TimelineViewConfig = {
  accessibility: {
    messageCreator: {
      autoFocus: true
    }
  }
}
