import Grid, {GridProps} from '@mui/material/Grid'
import React from 'react'

interface GridComposerProps extends GridProps {}

interface GridComposerState {}

// Column grid composer
class GridComposer extends React.Component<
  GridComposerProps,
  GridComposerState
> {
  render() {
    let {children, ...props} = {...this.props}
    let ch = React.Children.toArray(this.props.children)
    return (
      <Grid container {...props}>
        {ch.map((child, index) => (
          <Grid key={'composer' + index} item xs={12}>
            {child}
          </Grid>
        ))}
      </Grid>
    )
  }
}

export default GridComposer
