import React from 'react'
import {QuestionTextModel} from 'survey-react'
import {Slider, styled} from '@mui/material'
import {createRoot} from 'react-dom/client'

const WIDGET_NAME = 'pricerange'

const StyledSlided = styled(Slider)((props) => ({
  color: props.theme.palette.primary.main
}))

function BudgetSlide(props: any) {
  return (
    <StyledSlided
      {...props}
      getAriaLabel={(index) => {
        if (index === 0) {
          return 'Minimum value'
        }
        return 'Maximum value'
      }}
      // aria-label={'Use the two markings on the bar to set a minimum and a maximum of your budget range'}/>
    />
  )
}

export default function widgetReactBudget(Survey: any) {
  return {
    //the widget name. It should be unique and written in lowercase.
    name: WIDGET_NAME,
    //the widget title. It is how it will appear on the toolbox of the SurveyJS Editor/Builder
    title: 'Price range',
    //the name of the icon on the toolbox. We will leave it empty to use the standard one
    iconName: 'icon-nouislider',
    //SurveyJS library calls this function for every question to check, if it should use this widget instead of default rendering/behavior
    isFit: function (question: any) {
      return question.getType() === WIDGET_NAME
    },
    widgetIsLoaded: () => true,
    isDefaultRender: false,
    htmlTemplate: '<div></div>',
    afterRender: function (question: QuestionTextModel, el: HTMLElement) {
      question.setPropertyValue('name', 'Budget')
      question.setPropertyValue('filter', true)
      question.setPropertyValue('filterType', 'range')

      const step = parseInt(question.step || '1')
      const defaultValue = [
        parseInt(question.min || '0'),
        parseInt(question.max || '100')
      ]
      const root = createRoot(el)
      root.render(
        <BudgetSlide
          color={'primary'}
          defaultValue={defaultValue}
          onChange={(event: any, value: number | number[]) => {
            question.value = value
          }}
          valueLabelDisplay="auto"
          min={defaultValue[0]}
          max={defaultValue[1]}
          step={step}
        />
      )
    },
    activatedByChanged: () => {
      Survey.Serializer.addClass(WIDGET_NAME, [], undefined, 'empty')

      Survey.Serializer.addProperty(WIDGET_NAME, {
        name: 'min',
        type: 'number',
        default: 0,
        category: 'general'
      })

      Survey.Serializer.addProperty(WIDGET_NAME, {
        name: 'max',
        type: 'number',
        default: 100,
        category: 'general'
      })

      Survey.Serializer.addProperty(WIDGET_NAME, {
        name: 'step',
        type: 'number',
        default: 1,
        category: 'general'
      })
    }
  }
}
