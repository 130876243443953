import DemandsService, {AssignmentData} from 'views/demands/DemandsService'
import {DataQuery} from 'web-common/services/DataQuery'
import DemandsDispatcher from 'views/demands/listing/DemandsDispatcher'
import {Subject} from 'rxjs'
import {OfferStatus} from 'web-common/models/OfferModel'
import {AssignmentReviewStatusData} from 'web-common/models/AssignmentModel'
import DemandDispatcher from 'views/demands/demand/timeline/dispatchers/DemandDispatcher'
import {DemandData} from 'models/Demands'
import {
  BusEvent,
  Dispatcher,
  DispatcherPriority,
  EventBusRegistry
} from '@fnd/timeline'
import {ActiveConversationData} from '@fnd/timeline/dist/timeline/chat/services/types'
import {ChatAdapterDispatcher} from 'web-common/views/timeline/chat/ChatAdapterDispatcher'
import EventBus from 'web-common/services/EventBus'
import {ChatService} from 'web-common/views/timeline/chat/ChatService'

export interface DemandChatPreviewCounter {
  offers: number
  later: number
  deals: number
  reviews: number
  unread: number
  unseenOffer: number
}

export type DemandChatPreview<T> = T & {
  // is demand canceled or archived
  archived: boolean
  lastMessageDate?: number
  counters: DemandChatPreviewCounter
}

abstract class GenericQuery<T> extends DataQuery<DemandChatPreview<T>> {
  protected registry: EventBusRegistry | undefined
  protected conversations: Map<string, ActiveConversationData> = new Map()

  protected page: Subject<DemandChatPreview<T>[]> = new Subject<
    DemandChatPreview<T>[]
  >()

  protected constructor() {
    super({
      data: [],
      filtersFN: {}
    })
  }

  protected updateLastMessageTimestamp(assignmentId: string, ts: number) {
    const index = this.getRowDataIndexFromAssignmentId(assignmentId)
    if (index !== -1) {
      this.rawData[index].lastMessageDate = ts
      this.rawData[index].counters.unread++
      this.fetch()
    }
  }

  protected abstract updateDemandAssignment(assignmentId: string): Promise<void>

  protected updateOfferCounter(assignmentId: string) {
    const index = this.getRowDataIndexFromAssignmentId(assignmentId)
    if (index !== -1) {
      this.rawData[index].lastMessageDate = Date.now()
      this.rawData[index].counters.offers++
      this.rawData[index].counters.unseenOffer++
      this.fetch()
    }
  }

  protected abstract itemToChatPreview(item: T): DemandChatPreview<T>

  protected abstract getRowDataIndexFromAssignmentId(
    assignmentId: string
  ): number

  protected getCounterValue(
    assignment: Partial<AssignmentData>,
    key: keyof DemandChatPreviewCounter | string
  ): number {
    switch (key) {
      case 'deals':
        return assignment.status === 'ACCEPTED' &&
          assignment.offer?.status === OfferStatus.ACCEPTED
          ? 1
          : 0
      case 'later':
        return assignment.status === 'ACTIVE' &&
          assignment.offer?.status === OfferStatus.NEW
          ? 1
          : 0
      case 'offers':
        return assignment.status === 'ACCEPTED' &&
          assignment.offer?.status === OfferStatus.NEW
          ? 1
          : 0
      case 'reviews':
        return assignment.customerReview?.status ===
          AssignmentReviewStatusData.PUBLISHED
          ? 1
          : 0
      case 'unseenOffer':
        return assignment.offer && !assignment.offer.dateSeen ? 1 : 0
    }
    return 0
  }

  public abstract load(): void

  complete() {
    this.registry?.unregister()
    super.complete()
  }
}

export class DemandsQuery extends GenericQuery<DemandData> {
  constructor() {
    super()
    this.registry = new (class extends Dispatcher<any, any> {
      protected consumable: boolean = false

      protected criteria(event: BusEvent<any, any>): boolean {
        return (
          ChatAdapterDispatcher.shared().notificationCriteria(event) ||
          DemandsDispatcher.shared().criteria(event) ||
          DemandDispatcher.criteriaByDemand(event)
        )
      }

      protected priority: DispatcherPriority = 'HIGH'
    })(EventBus).register((event) => {
      if (ChatAdapterDispatcher.shared().notificationCriteria(event)) {
        this.updateLastMessageTimestamp(
          event.data?.meta?.assignment?._id ?? '',
          Date.now()
        )
      } else if (DemandsDispatcher.shared().criteria(event)) {
        this.updateDemandAssignment(event.data?.assignment?._id ?? '').then()
      } else if (DemandDispatcher.criteriaByDemand(event)) {
        this.updateOfferCounter(event.data?.assignment._id ?? '')
      }
    })
  }

  protected async updateDemandAssignment(assignmentId: string) {
    const result = await DemandsService.getDemandByAssignmentId(assignmentId)
    if (result.length === 0) {
      return
    }
    const index = this.rawData.findIndex(
      (demand) => demand._id === result[0]._id
    )
    if (index !== -1) {
      this.rawData[index] = this.itemToChatPreview(
        result[0] as unknown as DemandData
      )
      this.fetch()
    } else {
      const result = await DemandsService.getDemandByAssignmentId(assignmentId)
      this.rawData.unshift(
        this.itemToChatPreview(result[0] as unknown as DemandData)
      )
      this.fetch()
    }
  }

  protected itemToChatPreview(
    preview: DemandData
  ): DemandChatPreview<DemandData> {
    let lastMessageTimestamp = 0
    const counters: DemandChatPreviewCounter = {
      offers: 0,
      later: 0,
      deals: 0,
      reviews: 0,
      unread: 0,
      unseenOffer: 0
    }
    preview.offersPreview?.forEach((preview) => {
      const cnv = this.conversations.get(preview._id)
      const ts = cnv?.lastMessage?.ts ?? 0

      Object.keys(counters).forEach((key) => {
        ;(counters as any)[key] += this.getCounterValue(
          preview as unknown as Partial<AssignmentData>,
          key
        )
      })

      counters.unread += cnv?.unseen ?? 0
      if (ts > lastMessageTimestamp) {
        lastMessageTimestamp = ts
      }
    })

    return {
      ...preview,
      archived: preview.status === 'CANCELED',
      lastMessageDate: lastMessageTimestamp || undefined,
      counters: counters
    }
  }

  protected getRowDataIndexFromAssignmentId(assignmentId: string): number {
    return this.rawData.findIndex(
      (d) => !!d.offersPreview?.find((a) => a._id === assignmentId)
    )
  }

  async load(archived: boolean = false) {
    this.conversations = await ChatService.loadActiveConversations()
    const result = await DemandsService.getAllDemands(archived)
    this.rawData = result.data.map(this.itemToChatPreview.bind(this))
    this.fetch()
  }
}

export interface LiveAssignmentData extends AssignmentData {
  demandId: string
}

export class AssignmentQuery extends GenericQuery<LiveAssignmentData> {
  private constructor() {
    super()
    const scope = this
    this.registry = new (class extends Dispatcher<any, any> {
      protected consumable: boolean = false
      protected priority: DispatcherPriority = 'HIGH'

      protected criteria(event: BusEvent<any, any>): boolean {
        return (
          (ChatAdapterDispatcher.shared().notificationCriteria(event) ||
            DemandsDispatcher.shared().criteria(event) ||
            DemandDispatcher.criteriaByDemand(event, scope.demand?._id)) &&
          scope.demand?.assignments?.some(
            (ass) =>
              ass._id === event.data?.assignment?._id ||
              ass._id === event.data?.meta?.assignment?._id
          ) === true
        )
      }
    })(EventBus).register((event) => {
      if (ChatAdapterDispatcher.shared().notificationCriteria(event)) {
        this.updateLastMessageTimestamp(
          event.data?.meta?.assignment?._id ?? '',
          Date.now()
        )
      } else if (DemandsDispatcher.shared().criteria(event)) {
        this.updateDemandAssignment(event.data?.assignment?._id ?? '').then()
      } else if (DemandDispatcher.criteriaByDemand(event, scope.demand?._id)) {
        this.updateOfferCounter(event.data?.assignment._id ?? '').then()
      }
    })
  }

  private static instance: AssignmentQuery

  static shared() {
    if (!AssignmentQuery.instance) {
      AssignmentQuery.instance = new AssignmentQuery()
    }
    return AssignmentQuery.instance
  }

  private hasLoaded: boolean = false
  private demandId!: string
  private demand!: DemandData

  protected getRowDataIndexFromAssignmentId(assignmentId: string): number {
    return this.rawData.findIndex((d) => d._id === assignmentId)
  }

  protected itemToChatPreview(
    item: AssignmentData
  ): DemandChatPreview<LiveAssignmentData> {
    const conversation = this.conversations.get(item._id)
    const counters: DemandChatPreviewCounter = {
      offers: 0,
      later: 0,
      deals: 0,
      reviews: 0,
      unread: conversation?.unseen ?? 0,
      unseenOffer: 0
    }
    Object.keys(counters)
      .filter((key) => key !== 'unread')
      .forEach((key) => {
        ;(counters as any)[key] += this.getCounterValue(item, key)
      })
    return {
      ...item,
      demandId: this.demandId!,
      archived: this.demand.archived,
      lastMessageDate: conversation?.lastMessage?.ts,
      counters: counters
    }
  }

  protected async updateDemandAssignment(assignmentId: string) {
    const result = (
      await DemandsService.getDemandByAssignmentId(assignmentId)
    )[0]
    if (result) {
      const assignment = result.assignments?.find((a) => a._id === assignmentId)
      this.rawData.unshift(this.itemToChatPreview(assignment!))
      this.fetch()
    }
  }

  protected async updateOfferCounter(assignmentId: string) {
    const index = this.getRowDataIndexFromAssignmentId(assignmentId)
    if (index === -1) {
      return
    }
    const offer = await DemandsService.getOffer(
      this.rawData[index].demandId,
      assignmentId
    )
    if (offer.offer) {
      this.rawData[index].offer = offer.offer
    }
    super.updateOfferCounter(assignmentId)
  }

  fetch() {
    this.rawData = this.rawData.sort((a, b) => {
      const next = Math.max(
        ...[
          b.lastMessageDate ?? 0,
          b.dateCreated,
          b.dateAccepted ?? 0,
          b.dateActivated ?? 0
        ]
      )
      const current = Math.max(
        ...[
          a.lastMessageDate ?? 0,
          a.dateCreated,
          a.dateAccepted ?? 0,
          a.dateActivated ?? 0
        ]
      )
      return next - current
    })
    super.fetch()
  }

  updateUnread(assignmentId: string) {
    const assignment = this.rawData.find((a) => a._id === assignmentId)
    if (assignment) {
      assignment.counters.unread = 0
      assignment.counters.unseenOffer = 0
      this.fetch()
    }
  }

  async load(demandId?: string, assignmentId?: string, force: boolean = false) {
    if (this.hasLoaded && !force) {
      this.fetch()
      return this.demand
    }
    this.conversations = await ChatService.loadActiveConversations()
    let data
    if (!demandId) {
      data = (await DemandsService.getDemandByAssignmentId(assignmentId!))[0]
      this.demandId = data._id
    } else {
      this.demandId = demandId
      data = await DemandsService.getDemand(demandId)
    }
    this.demand = data
    this.rawData =
      data.assignments?.map(this.itemToChatPreview.bind(this)) ?? []
    this.hasLoaded = true
    this.fetch()
    return data
  }
}
