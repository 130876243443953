import {ControllerRenderProps} from 'react-hook-form/dist/types/controller'
import {SurveyElementFileUploader} from '@fnd/survey'
import {createRef, forwardRef, ReactNode, useContext, useState} from 'react'
import Box from '@mui/material/Box'
import {Button, Stack, styled, Typography} from '@mui/material'
import AddIcon from '@mui/icons-material/AddRounded'
import defaultImage from 'web-common/assets/img/image_placeholder.jpg'
import CloseIcon from '@mui/icons-material/CloseRounded'
import {SRContext} from 'web-common/components/survey/ui/view/SurveyRendererView'
import {useTranslation} from 'react-i18next'
import {AppFileGalleryImage} from 'web-common/services/AppFileService'

const FilePreviewBox = styled(Box)((props) => ({
  width: '150px',
  minWidth: '150px !important',
  height: '113px',
  boxShadow: props.theme.shadows[2],
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain'
}))

const PreviewStackCell = styled(Stack)({
  overflow: 'hidden',
  width: '150px',
  '& .MuiTypography-root': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '150px'
  }
})

const GalleryWrapper = styled(Stack)({
  flexWrap: 'wrap',
  rowGap: '1rem',
  columnGap: '1rem',
  marginTop: '1rem'
})

export interface FileUploaderWrapperProps extends ControllerRenderProps {
  element: SurveyElementFileUploader
}

const FileUploaderWrapper = forwardRef<
  HTMLButtonElement,
  FileUploaderWrapperProps
>((props, ref) => {
  const context = useContext(SRContext)
  const [t] = useTranslation()
  const fileInputRef = createRef<HTMLInputElement>()
  const onFilePick = () => fileInputRef.current?.click()
  const value = (
    Array.isArray(props.element.value) ? props.element.value : []
  ) as string[]
  const [fs, setFs] = useState<string[]>(value)

  const toBase64 = (file: File) => new AppFileGalleryImage(file).toString()

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files!)
    const b64 = await Promise.all(files.map(toBase64))
    const value = [...fs, ...b64]
    setFs(value)
    props.onChange(value)
  }

  const renderPreviewItem = (bs64: string, button: ReactNode) => {
    const splitIndex = bs64.indexOf('###')
    const data = splitIndex !== -1 ? bs64.split('###') : ['', bs64]
    return (
      <PreviewStackCell
        direction={'column'}
        alignItems={'flex-start'}
        spacing={1}
        key={bs64}
      >
        <FilePreviewBox sx={{backgroundImage: `url(${data[1]})`}} />
        {button}
        {!!data[0] && <Typography variant={'caption'}>{data[0]}</Typography>}
      </PreviewStackCell>
    )
  }

  const removeFile = (index: number) => {
    const copy = [...fs]
    copy.splice(index, 1)
    setFs(copy)
    props.onChange(copy)
  }

  const gallery = fs.map((bs64, i) =>
    renderPreviewItem(
      bs64,
      <Button
        color={'secondary'}
        variant={'text'}
        size={'small'}
        startIcon={<CloseIcon />}
        onClick={removeFile.bind(null, i)}
      >
        Remove
      </Button>
    )
  )

  const getAccept = () =>
    props.element.accept
      ?.replace('images', 'image/*')
      .replace('docs', 'application/pdf')

  return (
    <>
      <input
        type="file"
        accept={getAccept()}
        onChange={onChange}
        ref={fileInputRef}
        style={{display: 'none'}}
      />

      <GalleryWrapper direction={'row'} id={props.element.name}>
        {gallery}
        {renderPreviewItem(
          defaultImage,
          <Button
            color={'primary'}
            size={'small'}
            startIcon={<AddIcon />}
            ref={ref}
            onClick={onFilePick}
          >
            {t('common:fnd-common-upload-file', {lng: context.language})}
          </Button>
        )}
      </GalleryWrapper>
    </>
  )
})

export default FileUploaderWrapper
