import {useTranslation} from 'react-i18next'
import {
  AppBar,
  Container,
  IconButton,
  Stack,
  styled,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, {useState} from 'react'

const NewsBar = styled(AppBar)((props) => ({
  marginTop: '64px',
  padding: '0.5rem 0',
  [props.theme.breakpoints.down('lg')]: {
    marginTop: 0
  }
}))

export default function Banner() {
  const [t] = useTranslation()
  const [visible, setVisible] = useState(
    !window.sessionStorage?.getItem('hide-banner')
  )

  const onChangeVisibility = () => {
    window.sessionStorage?.setItem('hide-banner', '1')
    setVisible(false)
  }

  return (
    <>
      {visible && (
        <NewsBar color={'secondary'}>
          <Container>
            <Stack direction={'row'}>
              <Typography>
                {t('common:fnd-common-label-banner-downtime')}
              </Typography>
              <IconButton
                aria-label="close"
                color={'inherit'}
                onClick={onChangeVisibility}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Container>
        </NewsBar>
      )}
    </>
  )
}
