import React from 'react'
import Navigation from 'web-common/components/navigation/Navigation'
import LeftAction from 'components/navigation/LeftAction'
import AppPage, {
  AppMetaService,
  AppPageType
} from 'web-common/utilities/AppPage'
import DemandsView from 'views/demands/listing/DemandsView'
import Loading from 'web-common/components/loading/Loading'
import Offers from 'views/demands/demand/list/Offers'
import CustomerTimeline from 'views/demands/demand/timeline/CustomerTimeline'
import ServicesService from 'web-common/services/ServicesService'
import {Box, Typography} from '@mui/material'
import NotificationsChannelsStatusView from 'views/profile/notifications/NotificationsChannelsStatusView'
import DemandContextMenuView from 'views/demands/demand/DemandContextMenuView'
import {PageQuery} from 'web-common/services/PageQuery'
import {
  AssignmentQuery,
  DemandChatPreview,
  LiveAssignmentData
} from 'views/demands/DemandsQuery'
import {Subscription} from 'rxjs'
import {DemandData} from 'models/Demands'

interface RouteParams {
  demandId: string
  assignmentId?: string
}

interface DemandViewProps {}

interface DemandViewState {
  content: 'list' | 'timeline'
  assignments: DemandChatPreview<LiveAssignmentData>[]
  demand?: DemandData
  loading: boolean
}

class DemandView extends AppPage<
  DemandViewProps,
  DemandViewState,
  RouteParams
> {
  title = 'Demand'

  static path = '/demands/:demandId/:assignmentId?'

  state: DemandViewState = {
    loading: true,
    content: this.props.params.assignmentId ? 'timeline' : 'list',
    assignments: []
  }
  query: PageQuery<DemandChatPreview<LiveAssignmentData>> =
    AssignmentQuery.shared()
  subscription?: Subscription

  async componentDidMount() {
    super.componentDidMount()
    this.subscription = this.query.subscribe((data) => {
      this.setState({assignments: data})
    })
    await this.loadDemandFromURLParam()
  }

  async componentDidUpdate(
    prevProps: Readonly<AppPageType<DemandViewProps, RouteParams>>,
    prevState: Readonly<DemandViewState>,
    snapshot?: any
  ) {
    if (
      this.props.params.assignmentId !== prevProps.params.assignmentId ||
      this.props.params.demandId !== prevProps.params.demandId
    ) {
      await this.loadDemandFromURLParam()
      this.setState({
        content: this.props.params.assignmentId ? 'timeline' : 'list'
      })
    }
  }

  async loadDemandFromURLParam() {
    let demand
    if (this.props.params.demandId === 'undefined') {
      demand = await (this.query as AssignmentQuery).load(
        undefined,
        this.props.params.assignmentId!,
        true
      )
      this.props.navigate(
        DemandView.getPath(demand._id, this.props.params.assignmentId!),
        {replace: true}
      )
    } else {
      demand = await (this.query as AssignmentQuery).load(
        this.props.params.demandId,
        this.props.params.assignmentId,
        true
      )
    }
    this.setState({loading: false, demand: demand})
  }

  componentWillUnmount() {
    // this.query.complete()
    this.subscription?.unsubscribe()
  }

  onBack() {
    if (!this.props.params.assignmentId) {
      // GOTO DEMANDS
      this.props.navigate(DemandsView.getPath(true))
    } else {
      // GOTO TO DEMAND OFFER LISTING
      this.props.navigate(DemandView.getPath(this.props.params.demandId))
    }
  }

  onOfferOpen(assignmentId: string) {
    ;(this.query as AssignmentQuery).updateUnread(assignmentId)
    this.props.navigate(
      DemandView.getPath(this.props.params.demandId, assignmentId)
    )
  }

  renderPage() {
    if (this.state.content === 'list') {
      return (
        <Offers
          assignments={this.state.assignments}
          demand={this.state.demand!}
          onSelect={this.onOfferOpen.bind(this)}
        />
      )
    }
    const assignment = this.state.assignments.find(
      (a) => a._id === this.props.params.assignmentId!
    )
    if (!assignment) {
      return null
    }
    return (
      <CustomerTimeline
        assignment={assignment}
        assignmentsCount={this.state.assignments.length}
        demand={this.state.demand!}
        user={this.props.profile.customer}
      />
    )
  }

  renderNotificationsChannelStatus() {
    if (this.state.content !== 'list') {
      return null
    }
    return (
      <Box marginBottom={'20px'}>
        <NotificationsChannelsStatusView />
      </Box>
    )
  }

  getTitle() {
    const service = ServicesService.getServiceById(
      this.state.demand?.serviceId ?? ''
    )
    const serviceName = service?.service.name ?? ''
    const title = (() => {
      if (this.state.content === 'list') {
        return serviceName
      } else {
        const assignment = this.state.assignments.find(
          (ass) => ass._id === this.props.params.assignmentId
        )
        return assignment?.contractor?.name ?? ''
      }
    })()
    AppMetaService.setTitle(title)
    return title
  }

  render() {
    return (
      <>
        {/*NAVIGATION*/}
        <Navigation
          left={<LeftAction onClick={this.onBack.bind(this)} />}
          middle={this.getTitle()}
          right={<DemandContextMenuView demand={this.state.demand} />}
        />
        {/*NOTIFICATIONS*/}
        {this.renderNotificationsChannelStatus()}
        {/*ACCESSIBILITY*/}
        <Typography variant={'body1'} className={'sr-only'}>
          {this.props.t('fnd-aria-timeline-description')}
        </Typography>
        {/*TIMELINE*/}
        <Loading
          loading={this.state.loading}
          render={this.renderPage.bind(this)}
        />
      </>
    )
  }
}

export default DemandView.exportDefault()
