import React from 'react'
import AppPage from 'web-common/utilities/AppPage'
import {Box, Stack, Typography} from '@mui/material'
import ContactUsView from 'web-common/components/contactus/ContactUsView'

interface AppCrashRouteParams {}

interface AppCrashState {}

interface AppCrashProps {}

class AppCrash extends AppPage<
  AppCrashProps,
  AppCrashState,
  AppCrashRouteParams
> {
  static path = '/app-crash'
  title = this.props.t('fnd-page-app-crash-title')

  render() {
    return (
      <Stack direction={'column'} spacing={3}>
        <div />
        <Typography variant={'h1'}>
          {this.props.t('fnd-page-app-crash-title')}
        </Typography>
        <Typography variant={'body1'}>
          {this.props.t('fnd-page-app-crash-details')}
        </Typography>
        <Box maxWidth={'650px'}>
          <ContactUsView />
        </Box>
      </Stack>
    )
  }
}

export default AppCrash.exportDefault()
