import {QuestionTextModel} from 'survey-react'
import React from 'react'
import {DayPicker} from 'react-day-picker'
import {WithTranslation, withTranslation} from 'react-i18next'
import 'react-day-picker/dist/style.css'
import './datepicker.css'
import {createRoot} from 'react-dom/client'
import he from 'date-fns/locale/he'
import ar from 'date-fns/locale/ar'
import en from 'date-fns/locale/en-US'

const WIDGET_NAME = 'datepicker'

interface CustomDatePickerProps extends WithTranslation {
  date: string
  onChange: (date: number) => void
}

interface CustomDatePickerState {
  date?: Date
}

class CustomDatePicker extends React.Component<
  CustomDatePickerProps,
  CustomDatePickerState
> {
  // MONTHS = [
  //   this.props.t('common:fnd-common-month-january'),
  //   this.props.t('common:fnd-common-month-february'),
  //   this.props.t('common:fnd-common-month-march'),
  //   this.props.t('common:fnd-common-month-april'),
  //   this.props.t('common:fnd-common-month-may'),
  //   this.props.t('common:fnd-common-month-june'),
  //   this.props.t('common:fnd-common-month-july'),
  //   this.props.t('common:fnd-common-month-august'),
  //   this.props.t('common:fnd-common-month-september'),
  //   this.props.t('common:fnd-common-month-october'),
  //   this.props.t('common:fnd-common-month-november'),
  //   this.props.t('common:fnd-common-month-december')
  // ];
  // WEEKDAYS_SHORT = [
  //   this.props.t('common:fnd-common-week-sunday-mini'),
  //   this.props.t('common:fnd-common-week-monday-mini'),
  //   this.props.t('common:fnd-common-week-tuesday-min'),
  //   this.props.t('common:fnd-common-week-wednesday-mini'),
  //   this.props.t('common:fnd-common-week-thursday-mini'),
  //   this.props.t('common:fnd-common-week-friday-mini'),
  //   this.props.t('common:fnd-common-week-saturday-mini'),
  // ];

  state: CustomDatePickerState = {}

  componentDidMount() {
    if (this.props.date) {
      const num = parseInt(this.props.date)
      if (!isNaN(num)) {
        this.setState({date: new Date(num)})
      }
    }
  }

  onDayClick(rawDate: Date, modifiers: any) {
    if (modifiers.disabled) {
      return false
    }
    this.setState({date: rawDate}, () => {
      this.props.onChange(rawDate.getTime())
    })
  }

  render() {
    const locale: any = {
      he: he,
      en: en,
      ar: ar
    }
    return (
      <DayPicker
        mode={'single'}
        selected={this.state.date}
        onDayClick={this.onDayClick.bind(this)}
        disabled={{before: new Date()}}
        locale={locale[this.props.i18n.language]!}
        className={'survey-widget-custom-datepicker'}
      />
    )
  }
}

const El = withTranslation()(CustomDatePicker)

function widgetReactDatepicker(Survey: any) {
  return {
    name: WIDGET_NAME,
    title: 'Datepicker',
    iconName: 'icon-datepicker',
    isFit: function (question: QuestionTextModel) {
      return question.getType() === WIDGET_NAME
    },
    widgetIsLoaded: () => true,
    isDefaultRender: false,
    htmlTemplate: '<div></div>',
    afterRender: (question: QuestionTextModel, el: HTMLElement) => {
      const root = createRoot(el)
      root.render(
        <El
          date={question.value}
          onChange={(date) => {
            question.value = date
          }}
        />
      )
    },
    activatedByChanged: () => {
      Survey.Serializer.addClass(WIDGET_NAME, [], undefined, 'text')
    }
  }
}

export default widgetReactDatepicker
