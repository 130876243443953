import Grid from '@mui/material/Grid'
import React from 'react'
import StarIcon from '@mui/icons-material/StarRounded'
import {styled} from '@mui/material'
import {yellow} from '@mui/material/colors'

export interface RatingProps {
  rating: number
}

interface RatingState {}

const StarHolder = styled(Grid)({
  display: 'flex',
  alignItems: 'center'
})
const StarFill = styled(StarIcon)({
  color: yellow[700]
})
const StarEmpty = styled(StarIcon)((props) => ({
  color: props.theme.palette.grey[500]
}))

class Rating extends React.Component<RatingProps, RatingState> {
  render() {
    const rating = Math.round(this.props.rating)
    return (
      <Grid container wrap={'nowrap'} spacing={0}>
        {[1, 2, 3, 4, 5].map((index) => {
          const DynamicStarIcon = index > rating ? StarEmpty : StarFill
          return (
            <StarHolder item key={'rating-start-' + index}>
              <DynamicStarIcon fontSize={'small'} color={'inherit'} />
            </StarHolder>
          )
        })}
      </Grid>
    )
  }
}

export default Rating
