import React from 'react'
import {Grid, Stack, Typography} from '@mui/material'
import Box from '@mui/material/Box'
import {WithTranslation, withTranslation} from 'react-i18next'
import {ReviewData} from 'web-common/models/RatingModels'
import ServicesService from 'web-common/services/ServicesService'
import AppDate from 'web-common/components/date/AppDate'
import Rating from 'web-common/components/rating/Rating'

interface AppReviewProps extends WithTranslation {
  review: ReviewData
  isMyReview: boolean
  detailed?: boolean
}

interface AppReviewState {
  showFullComment: boolean
  review?: ReviewData
}

class AppReview extends React.Component<AppReviewProps, AppReviewState> {
  state: AppReviewState = {
    showFullComment: false,
    review: undefined
  }

  async componentDidMount() {
    await ServicesService.load(this.props.i18n.language)
    this.setState({
      review: this.props.review
    })
  }

  extractRateAbilitiesLabel(rateAbility: string) {
    switch (rateAbility) {
      case 'on-time':
        return 'common:fnd-common-rate-on-time-meet-up'
      case 'communication':
        return 'common:fnd-common-rate-communication'
      case 'fair-pricing':
        return 'common:fnd-common-rate-fair-pricing'
      case 'service-as-describe':
        return 'common:fnd-common-rate-service-as-described'
      default: {
        return rateAbility
      }
    }
  }

  renderComment() {
    const content = this.props.review.comment ?? ''
    const MAX_LENGTH = 150
    const handlers = [
      {
        // SHOW MORE HANDLER
        onClick: () => this.setState({showFullComment: true}),
        children: (
          <>
            ...{' '}
            <Box display={'inline'}>
              {this.props.t('common:fnd-common-show-more')}
            </Box>
          </>
        )
      },
      {
        // SHOW LESS HANDLER
        onClick: () => this.setState({showFullComment: false}),
        children: (
          <>
            <br />
            <Box display={'inline'}>
              {this.props.t('common:fnd-common-show-less')}
            </Box>
          </>
        )
      }
    ]

    return (
      <Typography
        variant={'body1'}
        style={{whiteSpace: 'pre-wrap', textOverflow: 'ellipsis'}}
      >
        {content.substr(0, this.state.showFullComment ? content.length : 150)}
        {((_) => {
          if (content.length > MAX_LENGTH) {
            return (
              <Box
                {...handlers[this.state.showFullComment ? 1 : 0]}
                style={{cursor: 'pointer'}}
                display={'inline'}
              />
            )
          }
        })()}
      </Typography>
    )
  }

  render() {
    if (this.state.review === undefined) {
      return null
    }
    const serviceName = ServicesService.getServiceById(
      this.state.review._refs.serviceId
    )?.service.name
    return (
      <Grid container direction={'column'} spacing={2}>
        {/* SERVICE */}
        <Grid item>
          <Stack spacing={1}>
            <Typography variant={'h6'}>{serviceName}</Typography>
            <Rating rating={this.props.review.rating} />
          </Stack>
        </Grid>

        {/* AUTHOR */}
        <Grid item>
          <Stack spacing={1}>
            <Typography variant={'body1'}>
              {this.props.isMyReview
                ? this.props.review.receiver
                : this.props.review.author}
            </Typography>
            <span>
              <Typography variant={'body2'} color={'textSecondary'}>
                <AppDate
                  timestamp={this.props.review.datePublished}
                  format={'date'}
                />
              </Typography>
              {/* COMMENT */}
              {this.renderComment()}
              {(() => {
                if (this.props.detailed) {
                  if (this.props.review.didWell !== undefined) {
                    // WHAT DID WELL
                    return (
                      <Typography variant={'subtitle1'} color={'textSecondary'}>
                        {this.props.t('common:fnd-common-rate-what-did-well')} (
                        {this.props.t('common:fnd-common-visible-only-for-you')}
                        ):{' '}
                        {this.props.review.didWell
                          .map((value) =>
                            this.props.t(this.extractRateAbilitiesLabel(value))
                          )
                          .join(', ')}
                      </Typography>
                    )
                  }
                  if (this.props.review.canImprove !== undefined) {
                    // HOW CAN IMPROVE
                    return (
                      <Typography variant={'subtitle1'} color={'textSecondary'}>
                        {this.props.t('common:fnd-common-rate-how-can-improve')}{' '}
                        (
                        {this.props.t('common:fnd-common-visible-only-for-you')}
                        ):{' '}
                        {this.props.review.canImprove
                          .map((value) =>
                            this.props.t(this.extractRateAbilitiesLabel(value))
                          )
                          .join(', ')}
                      </Typography>
                    )
                  }
                } else {
                  return null
                }
              })()}
            </span>
          </Stack>
        </Grid>
      </Grid>
    )
  }
}

export default withTranslation()(AppReview)
