import React from 'react'
import './index.css'
import App from './App'
import i18n from 'i18n/i18n'
import {withTranslation} from 'react-i18next'
import kc from 'web-common/services/auth'
import {AppConfig} from 'AppConfig'
import {BrowserRouter} from 'react-router-dom'
import TagManager from 'react-gtm-module'
import CustomerService from 'web-common/services/CustomerService'
import {RealmRoles} from 'web-common/models/Roles'
import ContractorService from 'web-common/services/ContractorService'
import SearchLogger from 'web-common/services/SearchLogger'
import {first} from 'rxjs/operators'
import SurveyApplyMaterialUI from 'SurveyCustomization'
import {UserContextSubject} from 'web-common/contexts/UserContext'
import ScrollToTop from 'components/navigation/SsrollToTop'
import ServicesService from 'web-common/services/ServicesService'
import RegionService from 'web-common/services/RegionService'
import PriceUnitsService from 'web-common/services/PriceUnitsService'
import URLShortenerService from 'services/URLShortenerService'
import {createRoot} from 'react-dom/client'
import * as serviceWorker from 'serviceWorker'
import EventBus from 'web-common/services/EventBus'

// Alter survey question appearances
SurveyApplyMaterialUI()

// Resolve and redirect shortened URL if the path param is present in the URL
URLShortenerService.resolve(window.location.href)

// KEYCLOAK INIT & CONFIG
kc.onAuthSuccess.subscribe(async () => {
  EventBus.setCredentials({
    userId: kc.instance.subject!,
    token: kc.instance.token!
  })
  await Promise.allSettled([RegionService.getAll(), EventBus.connect()])
  const customer = await CustomerService.get()
  let contractor = undefined
  if (kc.hasRole(RealmRoles.CONTRACTOR)) {
    try {
      contractor = await ContractorService.get()
    } catch (e) {
      console.error('Contractor profile is not setup')
    }
  }
  UserContextSubject.next({customer: customer, contractor: contractor})
  // Check SearchLogger for pending syncs
  SearchLogger.syncPendingLogs()
})
kc.onAuthLogout.subscribe(() => {
  UserContextSubject.next(undefined)
  EventBus.disconnect()
  // appContext.profile.next(undefined)
})
kc.onReady.pipe(first()).subscribe(async () => {
  await Promise.allSettled([
    ServicesService.load(i18n.language),
    PriceUnitsService.init()
  ])
  document
    .querySelector('.app-loading')
    ?.setAttribute('class', 'app-loading finish')
  // Load translate library
  setTimeout(() => {
    const AppLocale = withTranslation()(App)
    const container = document.getElementById('root')!
    const root = createRoot(container)
    root.render(
      <BrowserRouter>
        <ScrollToTop />
        <AppLocale i18n={i18n} />
      </BrowserRouter>
    )
    serviceWorker.register({onError: (e) => console.log(e)})
  }, 100)
})
kc.init(
  {
    clientId: AppConfig.kc.clientId || '',
    realm: AppConfig.kc.realm || '',
    url: AppConfig.kc.authUrl || ''
  },
  {
    enableLogging: AppConfig.isDevelopment,
    onLoad: 'check-sso'
  }
)

// UTILITIES
if (AppConfig.isDevelopment) {
  // @ts-ignore
  window.kc = kc.instance
} else if (AppConfig.isProduction) {
  // Enable TAG MANAGER ONLY FOR PRODUCTION
  TagManager.initialize({gtmId: 'GTM-PQQTQTG'})
}

// Get user phone number for smart look
;(window as any).getUserIdSmartLook = function () {
  const subs = UserContextSubject.subscribe((data) => {
    if (data && Object.keys(data).length > 0) {
      if ((window as any).smartlook) {
        ;(window as any).smartlook('identify', data?.customer?.contact.phone)
      }
      subs.unsubscribe()
    }
  })
}
if (typeof (window as any).smartlook == 'function') {
  ;(window as any).getUserIdSmartLook()
}
