import React from 'react'
import {styled, Box, ListItemText} from '@mui/material'
import ScheduleIcon from '@mui/icons-material/Schedule'
import {SearchableService} from 'web-common/models/CategoriesAndServices'
import Highlight from 'web-common/components/highlight/Highlight'

interface SearchResultProps {
  service: SearchableService
  query?: string
}

const Primary = styled(Box)((props) => ({
  color: props.theme.palette.primary.main,
  display: 'flex',
  gap: '0.25rem',
  alignItems: 'baseline',
  '&.inactive': {
    color: props.theme.palette.text.primary,
    opacity: 0.4
  }
}))

const SearchResult = (props: SearchResultProps) => {
  const name = props.service.service.name
  const category = props.service.category.name
  const main = props.service.parentCategory.name
  const icon = !props.service.active ? (
    <ScheduleIcon
      fontSize={'inherit'}
      style={{position: 'relative', top: '2px'}}
    />
  ) : null
  return (
    <ListItemText
      aria-label={[name, category, main].join(' / ')}
      primary={
        <Primary className={props.service.active ? '' : 'inactive'}>
          {icon}{' '}
          <Highlight
            text={name}
            highlight={props.query ?? ''}
            separator={' '}
          />
        </Primary>
      }
      secondary={category + ' / ' + main}
    />
  )
}

export default SearchResult
