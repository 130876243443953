import React from 'react'
import {IconButton, Menu, MenuItem} from '@mui/material'
import MenuIcon from '@mui/icons-material/MoreVert'
import {WithTranslation, withTranslation} from 'react-i18next'
import {spreadAriaButton} from 'web-common/utilities/whatsappUtilities'
import {AssignmentData} from 'views/demands/DemandsService'
import AppModal from 'web-common/components/modal/AppModal'
import DemandCancelView from 'views/demands/demand/DemandCancelView'
import Hidden from 'web-common/components/hidden/Hidden'
import CompareOffers from 'views/demands/demand/comparation/CompareOffers'
import DemandDetailsView from 'views/demands/demand/DemandDetailsView'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'
import {DemandData} from 'models/Demands'
import ContractorDetails from 'web-common/components/users/ContractorDetails'
import ContractorRatingList from 'views/demands/demand/timeline/ContactorRatingList'

interface RouteProps {
  demandId: string
  assignmentId?: string
}

interface DemandContextMenuViewProps
  extends WithTranslation,
    RouteComponentProps<RouteProps> {
  demand?: DemandData
}

interface DemandContextMenuViewState {
  showMenu: boolean
  showCancelDemandView: boolean
  showDemandDetailsView: boolean
  showContractorProfile: boolean
  showCompareOffers: boolean
  assignment?: AssignmentData
}

class DemandContextMenuView extends React.Component<
  DemandContextMenuViewProps,
  DemandContextMenuViewState
> {
  state: DemandContextMenuViewState = {
    showMenu: false,
    showCancelDemandView: false,
    showDemandDetailsView: false,
    showContractorProfile: false,
    showCompareOffers: false
  }

  menuRef = React.createRef<any>()

  componentDidUpdate(
    prevProps: Readonly<DemandContextMenuViewProps>,
    prevState: Readonly<DemandContextMenuViewState>,
    snapshot?: any
  ) {
    if (this.props.params.assignmentId !== prevProps.params.assignmentId) {
      this.closeModals()
    }
  }

  onMenuCall() {
    this.setState((state) => ({showMenu: !state.showMenu}))
  }

  showDemandDetails() {
    this.setState({
      showMenu: false,
      showDemandDetailsView: true
    })
  }

  showCancelDemand() {
    this.setState({
      showMenu: false,
      showCancelDemandView: true
    })
  }

  showContractorProfileModal() {
    const assignment = this.props.demand?.assignments?.find(
      (a) => a._id === this.props.params.assignmentId
    )
    this.setState({
      showMenu: false,
      showContractorProfile: true,
      assignment: assignment
    })
  }

  showCompareOffersModal() {
    this.setState({showCompareOffers: true, showMenu: false})
  }

  closeModals() {
    this.setState({
      showDemandDetailsView: false,
      showCancelDemandView: false,
      showContractorProfile: false,
      showCompareOffers: false
    })
  }

  render() {
    if (!this.props.demand) {
      return null
    }
    return (
      <>
        {/*MENU CONTROL*/}
        <IconButton
          ref={this.menuRef}
          {...spreadAriaButton(
            this.props.t('fnd-aria-open-demand-context-menu'),
            this.onMenuCall.bind(this)
          )}
        >
          <MenuIcon />
        </IconButton>

        {/*ACTUAL MENU*/}
        <Menu
          id="demand-context-menu"
          anchorEl={this.menuRef.current}
          open={this.state.showMenu}
          onClose={this.onMenuCall.bind(this)}
          MenuListProps={{'aria-labelledby': 'basic-button'}}
        >
          {/*OPTIONAL PROFILE CONTROLLER*/}
          {this.props.params.assignmentId && (
            <Hidden mdUp>
              <MenuItem onClick={this.showContractorProfileModal.bind(this)}>
                {this.props.t('fnd-contractor-profile')}
              </MenuItem>
            </Hidden>
          )}

          {/*COMPARE OFFERS*/}
          <MenuItem onClick={this.showCompareOffersModal.bind(this)}>
            {this.props.t('fnd-demand-compare-offers')}
          </MenuItem>

          {/*DEMANDS PREVIEW*/}
          <MenuItem onClick={this.showDemandDetails.bind(this)}>
            {this.props.t('fnd-title-preview-job-request')}
          </MenuItem>

          {/*OPTIONAL CLOSE DEMAND*/}
          {(this.props.demand.status === 'NEW' ||
            this.props.demand.status === 'ACTIVE') && (
            <MenuItem onClick={this.showCancelDemand.bind(this)}>
              {this.props.t('fnd-menu-close-demand')}
            </MenuItem>
          )}
        </Menu>

        {/*DEMANDS PREVIEW*/}
        <AppModal
          open={this.state.showDemandDetailsView}
          title={this.props.t('fnd-title-demand-details')}
          onClose={this.closeModals.bind(this)}
        >
          <DemandDetailsView
            demandId={this.props.demand._id}
            serviceId={this.props.demand.serviceId}
            dateCreated={this.props.demand.dateCreated}
          />
        </AppModal>

        {/*CLOSE DEMAND*/}
        <AppModal
          open={this.state.showCancelDemandView}
          title={this.props.t('fnd-title-close-demand')}
          onClose={this.closeModals.bind(this)}
        >
          <DemandCancelView
            demand={this.props.demand}
            onCancel={this.closeModals.bind(this)}
          />
        </AppModal>

        {/*CONTRACTOR DETAILS*/}
        <AppModal
          open={this.state.showContractorProfile}
          title={this.props.t('fnd-contractor-profile')}
          onClose={this.closeModals.bind(this)}
        >
          {this.state.assignment && (
            <ContractorDetails
              contractorUserId={this.state.assignment!.contractor.userId}
              profile={this.state.assignment!.contractor as any}
              ratingSummary={this.state.assignment!.contractor.ratingSummary}
              reviewsChild={
                <ContractorRatingList assignment={this.state.assignment!} />
              }
            />
          )}
        </AppModal>

        {/*COMPARE OFFERS*/}
        <AppModal
          open={this.state.showCompareOffers}
          title={this.props.t('fnd-demand-compare-offers')}
          onClose={this.closeModals.bind(this)}
          maxWidth={'lg'}
        >
          <CompareOffers demandId={this.props.demand._id} />
        </AppModal>
      </>
    )
  }
}

export default withTranslation()(withRouter(DemandContextMenuView))
