import React from 'react'
import Box from '@mui/material/Box'
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  GridSize,
  Typography
} from '@mui/material'
import Grid from '@mui/material/Grid'
import logoNonBeta from 'web-common/assets/img/logo.svg'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled'
import WorkerImage from './assets/pose-large.png'
import Hidden from '@mui/material/Hidden'
import aerialPhotography from './assets/services/aerial-photography.jpg'
import airCondition from './assets/services/air-condition-repair-emergancy.jpg'
import carHacking from './assets/services/car-hacking-service.jpg'
import cellular from './assets/services/cellular-technicians-urgent.jpg'
import dogTraining from './assets/services/dog-training.jpg'
import generalService from './assets/services/general-services.jpg'
import makeup from './assets/services/makeup.jpg'
import movingOffices from './assets/services/moving-apartments-and-offices.jpg'
import nailPolish from './assets/services/nail_polish.jpg'
import servicesVisit from './assets/services/services_visit.jpg'
import customCakeDesign from './assets/services/custom-cake-design.jpg'
import ServicesService from 'web-common/services/ServicesService'
import SearchView from 'views/search/SearchView'
import {spreadAriaButton} from 'web-common/utilities/whatsappUtilities'
import AppPage, {AppPageType} from 'web-common/utilities/AppPage'
import QuestionnaireBus from 'services/QuestionnaireBus'
import HowItWorksModal from 'web-common/components/modal/HowItWorksModal'
import Business from './assets/categories/business.png'
import ClassesAndLessons from './assets/categories/classes_and_lessons.png'
import Events from './assets/categories/events.png'
import HealthAndWellness from './assets/categories/health_and_wellness.png'
import HomeServices from './assets/categories/home_services.png'
import {
  ArrowWrapper,
  ContentWrapper,
  GrayBoxWrapper,
  HeaderWrapper,
  HomeCategoryGrid,
  HomeClickableGrid,
  HomeDivider,
  HowItWorksWrapper,
  TopCategoriesWrapper,
  UrgentServiceBox,
  UrgentServiceLabel,
  UrgentWrapper,
  WorkerWrapper
} from './HomeStyles'
import QuestionnairesView from 'views/questionnaires/QuestionnairesView'

interface HomeViewProps {}

interface HomeViewState {
  videoModalOpen: boolean
}

interface ServiceImage {
  key: string
  image: any
  size: GridSize
}

class HomeView extends AppPage<HomeViewProps, HomeViewState> {
  static path = '/'

  title = 'Home'
  state: HomeViewState = {
    videoModalOpen: false
  }

  urgentServices: ServiceImage[] = [
    {key: 'air-condition-repair', image: airCondition, size: 3},
    {key: 'car-hacking-service', image: carHacking, size: 3},
    {key: 'locksmith-services', image: servicesVisit, size: 3},
    {key: 'plumbing-visit', image: generalService, size: 3}
  ]

  popularService: ServiceImage[] = [
    {key: 'nail_polish', image: nailPolish, size: 6},
    {key: 'professional-makeup-event', image: makeup, size: 4},
    {key: 'taking-photos-small-event', image: aerialPhotography, size: 4},
    {key: 'dog-training', image: dogTraining, size: 4},
    {key: 'moving-apartments-and-offices', image: movingOffices, size: 4},
    {key: 'cellular-technicians-urgent', image: cellular, size: 4},
    {key: 'custom-made-cakes', image: customCakeDesign, size: 4}
  ]

  mainCats: ServiceImage[] = [
    {key: 'business', image: Business, size: 'auto'},
    {key: 'classes_and_lessons', image: ClassesAndLessons, size: 'auto'},
    {key: 'health_and_wellness', image: HealthAndWellness, size: 'auto'},
    {key: 'events', image: Events, size: 'auto'},
    {key: 'home_services', image: HomeServices, size: 'auto'}
  ]
  language = this.props.i18n.language
  searchMarker = React.createRef<HTMLDivElement>()

  componentDidMount() {
    super.componentDidMount()
    if (
      this.props.location.pathname.startsWith(QuestionnairesView.getPath(''))
    ) {
      this.loadQuestionnaire()
    }
    this.title = this.props.t('fnd-label-home-welcome')
    this.updateTitle()
  }

  componentDidUpdate(
    prevProps: Readonly<AppPageType<HomeViewProps>>,
    prevState: Readonly<HomeViewState>,
    snapshot?: any
  ) {
    if (
      this.props.location.pathname.startsWith(QuestionnairesView.getPath('')) &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.loadQuestionnaire()
    }
  }

  loadQuestionnaire() {
    const serviceId = this.props.location.pathname.replace(
      QuestionnairesView.getPath(''),
      ''
    )
    const service = ServicesService.getServiceById(serviceId)
    if (!service || !service?.active) {
      setTimeout(() => this.props.navigate('/404'))
      return
    }
    QuestionnaireBus.shared().request(serviceId)
  }

  getServiceName(id: string) {
    const item = ServicesService.getServiceById(id)
    return item?.service.name ?? id
  }

  getCategoryName(id: string) {
    const item = ServicesService.getMainCategoryById(id)
    if (item.length === 0) {
      return id
    }
    return item[0].parentCategory.name
  }

  onServiceClick(serviceId: string) {
    this.props.navigate(`/survey/${serviceId}`)
  }

  onBrowseAll() {
    this.props.navigate('/browse')
  }

  onBrowseCategory(categoryId: string) {
    this.props.navigate('/browse/' + categoryId)
  }

  onOpenVideoModal() {
    this.setState({videoModalOpen: true})
  }

  onCloseVideoModal() {
    this.setState({videoModalOpen: false})
  }

  onSearchGainFocus() {
    if (window.innerWidth < 600) {
      const isMobileSafariBrowser =
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/)
      const position =
        (this.searchMarker.current?.getBoundingClientRect().top ?? 0) -
        12 +
        window.scrollY
      if (position !== 0) {
        window.scrollTo({
          left: 0,
          top: position,
          behavior: isMobileSafariBrowser ? 'auto' : 'smooth'
        })
      }
    }
  }

  render() {
    return (
      <ContentWrapper>
        {/* SEARCH TITLE PAGE */}
        <HeaderWrapper>
          <Container maxWidth={'sm'}>
            <Typography
              variant={((_) => {
                const size = window.innerWidth
                if (size < 600) {
                  return 'h6'
                }
                if (size < 960) {
                  return 'h5'
                }
                return 'h5'
              })()}
              style={{
                marginBottom: '18px',
                textAlign: 'center',
                fontWeight: 300
              }}
              component={'h1'}
            >
              {/* FINDERELLA SLOGAN */}
              {this.props.t('fnd-home-slogan')}
            </Typography>
            <div ref={this.searchMarker} />
            <SearchView
              onFocus={this.onSearchGainFocus.bind(this)}
              size={'medium'}
            />
          </Container>
        </HeaderWrapper>

        {/* WORKER WITH VIDEO  */}
        <HowItWorksWrapper>
          <Grid container justifyContent={'center'} spacing={0}>
            <Hidden mdDown>
              <Grid item>
                {/* WORKER IMAGE */}
                <GrayBoxWrapper style={{width: '100px'}}>
                  <WorkerWrapper
                    style={{backgroundImage: `url(${WorkerImage})`}}
                  />
                </GrayBoxWrapper>
              </Grid>
            </Hidden>
            <Grid item md={'auto'} xs={12}>
              <GrayBoxWrapper style={{textAlign: 'center', padding: '0 30px'}}>
                {/*  HOW IT WORKS? */}
                <Typography
                  variant={'h6'}
                  component={'h2'}
                  style={{padding: '22px 0 10px', fontWeight: 300}}
                >
                  {this.props.t('fnd-home-how')}
                  <img
                    src={logoNonBeta}
                    aria-hidden
                    alt={'פינדרלה'}
                    style={{
                      position: 'relative',
                      top: '3px',
                      display: 'inline-block',
                      margin: `0 ${this.props.theme.spacing(0.5)}`
                    }}
                    width={'100px'}
                  />
                  <span className={'sr-only'}>Finderella</span>
                  {this.props.t('fnd-home-work')}
                </Typography>
                {/* WATCH VIDEO BUTTON */}
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={this.onOpenVideoModal.bind(this)}
                >
                  <PlayCircleFilledIcon />{' '}
                  {this.props.t('fnd-home-check-video')}
                </Button>
              </GrayBoxWrapper>
            </Grid>
            <Hidden mdDown>
              <Grid item>
                {/* ARROW */}
                <ArrowWrapper />
              </Grid>
            </Hidden>
          </Grid>
        </HowItWorksWrapper>

        {/* URGENT SERVICES */}
        <Box textAlign={'center'} marginBottom={'30px'}>
          <Typography variant={'h5'} component={'h3'} style={{fontWeight: 300}}>
            {this.props.t('fnd-home-urgent-services')}
          </Typography>
        </Box>

        <Box width={'100%'} bgcolor={'#f8f8f8'} textAlign={'center'}>
          {/* 24/7 LABEL */}
          <UrgentWrapper>
            <Typography variant={'caption'}>24/7</Typography>
          </UrgentWrapper>

          <Box marginTop={'-20px'}>
            <Container maxWidth={'lg'}>
              <Grid container spacing={4}>
                {this.urgentServices.map((item) => (
                  <HomeClickableGrid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={3}
                    {...spreadAriaButton(
                      this.getServiceName(item.key),
                      this.onServiceClick.bind(this, item.key)
                    )}
                    key={'image-' + item.key}
                  >
                    {/* IMAGE */}
                    <UrgentServiceBox>
                      <img src={item.image} alt={''} />
                    </UrgentServiceBox>

                    {/* LABEL */}
                    <UrgentServiceLabel>
                      <Typography variant={'subtitle2'}>
                        {this.getServiceName(item.key)}
                      </Typography>
                    </UrgentServiceLabel>
                  </HomeClickableGrid>
                ))}
              </Grid>
            </Container>
          </Box>
        </Box>

        {/* MOST POPULAR SERVICES */}
        <Box textAlign={'center'} marginTop={'100px'}>
          <Typography variant={'h5'} component={'h3'} style={{fontWeight: 300}}>
            {this.props.t('fnd-home-most-popular-service')}
          </Typography>
        </Box>

        {/* LIST MORE POPULAR SERVICES */}
        <Container maxWidth={'lg'}>
          <Grid container spacing={4}>
            {/* BIG THUMB */}
            <HomeClickableGrid
              item
              xs={12}
              sm={6}
              {...spreadAriaButton(
                this.getServiceName(this.popularService[0].key),
                this.onServiceClick.bind(this, this.popularService[0].key)
              )}
            >
              {/* IMAGE */}
              <UrgentServiceBox>
                <img src={this.popularService[0].image} alt={''} />
              </UrgentServiceBox>

              {/* LABEL */}
              <UrgentServiceLabel>
                <Typography variant={'subtitle2'}>
                  {this.getServiceName(this.popularService[0].key)}
                </Typography>
              </UrgentServiceLabel>
            </HomeClickableGrid>

            {/* SMALL THUMB */}
            <Grid item xs={12} sm={6}>
              <Grid container spacing={4}>
                {this.popularService.slice(1, 7).map((item) => (
                  <HomeClickableGrid
                    item
                    xs={6}
                    sm={4}
                    {...spreadAriaButton(
                      this.getServiceName(item.key),
                      this.onServiceClick.bind(this, item.key)
                    )}
                    key={'image-' + item.key}
                  >
                    {/* IMAGE */}
                    <UrgentServiceBox>
                      <img src={item.image} alt={''} />
                    </UrgentServiceBox>

                    {/* LABEL */}
                    <UrgentServiceLabel>
                      <Typography variant={'subtitle2'}>
                        {this.getServiceName(item.key)}
                      </Typography>
                    </UrgentServiceLabel>
                  </HomeClickableGrid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {/* EXPLORE OTHER SERVICE */}
          <Box textAlign={'center'} marginTop={'60px'}>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={this.onBrowseAll.bind(this)}
            >
              {this.props.t('fnd-home-explore-other-service')}
            </Button>
          </Box>

          <HomeDivider />
        </Container>

        {/* MOST POPULAR CATEGORIES */}
        <TopCategoriesWrapper>
          <Typography variant={'h5'} component={'h3'} style={{fontWeight: 300}}>
            {this.props.t('fnd-home-most-popular-categories')}
          </Typography>
        </TopCategoriesWrapper>

        {/* LIST MOST POPULAR CATEGORIES */}
        <Box
          width={'100%'}
          bgcolor={'#f8f8f8'}
          textAlign={'center'}
          padding={'30px 0'}
        >
          <Container maxWidth={'lg'}>
            <Grid container justifyContent={'space-between'} spacing={3}>
              {this.mainCats.map((item) => (
                <HomeCategoryGrid item key={item.key} xs={6} sm={2.4}>
                  <Card>
                    <CardActionArea
                      {...spreadAriaButton(
                        this.getCategoryName(item.key),
                        this.onBrowseCategory.bind(this, item.key)
                      )}
                    >
                      <CardMedia
                        component={'img'}
                        image={item.image}
                        alt={item.key}
                      />
                      <CardContent>
                        <Typography variant={'subtitle2'}>
                          {this.getCategoryName(item.key)}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </HomeCategoryGrid>
              ))}
            </Grid>
          </Container>
        </Box>

        <Box height={'100px'} />

        <HowItWorksModal
          open={this.state.videoModalOpen}
          onClose={this.onCloseVideoModal.bind(this)}
        />
      </ContentWrapper>
    )
  }
}

export default HomeView.exportDefault()
