import React from 'react'
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import Money from 'web-common/components/money/Money'
import AppAvatar from 'web-common/components/avatar/AppAvatar'
import DemandView from 'views/demands/demand/DemandView'
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import CompareOffersService, {
  CompareOfferColumn,
  CompareOfferEntry
} from 'views/demands/demand/comparation/CompareOffersService'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'
import {withAppContext, WithAppContext} from 'web-common/contexts/AppContext'

interface CompareOffersProps
  extends WithTranslation,
    RouteComponentProps,
    WithAppContext {
  demandId: string
}

interface CompareOffersState {
  loading: boolean
  rows: CompareOfferEntry[]
  columns: CompareOfferColumn[]
  order: {
    // Column key to order the results
    column?: string
    // asc - true | false , undefined - do not apply order
    asc?: boolean
  }
}

class CompareOffers extends React.Component<
  CompareOffersProps,
  CompareOffersState
> {
  state: CompareOffersState = {
    loading: true,
    rows: [],
    columns: [],
    order: {
      asc: true
    }
  }
  service = new CompareOffersService(
    this.props.demandId,
    this.props.i18n.language,
    this.props.t
  )

  componentDidMount() {
    this.service.subscribe((data) => {
      this.setState({rows: data.rows, columns: data.columns})
    })
  }

  componentDidUpdate(
    prevProps: Readonly<CompareOffersProps>,
    prevState: Readonly<CompareOffersState>,
    snapshot?: any
  ) {
    if (this.props.i18n.language !== prevProps.i18n.language) {
      this.service.changeLang(this.props.i18n.language, this.props.t)
    }
  }

  componentWillUnmount() {
    this.service.complete()
  }

  onOpen(entry: CompareOfferEntry) {
    this.props.navigate(DemandView.getPath(entry.demandId, entry.assignmentId))
  }

  isLegacyPricing() {
    return (
      this.state.rows.length > 0 &&
      this.state.rows.filter((offer) => Object.keys(offer.units).length === 0)
        .length > 0
    )
  }

  renderCell(key: string, row: CompareOfferEntry) {
    if (key === 'contractor') {
      return (
        <Button
          onClick={this.onOpen.bind(this, row)}
          variant={'text'}
          color={'inherit'}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <AppAvatar {...row.logo} size={24} />
            <span>{row.contractorName}</span>
          </Stack>
        </Button>
      )
    }
    if (key === 'later' && row.laterValue) {
      return this.props.t('common:fnd-common-no-offer-reason-' + row.laterValue)
    }
    if (row[key]) {
      return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Money value={row[key] as unknown as number} />
          {row.units[key]?.description && (
            <Tooltip title={row.units[key]?.description ?? ''}>
              <InfoRoundedIcon fontSize={'small'} color={'inherit'} />
            </Tooltip>
          )}
        </Stack>
      )
    }
    return '-'
  }

  getColumnIcon(key: string) {
    if (this.state.order.column === key && this.state.order.asc !== undefined) {
      return this.state.order.asc ? (
        <ArrowDropDownRoundedIcon />
      ) : (
        <ArrowDropUpRoundedIcon />
      )
    }
    return undefined
  }

  orderData(column: string) {
    const update = () => {
      this.service.sort(column, this.state.order.asc)
    }
    if (this.state.order.column === column) {
      if (this.state.order.asc) {
        this.setState({order: {column: column, asc: false}}, update)
      } else if (this.state.order.asc === false) {
        this.setState({order: {column: column, asc: undefined}}, update)
      } else if (this.state.order.asc === undefined) {
        this.setState({order: {column: column, asc: true}}, update)
      }
    } else {
      this.setState({order: {column: column, asc: true}}, update)
    }
  }

  render() {
    if (this.isLegacyPricing()) {
      return (
        <Typography variant={'body1'}>
          {this.props.t('fnd-compare-offers-feature-is-not-supported')}
        </Typography>
      )
    }
    return (
      <TableContainer component={Paper} elevation={0}>
        <Table
          size="small"
          aria-label={this.props.t('fnd-aria-demand-table-compare-offers')}
        >
          <TableHead>
            <TableRow>
              {this.state.columns.map((c) => (
                <TableCell key={c.key}>
                  <Button
                    size={'small'}
                    color={'inherit'}
                    variant={'text'}
                    onClick={this.orderData.bind(this, c.key)}
                    endIcon={this.getColumnIcon(c.key)}
                  >
                    {this.props.t(c.name)}
                  </Button>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.rows.map((row) => (
              <TableRow key={row.assignmentId}>
                {this.state.columns.map((c) => (
                  <TableCell
                    key={row.assignmentId + ' ' + c.key}
                    sx={{color: this.props.theme.palette.text.secondary}}
                  >
                    {this.renderCell(c.key, row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default withRouter(withTranslation()(withAppContext(CompareOffers)))
