import {Card, styled} from '@mui/material'

export const GreyCard = styled(Card)((props) => ({
  border: `1px solid ${props.theme.palette.grey[300]}`,
  '.MuiCardHeader-root': {
    background: props.theme.palette.grey[50]
  },
  '.MuiCardContent-root': {
    background: props.theme.palette.grey[50],
    paddingBottom: '1rem'
  }
}))
