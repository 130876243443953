// USER CONTEXT START
import React from 'react'
import {ContractorData, UserData} from 'web-common/models/Profile'
import {BehaviorSubject} from 'rxjs'

// CONTEXT MODEL
export interface AppUserData {
  logged: boolean // is logged
  profile: {
    customer?: UserData
    contractor?: ContractorData
  } // actual user data if is logged
  login: () => void // request login
  logout: () => void // request logout
  update: (data: any) => void // update user data
}

// ALIAS FOR THE HOC WRAPPER
export type WithUserContext = AppUserData

// ACTUAL CONTEXT
export const UserContext = React.createContext<AppUserData>({
  logged: false,
  profile: {
    customer: undefined,
    contractor: undefined
  },
  login: () => {},
  logout: () => {},
  update: (_) => {}
})

export const UserContextSubject = new BehaviorSubject<
  {customer?: UserData; contractor?: ContractorData} | undefined
>({})

// CONTEXT PROVIDER
export default class UserContextProvider extends React.Component<
  any,
  AppUserData
> {
  state = {
    logged: false,
    profile: {
      customer: undefined,
      contractor: undefined
    },
    login: () => {
      this.setState({logged: true})
    },

    logout: () => {
      this.setState({logged: false})
    },

    update: (data: {customer?: UserData; contractor?: ContractorData}) => {
      this.setState({profile: data})
    }
  }

  async componentDidMount() {
    UserContextSubject.subscribe((profile) => {
      if (profile !== undefined) {
        this.setState({profile: profile, logged: true})
      } else {
        this.setState({profile: {}, logged: false})
      }
    })
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

// HOC WRAPPER
export function withUserContext<T extends AppUserData>(
  WrappedComponent: React.ComponentType<T>
): React.ComponentClass<Omit<T, keyof AppUserData>> {
  return class extends React.Component<Omit<T, keyof AppUserData>> {
    render() {
      return (
        <UserContext.Consumer>
          {(user) => (
            <WrappedComponent {...user} {...(this.props as unknown as T)} />
          )}
        </UserContext.Consumer>
      )
    }
  }
}
