import {BehaviorSubject, Subject} from 'rxjs'
import axios from 'axios'
import kc from 'web-common/services/auth'

interface KeyValue {
  [key: string]: string
}

export class HttpParams {
  params: Set<KeyValue> = new Set()

  constructor(initParams?: {[key: string]: string}) {
    Object.entries(initParams ?? {}).forEach(([key, value]) => {
      this.set(key, value)
    })
  }

  private findByKey(key: string): KeyValue[] {
    return Array.from(this.params).filter(
      (item) => Object.keys(item)[0] === key
    )
  }

  has(param: string): boolean {
    return Array.from(this.params).some(
      (item) => Object.keys(item)[0] === param
    )
  }

  get(param: string): string | undefined {
    const r = this.getAll(param)
    return r.length > 0 ? r[0] : undefined
  }

  getAll(param: string): string[] {
    return this.findByKey(param).map((item) => item[param])
  }

  append(param: string, value: string): HttpParams {
    this.params = this.params.add({[param]: value})
    return this
  }

  set(param: string, value: string): HttpParams {
    Array.from(this.params)
      .filter((item) => Object.keys(item)[0] === param)
      .forEach((item) => {
        this.params.delete(item)
      })
    return this.append(param, value)
  }

  delete(param: string): HttpParams {
    this.findByKey(param).forEach((item) => this.params.delete(item))
    return this
  }

  toString(): string {
    return Array.from(this.params)
      .map((item) => {
        return (
          encodeURIComponent(Object.keys(item)[0]) +
          '=' +
          encodeURIComponent(Object.values(item)[0])
        )
      })
      .join('&')
  }
}

const pqAxios = axios.create()

export interface IPageResult<E> {
  data: Array<E>
  found: number
  offset: number
  limit: number
}

export interface IPageQueryConfig {
  path: string
  params?: HttpParams
  isPublic?: boolean
  enableURLFilter?: boolean
}

export enum PageQueryOperator {
  APPEND = 'APPEND',
  SET = 'SET',
  DELETE = 'DELETE'
}

/**
 * Set configuration before use
 * Example:
 * ```
 *     this.pageQuery = new PageQuery({
 *         path: '',
 *         params: null
 *     });
 *     this.pageQuery.subscribe();
 *     this.pageQuery.fetch();
 * }
 * ```
 */
export class PageQuery<E> {
  /// query parameters
  protected params: HttpParams = new HttpParams()

  /// Full path to service
  protected path: string

  /**
   * Result subject
   * Subscribe now and get latest news
   */
  protected page: Subject<E[]> = new BehaviorSubject<E[]>([])

  /**
   * Raw data from service
   * if you need to get the raw response from the server
   */
  public data: IPageResult<E> | undefined

  // Default http client for app
  protected axios = pqAxios

  // Notify subscribers when filters are called
  public onFetch: Subject<boolean> = new Subject<boolean>()

  // Store raw data without transformation
  public rawData: E[] = []

  // Store filters in url
  enableURLFilter?: boolean

  /**
   * Init Page query
   * @param config
   */
  constructor(config: IPageQueryConfig) {
    this.path = config.path
    this.enableURLFilter = config.enableURLFilter
    if (config.params) {
      this.params = config.params
    }
    if (config.enableURLFilter) {
      this.prefillFilterFromURL()
      if (config.params?.params?.size) {
        this.updateFilterURL(config.params)
      }
    }
    this.axios.interceptors.request.use(
      (req) => {
        if (config.isPublic !== true) {
          return kc.addBearerToken(req)
        }
        return req
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  private prefillFilterFromURL() {
    const url = new URL(window.location.href)
    url.searchParams.forEach((value, name) => {
      this.params.set(name, value)
    })
  }

  private updateFilterURL(
    params: HttpParams = this.params,
    cleanUrlParams: boolean = false
  ) {
    const url = new URL(window.location.href)

    if (cleanUrlParams) {
      // We have to make a new Array our of the URLSearchParams and loop over that.
      // If we would do this:

      // url.searchParams.forEach((name, value) => url.searchParams.delete(name))

      // then we would manipulate the object that we're iterating over
      // while the loop is in progress. This would result in some keys not being deleted
      // since the iteration would never get to them, thus some filters would stay in the URL
      // even though they are deleted from this.params.
      // see: https://stackoverflow.com/questions/60522437/node-urlsearchparams-delete-in-loop-doesnt-delete-all-entries
      Array.from(url.searchParams).forEach((keyValueTuple) => {
        url.searchParams.delete(keyValueTuple[0])
      })
    }

    Array.from(params.params).forEach((item) => {
      const key = Object.keys(item)[0]
      Object.values(item).forEach((value) => {
        url.searchParams.set(key, value)
      })
    })

    window.history.replaceState({}, '', url)
  }

  public complete() {
    this.page.complete()
  }

  // stay up to date with this method
  public subscribe(observer: (value: E[]) => void) {
    return this.page.subscribe(observer)
  }

  public cleanFilters(immediatelyApplyFiler = true) {
    /// Remove all filters
    this.params = new HttpParams()

    /// Reset pagination offset
    this.params = this.params.set('offset', '0')

    /// Fetch new batch
    if (immediatelyApplyFiler) {
      this.fetch()
    }
  }

  /**
   * Set filter for query
   *
   * @param op Operation type [a|s|d]
   * @param data Key value pair {foo:'bar'}
   * @param immediatelyApplyFiler Apply current filer. If its false manual fetching is required. Default is true
   */
  public setFilters(
    op: PageQueryOperator,
    data: any,
    immediatelyApplyFiler = true
  ) {
    const keys = Object.keys(data)

    /// Apply filters
    switch (op) {
      case PageQueryOperator.APPEND:
        keys.forEach((key) => {
          this.params = this.params.append(key, String(data[key]))
        })
        break
      case PageQueryOperator.SET:
        keys.forEach((key) => {
          this.params = this.params.set(key, String(data[key]))
        })
        break
      default:
        keys.forEach((key) => {
          this.params = this.params.delete(key)
        })
    }

    if (this.enableURLFilter) {
      this.updateFilterURL(this.params, true)
    }

    /// Fetch new batch
    if (immediatelyApplyFiler) {
      this.fetch()
    }
  }

  /**
   * Get cloned state of filters
   */
  public getFilters() {
    return {...this.params}
  }

  /**
   * Get current state of filter
   * @param filter
   */
  public getFilter(filter: string) {
    return this.params.get(filter)
  }

  /**
   * Same as getFilter but for array
   * @see getFilter
   */
  public getFilterArray(filter: string) {
    return this.params.getAll(filter)
  }

  public nextPage() {
    let offset = 0
    if (this.data) {
      offset = this.data.offset + this.data.limit
    }
    this.setOffset(offset)
  }

  public prevPage() {
    let offset = 0
    if (this.data) {
      offset = this.data.offset - this.data.limit
    }
    this.setOffset(offset)
  }

  public offsetPage(page: number) {
    let offset = 0
    if (this.data) {
      offset = this.data?.limit * page
    }
    this.setOffset(offset)
  }

  /**
   * Change offset
   * If offset is too big or too small it will be changed
   *
   * @param rawOffset
   */
  protected setOffset(rawOffset: number) {
    let offset = 0
    if (this.data) {
      offset = rawOffset
      if (rawOffset < 0) {
        offset = 0
      }
      if (rawOffset > this.data.found) {
        offset = this.data.found - this.data.limit
      }
    }
    this.params = this.params.set('offset', offset.toString())
    this.fetch()
  }

  /**
   * Fetch data with params
   * Push data to all subscribers
   */
  public fetch() {
    this.onFetch.next(true)
    this.axios
      .get<IPageResult<E>>(this.path, {
        params: {},
        paramsSerializer: () => this.params.toString()
      })
      .then((response) => {
        const result = response.data
        this.rawData = result.data
        this.data = result
        this.page.next(result.data)
      })
      .catch((error) => {
        console.error('PageQuery', error)
        throw error
      })
  }

  /**
   * Calculate results for current filter
   * If filter is not presented will return the data found property without fetching it again
   * @param filter
   */
  async calcResults(filter?: HttpParams): Promise<number> {
    if (!filter) {
      return this.data?.found ?? 0
    }
    const response = await this.axios.get<IPageResult<E>>(this.path, {
      params: {},
      paramsSerializer: () => filter.toString()
    })
    return response.data.found
  }
}
