import React from 'react'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'
import {Button, Chip, Grid, Stack, TextField, Typography} from '@mui/material'
import AddValueIcon from '@mui/icons-material/Add'

interface AppFormChipsProps extends AppFormComponent {}

interface AppFormChipsState {
  values: string[]
  selectedValue: string
}

class AppFormChips extends AppFormInputComponent<
  AppFormChipsProps,
  AppFormChipsState
> {
  state: AppFormChipsState = {
    values: this.props.values ?? [],
    selectedValue: ''
  }
  newValue = ''

  onChange(e: React.ChangeEvent<any>) {
    const {onChangeCallback, ...rest} = {...this.props}
    this.props.onChangeCallback(rest, `[${this.state.values.toString()}]`)
  }

  onAddItem(e: React.ChangeEvent<any>) {
    this.setState(
      (prev) => {
        prev.values.push(this.newValue)
        return prev
      },
      () => {
        this.newValue = ''
        this.onChange(e)
      }
    )
  }

  onRemoveItem(e: React.ChangeEvent<any>, value: string) {
    this.setState(
      (prev) => {
        const index = prev.values.findIndex((v) => v === value)
        if (index !== -1) {
          prev.values.splice(index, 1)
        }
        return prev
      },
      () => this.onChange(e)
    )
  }

  onEditValue(e: React.ChangeEvent<any>, index: number) {
    this.setState(
      {
        selectedValue: this.state.values[index]
      },
      () => this.onChange(e)
    )
  }

  renderItems() {
    return this.state.values?.map((value, index) => {
      return (
        <Grid item key={`${value}-${index}`}>
          <Chip
            label={value}
            onClick={(e) => this.onEditValue(e, index)}
            onDelete={(e) => this.onRemoveItem(e, value)}
            color="secondary"
          />
        </Grid>
      )
    })
  }

  render() {
    return (
      <Stack spacing={2}>
        <Typography variant={'h6'} component={'span'}>
          {this.props.label}
        </Typography>
        <Grid container spacing={1}>
          <Grid item>
            <TextField
              id="outlined-basic"
              variant="outlined"
              onChange={(event) => (this.newValue = event.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              variant={'text'}
              onClick={this.onAddItem.bind(this)}
              startIcon={<AddValueIcon />}
            >
              Add Value
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {this.renderItems()}
        </Grid>
      </Stack>
    )
  }
}

export default AppFormChips
