import {
  AssignmentQuery,
  DemandChatPreview,
  LiveAssignmentData
} from 'views/demands/DemandsQuery'
import {NoPricingReason, PricingItem} from 'web-common/models/OfferModel'
import {AppAvatarProps} from 'web-common/components/avatar/AppAvatar'
import PriceUnitsService from 'web-common/services/PriceUnitsService'
import {Subject, Subscription} from 'rxjs'

export interface CompareOfferEntry {
  contractorName: string
  assignmentId: string
  demandId: string
  logo: AppAvatarProps

  later?: NoPricingReason | null
  laterValue?: string
  units: {[unit: string]: PricingItem | undefined}

  [unit: string]: any
}

export interface CompareOfferColumn {
  key: 'contractor' | 'later' | string
  name: string
}

export interface CompareData {
  rows: CompareOfferEntry[]
  columns: CompareOfferColumn[]
}

export default class CompareOffersService {
  private subject = new Subject<CompareData>()
  private readonly subscription?: Subscription
  private rows: CompareOfferEntry[] = []
  private columns: CompareOfferColumn[] = []

  constructor(
    private demandId: string,
    private lang: string,
    private t: (key: string) => string
  ) {
    this.subscription = AssignmentQuery.shared().subscribe((liveAssignment) => {
      const rows = this.buildOfferRows(liveAssignment)
      const columns = this.buildOfferHeader(rows)
      this.rows = [...rows]
      this.columns = [...columns]
      this.subject.next({rows: rows, columns: columns})
    })
  }

  changeLang(lang: string, t: (key: string) => string) {
    this.lang = lang
    this.t = t
    const columns = this.buildOfferHeader(this.rows)
    this.columns = [...columns]
    this.subject.next({rows: this.rows, columns: columns})
  }

  subscribe(cb: (data: CompareData) => void) {
    this.subject.subscribe(cb)
    AssignmentQuery.shared().load(this.demandId).then()
  }

  complete() {
    this.subject.complete()
    this.subscription?.unsubscribe()
  }

  sort(column: string, asc: boolean | undefined) {
    const rows =
      asc === undefined
        ? [...this.rows]
        : this.rows.sort((a, b) => {
            const mod = asc ? 1 : -1
            if (column === 'contractor') {
              return (a.contractorName > b.contractorName ? 1 : -1) * mod
            }
            if (column === 'later') {
              return (
                (this.t(a.laterValue ?? '') > this.t(b.laterValue ?? '')
                  ? 1
                  : -1) * mod
              )
            }
            return (
              ((a[column] ?? Infinity * mod) - (b[column] ?? Infinity * mod)) *
              mod
            )
          })
    this.subject.next({rows: rows, columns: this.columns})
  }

  private static destructUnitId(unit: string): [string, number] {
    const splitIndex = unit.lastIndexOf('#')
    if (splitIndex === -1) {
      return [unit, 0]
    }
    const unitId = unit.slice(0, splitIndex)
    const num = parseInt(unit.slice(splitIndex + 1))
    return [unitId, num]
  }

  private buildOfferRows(
    assignments: DemandChatPreview<LiveAssignmentData>[]
  ): CompareOfferEntry[] {
    const units = new Set<string>()

    const getDuplicateIndex = (
      forUnit: string,
      withIndex: number,
      inItems: PricingItem[]
    ) => {
      const duplicates: number[] = []
      inItems.forEach((p, i) => {
        if (p._refs.unitId === forUnit) {
          duplicates.push(i)
        }
      })
      return duplicates.findIndex((i) => i === withIndex)
    }

    // Extract all possible pricing units + later
    assignments.forEach((ass) => {
      ass.offer?.pricing?.items?.forEach((pricing, index) => {
        if (pricing._refs.unitId) {
          // Should set duplicated pricing units in different columns
          units.add(
            pricing._refs.unitId +
              '#' +
              getDuplicateIndex(
                pricing._refs.unitId,
                index,
                ass.offer?.pricing?.items ?? []
              )
          )
        } else {
          units.add(pricing.unit!)
        }
      })
    })
    const arrayUnits: string[] = Array.from(units)
    return assignments.map((ass) => {
      const reasonLabel =
        ass.offer?.noPricingReason?.reason
          ?.replaceAll('_', '-')
          .toLocaleLowerCase() ?? undefined
      let d: CompareOfferEntry = {
        assignmentId: ass._id,
        demandId: ass.demandId,
        contractorName: ass.contractor.name,
        logo: {
          userId: ass.contractor.userId,
          url: ass.contractor.picture,
          type: 'logo'
        },
        later: ass.offer?.noPricingReason,
        laterValue: reasonLabel,
        units: {}
      }
      arrayUnits.forEach((unit) => {
        const [u, n] = CompareOffersService.destructUnitId(unit)
        let price = ass.offer?.pricing?.items?.find(
          (o, i) =>
            o._refs.unitId === u &&
            n === getDuplicateIndex(u, i, ass.offer?.pricing?.items ?? [])
        )
        if (price === undefined) {
          price = ass.offer?.pricing?.items?.find((o) => o.unit === unit)
        }
        d.units[unit] = price
        d[unit] = price?.price
      })
      return d
    })
  }

  private buildOfferHeader(rows: CompareOfferEntry[]): CompareOfferColumn[] {
    const units = Object.keys(rows[0] ? rows[0].units : [])
    const getUnitName = (unit: string) => {
      const [u, n] = CompareOffersService.destructUnitId(unit)
      const count = isNaN(n) || n === 0 ? '' : ` ${n}`
      return (
        (PriceUnitsService.priceUnits.find((priceUnit) => priceUnit.id === u)
          ?.l10n?.[this.lang].name ?? u) + count
      )
    }
    let columns: CompareOfferColumn[] = [
      {
        key: 'contractor',
        name: 'fnd-demand-compare-offers-column-contractor'
      }
    ]
    if (rows.some((r) => r.later)) {
      columns.push({
        key: 'later',
        name: 'fnd-demand-compare-offers-column-later'
      })
    }
    columns = [
      ...columns,
      ...units.sort().map((u) => ({
        key: u,
        name: getUnitName(u)
      }))
    ]
    return columns
  }
}
