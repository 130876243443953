import {Box, CardContent, CardHeader, Divider, Typography} from '@mui/material'
import TimelineEventTemplate from 'web-common/views/timeline/TimelineEventTemplate'
import {SystemCard} from 'web-common/views/timeline/styled/SystemCard'
import Rating from 'web-common/components/rating/Rating'
import {ReviewData} from 'web-common/models/RatingModels'
import {FunctionComponent} from 'react'
import AppDate from 'web-common/components/date/AppDate'
import {WithTranslation, withTranslation} from 'react-i18next'

export interface RatingEventProps {
  review: ReviewData
  from: 'contractor' | 'customer'
}

interface InternalProps extends RatingEventProps, WithTranslation {}

export interface RatingEventPropsState {}

interface CustomBoxProps {
  mb: string
  children?: JSX.Element
}

const CustomBox: FunctionComponent<CustomBoxProps> = (props) => {
  return <Box marginBottom={props.mb}>{props.children}</Box>
}

class RatingEvent extends TimelineEventTemplate<
  InternalProps,
  RatingEventPropsState
> {
  state: RatingEventPropsState = {}

  renderRating() {
    const title =
      this.props.from === 'customer'
        ? 'fnd-label-you-left-review'
        : 'fnd-label-contractor-left-review'
    return (
      <>
        <CardHeader
          title={this.props.t(title)}
          titleTypographyProps={{variant: 'subtitle2'}}
        />
        <Divider />
        <CardContent>
          {/*RATING*/}
          <CustomBox mb={'1rem'}>
            <Rating rating={this.props.review.rating ?? 0} />
          </CustomBox>

          {/*AUTHOR*/}
          <CustomBox mb={'0.5rem'}>
            <Typography variant={'body1'}>
              {this.props.review.author}
            </Typography>
          </CustomBox>
          {/*DATE*/}
          <CustomBox mb={'0rem'}>
            <Typography variant={'body2'} color={'textSecondary'}>
              <AppDate
                timestamp={this.props.review.datePublished}
                format={'date-time'}
              />
            </Typography>
          </CustomBox>
          {/*COMMENT*/}
          <Typography variant={'body1'} color={'textPrimary'}>
            {this.props.review.comment ?? ''}
          </Typography>
        </CardContent>
      </>
    )
  }

  render() {
    return <SystemCard>{this.renderRating()}</SystemCard>
  }
}

export default withTranslation()(RatingEvent)
