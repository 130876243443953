import React from 'react'
import {
  Button,
  Collapse,
  Divider,
  Grid,
  styled,
  TextField,
  Typography
} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import {RatingType} from 'web-common/models/RatingModels'
import ContractorService from 'web-common/services/ContractorService'
import CustomerService from 'web-common/services/CustomerService'
import AppModal from 'web-common/components/modal/AppModal'
import {WithAppContext, withAppContext} from 'web-common/contexts/AppContext'
import AppCheckbox from 'web-common/components/inputs/AppCheckbox'
import AppRatingControl from 'web-common/views/reviews/AppRatingControl'

interface RateUserViewProps extends WithTranslation, WithAppContext {
  reviewId?: string
  reviewReceiver?: string
  open: boolean
  onReviewSuccess: (data: RateData) => void
  rateContractor?: boolean
  onClose: () => void
}

interface RateUserViewState {
  rating?: RatingType
  comment: string
  rateAbilities: RateAbilitiesItem[]
}

interface RateAbilitiesItem {
  key: string
  label: string
  selected: boolean
}

interface RateData {
  rating: RatingType
  comment?: string
  didWell?: string[]
  canImprove?: string[]
}

const StyledDivider = styled(Divider)({
  margin: '30px 0'
})

class RateUserView extends React.Component<
  RateUserViewProps,
  RateUserViewState
> {
  state: RateUserViewState = {
    rating: undefined,
    comment: '',
    rateAbilities: [
      {
        key: 'on-time',
        label: 'common:fnd-common-rate-on-time-meet-up',
        selected: false
      },
      {
        key: 'communication',
        label: 'common:fnd-common-rate-communication',
        selected: false
      },
      {
        key: 'fair-pricing',
        label: 'common:fnd-common-rate-fair-pricing',
        selected: false
      },
      {
        key: 'service-as-describe',
        label: 'common:fnd-common-rate-service-as-described',
        selected: false
      }
    ]
  }

  rateAbilitiesTitle: string[] = [
    'common:fnd-common-rate-how-can-improve',
    'common:fnd-common-rate-what-did-well'
  ]
  // Threshold where rating move from bad to good
  ratingThreshold = 3
  // HTTP REQUEST FLAG
  inRequest: boolean = false

  rateCustomer(data: RateData) {
    ContractorService.rateCustomer(
      this.props.reviewId!,
      this.state.rating!,
      this.state.comment
    )
      .then(() => {
        this.overlayHandler(false)
        this.notify(
          'success',
          this.props.t('common:fnd-common-label-review-is-ready')
        )
        this.props.onReviewSuccess(data)
      })
      .catch((_) => {
        this.overlayHandler(false)
        this.notify(
          'error',
          this.props.t('common:fnd-common-error-while-sending-review')
        )
      })
  }

  rateContractor(data: RateData) {
    CustomerService.rateContractor(this.props.reviewId!, data)
      .then(() => {
        this.overlayHandler(false)
        this.notify(
          'success',
          this.props.t('common:fnd-common-label-review-is-ready')
        )
        this.props.onReviewSuccess(data)
      })
      .catch((_) => {
        this.overlayHandler(false)
        this.notify(
          'error',
          this.props.t('common:fnd-common-error-while-sending-review')
        )
      })
  }

  submitRating() {
    if (this.state.rating === undefined || this.inRequest) {
      return
    }
    this.overlayHandler(true)
    const data: RateData = {
      rating: this.state.rating,
      comment: this.state.comment
    }
    // Extract checkbox values
    const extractedOptions: string[] = this.state.rateAbilities
      .filter((item) => item.selected)
      .map((item) => item.key)
    if (
      this.state.rating < this.ratingThreshold &&
      extractedOptions.length > 0
    ) {
      // Can improve
      data.canImprove = extractedOptions
      data.didWell = undefined
    } else if (extractedOptions.length > 0) {
      // Did well
      data.didWell = extractedOptions
      data.canImprove = undefined
    }
    if (this.props.rateContractor) {
      this.rateContractor(data)
    } else {
      this.rateCustomer(data)
    }
  }

  overlayHandler(state: boolean) {
    this.inRequest = state
    this.props.setBackdrop(state)
  }

  notify(color: any, message: string) {
    this.props.pushNotification({
      color: color,
      message: message
    })
  }

  onRatingChange(rating: RatingType) {
    this.setState((state) => ({
      rating: rating,
      rateAbilities: state.rateAbilities.map((item) => {
        item.selected = false
        return item
      })
    }))
  }

  renderCheckBoxes() {
    if (!this.props.rateContractor) {
      return null
    }
    return (
      <>
        <StyledDivider />

        <Typography variant={'subtitle1'} style={{marginBottom: '20px'}}>
          {this.props.t(
            this.rateAbilitiesTitle[
              (this.state.rating ?? 0) < this.ratingThreshold ? 0 : 1
            ]
          )}
        </Typography>
        <Grid container>
          {this.state.rateAbilities.map((item) => (
            <Grid item key={item.key} xs={12}>
              <AppCheckbox
                label={this.props.t(item.label)}
                value={item.key}
                checked={item.selected}
                onChange={() => {
                  this.setState((state: RateUserViewState) => {
                    state.rateAbilities.forEach((ability) => {
                      if (ability.key === item.key) {
                        ability.selected = !ability.selected
                      }
                    })
                    return state
                  })
                }}
              />
            </Grid>
          ))}
        </Grid>
      </>
    )
  }

  renderRatingView() {
    return (
      <>
        <Typography variant={'body1'}>
          {this.props.t('common:fnd-common-we-wont-publish-your-rating-until')}
        </Typography>

        <AppRatingControl
          onRatingChange={this.onRatingChange.bind(this)}
          rating={this.state.rating}
        />

        {/* REVIEW SECTION */}
        <Collapse
          in={this.state.rating !== undefined}
          style={{paddingTop: '10px'}}
        >
          {/* RENDER CHECKBOXES */}
          {this.renderCheckBoxes()}

          <StyledDivider />

          {/* COMMENT */}
          <TextField
            value={this.state.comment}
            multiline={true}
            rows={5}
            // rowsMax={5}
            fullWidth
            onChange={(e) => this.setState({comment: e.target.value})}
            placeholder={this.props.t('common:fnd-common-comment-optional')}
          />

          <Grid container justifyContent={'center'} style={{marginTop: '30px'}}>
            {/* CANCEL */}
            <Grid item>
              <Button
                onClick={this.props.onClose.bind(this)}
                variant={'text'}
                color={'primary'}
              >
                {this.props.t('common:fnd-common-cancel')}
              </Button>
            </Grid>

            {/* SUBMIT */}
            <Grid item>
              <Button
                variant={'contained'}
                color={'primary'}
                onClick={this.submitRating.bind(this)}
              >
                {this.props.t('common:fnd-common-continue')}
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </>
    )
  }

  render() {
    if (this.props.reviewId === undefined) {
      return null
    }
    const title = this.props.t('common:fnd-common-how-was-experience-with', {
      user: this.props.reviewReceiver ?? ''
    })
    return (
      <AppModal
        open={this.props.open}
        title={this.props.t(title)}
        onClose={this.props.onClose.bind(this)}
      >
        {this.renderRatingView()}
      </AppModal>
    )
  }
}

export default withAppContext(withTranslation()(RateUserView))
