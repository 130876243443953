import React from 'react'
import {AppConfig} from 'AppConfig'
import {Box, Button, Link, styled, Typography} from '@mui/material'
import logoNonBeta from 'web-common/assets/img/logo.svg'
import {withTranslation, WithTranslation} from 'react-i18next'
import {getWhatsAppLink} from 'web-common/utilities/whatsappUtilities'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'

const LinkCustom = styled(Link)((props) => ({
  fontWeight: 'bold',
  '&:hover': {
    color: props.theme.palette.primary.main
  }
}))

interface PhoneAlreadyRegisteredProps
  extends WithTranslation,
    RouteComponentProps {
  phone: string
}

class PhoneAlreadyRegistered extends React.Component<
  PhoneAlreadyRegisteredProps,
  any
> {
  chatURL = getWhatsAppLink('fnd-already-register-whatsapp-link')

  onGoToFinderella() {
    window.location.href = AppConfig.contractor.base!
  }

  render() {
    return (
      <Box textAlign={'center'}>
        {/* LOGO */}
        <img
          src={logoNonBeta}
          alt={'finderella'}
          width={'259'}
          style={{marginTop: '30px'}}
        />

        {/* SHOW ERROR MESSAGE */}
        <Typography
          variant={'h6'}
          style={{
            maxWidth: '540px',
            margin: '30px auto 30px auto',
            fontWeight: 300
          }}
        >
          {this.props.t('fnd-register-user-already-registered', {
            phone: this.props.phone
          })}
        </Typography>

        {/* LOGIN */}
        <Button
          style={{marginBottom: '30px'}}
          color={'primary'}
          variant={'contained'}
          onClick={this.onGoToFinderella.bind(this)}
        >
          {this.props.t('fnd-register-continue-to-login')}
        </Button>

        {/* MISTAKE TEXT */}
        <Typography
          variant={'body1'}
          style={{maxWidth: '540px', margin: '0 auto', fontWeight: 300}}
        >
          {this.props.t('fnd-register-if-mistake-contact-us')}{' '}
          <LinkCustom href={this.chatURL} target={'_blank'}>
            WhatsApp
          </LinkCustom>
        </Typography>
        <br />
      </Box>
    )
  }
}

export default withTranslation()(withRouter(PhoneAlreadyRegistered))
