import React from 'react'
import {Box, Container, Divider, Hidden, Link, Stack} from '@mui/material'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import {WithTranslation, withTranslation} from 'react-i18next'
import {Link as RouterLink} from 'react-router-dom'
import logo from 'web-common/assets/img/logo.svg'
import {AppConfig} from 'AppConfig'
import {responsiveBP} from 'web-common/theme/AppTheme'
import BrowseView from 'views/browse/BrowseView'
import QnAView from 'views/qna/QnAView'
import AboutView from 'views/about/AboutView'
import HomeView from 'views/home/HomeView'
import LanguageSelector from 'components/LanguageSelector'
import withRouter, {RouteComponentProps} from 'web-common/utilities/router'
import AppModal from 'web-common/components/modal/AppModal'
import ContactUsView from 'web-common/components/contactus/ContactUsView'
import ContactView from 'web-common/components/contactus/ContactView'
import {WithAppContext, withAppContext} from 'web-common/contexts/AppContext'
import AccessibilityControl from 'components/accessibility/AccessibilityControl'
import GridComposer from 'web-common/components/layouts/GridComposer'
import HowItWorksModal from 'web-common/components/modal/HowItWorksModal'

export interface FooterProps
  extends WithTranslation,
    RouteComponentProps,
    WithAppContext {}

interface FooterState {
  contactUsModal: boolean
  howWorks: boolean
}

class Footer extends React.Component<FooterProps, FooterState> {
  state: FooterState = {
    contactUsModal: false,
    howWorks: false
  }

  socialLinks = [
    {
      link: 'https://www.facebook.com/Pinderellapp/',
      icon: FacebookIcon,
      alt: 'Facebook'
    },
    {
      link: 'https://m.youtube.com/channel/UCHVKKxUlE-XE1r3ViPoQs1Q',
      icon: YouTubeIcon,
      alt: 'Youtube'
    },
    {
      link: 'https://www.instagram.com/finderellapp/',
      icon: InstagramIcon,
      alt: 'Instagram'
    },
    {
      link: 'https://www.linkedin.com/in/%D7%A4%D7%99%D7%A0%D7%93%D7%A8%D7%9C%D7%94-%D7%9E%D7%A0%D7%95%D7%A2-%D7%9E%D7%A6%D7%99%D7%90%D7%94-529564142',
      icon: LinkedInIcon,
      alt: 'LinkedIn'
    }
  ]

  items = [
    // CUSTOMERS
    {
      label: 'fnd-footer-customers',
      links: [
        {
          path: BrowseView.getPath(true),
          label: 'fnd-footer-browse-by-category'
        },
        {
          path: '/',
          onClick: this.onOpenHowWorksModal.bind(this),
          label: 'fnd-footer-how-app-work'
        },
        {
          path: QnAView.getPath('customer'),
          label: 'fnd-footer-customer-qna'
        }
      ]
    },
    // CONTRACTORS
    {
      label: 'fnd-footer-contractor',
      links: [
        {
          path: QnAView.getPath('contractor'),
          label: 'fnd-footer-contractor-qna'
        }
      ]
    },
    // COMPANY
    {
      label: 'fnd-footer-company',
      links: [
        {
          path: AboutView.getPath(),
          label: 'fnd-footer-about'
        },
        {
          onClick: (e: any) => {
            this.setState({contactUsModal: true})
            e.preventDefault()
          },
          path: ContactView.getPath(),
          label: 'fnd-footer-contact-us'
        }
      ]
    },
    // LEGAL
    {
      label: 'fnd-footer-legal',
      links: [
        {
          path: AppConfig.publicDir + '/docs/he/Accessibility_Statement.html',
          label: 'fnd-footer-accessibility-statement',
          target: '_blank'
        },
        {
          path:
            AppConfig.publicDir +
            '/docs/he/Terms_and_Conditions_of_Use-Hebrew.html',
          label: 'fnd-footer-terms-of-use',
          target: '_blank'
        },
        {
          path: AppConfig.publicDir + '/docs/he/Privacy_Policy_Hebrew.html',
          label: 'fnd-footer-privacy-policy',
          target: '_blank'
        }
      ]
    }
  ]

  onCloseHowWorksModal() {
    this.setState({howWorks: false})
  }

  onOpenHowWorksModal() {
    this.setState({howWorks: true})
  }

  render() {
    return (
      <footer>
        <Hidden {...responsiveBP.forMobile}>{this.renderDesktop()}</Hidden>
        <Hidden {...responsiveBP.forDesktop}>{this.renderMobile()}</Hidden>
        {/*CONTACT US MODAL*/}
        <AppModal
          open={this.state.contactUsModal}
          onClose={() => {
            this.setState({contactUsModal: false})
          }}
          title={this.props.t('fnd-title-contact-us')}
        >
          <ContactUsView />
        </AppModal>

        {/*HOW IT WORKS*/}
        <HowItWorksModal
          open={this.state.howWorks}
          onClose={this.onCloseHowWorksModal.bind(this)}
        />
      </footer>
    )
  }

  renderProperLink(forDevice: 'mobile' | 'desktop', sb: any) {
    const tint = forDevice === 'mobile' ? 500 : 50
    return (
      <Link
        href={sb.path}
        to={sb.path}
        color={this.props.theme.palette.grey[tint]}
        onClick={sb.onClick}
        underline={'none'}
        component={sb.target ? 'a' : RouterLink}
        target={sb.target ? '_blank' : undefined}
      >
        {this.props.t(sb.label)}
      </Link>
    )
  }

  renderMobile() {
    return (
      <Stack
        direction={'column'}
        spacing={2}
        alignItems={'center'}
        sx={{padding: '1rem'}}
      >
        {/*LANGUAGE*/}
        <LanguageSelector
          onLanguageChange={this.props.i18n.changeLanguage.bind(
            this.props.i18n
          )}
          defaultValue={this.props.i18n.language}
          fullWidth
        />
        {/*ACCESSIBILITY CONTROL*/}
        <AccessibilityControl stationary />
        {/*FOOTER MENUS*/}
        <Typography
          variant={'body2'}
          className={'small-links'}
          component={'span'}
        >
          <GridComposer
            customize={{xs: 6}}
            justifyContent={'flex-end'}
            flexDirection={'row'}
            spacing={1}
          >
            {this.items.map((col) =>
              col.links.map((sb: any) => (
                <Box display="inline" key={col.label + sb.label}>
                  {this.renderProperLink('mobile', sb)}
                </Box>
              ))
            )}
          </GridComposer>
        </Typography>
      </Stack>
    )
  }

  renderDesktop() {
    return (
      <>
        {/*DARK BOX*/}
        <Box
          bgcolor={this.props.theme.palette.grey['700']}
          color={this.props.theme.palette.common.white}
          padding={'2rem 0'}
          sx={{typography: 'body2'}}
        >
          <Container maxWidth={'lg'}>
            <Grid container justifyContent={'space-between'}>
              {this.items.map((col) => (
                <Grid item key={col.label + col.links.length} xs={3}>
                  <Grid container direction={'column'} spacing={1}>
                    <Grid item>
                      <Typography variant={'subtitle2'} component={'div'}>
                        {this.props.t(col.label)}
                      </Typography>
                    </Grid>

                    {col.links.map((sb: any) => (
                      <Grid item key={col.label + sb.label}>
                        {this.renderProperLink('desktop', sb)}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        {/*LIGHT BOX*/}
        <Box paddingTop={'1rem'}>
          <Container maxWidth={'lg'}>
            <Grid
              container
              wrap={'nowrap'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              {/*APP LOGO*/}
              <Grid item>
                <Link to={HomeView.getPath()} component={RouterLink}>
                  <img alt="finderella" src={logo} style={{width: '100px'}} />
                </Link>
              </Grid>

              {/* SOCIAL LINKS */}
              <Grid item>
                <Grid container spacing={3}>
                  {this.socialLinks.map((item) => {
                    const Icon = item.icon
                    return (
                      <Grid item key={item.link}>
                        <Link
                          href={item.link}
                          target={'_blank'}
                          underline={'none'}
                          aria-label={item.alt}
                        >
                          <Icon fontSize={'inherit'} aria-label={item.alt} />
                        </Link>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Divider variant={'fullWidth'} style={{margin: '10px 0'}} />
          </Container>
        </Box>
      </>
    )
  }
}

export default withRouter(withTranslation()(withAppContext(Footer)))
