import React from 'react'
import {WithTranslation, withTranslation} from 'react-i18next'
import {
  AppFormComponent,
  AppFormInputComponent
} from 'web-common/components/formBuilder/AppFormFactory'
import {Autocomplete} from '@mui/material'
import AppDebounceInput from 'web-common/components/inputs/AppDebounceInput'

interface AppFormUsersProps extends AppFormComponent, WithTranslation {}

interface AppFormUsersState {
  loading: boolean
  options: any[]
  selected: any
}

class AppFormUsers extends AppFormInputComponent<
  AppFormUsersProps,
  AppFormUsersState
> {
  state: AppFormUsersState = {
    loading: false,
    selected: undefined,
    options: []
  }

  service: any

  async componentDidMount() {
    if (typeof this.props.data?.findUsers !== 'function') {
      throw new Error('Data props should have findUsers')
    } else {
      this.service = this.props.data
    }
  }

  onChange(userId?: string) {
    const {onChangeCallback, ...rest} = {...this.props}
    this.props.onChangeCallback(rest, userId ?? '')
  }

  onUserSelect(e: any, user: any) {
    this.setState({selected: user}, () => {
      this.onChange(user?._id)
    })
  }

  render() {
    const placeholder = this.props.placeholder ?? 'Search by phone or email'
    let error: string = 'Error'
    if (this.props.dynamicError) {
      error = this.props.dynamicError(this.props.value, this.props.validator)
    } else if (this.props.errorText) {
      error = this.props.t(this.props.errorText)
    }
    let label = this.props.label ?? ''
    if (typeof label === 'string') {
      label = this.props.t(label)
    }
    return (
      <Autocomplete
        value={this.state?.selected ?? null}
        onChange={this.onUserSelect.bind(this)}
        getOptionLabel={(option) =>
          `${option.contact.firstName} ${option.contact.lastName} - ${option.contact.phone} - ${option.contact.email}`
        }
        options={this.state.options}
        loading={this.state.loading}
        filterOptions={(x: any) => x}
        renderInput={(params: any) => (
          <AppDebounceInput
            placeholder={placeholder}
            onFinish={(value) => {
              this.setState(
                {
                  loading: true
                },
                async () => {
                  const users = await this.service?.findUsers?.(value)
                  this.setState({options: users.data.map((e: any) => e.user)})
                }
              )
            }}
            error={this.props.hasError}
            label={this.props.hasError ? error : label}
            {...params}
          />
        )}
      />
    )
  }
}

export default withTranslation()(AppFormUsers)
