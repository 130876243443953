import {useEffect} from 'react'

const InstallPrompt = () => {
  useEffect(() => {
    const installation = (e: any) => {
      e.prompt()
      e.userChoice.then((choiceResult: any) => {
        console.log(choiceResult)
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt')
        } else {
          console.log('User dismissed the A2HS prompt')
        }
      })
    }

    window.addEventListener('beforeinstallprompt', installation)
    return () => {
      window.removeEventListener('beforeinstallprompt', installation)
    }
  }, [])

  return <></>
}
export default InstallPrompt
