import {AppConfig} from 'AppConfig'
import {
  BusinessServiceTypeData,
  ContractorOfficeData,
  ContractorRegionData,
  RatingSummary,
  Subscription
} from 'web-common/models/Profile'
import {SearchableService} from 'web-common/models/CategoriesAndServices'
import {RegionSelectionData} from 'web-common/services/RegionService'
import {
  ApiConfig,
  RequestService
} from 'web-common/services/request/RequestService'

export interface MarketPlaceProfileData {
  _id: string
  name: string
  _refs: {
    userId: string
  }
  ratingSummary: RatingSummary
  subscriptions: Subscription[]
  serviceType: BusinessServiceTypeData
  regions: ContractorRegionData[]
  offices: ContractorOfficeData[]
  pictures: string | null
  onboardingDate: number
  stats: {
    leads: {
      assigned: number
      accepted: number
      deals: number
    }
    credits: {
      total: number
      spent: number
      balance: number
    }
  }
}

const paths = {
  mp: {
    contractors: {
      '*': {
        '*': {}
      }
    }
  },

  requireToken: false,
  toString: () => AppConfig.api.base!
}

class MarketProfileService extends RequestService {
  protected api = new ApiConfig<typeof paths>(paths)
  protected static instance: MarketProfileService

  static shared() {
    if (!MarketProfileService.instance) {
      MarketProfileService.instance = new MarketProfileService()
    }
    return MarketProfileService.instance
  }

  // url: string = AppConfig.api.base?.replace(/\/$/, '') + '/mp/contractors'

  get(contractorId: string, additionalParams: string) {
    // return axios.get<MarketPlaceProfileData>(`${this.url}/${contractorId}/profile${additionalParams}`).then(response => response.data)
    return this.api.paths.mp.contractors['*']['*'].get<MarketPlaceProfileData>(
      this.request,
      contractorId,
      `profile${additionalParams}`
    )()
  }

  getCategoryName(categoryId: string, categories: SearchableService[]) {
    return (
      categories.find((item) => item.category.id === categoryId)?.category
        .name ?? categoryId
    )
  }

  getServiceName(serviceId: string, categories: SearchableService[]) {
    return (
      categories.find((item) => item.service.id === serviceId)?.service.name ??
      serviceId
    )
  }

  getRegionName(
    regionId: string,
    regions: RegionSelectionData[],
    lang: string
  ): string {
    const region = regions.find((region) => region._id === regionId)
    if (region === undefined) {
      return ''
    }
    switch (lang) {
      case 'he':
        return region['name:he'] ?? region.name
      case 'en':
        return region['name:en'] ?? region.name
      default:
        return region.name
    }
  }
}

export default MarketProfileService.shared()
