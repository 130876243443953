import axios, {AxiosInstance} from 'axios'
import kc from 'web-common/services/auth'
import {ApiConfig} from 'web-common/services/request/ApiEndpoint'
import {EndpointRequest} from 'web-common/services/request/Request'

export {ApiConfig} from 'web-common/services/request/ApiEndpoint'

export abstract class RequestService {
  protected abstract api: ApiConfig<any>
  protected request = new EndpointRequest(this.client)

  protected static instance: RequestService

  static shared(): RequestService {
    throw new Error(`
    All inherit class should be singleton:
      protected static instance: CustomService
      static shared() {
        if (!CustomService.instance) {
          this.instance = new CustomService()
        }
        return CustomService.instance
      }
    `)
  }

  protected constructor(private client: AxiosInstance = axios.create()) {
    this.client.interceptors.request.use((req) => {
      if (this.api.doesPathRequireToken(req.url ?? '')) {
        return kc.addBearerToken(req)
      }
      return req
    })
    this.client.interceptors.response.use(
      (res) => {
        return res
      },
      (error) => Promise.reject(error?.response ?? error)
    )
  }

  public enhancedRequest<T extends EndpointRequest>(
    getInstance: (client: AxiosInstance) => T
  ): T {
    return getInstance(this.client)
  }

  public getFindEndpoint(filter: any) {
    return this.api.paths.get<any>(this.request)({params: filter})
  }
}
