import React from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import {WithTranslation, withTranslation} from 'react-i18next'
import ReviewOfferIcon from '@mui/icons-material/StarRounded'
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded'
import {AppConfig} from 'AppConfig'
import {RateData, ReviewData} from 'web-common/models/RatingModels'
import {
  HttpParams,
  PageQuery,
  PageQueryOperator
} from 'web-common/services/PageQuery'
import ServicesService from 'web-common/services/ServicesService'
import ContractorService from 'web-common/services/ContractorService'
import CustomerService from 'web-common/services/CustomerService'
import AppDate from 'web-common/components/date/AppDate'
import RateUserView from 'web-common/views/reviews/RateUserView'
import AppReview from 'web-common/views/reviews/AppReview'
import GridComposer from 'web-common/components/layouts/GridComposer'

interface MyReviewsViewProps extends WithTranslation {
  contractorReviews: boolean
}

interface MyReviewsViewState {
  pendingReviews: ReviewData[]
  publishedReviews: ReviewData[]
  loading: boolean
  review?: ReviewData
}

class MyReviewsView extends React.Component<
  MyReviewsViewProps,
  MyReviewsViewState
> {
  state: MyReviewsViewState = {
    pendingReviews: [],
    publishedReviews: [],
    loading: false,
    review: undefined
  }

  httpParams = new HttpParams()
  limit = 3
  offset = 0

  memberPath = this.props.contractorReviews ? 'contractor' : 'customer'

  pageQuery: PageQuery<any> = new PageQuery({
    path: `${AppConfig.api.base}/${this.memberPath}/reviews/find`,
    params: new HttpParams().set('limit', this.limit.toString())
  })

  async componentDidMount() {
    await ServicesService.load(this.props.i18n.language)
    let pendingReviews
    if (this.props.contractorReviews) {
      pendingReviews = await ContractorService.getMyPendingReviews()
    } else {
      pendingReviews = await CustomerService.getMyPendingReviews()
    }
    this.setState({
      pendingReviews: pendingReviews.data
    })
    this.pageQuery.subscribe((data) => {
      this.setState((state) => ({
        publishedReviews: state.publishedReviews.concat(data),
        loading: false
      }))
    })
    this.pageQuery.fetch()
  }

  loadMore() {
    this.pageQuery.setFilters(PageQueryOperator.SET, {
      offset: (this.pageQuery.data?.offset ?? 0) + this.limit,
      limit: this.limit
    })
  }

  renderPendingReview(review: ReviewData) {
    const serviceName = ServicesService.getServiceById(review._refs.serviceId)
      ?.service.name
    const reviewButtonLabel = this.props.contractorReviews
      ? 'common:fnd-common-rate-customer'
      : 'common:fnd-common-rate-contractor'
    return (
      <Box marginTop={'10px'} aria-details={`pending-review-${review._id}`}>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid item id={'pending-review-' + review._id}>
            <Typography variant={'subtitle1'}>{serviceName}</Typography>
            <Typography variant={'subtitle2'}>{review.receiver}</Typography>
            <Typography variant={'body2'}>
              <AppDate timestamp={review.dateCreated} format={'date'} />
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              fullWidth={true}
              aria-label={this.props.t(
                'common:fnd-common-aria-rate-contractor-name',
                {name: review.receiver}
              )}
              onClick={() => {
                this.setState({
                  review: review
                })
              }}
              startIcon={<ReviewOfferIcon />}
            >
              {this.props.t(reviewButtonLabel)}
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  renderPendingReviews() {
    if (this.state.pendingReviews.length === 0) {
      return undefined
    }
    const pendingReviews = this.state.pendingReviews.map((review, index) => {
      return (
        <Grid
          key={`pending-${review._id}`}
          direction={'column'}
          container
          spacing={2}
        >
          <Grid item>{this.renderPendingReview(review)}</Grid>
          {index < this.state.pendingReviews.length - 1 && (
            <Grid item>
              <Divider variant={'fullWidth'} />
            </Grid>
          )}
        </Grid>
      )
    })
    return (
      <Card variant={'outlined'}>
        <CardHeader
          title={
            <Typography variant={'h5'}>
              {this.props.t('common:fnd-common-pending-reviews')}
            </Typography>
          }
        />
        <CardContent>{pendingReviews}</CardContent>
      </Card>
    )
  }

  renderPublishedReviews() {
    if (this.state.publishedReviews.length === 0) {
      const message = this.props.contractorReviews
        ? 'common:fnd-common-empty-my-review-contractor'
        : 'common:fnd-common-empty-my-review-customer'
      return <Typography variant={'body2'}>{this.props.t(message)}</Typography>
    } else {
      const publishedReviews = this.state.publishedReviews.map((review) => {
        return (
          <AppReview
            key={`published-${review._id}`}
            isMyReview={true}
            review={review}
          />
        )
      })
      return (
        <>
          <GridComposer>{publishedReviews}</GridComposer>
          {(() => {
            if (
              (this.pageQuery.data?.offset ?? 0) +
                (this.pageQuery.data?.data.length ?? 0) <
              (this.pageQuery.data?.found ?? 0)
            ) {
              return (
                <Box
                  style={{cursor: 'pointer'}}
                  fontSize={'14px'}
                  onClick={this.loadMore.bind(this)}
                  marginTop={'32px'}
                  marginBottom={'10px'}
                >
                  {this.props.t('common:fnd-common-more-reviews')}
                  <Box
                    fontSize={'18px'}
                    display={'inline'}
                    position={'relative'}
                    top={'2px'}
                    left={'4px'}
                    right={'4px'}
                  >
                    {((_) => {
                      return this.props.i18n.dir() === 'ltr' ? (
                        <ChevronRightIcon fontSize={'inherit'} />
                      ) : (
                        <ChevronLeftIcon fontSize={'inherit'} />
                      )
                    })()}
                  </Box>
                </Box>
              )
            }
          })()}
        </>
      )
    }
  }

  onReviewSuccess(rateData: RateData) {
    this.setState(
      (prev) => {
        const pendingReviewIndex = prev.pendingReviews.findIndex(
          (review) => review._id === this.state.review?._id
        )
        if (pendingReviewIndex !== undefined) {
          const review = prev.pendingReviews[pendingReviewIndex]
          prev.pendingReviews.splice(pendingReviewIndex, 1)
          const now = new Date().getTime()
          review.rating = rateData.rating
          review.comment = rateData.comment
          review.canImprove = rateData.canImprove
          review.didWell = rateData.didWell
          review.dateCompleted = now
          review.datePublished = now
          prev.publishedReviews.splice(0, 0, review)
        }
        return prev
      },
      () =>
        this.setState({
          review: undefined
        })
    )
  }

  render() {
    return (
      <>
        <Stack direction={'column'} spacing={3}>
          {this.renderPendingReviews()}
          <Card>
            <CardHeader
              title={this.props.t('common:fnd-common-past-reviews')}
            />
            <Divider />
            <CardContent>{this.renderPublishedReviews()}</CardContent>
          </Card>
        </Stack>
        <RateUserView
          open={this.state.review !== undefined}
          reviewId={this.state.review?._id}
          reviewReceiver={this.state.review?.receiver ?? ''}
          rateContractor={!this.props.contractorReviews}
          onReviewSuccess={(rateData) => {
            this.onReviewSuccess(rateData)
          }}
          onClose={() =>
            this.setState({
              review: undefined
            })
          }
        />
      </>
    )
  }
}

export default withTranslation()(MyReviewsView)
