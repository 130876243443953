import {Typography, SxProps, Box, Grid, Badge, useTheme} from '@mui/material'
import {useTranslation} from 'react-i18next'
import BehaviorOption from './BehaviorOption'
import BehaviorOptions from './BehaviorOptions.type'
import browserStorage from 'web-common/services/BrowserStorage'

export interface SurveyBehaviorSelectorProps {
  onBehaviorSelection: (value: BehaviorOptions) => void
  behavior?: BehaviorOptions
  isError?: boolean
  wasUsed?: boolean
  sx?: SxProps
}

const validationOutlineOffset = 8
// Needed so that the absolutely positioned ring doesn't cover the content
const gridItemErrorStyles = {
  position: 'relative',
  zIndex: 2
}

function getRandomFirstOption(): BehaviorOptions {
  const randomValue = Math.random()
  return randomValue < 0.5 ? 'classic' : 'quick'
}

const firstOptionStorageKey = 'fnd_survey_behavior_1st_option'

function SurveyBehaviorSelector({
  onBehaviorSelection,
  behavior,
  isError,
  wasUsed,
  sx
}: SurveyBehaviorSelectorProps) {
  const {t} = useTranslation()
  const theme = useTheme()
  // The order of the options has to be random, but consistent for a given user
  // So that the order doesn't bias the selection. This might be removed if this becomes a permanent feature
  const savedFirstOption = browserStorage.get(firstOptionStorageKey)
  let firstOption = ''
  if (savedFirstOption) {
    firstOption = savedFirstOption
  } else {
    firstOption = getRandomFirstOption()
    browserStorage.set(firstOptionStorageKey, firstOption)
  }

  return (
    <Box sx={{...sx, textAlign: 'start'}}>
      <Typography
        variant="h6"
        sx={{
          marginBottom: '1rem',
          display: 'flex',
          gap: '4px',
          alignItems: 'center'
        }}
      >
        <Box component="span">{t('fnd-survey-behavior-title')}</Box>
        {!wasUsed && (
          <Badge
            color="primary"
            badgeContent={t('fnd-new-feature-badge')}
            sx={{
              '> .MuiBadge-badge': {
                position: 'static',
                transform: 'none',
                textTransform: 'uppercase',
                fontSize: '0.75rem'
              }
            }}
          />
        )}
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          position: 'relative',
          // switch order based on the random selection
          flexDirection: firstOption === 'quick' ? 'row-reverse' : 'row',
          '&:after': {
            content: isError ? "''" : undefined,
            position: 'absolute',
            // we need to do positive offsets on top / left
            // cause the grid container has a negative margin on those sides
            top: validationOutlineOffset,
            bottom: validationOutlineOffset * -1,
            left: validationOutlineOffset,
            right: validationOutlineOffset * -1,
            border: `1px solid ${theme.palette.error.main}`,
            borderRadius: 3 // uses the theme sizing
          }
        }}
      >
        <Grid xs={6} item sx={isError ? gridItemErrorStyles : undefined}>
          <BehaviorOption
            title={t('fnd-survey-behavior-classic-title')}
            description={t('fnd-survey-behavior-classic-description')}
            value="classic"
            onBehaviorSelection={onBehaviorSelection}
            selectedBehavior={behavior}
          />
        </Grid>
        <Grid xs={6} item sx={isError ? gridItemErrorStyles : undefined}>
          <BehaviorOption
            title={t('fnd-survey-behavior-quick-title')}
            description={t('fnd-survey-behavior-quick-description')}
            value="quick"
            onBehaviorSelection={onBehaviorSelection}
            selectedBehavior={behavior}
          />
        </Grid>
      </Grid>
      {isError && (
        <Typography color="error.main" variant="body2" sx={{mt: 2}}>
          {t('fnd-survey-behavior-error')}
        </Typography>
      )}
    </Box>
  )
}

export default SurveyBehaviorSelector
