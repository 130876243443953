import React from 'react'
import AppPage from 'web-common/utilities/AppPage'
import ContactUsView from 'web-common/components/contactus/ContactUsView'
import {Card, CardContent, Container} from '@mui/material'

interface ContactViewProps {}

interface ContactViewState {}

class ContactView extends AppPage<ContactViewProps, ContactViewState> {
  static path = '/contact'

  title = this.props.t('common:fnd-title-contact-us')

  render() {
    return (
      <Container maxWidth={'md'} sx={{marginTop: '2rem'}}>
        <Card variant={'outlined'}>
          <CardContent>
            <ContactUsView />
          </CardContent>
        </Card>
      </Container>
    )
  }
}

export default ContactView.exportDefault()
