import React, {ErrorInfo} from 'react'
import AppPage, {AppPageType} from 'web-common/utilities/AppPage'
import AppCrash from 'views/system/AppCrash'

interface ErrorBoundaryProps {
  children: any
}

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends AppPage<ErrorBoundaryProps, ErrorBoundaryState> {
  title: string = ''

  state: ErrorBoundaryState = {
    hasError: false
  }

  static getDerivedStateFromError(_: any) {
    return {hasError: true}
  }

  componentDidUpdate(
    prevProps: Readonly<AppPageType<ErrorBoundaryProps>>,
    prevState: Readonly<ErrorBoundaryState>,
    snapshot?: any
  ) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({hasError: false})
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log error
    console.log('App Error', error)
  }

  render() {
    if (this.state.hasError) {
      return <AppCrash />
    }
    return this.props.children
  }
}

export default ErrorBoundary.exportDefault()
