import {SurveyFactoryElementBaseProps} from 'web-common/components/survey/ui/elements/SurveyElementFactory'
import {SurveyElementDatepicker} from '@fnd/survey'
import 'react-day-picker/dist/style.css'
import CalendarWrapper from 'web-common/components/survey/ui/elements/helpers/CalendarWrapper'

export default function SurveyElementUICalendar({
  field,
  element
}: SurveyFactoryElementBaseProps<SurveyElementDatepicker>) {
  return <CalendarWrapper {...field} multiple={element.multiple} />
}
